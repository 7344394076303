<template>
    <!-- <LoadingComponent v-if="getLoading"></LoadingComponent> -->
    <div class="login-page">
        <div class="login-box">
            <div class="card card-outline card-primary">
                <div class="card-header text-center">
                    <img style="max-width: 150px;width: 100%;" src="/img/LOGOTIPOGOLDERGFUND.jpg">
                </div>
                <div class="card-body">
                    <p class="login-box-msg">Inicia sesión para comenzar</p>
                    <Form ref="form" @submit="onSubmit">
                        <div class="input-group mb-3">
                            <CompanySelect v-model="form.companyId" :name="'companyId'" :options="companyOptions"></CompanySelect>
                        </div>
                        <div class="input-group mb-3">
                            <Field v-model="form.email" type="text" class="form-control" name="email" placeholder="Correo"
                                rules="required|email" />
                            <ErrorMessage name="email" class="input-error"></ErrorMessage>
                        </div>
                        <div class="input-group mb-3">
                            <Field v-model="form.password" type="password" class="form-control" name="password" placeholder="Password"
                                rules="required|min:6" />
                            <ErrorMessage name="password" class="input-error"></ErrorMessage>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="submit" class="btn btn-success btn-block">Iniciar sesión</button>
                            </div>

                        </div>
                    </Form>

                    <!-- <p class="mt-3">
                        <a href="forgot-password.html">Olvidé mi contraseña</a>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as helper from '@/helpers';
// TODO - Revisar como agregar como modulo global useToast
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Swal from 'sweetalert2';
// import { useStore } from 'pinia';
import { storeToRefs, mapState, mapActions } from 'pinia';
import { useAuthStore  } from '@/pinia/auth.module';
import { useCompaniesStore } from '@/pinia/companies.module';

import CompanySelect from '@/Components/Selects/SelectField.vue';

// const auth = useAuthStore();
// const { fetchLogin } = storeToRefs(auth);

// const store = useStore();
export default {
    components: {
        CompanySelect,
    },
    mixins: [],
    data() {
        return {
            form: {
                companyId: 0,
                email: "",
                password: ""
            }
        }
    },
    watch: {},
    computed: {
        ...mapState(useAuthStore, ['isAuthenticated']),
        ...mapState(useCompaniesStore, ['getCompanies']),
        companyOptions() {
            return helper.mapOptions(this.getCompanies);
        },
    },
    methods: {
        ...mapActions(useAuthStore, ['fetchLogin']),
        ...mapActions(useCompaniesStore, ['fetchCompanies']),
        async onSubmit() {
            try {
                // Llamar a la acción de Vuex para iniciar sesión
                // pinia.auth.fetchLogin(this.form);
                // await this.fetchLogin(this.form);
                // pinia.useStore('auth').fetchLogin(this.form);
                // pinia.useAuthStore().fetchLogin(this.form);
                // console.log('this.$store', this.$store);
                // console.log('this.$store.state', this.$store.state);
                this.fetchLogin(this.form);
                // store.fetchLogin(this.form);
            } catch (error) {
                console.error('Error al iniciar sesión:', error);
            }
        },
    },
    created() {
        this.fetchCompanies();
    },
    mounted() {
        return this.isAuthenticated;
        // const authStore = useAuthStore();
        // const userData = {
        // // Datos de inicio de sesión del usuario
        // };
        // authStore.fetchLogin(userData);
    },
};

</script>

<style>
.content-wrapper.login, .content-wrapper.NotFound {
  margin-left: 0 !important;
}
</style>