<template>
  <div class="d-flex justify-content-between">
    <h3 class="card-title">Lista de Cuentas</h3>
    <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg" @click="openCreationForm">
      {{ $getLocaleMessage('buttons', 'addNewRecord') }}
    </PrimaryButton>
  </div>


  <div class="card-body">
    <div class="d-flex">
      <input type="text" class="form-control" placeholder="Buscar Cuentas..." v-model="searchString" />
    </div>

    <div class="position-relative col-sm-12" style="margin:auto;">
      <AccountsTable :list="filteredAccounts" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
        @editItem="openEditionForm"></AccountsTable>
      <Pagination :list="filteredAccounts" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
        @currentPage="currentPage = $event"></Pagination>

      <Modal>
        <AddForm v-if="modalContent === 1 && editing === false" @close-modal="modalStatus"></AddForm>
        <EditForm v-else-if="modalContent === 2 && editing === true" :selected-record="selectedRecord"
          @close-modal="modalStatus"></EditForm>
      </Modal>

    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAccountsStore } from '@/pinia/accounts.module';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useBanksStore } from '@/pinia/banks.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import AccountsTable from '@/Components/Tables/AccountsTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddForm from '@/Components/Forms/Accounts/Add.vue';
import EditForm from '@/Components/Forms/Accounts/Edit.vue';

export default {
  components: {
    PrimaryButton,
    AccountsTable,
    Pagination,
    Modal,
    AddForm,
    EditForm,
  },
  data() {
    return {
      modalContent: 0,
      editing: false,
      selectedRecord: {},
      searchString: '',
    }
  },
  computed: {
    ...mapState(useFinancialFundersStore, ['getActiveFinancialFunders']),
    ...mapState(useAccountsStore, ['getAccounts', 'filterAccountsBySearchString']),
    filteredAccounts() {
      return this.filterAccountsBySearchString(this.searchString);
    },
  },
  methods: {
    ...mapActions(useAccountsStore, ['fetchAccountsByCompany']),
    ...mapActions(useFinancialFundersStore, ['fetchActiveFinancialFundersByCompany']),
    ...mapActions(useBanksStore, ['fetchBanks']),
    ...mapActions(useStatusStore, ['fetchStatus']),
    modalStatus() {
      $('#modal-lg').modal('hide');
    },
    openCreationForm() {
      this.modalContent = 1;
      this.editing = false;
    },
    openEditionForm(selectedItem) {
      this.selectedRecord = { ...selectedItem }; // Clonar profundamente el objeto reactivo
      this.modalContent = 2;
      this.editing = true;
    },
  },
  created() {
    this.fetchAccountsByCompany();
    this.fetchActiveFinancialFundersByCompany();
    this.fetchBanks();
  },
  mounted() {
    $('#modal-lg').on('hidden.bs.modal', () => {
      this.modalContent = 0;
      this.editing = false;
    });
  },
}
</script>