<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Test Page</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Test Page</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Select2 Component</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg" @click="modalContent = 1; editing = false">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex"></div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12 mb-4" style="margin:auto;">
                                <Modal>
                                    <AddTestForm v-if="modalContent == 1 && editing == false"></AddTestForm>
                                </Modal>

                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddTestForm from '@/Components/Forms/Test/Add.vue';

export default {
    components: { Modal, AddTestForm},
    data: () => {
        return {
            modalContent: 0,
            editing: false,
        }
    },
    computed: {},
    methods: {}
}
</script>