<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination">

            <li @click="getPreviousPage()" :class="{ 'disabled': currentPage === 1 }" class="page-item"> <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>

            <li v-for="page in totalPages" @click="setCurrentPage(page)" :class="isActive(page)" class="page-item"><a class="page-link" href="#">{{
                page }}</a>
            </li>

            <li @click="getNextPage()" :class="{ 'disabled': currentPage === totalPages}" class="page-item"> <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>

        </ul>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            childCurrentPage: this.currentPage,
        }
    },
    emits: ['current-page'],
    props: {
        totalItems: {
            type: Number,
            default: () => 0,
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.itemsPerPage);
        }
    },
    methods: {
        getPreviousPage() {
            if (this.childCurrentPage === 1) return;
            this.childCurrentPage--;
            this.$emit('current-page', this.childCurrentPage);
        },
        getNextPage() {
            if (this.childCurrentPage === this.totalPages) return;
            this.childCurrentPage++;
            this.$emit('current-page', this.childCurrentPage);
        },
        isActive(pageNumber){
            if (pageNumber == this.currentPage) {
                return 'active';
            }
        },
        setCurrentPage(newPage) {
            if (newPage === this.childCurrentPage) return;
            this.childCurrentPage = newPage;
            this.$emit('current-page', newPage);
        }
    },
};
</script>