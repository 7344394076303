<template>
  <Field v-model="selectedValue" :rules="customValidation" :name="name" as="select" @update:modelValue="emitSelectedValue" class="form-control" :disabled="isDisabled">
    <option value="" disabled>Seleccione una opción</option>
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </Field>
  <ErrorMessage :name="name" class="input-error"></ErrorMessage>
</template>

<script>
import { ref, watch } from "vue";
import { Field } from "vee-validate";

export default {
  components: {
    Field,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    customValidation: {
      type: [String, Function],
      default: 'required'
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    modelValue: [Number, String], // Agregamos la prop modelValue
  },
  emits: ["update:modelValue", "update"],
  setup(props, { emit }) {
    const selectedValue = ref(props.modelValue || ""); // Inicializamos con el valor de modelValue

    // Watcher para actualizar selectedValue cuando modelValue cambie
    watch(() => props.modelValue, (newValue) => {
      selectedValue.value = newValue;
    });

    return {
      selectedValue,
      emitSelectedValue() {
        emit("update:modelValue", selectedValue.value);
        emit("update", selectedValue.value); // We can use this event in the select implementations as @update="" instead of @update:modelValue, so we can execute something else
      },
    };
  },
};
</script>
