<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ formTitle }} Calificación
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Información de la calificación</h3>
                </div>
                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="referenceNumber1">Folio de Calificación:</label>
                        <Field v-model="referenceNumberCreditScore" type="text" class="form-control" name="referenceNumber1" disabled></Field>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="referenceNumber2">Folio de Solicitud:</label>
                        <Field v-model="referenceNumberLoanApplication" type="text" class="form-control" name="referenceNumber2" disabled></Field>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="referenceNumber3">Folio de Prospecto:</label>
                        <Field v-model="referenceNumberProspectus" type="text" class="form-control" name="referenceNumber3" disabled></Field>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="personTypeName">Tipo de Persona:</label>
                        <Field v-model="personType" type="text" class="form-control" name="personTypeName" disabled></Field>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="branchOfficeName">Nombre de Matriz y/o Sucursal {{  }}:</label>
                        <Field v-model="branchOfficeName" type="text" class="form-control" name="branchOfficeName" disabled></Field>
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Cuestionario</h3>
                </div>
                <div class="row card-body">
                    <div v-if="questions.length > 0">
                        <ul v-for="(question, index) in questions" :key="index">
                            <li>
                                {{question.questionText}}
                                <AnswersSelect v-model="selectedAnswers[index].answerId" :name="'answerId' + index" :options="getAnswersOptions(question.answers)" @update="updateAnswer(index, question.id, $event)"></AnswersSelect>
                            </li>
                        </ul>
                    </div>
                    <div v-if="questions.length > 0" class="col-sm-12">
                        <label for="score">Score Total:</label>
                        <p>{{ totalScorePercent}}</p>
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Perfil de riesgo</h3>
                </div>
                <div class="card-body">
                    <table v-if="getQuestions !== null && getQuestions.length > 0" class="table table-hover no-overflow">
                        <thead>
                            <tr>
                                <th>NIVEL</th>
                                <th>% Autorizado</th>
                                <th>Observaciones</th>
                                <th>POR EXCEPCIÓN</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="sorting_1 dtr-control">{{getProfile.name}}</td>
                                <td>{{getProfile.percentValue}}</td>
                                <td>{{getProfile.remarks}}</td>
                                <td>{{getProfile.exception}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Monto autorizado</h3>
                </div>
                <div class="row card-body">
                    <div v-if="getQuestions !== null && getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="creditAmount">Monto de Crédito solicitado</label>
                        <p>{{ $filters.formatNumber(getLoanApplication.quotation.creditAmount) }}</p>
                    </div>
                    <div v-if="getQuestions !== null && getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="creditAmount">Monto de Crédito autorizado</label>
                        <p>{{authorizedAmount}}</p>
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Monto autorizado por excepción</h3>
                </div>
                <div class="row card-body">
                    <div v-if="getQuestions !== null && getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="percent">Porcentaje</label>
                        <Percent v-model="selectedPercentId" :name="'percent'" :options="exceptionPercentOptions" :customValidation="notRequired"></Percent>
                    </div>
                    <div v-if="getQuestions !== null && getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="creditAmount">Monto de Crédito autorizado</label>
                        <p>{{authorizedAmount}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText}}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ submitButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useCreditScoresStore } from '@/pinia/creditScores.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import AnswersSelect from "@/Components/Selects/SelectField.vue";
import Percent from "@/Components/Selects/SelectField.vue";

import CreditScore from '@/Models/CreditScores/CreditScore.js';
import ScoreQuestionAnswer from '@/Models/CreditScores/ScoreQuestionAnswer.js';

export default {
    components: {
        PrimaryButton,
        AnswersSelect,
        Percent,
    },
    mixins: [],
    data() {
        return {
            creditScore: new CreditScore(),
            questions: [],
            selectedAnswers: [],
            selectedPercentId: null,
            referenceNumberCreditScore: '',
            referenceNumberLoanApplication: '',
            referenceNumberProspectus: '',
            personType: '',
            branchOfficeName: '',
        }
    },
    emits: ['close-modal', 'show-table'],
    props: {
        selectedItem: {
            type: Object,
            default: () => new CreditScore({}),
        },
    },
    computed: {
        ...mapState(useCreditScoresStore, ['getCreditScore', 'getCreditProfiles', 'getExceptionPercents']),
        formTitle() {
            return this.$getLocaleMessage('buttons', 'updating');
        },
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel');
        },
        submitButtonText() {
            return this.$getLocaleMessage('buttons', 'update');
        },
        exceptionPercentOptions() {
            return (this.getExceptionPercents) ? helper.mapOptions(this.getExceptionPercents, 'id', 'textValue') : [];
        },
        getTotalScore() {
            try {
                if (this.selectedAnswers.length === 0) return 0;
                return this.selectedAnswers.reduce((total, {questionId, answerId}) => {
                    const question = this.questions.find((q) => q.id == questionId);
                    const answer = question.answers.find((a) => a.id == answerId);
                    return total + (answer ? answer.numberValue : 0);
                }, 0);
            } catch (error) {
                console.error(error);
            }
        },
        totalScorePercent() {
            return this.$filters.formatPercent2(this.getTotalScore);
        },
        getProfile() {
            const num1 = 70;
            const num2 = 85;
            let total = this.getTotalScore;
            if (total >= num2) {
                this.creditScore.creditProfileId = this.getCreditProfiles[0].id;
                return this.getCreditProfiles[0]
            } else if (total >= num1 && total < num2) {
                this.creditScore.creditProfileId = this.getCreditProfiles[1].id;
                return this.getCreditProfiles[1]
            } else if (total < num1) {
                this.creditScore.creditProfileId = this.getCreditProfiles[2].id;
                return this.getCreditProfiles[2]
            }
        },
        calculateAuthorizedAmount() {
            const score = (this.getTotalScore > 100) ? 100 : this.getTotalScore;
            const creditAmount = this.creditScore.loanApplication.quotation.creditAmount;
            if (this.selectedPercentId !== null) {
                const percent = this.getExceptionPercents.find(p => p.id === this.selectedPercentId);
                this.creditScore.exceptionPercentId = percent.id;
                return (creditAmount * (percent.numberValue / 100));
            }
            this.creditScore.exceptionPercentId = null;
            return (creditAmount * (score / 100));
        },
        authorizedAmount() {
            if (this.calculateAuthorizedAmount) {
                return this.$filters.formatNumber(this.calculateAuthorizedAmount);
            } else {
                return this.$filters.formatNumber(0);
            }
        },
        getQuestions() {
            return this.creditScore.questions;
        },
        getLoanApplication() {
            return this.creditScore.loanApplication;
        },
        getSelectedPercent() {
            if (this.creditScore.exceptionPercentId !== null) {
                return this.creditScore.exceptionPercentId;
            }
            return;
        },
        getSelectedAnswers() {
            return this.getCreditScore.responses.map((response) => {
                return new ScoreQuestionAnswer({
                    questionId: response.questionId,
                    answerId: response.answerId,
                });
            });
        },
    },
    methods: {
        ...mapActions(useCreditScoresStore, ['fetchCreditScoreById','updateCreditScore']),
        onSubmit() {
            this.updateCreditScore(this.creditScore);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        getAnswersOptions(answers) {
            return (answers) ? helper.mapOptions(answers, 'id', 'answerText') : [];
        },
        updateAnswer(index, questionId, answerId) {
            this.selectedAnswers[index].answerId = Number(answerId);
            this.updateCreditScores();
        },
        updateCreditScores() {
            this.creditScore.selectedAnswers = this.selectedAnswers;
            this.creditScore.score = this.getTotalScore;
        },
        setNewPercent(percentId) {
            this.selectedPercentId = percentId;
        },
        // validations:
        notRequired(value) {
            if (!value) return true;
            return true;
        },
    },
    watch: {
        'getCreditScore': {
            handler(newValue) {
                if (newValue?.id) {
                    this.creditScore = newValue;
                    this.referenceNumberCreditScore = newValue.referenceNumber;
                    this.referenceNumberLoanApplication = newValue.loanApplication.referenceNumber;
                    this.referenceNumberProspectus = newValue.loanApplication.prospectus.referenceNumber;
                    this.personType = newValue.personType.name;
                    this.branchOfficeName = newValue.loanApplication.prospectus.branchOffice.name;
                    this.questions = newValue.questions;
                    this.selectedAnswers = this.getSelectedAnswers;
                    this.creditScore.selectedAnswers = this.selectedAnswers;
                    this.selectedPercentId = this.creditScore.exceptionPercentId;
                }
            },
            immediate: true,
        },
    },
    created() { 
        this.fetchCreditScoreById(this.selectedItem.id);
    },
    mounted() {},
}
</script>