
export default class Question {
  constructor({
    id = 0, 
    dataValue = '', 
    group = '', 
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.dataValue = dataValue || '';
    this.group = group || '';
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
