export default class CertificateOfAcknowledgment {
    constructor({
        id = 0,
        contextId = 2,
        loanApplicationId = null,
        statusId = 2,
        referenceNumber = null,
        respondentFullName = null,
        respondentSignature = null,
        address = null,
        questions = null,
        loanApplication = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date(),
    } = {}) {
        this.id = id;
        this.contextId = contextId;
        this.loanApplicationId = loanApplicationId;
        this.statusId = statusId;
        this.referenceNumber = referenceNumber;
        this.respondentFullName = respondentFullName;
        this.respondentSignature = respondentSignature;
        this.address = address;
        this.questions = questions;
        this.loanApplication = loanApplication;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}