<template>
<Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'update') }} documento <strong>{{ getDocument.documentName  }}</strong>
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <div class="form-group">
                        <label for="name">Nombre:</label>
                        <Field v-model="document.documentName" type="text" class="form-control" name="name"
                            placeholder="Nombre" rules="required"/>
                        <ErrorMessage name="name" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="document.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { documentsStore } from '@/pinia/documents.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from "@/Components/PrimaryButton.vue";
import StatusSelect from '@/Components/Selects/SelectField.vue';

import Document from '@/Models/Document';

export default {
    components: {
        PrimaryButton,
        StatusSelect,
    },
    data() {
        return {
            document: new Document(),
        };
    },
    props: {
        selectedRecord: {
            type: Object,
            default: () => {}
        },
    },
    emits: ['close-modal'],
    computed: {
        ...mapState(documentsStore, ['getDocument']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(5));
        },
    },
    methods: {
        ...mapActions(documentsStore, ['setSelectedDocument', 'updateDocument']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        onSubmit() {
            this.updateDocument(this.document);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
    },
    watch: {
        selectedRecord: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.document = newValue;
                }
            }
        }
    },
    created() {
        this.setSelectedDocument(this.selectedRecord.id);
        this.setSelectedStatus(this.selectedRecord.statusId);
    }
};
</script>