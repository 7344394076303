import { useAuthStore } from '@/pinia/auth.module';

// TODO: Add the missing properties to the list (check the BranchOffice service model properties)
export default class BranchOffice {
  constructor({
    id = 0,
    stateId = 0,
    municipalityId = 0,
    statusId = 0,
    name = '',
    identifier = null,
    code = null,
    address = '',
    rfc = '',
    phone = '',
    email = '',
    legalName = '',
    companyRel = null,
    userRel = null,
    statusRel = {},
    stateRel = null,
    createdAt = new Date(),
    updatedAt = new Date(),
    userAuth = useAuthStore(),
  } = {}) {
    this.id = id;
    this.stateId = stateId;
    this.municipalityId = municipalityId;
    this.companyId = userAuth.user.companyId;
    this.statusId = statusId;
    this.name = name;
    this.identifier = identifier;
    this.code = code;
    this.address = address;
    this.rfc = rfc;
    this.phone = phone;
    this.email = email;
    this.legalName = legalName;
    this.companyRel = companyRel;
    this.userRel = userRel;
    this.statusRel = statusRel;
    this.stateRel = stateRel;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
