export default class BureauInquiry {
    constructor({
        id = 0,
        loanApplicationId = null,
        statusId = null,
        referenceNumber = '',
        placeIssuance = null,
        dateIssuance = null,
        signedAddress = null,
        signedDate = null,
        officerFullName = null,
        personAuthorizingFullName = null,
        companyName = null,
        inquiryDate = null,
        loanApplication = null,
        status = null,
        createdAt = null,
        updatedAt = null
    } = {}) {
        this.id = id;
        this.loanApplicationId = loanApplicationId;
        this.statusId = statusId;
        this.referenceNumber = referenceNumber;
        this.placeIssuance = placeIssuance;
        this.dateIssuance = dateIssuance;
        this.signedAddress = signedAddress;
        this.signedDate = signedDate;
        this.officerFullName = officerFullName;
        this.personAuthorizingFullName = personAuthorizingFullName;
        this.companyName = companyName;
        this.inquiryDate = inquiryDate;
        this.loanApplication = loanApplication;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}