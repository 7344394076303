export default class IndividualLoanApplicationData {
  constructor({
    loanApplication = {},
    branchOffice = {},
    monthlyIncomes = {},
    monthlyExpenses = {},
    economicDependents = {},
    directDebit = {},
    financialInformation = {},
    applicantLoanReferences = {},
    productDetails = {},
    interview = {},
    answers = []
  }) {
    this.loanApplication = loanApplication || {};
    this.branchOffice = branchOffice || {};
    this.monthlyIncomes = monthlyIncomes || {};
    this.monthlyExpenses = monthlyExpenses || {};
    this.economicDependents = economicDependents || {};
    this.directDebit = directDebit || {};
    this.financialInformation = financialInformation || {};
    this.applicantLoanReferences = applicantLoanReferences || {};
    this.productDetails = productDetails || {};
    this.interview = interview || {};
    this.answers = answers || [];
  }
}
