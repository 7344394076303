<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('buttons', 'updating') }} Banco {{ this.bank.referenceNumber }}
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="form-group">
                    <label for="bankName">Nombre de Entidad Bancaria:</label>
                    <Field v-model="bank.name" type="text" class="form-control" name="bankName" placeholder="Nombre"
                        rules="required|max:100" />
                    <ErrorMessage name="bankName" class="input-error"></ErrorMessage>
                </div>
            </div>

            <div class="form-group">
                <div class="form-group">
                    <label for="headquartersLocation">Dirección:</label>
                    <Field v-model="bank.headquartersLocation" type="text" class="form-control" rules="max:100"
                        name="headquartersLocation" placeholder="Dirección" />
                </div>
            </div>

            <div class="form-group">
                <div class="form-group">
                    <label for="foundedDate">Fecha de fundación:</label>
                    <Field v-model="bank.foundedDate" type="date" class="form-control" name="foundedDate" />
                </div>
            </div>

            <div class="form-group">
                <div class="form-group">
                    <label for="website">Página web:</label>
                    <Field v-model="bank.website" type="text" class="form-control" name="website" rules="max:100"
                        placeholder="Página web" />
                </div>
            </div>

            <div class="form-group">
                <div class="form-group">
                    <label for="contactNumber">Número de contacto:</label>
                    <Field v-model="bank.contactNumber" type="text" class="form-control" name="contactNumber" rules="max:100"
                        placeholder="Número de contacto" />
                </div>
            </div>

        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons',
                'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useBanksStore } from '@/pinia/banks.module';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Bank from '@/Models/Bank';

export default {
    components: {
        PrimaryButton,
    },
    mixins: [],
    data: () => ({
        form: {},
        // bankModel: new Bank({}),
    }),
    props: {
        bank: {
            type: Bank,
            default: () => new Bank(),
        },
    },
    emits: ['closeModal'],
    computed: {
        ...mapState(useBanksStore, ['banks']),
    },
    watch: {
        bank: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.bank.foundedDate = this.formatDate(newValue.foundedDate)
                }
            }
        }
    },
    methods: {
        ...mapActions(useBanksStore, ['updateBank']),
        formatDate(date) {
            return this.$filters.formatDate(date, 'yyyy-MM-dd');
        },
        onSubmit() {
            this.updateBank(this.bank);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
    },
}
</script>