<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Clientes</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Clientes</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Clientes</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                                    @click="updateClientsList">
                                    {{ $getLocaleMessage('buttons', 'update') }} lista de Clientes
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex"></div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <ClientsTable 
                                    :list="this.getClients" 
                                    :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage">
                                </ClientsTable>
                                <Pagination 
                                    :list="this.getClients" 
                                    :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage" 
                                    @current-page="currentPage = $event">
                                </Pagination>

                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';
import ClientsTable from '@/Components/Tables/ClientsTable.vue';

import { mapState, mapActions } from 'pinia';
import { useClientsStore } from '@/pinia/clients.module';

export default {
    components: {
        PrimaryButton,
        ClientsTable,
    },
    data() {
        return {
            editing: false,
            currentPage: 1,
            itemsPerPage: 10,
        }
    },
    computed: {
        ...mapState(useClientsStore, ['getClients']),
    },
    methods: {
        ...mapActions(useClientsStore, ['fetchClients', 'updateClientsList']),
    },
    created() {
        this.fetchClients();
    },
}
</script>