<template>
    <div class="modal-header">
        <h4 class="modal-title">
            Seleccione un expediente
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card-body">
            <table class="table table-hover text-nowrap">
                <!-- <colgroup>
                    <col span="1" style="width: 33.33%;">
                    <col span="1" style="width: 33.33%;">
                    <col span="1" style="width: 33.33%;">
                </colgroup> -->
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Estatus</th>
                        <th>Nombre</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="getDataPage().length">
                        <tr v-for="item in getDataPage()" :key="item.id" @click="selectFinancialRecord(item)">
                            <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                            <td>{{ item.status.name }}</td>
                            <td>{{ item.name }}</td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td colspan="3" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {
        }
    },
    emits: ['back-to-form', 'selected-item'],
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getCompanies() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            if (!this.list || this.list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        selectFinancialRecord(financialRecord) {
            this.$emit('back-to-form');
            this.$emit('selected-item', financialRecord);
        },
    },
    watch: {
        updateSelectedFinancialRecord(newVal) {
            this.selectFinancialRecord = newVal;
        }
    },
    created() {
        this.getDataPage();
    }
}
</script>