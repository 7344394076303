<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th @click="sortBy('financialRecordDocumentId')">
                    ID
                    <span v-if="sortKey === 'financialRecordDocumentId'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('financialRecord.referenceNumber')">
                    Folio de expediente
                    <span v-if="sortKey === 'financialRecord.referenceNumber'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('document.documentName')">
                    Nombre de documento
                    <span v-if="sortKey === 'document.documentName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('remarks')">
                    Observaciones
                    <span v-if="sortKey === 'remarks'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('isRequired')">
                    Condición
                    <span v-if="sortKey === 'isRequired'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th @click="sortBy('status.name')">
                    Estatus
                    <span v-if="sortKey === 'status.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                </th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in sortedData" :key="item.financialRecordDocumentId" v-if="sortedData.length != 0">
                <td class="sorting_1 dtr-control">{{ item.financialRecordDocumentId }}</td>
                <td>{{ item.financialRecord.referenceNumber }}</td>
                <td>{{ item.document.documentName }}</td>
                <td>{{ item.remarks }}</td>
                <td>{{ getCondition(item.isRequired) }}</td>
                <td>{{ item.status.name }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-lg"
                        @click.prevent="editItem(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="7" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
            sortKey: '',
            sortOrder: 1, // 1 for ascending, -1 for descending
        }
    },
    emits: ['click-on-edit-btn', 'click-on-delete-btn'],
    props: {
        list: {
            type: Array,
            default: () => [],
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        sortedData() {
            let sortedList = [...this.list];
            if (this.sortKey) {
                sortedList.sort((a, b) => {
                    let aValue = this.getNestedValue(a, this.sortKey);
                    let bValue = this.getNestedValue(b, this.sortKey);

                    if (aValue < bValue) return -1 * this.sortOrder;
                    if (aValue > bValue) return 1 * this.sortOrder;
                    return 0;
                });
            }
            return this.paginate(sortedList);
        },
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1; // Toggle the sort order
            } else {
                this.sortOrder = 1; // Reset to ascending if a new column is sorted
            }
            this.sortKey = key;
        },
        getNestedValue(obj, key) {
            return key.split('.').reduce((o, i) => o[i], obj);
        },
        paginate(list) {
            if (!list || list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            return list.slice(start, start + this.itemsPerPage);
        },
        editItem(item) {
            this.$emit('click-on-edit-btn', item);
        },
        deleteItem(item) {
            this.$emit('click-on-delete-btn', item);
        },
        getCondition(item) {
            return item ? 'Obligatorio' : 'Opcional';
        }
    },
    created() {
        //this.getDataPage();
    }
}
</script>