<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Productos</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Productos</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Productos</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                                    @click="openCreationForm">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Buscar productos..."
                                    v-model="searchString" />
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12 mb-4" style="margin:auto;">
                                <ProductsTable :list="filteredProducts" :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage" @click-on-edit-btn="setProduct">
                                </ProductsTable>

                                <Pagination :list="filteredProducts" :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage" @current-page="currentPage = $event">
                                </Pagination>

                                <Modal>
                                    <AddProductForm v-if="modalContent === 1 && editing === false"
                                        @close-modal="modalStatus" @change-modal-content="updateModalContent">
                                    </AddProductForm>
                                    <EditProductForm v-else-if="modalContent === 2 && editing === true"
                                        :selectedRecord="selectedRecord" @close-modal="modalStatus"
                                        @change-modal-content="updateModalContent">
                                    </EditProductForm>

                                    <AddReferenceRateForm v-else-if="modalContent === 3" :isEdition="editing"
                                        @change-modal-content="updateModalContent">
                                    </AddReferenceRateForm>
                                    <AddPenaltyChargeForm v-else-if="modalContent === 4" :isEdition="editing"
                                        @change-modal-content="updateModalContent">
                                    </AddPenaltyChargeForm>
                                    <AddGuaranteeForm v-else-if="modalContent === 5" :isEdition="editing"
                                        :companyId="companyId" @change-modal-content="updateModalContent">
                                    </AddGuaranteeForm>
                                </Modal>

                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useProductsStore } from '@/pinia/products.module';
import { useTaxesStore } from '@/pinia/taxes.module';
import { useCurrencyStore } from '@/pinia/currency.module';
import { useStatusStore } from '@/pinia/status.module';

import ProductsTable from '@/Components/Tables/ProductsTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddProductForm from '@/Components/Forms/Products/Add.vue';
import EditProductForm from '@/Components/Forms/Products/Edit.vue';
import AddReferenceRateForm from '@/Components/Forms/Products/AddReferenceRate.vue';
import AddPenaltyChargeForm from '@/Components/Forms/Products/AddPenaltyCharge.vue';
import AddGuaranteeForm from '@/Components/Forms/Products/AddGuarantee.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        ProductsTable,
        Pagination,
        Modal,
        AddProductForm,
        EditProductForm,
        AddReferenceRateForm,
        AddPenaltyChargeForm,
        AddGuaranteeForm,
        PrimaryButton,
    },
    data() {
        return {
            editing: false,
            currentPage: 1,
            itemsPerPage: 10,
            modalContent: 0,
            companyId: 1,
            selectedRecord: {},
            searchString: '',
        }
    },
    computed: {
        ...mapState(useProductsStore, [
            'getProducts',
            'getSelectedProduct',
            'getGuarantees',
            'filterProductsBySearchString',
        ]),
        filteredProducts() {
            return this.filterProductsBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices', 'setSelectedActiveBranchOffice']),
        ...mapActions(useProductsStore, [
            'fetchProducts',

            'fetchActiveProductTypes',
            'setSelectedActiveProductType',

            'fetchActiveRateTypes',
            'setSelectedActiveRateType',

            'setSelectedActiveReferenceRate',

            'fetchActivePenaltyChargeRates',
            'setSelectedActivePenaltyChargeRate',

            'setSelectedActivePenaltyCharge',

            'fetchActiveGuarantees',
            'setSelectedActiveGuarantee',

            'fetchActiveLoanTerms',
            'setSelectedActiveLoanTerm',
        ]),
        ...mapActions(useTaxesStore, ['setSelectedActiveTax']),
        ...mapActions(useCurrencyStore, ['fetchActiveCurrencies', 'setSelectedActiveCurrency']),
        ...mapActions(useStatusStore, ['fetchStatus', 'setSelectedStatus']),
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        openCreationForm() {
            this.modalContent = 1;
            this.editing = false;
        },
        openEditionForm() {
            this.modalContent = 2;
            this.editing = true;
        },
        setProduct(product) {
            this.selectedRecord = product;
            this.modalContent = 2;
            this.editing = true;
        },
        updateModalContent(value) {
            this.modalContent = value;
        },
    },
    created() {
        this.fetchActiveBranchOffices();
        this.fetchProducts();
        this.fetchActiveProductTypes();
        this.fetchActiveRateTypes();
        this.fetchActivePenaltyChargeRates();
        this.fetchActiveGuarantees();
        this.fetchActiveLoanTerms();
        this.fetchActiveCurrencies();
        this.fetchStatus();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            this.setSelectedActiveBranchOffice();
            this.setSelectedActiveProductType();
            this.setSelectedActiveRateType();
            this.setSelectedActiveReferenceRate();
            this.setSelectedActivePenaltyChargeRate();
            this.setSelectedActivePenaltyCharge();
            this.setSelectedActiveGuarantee();
            this.setSelectedActiveLoanTerm();
            this.setSelectedActiveTax();
            this.setSelectedActiveCurrency();
            this.setSelectedStatus();
        });
    }
}
</script>