<template>
    <div class="d-flex justify-content-between">
        <h3 class="card-title">Lista de Beneficiarios</h3>
        <div class="d-flex">
            <PrimaryButton class="btn btn-primary mr-3" data-toggle="modal" :data-target="modalDataTarget"
                @click="openLoanApplicationTable">
                Agregar Beneficiario
            </PrimaryButton>
        </div>
    </div>

    <div class="d-flex">
        <input type="text" class="form-control" placeholder="Buscar Beneficiarios..."
            v-model="searchString" />
    </div>
    <!-- /.d-flex -->

    <div class="position-relative col-sm-12">

        <BeneficiariesTable :list="filteredBeneficiaries" :dataTarget="modalDataTarget" :currentPage="currentPage"
            :itemsPerPage="itemsPerPage" @click-on-edit-btn="openEditionForm">
        </BeneficiariesTable>
        <Pagination :totalItems="filteredBeneficiaries.length" :currentPage="currentPage" @current-page="currentPage = $event">
        </Pagination>

        <Modal :modalId="modalId" :customClass="modalId">

            <div v-if="modalContent === 3">
                <LoanApplicationsMinTable :list="getMixedLoanApplications" @selected-item="setSelectedLoanApplication">
                </LoanApplicationsMinTable>
                <Pagination :totalItems="getMixedLoanApplications.length" :currentPage="currentPage" @current-page="currentPage = $event">
                </Pagination>
            </div>

            <PFBeneficiaryForm v-if="modalContent == 1 && !editing" 
                :initialData="initialIndividualBeneficiary"
                :onSubmit="createIndividualBeneficiaryRecord" 
                :formTitle="createPFFormTitle" 
                :submitButtonText="createButtonText" 
                :statusValues="getBeneficiaryStatus"
                :notBeneficiary="false" 
                @closeModal="modalStatus"
            ></PFBeneficiaryForm>
            <PMBeneficiaryForm v-if="modalContent == 2 && !editing" 
                :initialData="initialCorporateBeneficiary"
                :onSubmit="createCorporateBeneficiaryRecord" 
                :formTitle="createPMFormTitle" 
                :submitButtonText="createButtonText" 
                :statusValues="getBeneficiaryStatus"
                :notBeneficiary="false" 
                @closeModal="modalStatus"
            >
            </PMBeneficiaryForm>

            <PFBeneficiaryForm v-if="modalContent == 1 && editing" 
                :initialData="getIndividualBeneficiaryFromStore"
                :fetchById="individualBeneficiaryById"
                :onSubmit="updateIndividualBeneficiaryRecord"
                :formTitle="updatePFFormTitle" 
                :submitButtonText="updateButtonText" 
                :statusValues="getBeneficiaryStatus"
                :notBeneficiary="false" 
                @closeModal="modalStatus">
            </PFBeneficiaryForm>
            <PMBeneficiaryForm v-if="modalContent == 2 && editing" 
                :initialData="getCorporateBeneficiaryFromStore"
                :fetchById="corporateBeneficiaryById"
                :onSubmit="updateCorporateBeneficiaryRecord" 
                :formTitle="updatePMFormTitle" 
                :submitButtonText="updateButtonText" 
                :statusValues="getBeneficiaryStatus"
                :notBeneficiary="false" 
                @closeModal="modalStatus"
            >
            </PMBeneficiaryForm>

        </Modal>

    </div>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBeneficiaryStore } from '@/pinia/Beneficiaries/BeneficiaryStore.module';
import { useIndividualBeneficiaryStore } from '@/pinia/Beneficiaries/IndividualBeneficiaryStore.module';
import { useCorporateBeneficiaryStore } from '@/pinia/Beneficiaries/CorporateBeneficiaryStore.module';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import BeneficiariesTable from '@/Components/Tables/BeneficiariesTable.vue';
import LoanApplicationsMinTable from '@/Components/Tables/LoanApplicationsMinTable.vue';
import Pagination from '@/Components/Bootstrap/Paginationv2.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import PFBeneficiaryForm from '@/Components/Forms/LoanApplications/PLD/IdentificationPFDetails.vue';
import PMBeneficiaryForm from '@/Components/Forms/LoanApplications/PLD/IdentificationPMDetails.vue';

import IndividualBeneficiary from '@/Models/Beneficiaries/IndividualBeneficiary';
import CorporateBeneficiary from '@/Models/Beneficiaries/CorporateBeneficiary';

export default {
    components: {
        PrimaryButton,
        BeneficiariesTable,
        LoanApplicationsMinTable,
        Pagination,
        Modal,
        PFBeneficiaryForm,
        PMBeneficiaryForm,
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 10,
            searchString: '',
            modalId: 'modal-lg',
            modalDataTarget: '#modal-lg',
            modalContent: 0,
            editing: false,
            selectedLoanApplication: {},
            selectedRecordId: 0,
            individualBeneficiary: new IndividualBeneficiary(),
            corporateBeneficiaryGetter: {
                getter: () => useBeneficiaryStore().getBeneficiary,
                processCorporateData: (newValue) => {
                    const validProperties = ['generalInformation', 'fiscalAddress', 'legalRepresentative'];
                    if (helper.validateObjectProperties(newValue, validProperties)) {
                        return {
                            statusId: newValue.statusId,
                            generalInformation: newValue.generalInformation,
                            fiscalAddress: newValue.fiscalAddress,
                            legalRepresentative: newValue.legalRepresentative,
                        }
                    }
                    return {};
                }
            },
        }
    },
    computed: {
        ...mapState(useBeneficiaryStore, ['filterBeneficiariesBySearchString', 'getBeneficiary']),
        ...mapState(useIndividualLoanApplicationStore, ['getMixedLoanApplications']),
        createPFFormTitle() {
            return this.$getLocaleMessage('formTitles', 'create') + ' ' + "datos y documentos de identificación del beneficiario controlador que sean personas físicas extranjeras, con las condiciones de estancia de visitante o distinta a las establecidas en la fracción I del artículo 12 de las reglas, en términos de la ley de migración.";;
        },
        createPMFormTitle() {
            return this.$getLocaleMessage('formTitles', 'create') + ' ' + "datos y documentos de identificación del beneficiario controlador que sean personas morales de nacionalidad mexicana.";
        },
        updatePFFormTitle() {
            return this.$getLocaleMessage('formTitles', 'update') + ' ' + "datos y documentos de identificación del beneficiario controlador que sean personas físicas extranjeras, con las condiciones de estancia de visitante o distinta a las establecidas en la fracción I del artículo 12 de las reglas, en términos de la ley de migración.";;
        },
        updatePMFormTitle() {
            return this.$getLocaleMessage('formTitles', 'update') + ' ' + "datos y documentos de identificación del beneficiario controlador que sean personas morales de nacionalidad mexicana.";
        },
        createButtonText() {
            return this.$getLocaleMessage('buttons', 'create');
        },
        updateButtonText() {
            return this.$getLocaleMessage('buttons', 'update');
        },
        filteredBeneficiaries() {
            return this.filterBeneficiariesBySearchString(this.searchString);
        },
        initialIndividualBeneficiary() {
            return this.getBeneficiary ? this.getBeneficiary : new IndividualBeneficiary();
        },
        initialCorporateBeneficiary() {
            return this.getBeneficiary ? this.getBeneficiary : new CorporateBeneficiary();
        },
        getIndividualBeneficiaryFromStore() {
            return useIndividualBeneficiaryStore().getBeneficiary;
        },
        getCorporateBeneficiaryFromStore() {
            return useCorporateBeneficiaryStore().getBeneficiary;
        },
        getBeneficiaryStatus() {
            return useStatusStore().getAllStatusByType(1);
        },
    },
    methods: {
        ...mapActions(useBeneficiaryStore, ['fetchAllBeneficiariesByCompanyId']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchMixedLoanApplications']),
        modalStatus(status) {
            if (status) {
                $(this.modalDataTarget).modal('hide');
                this.modalContent = 0;
                this.editing = false;
            }
        },
        individualBeneficiaryById() {
            useIndividualBeneficiaryStore().fetchBeneficiaryById(this.selectedRecordId);
        },
        corporateBeneficiaryById() {
            useCorporateBeneficiaryStore().fetchBeneficiaryById(this.selectedRecordId);
        },
        createIndividualBeneficiaryRecord(formData) {
            // Add form submission logic here
            this.individualBeneficiary = {
                loanApplicationId: this.selectedLoanApplication.id,
                branchOfficeId: this.selectedLoanApplication.prospectus.branchOffice.id,
                ...formData
            };
            useIndividualBeneficiaryStore().createBeneficiary(this.individualBeneficiary);
        },
        updateIndividualBeneficiaryRecord(formData) {
            // Add form submission logic here
            this.individualBeneficiary = {
                id: this.getIndividualBeneficiaryFromStore.id,
                loanApplicationId: this.getIndividualBeneficiaryFromStore.loanApplicationId,
                branchOfficeId: this.getIndividualBeneficiaryFromStore.branchOfficeId,
                ...formData
            };
            useIndividualBeneficiaryStore().updateBeneficiary(this.individualBeneficiary);
        },
        createCorporateBeneficiaryRecord(formData) {
            // Add form submission logic here
            this.individualBeneficiary = {
                loanApplicationId: this.selectedLoanApplication.id,
                branchOfficeId: this.selectedLoanApplication.prospectus.branchOffice.id,
                ...formData
            };
            useCorporateBeneficiaryStore().createBeneficiary(this.individualBeneficiary);
        },
        updateCorporateBeneficiaryRecord(formData) {
            // Add form submission logic here
            this.corporateBeneficiary = {
                id: this.getCorporateBeneficiaryFromStore.id,
                loanApplicationId: this.getCorporateBeneficiaryFromStore.id,
                branchOfficeId: this.getCorporateBeneficiaryFromStore.branchOfficeId,
                ...formData
            };
            useCorporateBeneficiaryStore().updateBeneficiary(this.corporateBeneficiary);
        },
        openLoanApplicationTable() {
            this.modalContent = 3;
            this.fetchMixedLoanApplications();
        },
        setSelectedLoanApplication(item) {
            this.selectedLoanApplication = item;
            this.modalContent = item.prospectus.personTypeId;
        },
        openEditionForm(item) {
            this.selectedItem = item;
            this.selectedRecordId = item.id;
            // Dependiendo del tipo de persona, se mostrará un formulario u otro
            this.modalContent = item.loanApplication.prospectus.personTypeId;
            this.editing = true;
        },
    },
    created() {
        this.fetchAllBeneficiariesByCompanyId();
        this.fetchMixedLoanApplications();
        useStatusStore().fetchStatus();
    },
    mounted() {
        $(`#${this.modalId}`).on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
        });
    }
}
</script>