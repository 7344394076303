import './bootstrap';
import '../css/app.css';
import { createApp } from 'vue';
// import { createRouter, createWebHistory } from 'vue-router';
import App from './Layouts/App.vue';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faGooglePlus} from '@fortawesome/free-brands-svg-icons';

import _ from 'underscore';
// Test
// Import AdminLTE scripts ( uncomment as necessary)
import * as Adminlte from "admin-lte/dist/js/adminlte.js"
// import "admin-lte/plugins/select2/js/select2.full.min.js"
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
//import "admin-lte/plugins/summernote/summernote-bs4.min.js"
//import "admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "admin-lte/plugins/daterangepicker/daterangepicker.js"
//mport "admin-lte/plugins/moment/moment.min.js"

import "admin-lte/plugins/jquery-knob/jquery.knob.min.js"
//import "admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js"
//import "admin-lte/plugins/jqvmap/jquery.vmap.min.js"
import "admin-lte/plugins/sparklines/sparkline.js"
//import "admin-lte/plugins/chart.js/Chart.min.js"
import "admin-lte/plugins/jquery/jquery.min.js"
import "admin-lte/plugins/jquery-ui/jquery-ui.min.js"

// import "admin-lte/plugins/inputmask/jquery.inputmask.min.js"
//import "admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
// import "admin-lte/plugins/bs-stepper/js/bs-stepper.min.js"
// import "admin-lte/plugins/dropzone/min/dropzone.min.js"
// import bsCustomFileInput from "admin-lte/plugins/bs-custom-file-input/bs-custom-file-input.js";

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import router from './routes/index';
// import { pinia } from './pinia';
import { createPinia } from 'pinia';
import piniaPluginPesistedstate from 'pinia-plugin-persistedstate';
// import store from '@/store';

import { Form, Field, ErrorMessage } from '@/Components/Validators/index.js';
import LoadingComponent from '@/Components/Loading.vue';
import numeral from 'numeral';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getLocaleMessage } from '@/services/MessagesService';
// import treeViewInitOnce from '@/Plugins/TreeviewFix.js';

let treeViewInit = function(){
    $('[data-widget="treeview"]').each(function () {
        Adminlte.Treeview._jQueryInterface.call(
            $(this),
            "init"
        );
    });
};

let treeViewInitOnce = _.once(treeViewInit);

numeral.register('locale', 'es', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'mm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        var b = number % 10;
        return (b === 1 || b === 3) ? 'er' :
            (b === 2) ? 'do' :
                (b === 7 || b === 0) ? 'mo' :
                    (b === 8) ? 'vo' :
                        (b === 9) ? 'no' : 'to';
    },
    currency: {
        symbol: '$'
    }
});

// const localeMessagesPlugin = {
//     install(app) {
//       app.config.globalProperties.$getLocaleMessage = getLocaleMessage;
//     },
//   };

const pinia = createPinia();
pinia.use(piniaPluginPesistedstate);
const app = createApp(App);

app.config.globalProperties.$filters = {
    formatNumber(value) {
        if (value == null || typeof value == undefined) return;
        return numeral(value).format('$0,0.00');
    },
    formatPercent(value) {
        if (value == null || typeof value == undefined) return;
        return numeral(value).format('0.00%');
    },
    formatPercent2(value) {
        if (value == null || typeof value == undefined) return;
        const p = value / 100;
        return numeral(p).format('0.0%');
    },
    // Date value is expected to be in ISO 8601 format (E.g: 2024-04-17T00:00:00)
    formatDate(value, fmt="dd 'de' MMM 'del' yyy") {
        if (value == null || typeof value == undefined) return;
        const date = new Date(value);
        const res = format(date, fmt, {
            locale: es
          })
        return res;
    },
}

app.config.globalProperties.$treeViewInitOnce = treeViewInitOnce;
app.config.globalProperties.$getLocaleMessage = getLocaleMessage;
app.config.errorHandler = (err, instance, info) => {
    // Log the error to the console
    console.error('Vue error:', err, instance, info);
  
    // Send the error to Sentry
    // Sentry.captureException(err);
  };

app.component('Form', Form)
app.component('Field', Field)
app.component('ErrorMessage', ErrorMessage)
app.component('LoadingComponent', LoadingComponent)
app.component('v-select', vSelect)
app.component(FontAwesomeIcon)
// app.use(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus)
app.use(router)
app.use(pinia)
app.mount('#app');