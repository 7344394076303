export default class DocumentFile {
    constructor({
        loanApplicationId = 0,
        financialRecordId = null,
        documentId = null,
        fileId = null,
        loanApplication = null,
        financialRecord = null
    } = {}) {
        this.loanApplicationId = loanApplicationId;
        this.financialRecordId = financialRecordId;
        this.documentId = documentId;
        this.fileId = fileId;
        this.loanApplication = loanApplication;
        this.financialRecord = financialRecord;
    }

    static fromAddFormData(formData) {
        return new DocumentFile({
            loanApplicationId: formData.loanApplicationId,
            financialRecordId: formData.financialRecordId,
            isCompleted: formData.isCompleted,
            documentId: formData.documentId,
            documentIds: formData.documentIds,
            files: formData.files,
        });
    }

    static fromRawData(rawData) {
        return new DocumentFile({
            loanApplicationId: rawData.loanId,
            financialRecordId: rawData.recordId,
            documentId: rawData.documentId,
            fileId: rawData.fileId,
            loanApplication: {
                id: rawData.loanApplication.loanApplicationId,
                prospectusId: rawData.loanApplication.loan_ProspectusId,
                referenceNumber: rawData.loanApplication.loan_ReferenceNumber,
                prospectus: {
                    prospectusId: rawData.loanApplication.prospectus.prospectusId,
                    personTypeId: rawData.loanApplication.prospectus.personTypeId,
                    referenceNumber: rawData.loanApplication.prospectus.prospectus_ReferenceNumber,
                    generalInformation: {
                        generalInfoId: rawData.loanApplication.prospectus.generalInfo.generalInfoId,
                        businessName: rawData.loanApplication.prospectus.generalInfo.businessName,
                        firstName: rawData.loanApplication.prospectus.generalInfo.firstName,
                        lastName: rawData.loanApplication.prospectus.generalInfo.lastName,
                        mothersMaidenName: rawData.loanApplication.prospectus.generalInfo.mothersMaidenName,
                    },
                    branchOffice: {
                        id: rawData.loanApplication.prospectus.branchOffice.id,
                        name: rawData.loanApplication.prospectus.branchOffice.branchOffice_Name,
                        identifier: rawData.loanApplication.prospectus.branchOffice.identifier,
                        code: rawData.loanApplication.prospectus.branchOffice.code,
                    },
                },
            },
            financialRecord: {
                id: rawData.financialRecord.financialRecordId,
                recordTypeId: rawData.financialRecord.record_RecordTypeId,
                referenceNumber: rawData.financialRecord.record_ReferenceNumber,
                name: rawData.financialRecord.record_Name,
                recordType: {
                    id: rawData.financialRecord.recordType.recordTypeId,
                    name: rawData.financialRecord.recordType.recordType_Name
                }
            }
        });
    }

    static toRawData(documentFile) {
        return {
            loanId: documentFile.loanApplicationId,
            recordId: documentFile.financialRecordId,
            documentId: documentFile.documentId,
            fileId: documentFile.fileId,
            loanApplication: {
                loanApplicationId: documentFile.loanApplication.id,
                loan_ProspectusId: documentFile.loanApplication.prospectusId,
                loan_ReferenceNumber: documentFile.loanApplication.referenceNumber,
                prospectus: {
                    prospectusId: documentFile.loanApplication.prospectus.prospectusId,
                    personTypeId: documentFile.loanApplication.prospectus.personTypeId,
                    prospectus_ReferenceNumber: documentFile.loanApplication.prospectus.referenceNumber,
                    generalInfo: {
                        generalInfoId: documentFile.loanApplication.prospectus.generalInformation.generalInfoId,
                        businessName: documentFile.loanApplication.prospectus.generalInformation.businessName,
                        firstName: documentFile.loanApplication.prospectus.generalInformation.firstName,
                        lastName: documentFile.loanApplication.prospectus.generalInformation.lastName,
                        mothersMaidenName: documentFile.loanApplication.prospectus.generalInformation.mothersMaidenName,
                    },
                    branchOffice: {
                        id: documentFile.loanApplication.prospectus.branchOffice.id,
                        branchOffice_Name: documentFile.loanApplication.prospectus.branchOffice.name,
                        identifier: documentFile.loanApplication.prospectus.branchOffice.identifier,
                        code: documentFile.loanApplication.prospectus.branchOffice.code,
                    },
                },
            },
            financialRecord: {
                financialRecordId: documentFile.financialRecord.id,
                record_RecordTypeId: documentFile.financialRecord.recordTypeId,
                record_ReferenceNumber: documentFile.financialRecord.referenceNumber,
                record_Name: documentFile.financialRecord.name,
                recordType: {
                    recordTypeId: documentFile.financialRecord.recordType.id,
                    recordType_Name: documentFile.financialRecord.recordType.name
                }
            }
        };
    }
}
