import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import CurrencyType from '@/Models/CurrencyType';

import * as service from '@/services';

const $toast = useToast();

export const useCurrencyStore = defineStore('currency', {
    persist: false,
    state: () => {
        return {
            currencies: [],
            currency: {},

            activeCurrencies: [],
            activeCurrency: {},

            paginatedCurrencies: [],
        }
    },
    getters: {
        getSelectedCurrency: state => state.currency,
        getCurrencies: state => state.currencies,
        getCurrencyById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.currencies.find(c => c.id === id);
        },
        
        getSelectedActiveCurrency: state => state.activeCurrency,
        getActiveCurrencies: state => state.activeCurrencies,
        getActiveCurrencyById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activeCurrencies.find(c => c.id === id);
        }
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        setSelectedCurrency(id) {
            this.currency = this.getCurrencyById(id);
        },
        setSelectedActiveCurrency(id) {
            this.activeCurrency = this.getActiveCurrencyById(id);
        },
        async fetchCurrencies() {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.currenciesApi.get(`/api/currencies`);
                if (data) {
                    useLoadingStore().loading = false;
                    this.currencies = data;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar las monedas.");
                this.setErrors(error);
            }
        },
        async fetchActiveCurrencies() {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.currenciesApi.get(`/api/currencies`);
                if (data) {
                    useLoadingStore().loading = false;
                    this.activeCurrencies = data;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar las monedas.");
                this.setErrors(error);
            }
        },
    },
});
