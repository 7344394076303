export default class CorporateLoanApplicationData {
    constructor({
      loanApplication = {},
      branchOffice = {},
      legalEntityGuarantee = {},
      directDebit = {},
      commercialLoanReferences = {},
      bankLoanReferences = {},
      productDetails = {},
      interview = {},
      answers = {}
    }) {
      this.loanApplication = loanApplication || {};
      this.branchOffice = branchOffice || {};
      this.legalEntityGuarantee = legalEntityGuarantee || {};
      this.directDebit = directDebit || {};
      this.commercialLoanReferences = commercialLoanReferences || {};
      this.bankLoanReferences = bankLoanReferences || {};
      this.productDetails = productDetails || {};
      this.interview = interview || {};
      this.answers = answers || {};
    }
  }
  