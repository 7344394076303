<template>
    <LoadingComponent v-if="getLoading"></LoadingComponent> 
    <router-view></router-view>
</template>

<script>
import { useLoadingStore } from '@/pinia/loading.module';

export default {
    computed: {
        getLoading() {
            return useLoadingStore().getLoading;
        },
    },

}
</script>