<template>
    <div class="d-flex justify-content-between">
        <h3 class="card-title">Lista de Constancias de Benenficiarios</h3>
        <div class="d-flex">
            <PrimaryButton class="btn btn-primary mr-3" data-toggle="modal" :data-target="modalDataTarget"
                @click="openLoanApplicationTable">
                Crear Constancia
            </PrimaryButton>
        </div>
    </div>

    <div class="position-relative col-sm-12">
        <CertificatesTable :list="getCertificates" :itemsPerPage="itemsPerPage" :currentPage="currentPage" :modalDataTarget="modalDataTarget" @editItem="openEditionForm"></CertificatesTable>
        <Pagination :totalItems="getCertificates.length" :currentPage="currentPage" @current-page="currentPage = $event"></Pagination>
        
        <Modal :modalId="modalId" :customClass="modalId">

            <div v-if="modalContent === 1">
                <!-- Agregar tabla de Solicitudes para escoger una -->
                 <LoanApplicationsMinTable :list="getMixedLoanApplications" @selected-item="setSelectedLoanApplication"></LoanApplicationsMinTable>
            </div>
            
            <!-- Agregar formulario de creación -->
            <CertificateOfAcknowledgmentDetails v-if="modalContent == 2 && editing == false" :loanApplicationId="this.selectedLoanApplication.id" :personTypeId="this.selectedLoanApplication.personTypeId" :clientLabel="clientsLabelName" @closeModal="modalStatus"></CertificateOfAcknowledgmentDetails>
            <!-- Agregar formulario de edición -->
             <CertificateOfAcknowledgmentDetailsEdit v-if="modalContent == 3 && editing == true" :selectedItem="selectedItem" :clientLabel="clientsLabelName" @closeModal="modalStatus"></CertificateOfAcknowledgmentDetailsEdit>

        </Modal>

    </div>
</template>

<script>

import { mapState, mapActions } from 'pinia';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useCorporateProspectsStore } from '@/pinia/Prospects/corporate.module';
import { useCertificateOfAcknowledgment } from '@/pinia/certificateOfAcknowledgment.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import LoanApplicationsMinTable from '@/Components/Tables/LoanApplicationsMinTable.vue';
import Pagination from '@/Components/Bootstrap/Paginationv2.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import CertificatesTable from '@/Components/Tables/CertificateOfAcknowledgmentTable.vue';
import CertificateOfAcknowledgmentDetails from '@/Components/Forms/LoanApplications/PLD/CertificateOfAcknowledgmentDetails.vue';
import CertificateOfAcknowledgmentDetailsEdit from '@/Components/Forms/LoanApplications/PLD/CertificateOfAcknowledgmentDetailsEdit.vue';

export default {
    components: {
        PrimaryButton,
        LoanApplicationsMinTable,
        Pagination,
        Modal,
        CertificatesTable,
        CertificateOfAcknowledgmentDetails,
        CertificateOfAcknowledgmentDetailsEdit,
    },
    data() {
        return {
            modalId: 'modal-xl',
            modalDataTarget: '#modal-xl',
            editing: false,
            modalContent: 0, // (1: Tabla de Identificaciones), (2: ProofOfBeneficiary)
            currentPage: 1,
            itemsPerPage: 10,
            // TODO: Eliminar esta propiedad y hacer pruebas nuevamente
            personTypeId: 0,
            objectsIds: { prospectusId: 0, loanApplicationId: 0 },
            selectedLoanApplication: {},
            selectedItem: {},
        }
    },
    computed: {
        ...mapState(useCertificateOfAcknowledgment, ['getCertificates']),
        ...mapState(useIndividualLoanApplicationStore, ['getMixedLoanApplications']),
        ...mapState(useIndividualProspectsStore, ['getAllProspects']),
        clientsLabelName() {
            // Al seleccionar la Solicitud de la tabla, de ahí se deberá obtener el personTypeId (en el Watch se setea):
            return this.selectedLoanApplication?.personTypeId === 1 ? 'Nombre del cliente o usuario:' : 'Nombre del apoderado o representante del cliente o usuario:';
        },
    },
    methods: {
        ...mapActions(useCertificateOfAcknowledgment, ['fetchCertificates']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchMixedLoanApplications']),
        ...mapActions(useIndividualProspectsStore, ['fetchMixedProspects', 'fetchIndividualProspectusById']),
        ...mapActions(useCorporateProspectsStore, ['fetchCorpProspectusById']),
        modalStatus(status) {
            if (status) {
                $(this.modalDataTarget).modal('hide');
                this.modalContent = 0;
                this.editing = false;
            }
        },
        openLoanApplicationTable() {
            this.modalContent = 1;
            this.fetchMixedLoanApplications();
        },
        openEditionForm(item) {
            this.selectedItem = item;
            // Dependiendo del tipo de persona, se mostrará un formulario u otro
            this.modalContent = 3;
            this.editing = true;
        },
        setSelectedLoanApplication(item) {
            this.selectedLoanApplication = item;
            this.modalContent = 2;
        },
        setModalContent(number) {
            this.modalContent = number;
        },
    },
    created() {
        this.fetchCertificates();
    },
    mounted() {
        $(this.modalDataTarget).on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            this.selectedLoanApplication = {};
        });
    }
}
</script>