export default class RecordsProspects {
  constructor({
    id = 0, 
    branchOfficeId = 0, 
    recordId = 0, 
    prospectusId = 0, 
    statusId = 0, 
    referenceNumber = '', 
    branchOffice = null, 
    financialRecord = null, 
    prospectus = null, 
    status = null, 
    files = [],
    createdAt = new Date(), 
    updatedAt = new Date(),
  }) {
    this.id = id || 0;
    this.branchOfficeId = branchOfficeId || 0;
    this.recordId = recordId || 0;
    this.prospectusId = prospectusId || 0;
    this.statusId = statusId || 0;
    this.referenceNumber = referenceNumber || '';
    this.branchOffice = branchOffice || null;
    this.financialRecord = financialRecord || null;
    this.prospectus = prospectus || null;
    this.status = status || null;
    this.files = files;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

}
