export default class Question {
    constructor({
        id = 0,
        questionText = null,
        answerType = null,
        groupNumber = null,
        fields = null,
        createdAt = new Date(),
        updatedAt = new Date(),
    } = {}) {
        this.id = id;
        this.questionText = questionText;
        this.answerType = answerType;
        this.groupNumber = groupNumber;
        this.fields = fields;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}