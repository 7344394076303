<template>
    <Form ref="guaranteeForm" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Garantía
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre:</label>
                    <Field v-model="guarantee.name" type="text" class="form-control" name="name" placeholder="Nueva garantía"
                        rules="required" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="guarantee.statusId" :name="'statusId'" :options="statusOptions" :isDisabled="true"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useProductsStore } from '@/pinia/products.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';

import Guarantee from '@/Models/Guarantee';

export default {
    components: {
        PrimaryButton,
        StatusSelect,
    },
    mixins: [],
    data() {
        return {
            guarantee: new Guarantee(),
            contentNumber: 0,
        }
    },
    emits: [
        'change-modal-content',
    ],
    props: {
        isEdition: {
            type: Boolean,
            default: () => false,
        },
        companyId: {
            type: Number,
            default: () => 0,
        }
    },
    computed: {
        ...mapState(useStatusStore, ['getAllStatusByType']),
        getStatusList() {
            return this.getAllStatusByType(1);
        },
        getActiveStatus() {
            const st = this.statusOptions.find((s) => s.label === "Activo");
            return st.value;
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
    },
    methods: {
        ...mapActions(useProductsStore, ['createGuarantee', 'setSelectedActiveGuarantee']),
        onSubmit() {
            this.createGuarantee(this.guarantee).then(g => {
                this.setSelectedActiveGuarantee(g.id);
                this.$refs.guaranteeForm.resetForm();
                this.contentNumber = (this.isEdition) ? 2 : 1;
                this.$emit('change-modal-content', this.contentNumber);
            });
        },
    },
    watch: {
        companyId: {
            immediate: true,
            handler(newCompanyId) {
                if (newCompanyId) {
                    this.guarantee.companyId = newCompanyId;
                }
            },
        },
    },
    created() {
        this.guarantee.statusId = this.getActiveStatus;
    },
    mounted() {}
}
</script>