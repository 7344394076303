export default class MenuGroup {
    constructor({
        id = null,
        name = '',
        itemOrder = 0,
        createdAt = new Date(),
        updatedAt = new Date(),
    }) {
        this.id = id;
        this.name = name;
        this.itemOrder = itemOrder;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}