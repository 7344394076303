import { defineStore } from 'pinia';
import { getLocaleMessage } from '@/services/MessagesService';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Account from '../Models/Account';
import { useAuthStore } from '@/pinia/auth.module';

import * as service from '@/services';

const $toast = useToast();

export const useAccountsStore = defineStore('account', {
    persist: false,
    state: () => {
        return {
            accounts: [],
            account: {},

            activeAccounts: [],
            activeAccount: {},
            errors: null,
        }
    },
    getters: {
        getSelectedAccount: state => state.account,
        getAccounts: state => state.accounts,
        getAccountById: state => id => {
            return state.accounts.find(c => c.idAccount === id);
        },
        getActiveAccounts: state => state.activeAccounts,
        getSelectedActiveAccount: state => state.activeAccount,
        getActiveAccountById: state => (id = 0) => {
            if (typeof id === "undefined" || id === 0 || id === null) return {};
            return state.activeAccounts.find(c => c.id === id);
        },

        getPaginatedAccounts: state => state.paginatedAccounts,
        filterAccountsBySearchString: state => (searchString = '') => {
            if (!searchString) return state.accounts;
            console.log(state.accounts);
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.accounts.filter(account => {
                const referenceNumber = account.referenceNumber ? account.referenceNumber.toLowerCase() : '';
                const financialFunderName = account.financialFunder?.anchor ? account.financialFunder?.anchor.toLowerCase() : '';
                const bankName = account.bank?.name ? account.bank?.name.toLowerCase() : '';
                const accountNumber = account.accountNumber ? account.accountNumber.toLowerCase() : '';
                const observations = account.observations ? account.observations.toLowerCase() : '';
                const statusName = account.status?.name ? account.status?.name.toLowerCase() : '';

                return referenceNumber.includes(lowerCaseSearchString) ||
                    financialFunderName.includes(lowerCaseSearchString) ||
                    bankName.includes(lowerCaseSearchString) ||
                    accountNumber.includes(lowerCaseSearchString) ||
                    observations.includes(lowerCaseSearchString) ||
                    statusName.includes(lowerCaseSearchString)

            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        async fetchAccountsByCompany() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.get(`/api/Accounts/company/${useAuthStore().user.companyId}`);
                if (data) {
                    const accountData = data.data;
                    this.accounts = accountData.map((a) => new Account(a));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchAccountById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.get(`/api/Accounts/${id}`);
                if (data) {
                    const accountData = data.data;
                    this.account = new Account(accountData);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveAccountsByCompany() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.get(`/api/Accounts/company/${useAuthStore().user.companyId}/active`);
                if (data) {
                    const accountData = data.data;
                    this.accounts = accountData.map((a) => new Account(a));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createAccount(account) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.post(`/api/Accounts`, account);
                if (data) {
                    $toast.success(getLocaleMessage('accounts', 'creatingSuccess'));
                    const accountData = data.data;
                    this.accounts.unshift(new Account(accountData));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateAccount(account) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.put(`/api/Accounts`, account);
                if (data) {
                    $toast.success(getLocaleMessage('funders', 'editingSuccess'));
                    const accountData = data.data;
                    const index = this.accounts.findIndex(a => a.id === accountData.id);
                    if (index !== -1) {
                        this.accounts.splice(index, 1, accountData);
                        this.accounts.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        }
    }
});