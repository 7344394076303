export default class DirectDebit {
  constructor({
    id = 0, 
    loanApplicationId = 0, 
    authorizedPersonName = null, 
    accountNumber = null, 
    clabe = null, 
    bank = null, 
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.authorizedPersonName = authorizedPersonName || null;
    this.accountNumber = accountNumber || null;
    this.clabe = clabe || null;
    this.bank = bank || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
