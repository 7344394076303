<template>
    <Form ref="documentCheckList" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">Check List Recepción de Documentación</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-sm-4">
                    <label for="date">Fecha:</label>
                    <Field v-model="form.date" type="date" class="form-control" name="date" />
                    <ErrorMessage name="date" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group col-sm-4">
                    <label for="phone">Teléfono:</label>
                    <Field v-model="form.phone" type="text" class="form-control" name="phone" placeholder="Teléfono" />
                    <ErrorMessage name="phone" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group col-sm-4">
                    <label for="fullName">Nombre Completo:</label>
                    <Field v-model="form.fullName" type="text" class="form-control" name="fullName"
                        placeholder="Nombre Completo" />
                    <ErrorMessage name="fullName" class="input-error"></ErrorMessage>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-6">
                    <label for="address">Domicilio:</label>
                    <Field v-model="form.address" type="text" class="form-control" name="address"
                        placeholder="Domicilio" />
                    <ErrorMessage name="address" class="input-error"></ErrorMessage>
                </div>
            </div>

            <!-- Campos de selección -->
            <div class="row">
                <div class="form-group col-sm-6">
                    <label for="solicitud">Solicitud:</label>
                    <Select v-model="form.solicitud" :name="'solicitud'" :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="creditBureauAuthorization">Carta autorización de consulta al buró de crédito:</label>
                    <Select v-model="form.creditBureauAuthorization" :name="'creditBureauAuthorization'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="officialId">Copia identificación oficial vigente con foto y firma:</label>
                    <Select v-model="form.officialId" :name="'officialId'" :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="addressProof">Comprobante de domicilio:</label>
                    <Select v-model="form.addressProof" :name="'addressProof'" :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="rfcCopy">Copia del RFC:</label>
                    <Select v-model="form.rfcCopy" :name="'rfcCopy'" :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="taxPayments">Copia pagos provisionales del año en curso y última declaración
                        anual:</label>
                    <Select v-model="form.taxPayments" :name="'taxPayments'" :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="commercialReferences">3 Referencias comerciales:</label>
                    <Select v-model="form.commercialReferences" :name="'commercialReferences'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="bankStatements">Últimos 3 estados de cuenta de cheques:</label>
                    <Select v-model="form.bankStatements" :name="'bankStatements'" :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="bankAccountForDeposit">Cuenta bancaria para depósito:</label>
                    <Select v-model="form.bankAccountForDeposit" :name="'bankAccountForDeposit'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="bankAccountForDomiciliation">Cuenta bancaria para domiciliación:</label>
                    <Select v-model="form.bankAccountForDomiciliation" :name="'bankAccountForDomiciliation'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="insuranceRequest">Solicitud de seguro:</label>
                    <Select v-model="form.insuranceRequest" :name="'insuranceRequest'" :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="companyConstitution">Copia de la escritura constitutiva:</label>
                    <Select v-model="form.companyConstitution" :name="'companyConstitution'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="beneficiaryIdentification">Formato para la identificación del Dueño
                        Beneficiario:</label>
                    <Select v-model="form.beneficiaryIdentification" :name="'beneficiaryIdentification'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="resourceProviderIdentification">Formato para la identificación del Proveedor de
                        Recursos:</label>
                    <Select v-model="form.resourceProviderIdentification" :name="'resourceProviderIdentification'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="financialStatements">Estados financieros parciales y últimos anuales:</label>
                    <Select v-model="form.financialStatements" :name="'financialStatements'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="marriageCertificate">Acta de matrimonio (Representante o Apoderado):</label>
                    <Select v-model="form.marriageCertificate" :name="'marriageCertificate'"
                        :options="options"></Select>
                </div>
                <div class="form-group col-sm-6">
                    <label for="spouseIdentification">Identificación del cónyuge (sociedad conyugal):</label>
                    <Select v-model="form.spouseIdentification" :name="'spouseIdentification'"
                        :options="options"></Select>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-6">
                    <label for="nombreFirmaEntrego">Nombre y Firma (Entregó):</label>
                    <Field v-model="form.nombreFirmaEntrego" type="text" class="form-control"
                    name="nombreFirmaEntrego" />
                    <ErrorMessage name="nombreFirmaEntrego" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group col-sm-6">
                    <label for="nombreFirmaRecibio">Nombre y Firma (Recibió):</label>
                    <Field v-model="form.nombreFirmaRecibio" type="text" class="form-control"
                    name="nombreFirmaRecibio" />
                    <ErrorMessage name="nombreFirmaRecibio" class="input-error"></ErrorMessage>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12">
                    <label for="observations">Observaciones:</label>
                    <Field v-model="form.observations" as="textarea" class="form-control" name="observations" />
                    <ErrorMessage name="observations" class="input-error"></ErrorMessage>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                Crear
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import Select from '@/Components/Selects/SelectField.vue';

export default {
    components: {
        Select
    },
    data() {
        return {
            form: {
                date: '',
                phone: '',
                fullName: '',
                address: '',
                solicitud: '',
                creditBureauAuthorization: '',
                officialId: '',
                addressProof: '',
                rfcCopy: '',
                taxPayments: '',
                commercialReferences: '',
                bankStatements: '',
                bankAccountForDeposit: '',
                bankAccountForDomiciliation: '',
                insuranceRequest: '',
                companyConstitution: '',
                beneficiaryIdentification: '',
                resourceProviderIdentification: '',
                financialStatements: '',
                marriageCertificate: '',
                spouseIdentification: ''
            },
            options: [
                { label: 'Solicitante', value: 1 },
                { label: 'Obligado Solidario - Persona Física', value: 2 },
                { label: 'Obligado Solidario - Persona Moral', value: 3 }
            ]
        };
    },
    methods: {
        onSubmit() {
            // Handle form submission
        }
    }
};
</script>

<style scoped>
.input-error {
    color: red;
}
</style>
