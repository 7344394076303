import Beneficiary from './Beneficiary';

export default class CorporateBeneficiary extends Beneficiary {
    constructor(
        {
        // additional attributes
        fiscatAddress = null,
        legalRepresentative = null,
        ...rest
    } = {}) {
        super(rest);
        // additional attributes
        this.fiscatAddress = fiscatAddress;
        this.legalRepresentative = legalRepresentative;
    }
}