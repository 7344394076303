<template>
    <div class="modal fade" :id="modalId">
        <div class="modal-dialog" :class="customClass">
            <div class="modal-content">
                <slot />
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
</template>

<script>
    export default {
        props: {
            modalId: {
                type: String,
                required: false,
                default: 'modal-lg'
            },
            customClass: {
                type: String,
                default: 'modal-lg',
            }
        }
    }
</script>