export default class FiscalAddress {
    constructor({
        addressId = 0,
        street = null, 
        exteriorNumber = null, 
        interiorNumber = null, 
        district = null, 
        municipality = null, 
        city = null, 
        state = null, 
        country = null, 
        postalCode = null, 
        references = null, 
        propertyType = null, 
        ageInHome = null, 
        legalStatusProperty = null, 
        mortgagedHome = null,
    } = {}) {
        this.addressId = addressId;
        this.street = street;
        this.exteriorNumber = exteriorNumber;
        this.interiorNumber = interiorNumber;
        this.district = district;
        this.municipality = municipality;
        this.city = city;
        this.state = state;
        this.country = country;
        this.postalCode = postalCode;
        this.references = references;
        this.propertyType = propertyType;
        this.ageInHome = ageInHome;
        this.legalStatusProperty = legalStatusProperty;
        this.mortgagedHome = mortgagedHome;
    }
}
    