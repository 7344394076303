import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';

import { useLoadingStore } from '@/pinia/loading.module';
import { useHandlerStore } from '@/pinia/handler.module';

import Company from '@/Models/Company';

import { getLocaleMessage } from '@/services/MessagesService';
import * as service from '@/services';

const $toast = useToast();

export const useCompaniesStore = defineStore('companies', {
    persist: false,
    state: () => {
        return {
            companies: [],
            company: {},
            paginatedCompanies: [],
        }
    },
    getters: {
        getSelectedCompany: state => state.company,
        getCompanies: state => state.companies,
        getCompanyById: state => (id = 0) => {
            return state.companies.find(c => c.id === id);
        },
        getPaginatedCompanies: state => state.paginatedCompanies,
        filterBusinessBySearchString: state => (searchString = '') => {
            if (!searchString) return state.companies;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.companies.filter(company => {
                const name = company.name ? company.name.toLowerCase() : '';
                const address = company.address ? company.address.toLowerCase() : '';
                const statusName = company.statusRel?.name ? company.statusRel?.name.toLowerCase() : '';
                return name.includes(lowerCaseSearchString) ||
                    address.includes(lowerCaseSearchString) ||
                    statusName.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchCompanies() {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.companiesApi.get("/companies");
                if (data) {
                    useLoadingStore().loading = false;
                    this.companies = data;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar las empresas.");
                this.setErrors(error);
            }
        },
        async createCompany(company) {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.companiesApi.post(`/companies`, company);
                if (data) {
                    this.companies.unshift(data);
                    $toast.success(getLocaleMessage('companies', 'creatingSuccess'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateCompany(company) {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.companiesApi.put(`/companies`, company);
                if (data) {
                    $toast.success(getLocaleMessage('companies', 'editingSuccess'));
                    const newCompany = new Company(data);
                    const index = this.companies.findIndex(c => c.id === newCompany.id);
                    if (index !== -1) {
                        this.companies.splice(index, 1, newCompany);
                        this.companies.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async deleteCompany(id) {
            try {
                const { data: data } = await service.companiesApi.delete(`/companies/${id}`);
                $toast.success("Empresa eliminada correctamente.");
                const index = this.companies.findIndex(c => c.id === id);
                if (index !== -1) {
                    this.companies.splice(index, 1);
                }
                return data;
            } catch (error) {
                $toast.error("Ocurrió un error al eliminar el registro.");
                this.setErrors(error);
            }
        },
        setPaginatedCompanies(companies) {
            this.paginatedCompanies = companies;
        },
        updatePaginatedCompany(company) {
            const index = this.paginatedCompanies.findIndex(c => c.id === company.id);
            if (index !== -1) {
                this.paginatedCompanies.splice(index, 1, company);
            }
        }

    },
});