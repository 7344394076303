<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Calificaciones</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Calificaciones</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Calificaciones</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                                    @click="modalContent = 1; editing = false">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex"></div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <CreditScoresTable 
                                    :list="this.getCreditScores" 
                                    :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage"
                                    @click-on-edit-btn="openEditionForm"
                                ></CreditScoresTable>
                                <Pagination 
                                    :list="this.getCreditScores" 
                                    :itemsPerPage="itemsPerPage" 
                                    :currentPage="currentPage"
                                    @current-page="currentPage = $event"
                                ></Pagination>

                                <Modal>
                                    <AddCreditScoreForm 
                                        v-if="modalContent == 1 && editing == false" 
                                        :prospectus="selectedProspectus"
                                        :loanApplication="selectedLoanApplication"
                                        :temp-values="tempValues"
                                        @show-table="showTable($event)"
                                        @closeModal="modalStatus"
                                        @change-modal-content="modalContent = $event">
                                    </AddCreditScoreForm>

                                    <div v-if="modalContent === 2">
                                        <ProspectsTable
                                        :list="getAllProspects"
                                        @selected-prospectus="selectedProspectus = $event;"
                                        @back-to-form="backToForm($event)">
                                        </ProspectsTable>
                                        <Pagination
                                        :list="getAllProspects"
                                        :itemsPerPage="itemsPerPage">
                                        </Pagination>
                                    </div>

                                    <div v-if="modalContent === 3">
                                        <LoanApplicationsTable
                                        :list="getUnratedLoanApplications"
                                        @selected-item="selectedLoanApplication = $event"
                                        @back-to-form="backToForm($event)">
                                        </LoanApplicationsTable>
                                    </div>

                                    <EditCreditScoreForm 
                                        v-if="modalContent == 4 && editing == true" 
                                        :selected-item="selectedRecord"
                                        :temp-values="tempValues"
                                        @show-table="showTable($event)"
                                        @closeModal="modalStatus"
                                        @change-modal-content="modalContent = $event">
                                    </EditCreditScoreForm>
                                </Modal>

                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddCreditScoreForm from '@/Components/Forms/CreditScores/Add.vue';
import EditCreditScoreForm from '@/Components/Forms/CreditScores/Edit.vue';
import CreditScoresTable from '@/Components/Tables/CreditScoresTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import ProspectsTable from '@/Components/Tables/ProspectsMinTable.vue';
import LoanApplicationsTable from '@/Components/Tables/LoanApplicationsMinTable.vue';

import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useCreditScoresStore } from '@/pinia/creditScores.module';
import { sharedStore } from '@/pinia/sharedStore.module';

import Prospectus from '@/Models/Prospects';

export default {
    components: {
        PrimaryButton,
        Modal,
        AddCreditScoreForm,
        EditCreditScoreForm,
        Pagination,
        CreditScoresTable,
        ProspectsTable,
        LoanApplicationsTable,
    },
    data() {
        return {
            selectedRecord: {},
            editing: false,
            currentPage: 1,
            itemsPerPage: 10,
            modalContent: 0,
            selectedProspectus: new Prospectus({}),
            selectedLoanApplication: {},
            tempValues: {},
        }
    },
    computed: {
        ...mapState(useBranchOfficesStore, ['getSelectedBranchOffice']),
        ...mapState(useIndividualLoanApplicationStore, ['getActiveMixedLoanApplications']),
        ...mapState(useCreditScoresStore, ['getCreditScores', 'getCreditScore']),
        ...mapState(sharedStore, ['getAllProspects', 'getProspectusById', 'getProspectus', 'getUnratedLoanApplications']),

    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices', 'setSelectedBranchOffice']),
        ...mapActions(useIndividualProspectsStore, ['fetchActiveMixedProspectsByBranchOffice']),
        ...mapActions(useCreditScoresStore, ['fetchCreditScores', 'fetchScoreQuestionsByPersonType', 'fetchCreditScoreById', 'fetchCreditProfiles', 'fetchExceptionPercents']),
        ...mapActions(sharedStore, ['setSelectedProspectus', 'setSelectedLoanApplication', 'resetProspectsList', 'resetUnratedLoanApplicationsList']),
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        backToForm() {
            this.modalContent = this.editing ? 4 : 1;
        },
        showTable(data) {
            this.tempValues = data;
            this.modalContent = data.contentNumber;
        },
        openEditionForm(score) {
            this.selectedRecord = score;
            this.modalContent = 4; 
            this.editing = true
        },
    },
    watch: {
        // Watching for changes to the 'getSelectedBranchOffice' getter in the pinia store:
        'getSelectedBranchOffice.id': {
            handler(newBranchOfficeId) {
                if (newBranchOfficeId && newBranchOfficeId > 0) {
                    // Reset prospectus to make sure the user selects a new one
                    if (this.getProspectus.id !== 0) {
                        this.setSelectedProspectus(0);
                    }
                    this.fetchActiveMixedProspectsByBranchOffice(newBranchOfficeId);
                }
            },
            immediate: true, // Llama al handler inmediatamente para manejar el caso donde los documentos ya están disponibles durante la creación del componente
        },
        selectedProspectus: {
            handler(newItem) {
                if (newItem && newItem.prospectusId !== 0) {
                    this.setSelectedProspectus(newItem.prospectusId);
                }
            },
            immediate: true,
        },
        'selectedLoanApplication': {
            handler(newItem) {
                // Check if the loan application id is not null and not undefined
                if (newItem && newItem.id !== null && newItem.id !== undefined && newItem.personTypeId !== null && newItem.personTypeId !== undefined) {
                    // Fetch the questions for the person type
                    this.fetchScoreQuestionsByPersonType(newItem.personTypeId);
                }
            },
            immediate: true,
        }
    },
    created() {
        this.fetchCreditScores();
        this.fetchActiveBranchOffices();
        this.fetchCreditProfiles();
        this.fetchExceptionPercents();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            this.selectedProspectus = new Prospectus();
            this.selectedLoanApplication = {};
            this.tempValues = {};
            this.setSelectedBranchOffice();
            this.setSelectedProspectus();
            this.resetProspectsList();
            this.setSelectedLoanApplication();
            this.resetUnratedLoanApplicationsList();
            // Reset the Questions list:
            const creditScoreStore = useCreditScoresStore();
            creditScoreStore.scoreQuestions = [];
        });
    }
}
</script>