
/**
 * Gets the custom field value of a given object
 * @date 3/4/2024 - 10:55:50 PM
 *
 * @param {Object} item - The object data
 * @param {string|string[]} fields - The field name or field names in array
 * @returns {string|number}
 */
function getCustomFieldValue(item, fields) {
    // console.log("Item: ",item);
    // console.log("Value: ",fields);

    if (Array.isArray(fields)) {
        return fields.map((field) => item[field]).join(' '); // Obtener el valor de los campos que contienen el texto a mostrar
    }

    return item[fields]; // Obtener el valor del campo que contiene el texto a mostrar
}

/**
 * Maps the data that will be use in the custom Select Component
 * @date 3/4/2024 - 4:58:06 PM
 *
 * @param {Object[]} data - The array for the custom Select component options
 * @param {string} [customValue='id'] - The field corresponding to the db table row that will be use for the Select value property
 * @param {string} [customLabel='name'] - The field corresponding to the db table row that will be use for the Select label property
 * @returns {Array}
 */
function mapOptions(data, customValue='id', customLabel='name') {
    let label;
    let value;
    let options = [];

    try {
        options = data.map(item => {
            value = getCustomFieldValue(item, customValue);
            label = getCustomFieldValue(item, customLabel);
    
            return {
                value: value,
                label: label,
            }
        });

        return options;
    } catch (error) {
        // TODO: Make use of Sentry
        console.log(error);
    }
    
}

export default mapOptions;