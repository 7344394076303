<template>
    <div class="modal-header">
        <h4 class="modal-title">
            Seleccione un prospecto
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card-body">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Folio de Prospectos</th>
                        <th>Matriz y/o Sucursales</th>
                        <th>Nombres</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in getDataPage()" :key="item.prospectusId" v-if="getDataPage().length != 0" @click="selectedProspectus(item)">
                        <td class="sorting_1 dtr-control">{{ item.referenceNumber }}</td>
                        <td>{{ item.branchOffice.name }}</td>
                        <td>{{getName(item)}}</td>
                        <td>{{ item.generalInformation.email }}</td>
                        <td>{{ getPhone(item) }}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="5" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    components: { },
    data() {
        return {
        }
    },
    emits: ['change-modal-content', 'selected-prospectus', 'back-to-form'],
    props: {
        // This checks if the component is being called from the LoanApplication component
        callingFromLoanApplication: {
            type: Boolean,
            default: () => false,
            required: false
        },
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getProspects() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            if (this.list == undefined) {
                return 0;
            }
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        getName(item) {
            if (item.generalInformation.businessName != null) {
                return item.generalInformation.businessName;
            } else {
                return `${item.generalInformation.firstName} ${item.generalInformation.lastName} ${item.generalInformation.mothersMaidenName}` ;
            }
        },
        getPhone(item) {
            if(item.personTypeId == 1) {
                return item.generalInformation.cellPhone;
            } else {
                return item.generalInformation.officePhone;
            }
        },
        getAddress(item) {
            if(item.personTypeId == 1) {
                return item.homeAddress.postalCode;
            } else {
                return item.fiscalAddress.postalCode
            }
        },
        editProspect(prospect) {
            this.$emit('edit-prospect', prospect);
        },
        deleteProspect(prospect) {
            this.$emit('delete-prospect', prospect);
        },
        selectedProspectus(prospect) {
            if (this.callingFromLoanApplication) {
                this.$emit('change-modal-content', 2);
            }
            this.$emit('back-to-form');
            this.$emit('selected-prospectus', prospect);
        },
    },
    watch: {
        updateSelectedProspect(val) {
            this.selectedProspectus = val;
        },
    },
    created() {
        this.getDataPage();
    }
}
</script>