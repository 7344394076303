<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ formTitle }} Calificación
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Selección de Información</h3>
                </div>
                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                        <BranchOfficesSelect v-model="selectedBranchOfficeId" :name="'branchOfficeId'"
                            :options="activeBranchOfficesOptions"></BranchOfficesSelect>
                    </div>
                    <div class="col-sm-6 btn-search-table">
                        <PrimaryButton v-if="enableProspectusBtn" class="btn btn-success" @click.prevent="openTable(2)"><i
                                class="fa fa-search"></i>Seleccionar Prospecto</PrimaryButton>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="ProspectFullname">Prospecto:</label>
                        <Field v-model="getProspectusFullname" type="text" class="form-control"
                            name="ProspectFullname" disabled />
                    </div>
                    <div class="col-sm-6 btn-search-table">
                        <PrimaryButton v-if="enableLoanApplicationsBtn" class="btn btn-success" @click.prevent="openTable(3)"><i class="fa fa-search"></i>Seleccionar Solicitud</PrimaryButton>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="referenceNumber">Solicitud:</label>
                        <Field v-model="loanApplication.referenceNumber" type="text" class="form-control"
                            name="referenceNumber" disabled />
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Cuestionario</h3>
                </div>
                <div class="row card-body">
                    <div v-if="getQuestions.length > 0">
                        <ul v-for="(question, index) in getQuestions" :key="index">
                            <li>
                                {{ question.questionText }}
                                <AnswersSelect v-model="questionsAnswers[question.id]" :name="'answerId' + question.id" :options="getAnswersOptions(question.answers)" @update="updateCreditScores"></AnswersSelect>
                            </li>
                        </ul>
                    </div>
                    <div v-if="getQuestions.length > 0" class="col-sm-12">
                        <label for="score">Score Total:</label>
                        <p>{{ totalScorePercent }}</p>
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Perfil de riesgo</h3>
                </div>
                <div class="card-body">
                    <table v-if="getQuestions.length > 0" class="table table-hover no-overflow">
                        <thead>
                            <tr>
                                <th>NIVEL</th>
                                <th>% Autorizado</th>
                                <th>Observaciones</th>
                                <th>POR EXCEPCIÓN</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="sorting_1 dtr-control">{{ getProfile.name }}</td>
                                <td>{{ getProfile.percentValue }}</td>
                                <td>{{ getProfile.remarks }}</td>
                                <td>{{ getProfile.exception }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Monto autorizado</h3>
                </div>
                <div class="row card-body">
                    <div v-if="getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="creditAmount">Monto de Crédito solicitado</label>
                        <p>{{ $filters.formatNumber(loanApplication.quotation.creditAmount) }}</p>
                    </div>
                    <div v-if="getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="creditAmount">Monto de Crédito autorizado</label>
                        <p>{{ authorizedAmount }}</p>
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Monto autorizado por excepción</h3>
                </div>
                <div class="row card-body">
                    <div v-if="getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="percent">Porcentaje</label>
                        <Percent :fieldName="'percent'" :options="getExceptionPercents" :textToShow="'textValue'"
                            :customValidation="notRequired" @change="setNewPercent"></Percent>
                    </div>
                    <div v-if="getQuestions.length > 0" class="form-group col-sm-6">
                        <label for="creditAmount">Monto de Crédito autorizado</label>
                        <p>{{ authorizedAmount }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ submitButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from "@/pinia/branchOffices.module";
import { useIndividualProspectsStore } from "@/pinia/Prospects/individual.module";
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useCorpLoanApplicationStore } from '@/pinia/LoanApplications/corporate.module';
import { sharedStore } from "@/pinia/sharedStore.module";
import { useCreditScoresStore } from '@/pinia/creditScores.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import BranchOfficesSelect from "@/Components/Selects/SelectField.vue";
import LoanApplicationsSelect from "@/Components/Selects/SelectField.vue";
import AnswersSelect from "@/Components/Selects/SelectField.vue";
import Percent from '@/Components/Selects/BaseSelect.vue';

import RecordsProspects from '@/Models/RecordsProspects';
import CreditScore from '@/Models/CreditScores/CreditScore.js';
import ScoreQuestionAnswer from '@/Models/CreditScores/ScoreQuestionAnswer.js';

export default {
    components: {
        PrimaryButton,
        BranchOfficesSelect,
        LoanApplicationsSelect,
        AnswersSelect,
        Percent,
    },
    mixins: [],
    data() {
        return {
            recordProspectus: new RecordsProspects({}),
            creditScore: new CreditScore({}),
            selectedBranchOfficeId: null,
            dataValues: {},
            selectedAnswers: [],
            selectedPercent: null,
            questionsAnswers: [],
        }
    },
    emits: ['close-modal', 'show-table'],
    props: {
        prospectus: {
            type: Object,
            default: () => new Prospectus({}),
        },
        loanApplication: {
            type: Object,
            default: () => {referenceNumber: null},
        },
        tempValues: {
            type: Object,
            default: () => { },
        },
    },
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
        ...mapState(sharedStore, ['getProspectus', 'getProspectusFullname', 'getSelectedLoanApplication']),
        ...mapState(useCreditScoresStore, ['getQuestions', 'getCreditProfiles', 'getExceptionPercents']),
        formTitle() {
            return this.$getLocaleMessage('formTitles', 'create');
        },
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel');
        },
        submitButtonText() {
            return this.$getLocaleMessage('buttons', 'create');
        },
        activeBranchOfficesOptions() {
            return (this.getActiveBranchOffices) ? helper.mapOptions(this.getActiveBranchOffices) : [];
        },
        branchOfficeIsSelected() {
            return this.selectedBranchOfficeId !== null;
        },
        prospectusIsSelected() {
            return this.prospectus.prospectusId != null && this.prospectus.prospectusId !== 0;
        },
        enableProspectusBtn() {
            return this.branchOfficeIsSelected || this.prospectusIsSelected;
        },
        enableLoanApplicationsBtn() {
            return this.branchOfficeIsSelected && this.prospectusIsSelected;
        },
        selectedLoanApplication() {
            return this.loanApplication.id;
        },
        getTotalScore() {
            return this.selectedAnswers.reduce((total, {questionId, answerId}) => {
                const question = this.getQuestions.find((q) => q.id == questionId);
                const answer = (answerId ? question.answers.find((a) => a.id == answerId) : 0) ?? 0;
                return total + (answer?.numberValue ? answer.numberValue : 0);
            }, 0);
        },
        totalScorePercent() {
            return this.$filters.formatPercent2(this.getTotalScore);
        },
        getProfile() {
            const num1 = 70;
            const num2 = 85;
            let total = this.getTotalScore;
            if (total >= num2) {
                this.creditScore.creditProfileId = this.getCreditProfiles[0].id;
                return this.getCreditProfiles[0]
            } else if (total >= num1 && total < num2) {
                this.creditScore.creditProfileId = this.getCreditProfiles[1].id;
                return this.getCreditProfiles[1]
            } else if (total < num1) {
                this.creditScore.creditProfileId = this.getCreditProfiles[2].id;
                return this.getCreditProfiles[2]
            }
        },
        calculateAuthorizedAmount() {
            const score = (this.getTotalScore > 100) ? 100 : this.getTotalScore;
            const creditAmount = this.loanApplication.quotation.creditAmount;
            if (this.selectedPercent !== null && this.selectedPercent !== "") {
                const percent = this.getExceptionPercents.find(p => p.id === this.selectedPercent);
                this.creditScore.exceptionPercentId = percent.id;
                return (creditAmount * (percent.numberValue / 100));
            }
            this.creditScore.exceptionPercentId = null;
            return (creditAmount * (score / 100));
        },
        authorizedAmount() {
            if (this.calculateAuthorizedAmount) {
                return this.$filters.formatNumber(this.calculateAuthorizedAmount);
            } else {
                return this.$filters.formatNumber(0);
            }
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
        ...mapActions(useIndividualProspectsStore, ['fetchActiveMixedProspectsByBranchOffice']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchUnratedIndividualLoanApplicationsByProspectusId']),
        ...mapActions(useCorpLoanApplicationStore, ['fetchUnratedCorpLoanApplicationsByProspectusId']),
        ...mapActions(useCreditScoresStore, ['createCreditScore']),
        ...mapActions(sharedStore, ['resetProspectsList']),
        onSubmit() {
            this.createCreditScore(this.creditScore);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        openTable(number) {
            this.dataValues.contentNumber = number;
            this.dataValues.branchOfficeId = this.selectedBranchOfficeId;
            this.$emit('show-table', this.dataValues);
        },
        getAnswersOptions(answers) {
            return (answers) ? helper.mapOptions(answers, 'id', 'answerText') : [];
        },
        updateCreditScores() {
            this.selectedAnswers = Object.entries(this.questionsAnswers).map(([questionId, answerId]) =>
                new ScoreQuestionAnswer({ questionId: Number(questionId), answerId: Number(answerId) })
            );
            this.creditScore.selectedAnswers = this.selectedAnswers;
            this.creditScore.score = this.getTotalScore;
        },
        setNewPercent(percentId) {
            this.selectedPercent = percentId;
        },
        // validations:
        notRequired(value) {
            if (!value) return true;
            return true;
        },
    },
    watch: {
        selectedBranchOfficeId: {
            handler(newValue) {
                if (newValue) {
                    this.recordProspectus.branchOfficeId = newValue;
                    this.resetProspectsList();
                    this.fetchActiveMixedProspectsByBranchOffice(newValue);
                }
            }
        },
        getProspectus: {
            handler(newProspectus) {
                if (newProspectus != undefined && newProspectus != null && newProspectus.prospectusId != 0) {
                    if (newProspectus.personTypeId == 1) {
                        this.fetchUnratedIndividualLoanApplicationsByProspectusId(newProspectus.prospectusId);
                    } else {
                        this.fetchUnratedCorpLoanApplicationsByProspectusId(newProspectus.prospectusId);
                    }
                }
            },
            immediate: true,
        },
        loanApplication: {
            handler(newValue) {
                if (newValue?.id) {
                    this.creditScore.personTypeId = newValue.personTypeId;
                    this.creditScore.loanApplicationId = newValue.id;
                }
            },
            immediate: true,
        },
        getTotalScore: {
            handler(newValue) {
                if (newValue) {
                    this.creditScore.score = newValue;
                }
            }
        },
        getProfile: {
            handler(newProfile) {
                if (newProfile) {
                    this.creditScore.creditProfileId = newProfile.id;
                }
            }
        },
        selectedPercent: {
            handler(newValue) {
                if (newValue) {
                    this.creditScore.exceptionPercentId = newValue;
                }
            },
            immediate: true,
        },
    },
    created() { },
    mounted() {
        this.selectedBranchOfficeId = (this.tempValues?.branchOfficeId ? this.tempValues.branchOfficeId : null);
     },
}
</script>