<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>ID</th>
                <th>Nombre de Usuario</th>
                <th>Empresa</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Puesto</th>
                <th>Área</th>
                <th>Teléfono</th>
                <th>Observaciones</th>
                <th>Estatus</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in getDataPage()" :key="item.id" v-if="getDataPage().length != 0" :class="{ statusBaja: isDisabled(item) }">
                <td class="sorting_1 dtr-control"> {{ item.id }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.company.name }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.lastName }}</td>
                <td>{{ item.jobTitle }}</td>
                <td>{{ item.workArea }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.remarks }}</td>
                <td>{{ item.status.name }}</td>
                <td>
                    <PrimaryButton :disabled="isDisabled(item)" :class=" [(isDisabled(item)) ? 'btn-secondary':'btn-success'] " data-toggle="modal" data-target="#modal-lg"
                        @click.prevent="editUser(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="10" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
        }
    },
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getUsers() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        editUser(user) {
            this.$emit('edit-user', user);
        },
        deleteUser(user) {
            this.$emit('delete-user', user);
        },
        isDisabled(item) {
            return item.status.name === "Baja";
        }
    },
    created() {
        this.getDataPage();
    }
}
</script>