import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import MenuGroup from '@/Models/MenuGroup';
import MenuItem from '@/Models/MenuItem';

import * as service from '@/services';

const $toast = useToast();

export const useMenuStore = defineStore('menus', {
    persist: false,
    state: () => {
        return {
            menu_groups: [],
            menugroup: {},
            menu_items: [],
            menuitem: {},
        }
    },
    getters: {
        getMenuGroups(state) { return state.menu_groups },
        getMenuGroupById: state => id => {
            return state.menu_groups.find(c => c.id === id);
        },
        getMenuItems(state) { return state.menu_items },
        getMenuItemById: state => id => {
            return state.menu_items.find(c => c.id === id);
        },
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },

        // MenuGroups
        SET_MENU_GROUPS(menus) { this.menu_groups = menus },

        // MenuItems
        SET_MENU_ITEMS(menus) { this.menu_items = menus },

        async fetchMenuGroups() {
            try {
                const { data: data } =await service.profilesApi.get("/api/menugroup");
                const menus = data.map((menuData) => new MenuGroup(menuData));
                this.SET_MENU_GROUPS(menus);
            } catch (error) {
                $toast.error("Ocurrió un error al cargar los menus.");
                this.setErrors(error);
            }
        },
        async fetchMenuItems() {
            try {
                const { data: data } =await service.profilesApi.get("/api/menuitem");
                const menus = data.map((menuData) => new MenuItem(menuData));
                this.SET_MENU_ITEMS(menus);
            } catch (error) {
                $toast.error("Ocurrió un error al cargar los menus.");
                this.setErrors(error);
            }
        },
    },
});