<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('buttons', 'updating') }} {{ form.name }}
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre:</label>
                    <Field v-model="user.name" type="text" class="form-control" name="name" placeholder="Nombre"
                        rules="required" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="lastname">Apellidos:</label>
                    <Field v-model="user.lastName" type="text" class="form-control" name="lastname" placeholder="Apellidos"
                        rules="required" />
                    <ErrorMessage name="lastname" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="email">Correo:</label>
                    <Field v-model="user.email" type="text" class="form-control" name="email" placeholder="Correo"
                        rules="required" />
                    <ErrorMessage name="email" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="password">Contraseña:</label>
                    <Field v-model="user.password" type="password" class="form-control" name="password" placeholder="Contraseña"
                        rules="min:6" />
                    <ErrorMessage name="password" class="input-error"></ErrorMessage>
                </div>
                <!-- TODO - Agregar un campo mas para confirmar el password y poder hacer preview-->
                <div class="form-group">
                    <div class="form-group">
                        <label for="jobTitle">Puesto:</label>
                        <Field v-model="user.jobTitle" type="text" class="form-control" name="jobTitle"
                            placeholder="Puesto" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="workArea">Área:</label>
                        <Field v-model="user.workArea" type="text" class="form-control" name="workArea"
                            placeholder="Área" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="phone">Teléfono:</label>
                        <Field v-model="user.phone" type="text" class="form-control" name="phone"
                            placeholder="Teléfono" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="remarks">Observaciones:</label>
                        <Field v-model="user.remarks" type="text" class="form-control" name="remarks"
                            placeholder="Dirección" />
                    </div>
                </div>
                <div class="form-group">
                    <label for="companyId">Empresa:</label>
                    <CompaniesSelect v-model="user.companyId" :name="'companyId'" :options="companyOptions"></CompaniesSelect>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="user.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>                
                </div>
                <div class="form-group">
                    <label for="roleIds">Rol:</label>
                    <RoleSelect v-model="user.roles[0].id" :name="'roleIds'" :options="roleOptions" @update="setNewRole"></RoleSelect>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import User from '@/Models/User';
import * as helper from '@/helpers';

import { mapState, mapActions } from 'pinia';
import { useUsersStore } from '@/pinia/users.module';
import { useCompaniesStore } from '@/pinia/companies.module';
import { useRolesStore } from '@/pinia/roles.module';
import { useStatusStore } from '@/pinia/status.module';

import StatusSelect from '@/Components/Selects/SelectField.vue';
import CompaniesSelect from '@/Components/Selects/SelectField.vue';
import RoleSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton, StatusSelect, CompaniesSelect, RoleSelect },
    mixins: [],
    data() {
        return {
            statusId: null,
            companyId: null,
            form: {},
            user: new User(),
            selectedRole: null,
        }
    },
    emits: ['new-changes'],
    props: {
        selectedRecord: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        ...mapState(useCompaniesStore, ['getCompanies']),
        ...mapState(useRolesStore, ['getRoles']),
        ...mapState(useStatusStore, ['getAllStatusByType']),
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
        companyOptions() {
            return helper.mapOptions(this.getCompanies);
        },
        roleOptions() {
            return helper.mapOptions(this.getRoles);
        },
    },
    methods: {
        ...mapActions(useUsersStore, ['updateUser']),
        ...mapActions(useRolesStore, ['getRoleByUserId']),
        onSubmit() {
            this.updateUser(this.user);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal');
        },
        setNewRole(roleId) {
            this.user.roleIds = [roleId.toString()];
        },
    },
    watch: {
        selectedRecord: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.user = newValue;
                    this.setNewRole(newValue?.roles[0]?.id);
                }
            }
        },
    },
    created() { },
    mounted() { },
};
</script>