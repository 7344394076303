<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>Folio de Clientes</th>
                <th>Folio de Solicitudes</th>
                <th>Matriz y/o Sucursales</th>
                <th>Folio de Calificaciones</th>
                <th>Score</th>
                <th>Folio de Disposiciones</th>
            </tr>
        </thead>
        <tbody>
            <template v-if="getDataPage().length">
                <tr v-for="item in getDataPage()" :key="item.id">
                    <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                    <td>{{ item.provision.loanApplication.referenceNumber }}</td>
                    <td>{{ item.provision.prospectus.branchOffice.name }}</td>
                    <td>{{ item.provision.creditScore.referenceNumber }}</td>
                    <td>{{ item.provision.creditScore.score  }}</td>
                    <td>{{ item.provision.referenceNumber }}</td>
                </tr>
            </template>
            <tr v-else>
                <td colspan="6" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
        }
    },
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: { },
    methods: {
        getDataPage() {
            if (this.list == undefined) {
                return 0;
            }
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
    },
    created() {
        this.getDataPage();
    }
}
</script>