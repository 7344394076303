export default class CertificateResponse {
    constructor({
        id = 0,
        certificateId = null,
        questionId = null,
        ResponseText = null,
        certificateFieldResponses = [],
    } = {}) {
        this.id = id;
        this.certificateId = certificateId;
        this.questionId = questionId;
        this.ResponseText = ResponseText;
        this.certificateFieldResponses = certificateFieldResponses;
    }
}