<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Roles</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Roles</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Roles</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg" @click="editing = false">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <RolesTable 
                                :list="roles" 
                                :itemsPerPage="itemsPerPage" 
                                :currentPage="currentPage" 
                                @edit-role="selectedRecord = $event; editing = true"
                                @delete-role="deleteRow($event)">
                                </RolesTable>

                                <Pagination 
                                :list="roles" 
                                :itemsPerPage="itemsPerPage" 
                                :currentPage="currentPage" 
                                @current-page="currentPage = $event">
                                </Pagination>

                                <Modal>
                                    <EditRoleForm v-if="editing" :role="getSelectedRole" @closeModal="modalStatus"></EditRoleForm>
                                    <AddRoleForm v-else @closeModal="modalStatus"></AddRoleForm>
                                </Modal>

                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import $ from 'jquery';
import RolesTable from '@/Components/Tables/RolesTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddRoleForm from '@/Components/Forms/Roles/Add.vue';
import EditRoleForm from '@/Components/Forms/Roles/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import '@/Components/Validators/index';
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'pinia';
import { useRolesStore } from '@/pinia/roles.module';

export default {
    components: { 
        RolesTable, 
        Pagination, 
        Modal, 
        AddRoleForm, 
        EditRoleForm, 
        PrimaryButton, 
    },
    mixins: [],
    data() {
        return {
            selectedRecord: [],
            itemsPerPage: 7,
            currentPage: 1,
            editing: false,
        }
    },
    watch: {},
    computed: {
        ...mapState(useRolesStore, ['getRoles', 'getPaginatedRoles']),
        roles() {
            return this.getRoles;
        },
        getSelectedRole() {
            return this.selectedRecord;
        },
    },
    methods: {
        ...mapActions(useRolesStore, ['fetchRoles', 'deleteRole']),
        deleteRow(role) {
            const alert = Swal.mixin({
                buttonsStyling: true
            });
            alert.fire({
                title: `Desea eliminar ${role.name}?`,
                icon: 'question', showCancelButton: true,
                confirmButtonText: '<i class="fa fa-solid fa-check"></i> Si, eliminar',
                cancelButtonText: '<i class="fa fa-solid fa-ban"></i> Cancelar',
            }).then(res => {
                if (res.isConfirmed) {
                    this.deleteRole(role.id);
                }
            });

        },
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
    },
    created() { 
        this.fetchRoles();
    },
    mounted() {
    }
};
</script>