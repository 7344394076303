export default class Holiday {
    constructor({
        id = null,
        branchOfficeId = null,
        statusId = null,
        name = '',
        date = new Date(),
        remarks = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    } = {})
    {
        this.id = id;
        this.branchOfficeId = branchOfficeId;
        this.statusId = statusId;
        this.name = name;
        this.date = date;
        this.remarks = remarks;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}