export default class UserAuth {
    constructor({
      id = null,
      companyId = null,
      email = '',
      name = '',
      roles = null
    } = {}) {
      this.id = id;
      this.companyId = companyId;
      this.email = email;
      this.name = name;
      this.roles = roles;
    }
  }
  