import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import CreditScore from '@/Models/CreditScores/CreditScore';
import ScoreQuestion from '@/Models/CreditScores/ScoreQuestion';
import CreditProfile from '@/Models/CreditScores/CreditProfile';
import ExceptionPercent from '@/Models/CreditScores/ExceptionPercent';

import * as service from '@/services';

const $toast = useToast();

export const useCreditScoresStore = defineStore('creditScore', {
    persist: false,
    state: () => {
        return {
            creditScores: [],
            creditScore: new CreditScore({}),
            scoreQuestions: [],
            scoreQuestion: new ScoreQuestion({}),
            creditProfiles: [],
            creditProfile: new CreditProfile({}),
            exceptionPercents: [],
            exceptionPercent: new ExceptionPercent({}),
            errors: []
        }
    },
    getters: {
        getCreditScores: state => state.creditScores,
        getCreditScore: state => state.creditScore,
        getQuestions: state => state.scoreQuestions,
        getQuestion: state => state.scoreQuestion,
        getCreditProfiles: state => state.creditProfiles,
        getCreditProfile: state => state.creditProfile,
        getExceptionPercents: state => state.exceptionPercents,
        getExceptionPercent: state => state.exceptionPercent,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        // TODO: Add a ClearQuestionsList method to reset ScoreQuestions array
        async fetchCreditScores() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.creditScoresApi.get('/api/CreditScores');
                if (data && data.status) {
                    const response = data.data;
                    const creditScores = response.map(cs => new CreditScore(cs));
                    this.creditScores = creditScores;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar las calificaciones.");
                this.setErrors(error);
            }
        },
        async fetchCreditScoreById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.creditScoresApi.get(`/api/CreditScores/${id}`);
                if (data && data.status) {
                    const creditScore = data.data;
                    this.creditScore = new CreditScore(creditScore);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar la calificación.");
                this.setErrors(error);
            }
        },
        async fetchScoreQuestionsByPersonType(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.creditScoresApi.get(`/api/ScoreQuestions/personType/${id}`);
                if (data && data.status) {
                    const questions = data.data;
                    this.scoreQuestions = questions.map(q => new ScoreQuestion(q));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar las preguntas de las calificaciones.");
                this.setErrors(error);
            }
        },
        async createCreditScore(creditScore) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.creditScoresApi.post(`/api/CreditScores`, creditScore);
                if (data && data.status) {
                    this.creditScores.unshift(data.data);
                    $toast.success("Calificación creada correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al crear la calificación.");
                this.setErrors(error);
            }
        },
        async updateCreditScore(creditScore) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.creditScoresApi.put(`/api/CreditScores`, creditScore);
                if (data && data.status) {
                    $toast.success("Calificación actualizada correctamente.");
                    const index = this.creditScores.findIndex(cs => cs.id === data.data.id);
                    if (index !== -1) {
                        this.creditScores.splice(index, 1, data.data);
                        this.creditScores.sort((a, b) => a.id - b.id);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al actualizar la calificación.");
                this.setErrors(error);
            }
        },
        async fetchCreditProfiles() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.creditScoresApi.get('/api/CreditProfiles');
                if (data && data.status) {
                    const response = data.data;
                    const creditProfiles = response.map(cp => new CreditProfile(cp));
                    this.creditProfiles = creditProfiles;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los perfiles de crédito.");
                this.setErrors(error);
            }
        },
        async fetchExceptionPercents() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.creditScoresApi.get('/api/ExceptionPercents');
                if (data && data.status) {
                    const response = data.data;
                    const exceptionPercents = response.map(ep => new ExceptionPercent(ep));
                    this.exceptionPercents = exceptionPercents;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los porcentajes de excepción.");
                this.setErrors(error);
            }
        },
    }
});