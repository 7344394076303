export default class CreditScore {
    constructor({
        id,
        personTypeId,
        loanApplicationId,
        creditProfileId,
        exceptionPercentId = null,
        referenceNumber = null,
        score = null,
        questions = null,
        selectedAnswers = null,
        responses = null,
        personType = null,
        loanApplication = null,
        exceptionPercent = null,
        createdAt = new Date(),
        updatedAt = new Date()
    } = {}) {
        this.id = id;
        this.personTypeId = personTypeId;
        this.loanApplicationId = loanApplicationId;
        this.creditProfileId = creditProfileId;
        this.exceptionPercentId = exceptionPercentId;
        this.referenceNumber = referenceNumber;
        this.score = score;
        this.questions = questions;
        this.selectedAnswers = selectedAnswers;
        this.responses = responses;
        this.personType = personType;
        this.loanApplication = loanApplication;
        this.exceptionPercent = exceptionPercent;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}