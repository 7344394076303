export default class LoanApplication {
    constructor({
        id = 0,
        personTypeId = null,
        prospectusId = null,
        quotationId = null,
        financialFunderId = null,
        applicantTypeId = null,
        statusId = null,
        referenceNumber = null,
        applicationDate = null,
        applicantsName = null,
        numberOfDisbursements = 1,
        prospectus = null,
        quotation = null,
        applicantType = null,
        status = null,
        financialInformation = null,
        monthlyIncomes = null,
        monthlyExpenses = null,
        economicDependents = null,
        applicantLoanReferences = null,
        commercialLoanReferences = null,
        bankLoanReferences = null,
        directDebit = null,
        legalEntityGuarantee = null,
        productDetails = null,
        interview = null,
        createdAt = new Date(),
        updatedAt = new Date()
    } = {}) {
        this.id = id;
        this.personTypeId = personTypeId;
        this.prospectusId = prospectusId;
        this.quotationId = quotationId;
        this.financialFunderId = financialFunderId;
        this.applicantTypeId = applicantTypeId;
        this.statusId = statusId;
        this.referenceNumber = referenceNumber;
        this.applicationDate = applicationDate;
        this.applicantsName = applicantsName;
        this.numberOfDisbursements = numberOfDisbursements;
        this.prospectus = prospectus;
        this.quotation = quotation;
        this.applicantType = applicantType;
        this.status = status;
        this.financialInformation = financialInformation;
        this.monthlyIncomes = monthlyIncomes;
        this.monthlyExpenses = monthlyExpenses;
        this.economicDependents = economicDependents;
        this.applicantLoanReferences = applicantLoanReferences;
        this.commercialLoanReferences = commercialLoanReferences;
        this.bankLoanReferences = bankLoanReferences;
        this.directDebit = directDebit;
        this.legalEntityGuarantee = legalEntityGuarantee;
        this.productDetails = productDetails;
        this.interview = interview;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}