export default class EconomicDependent {
  constructor({
    id = 0, 
    loanApplicationId = 0, 
    spouse = 0, 
    children = 0, 
    parents = 0, 
    siblings = 0, 
    other = 0,
    total = 0,
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.spouse = spouse || 0;
    this.children = children || 0;
    this.parents = parents || 0;
    this.siblings = siblings || 0;
    this.other = other || 0;
    this.total = total || 0;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
