import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { getLocaleMessage } from '@/services/MessagesService';

import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Document from '@/Models/Document';

import * as service from '@/services';

const $toast = useToast();

export const documentsStore = defineStore('documents', {
    persist: false,
    state: () => {
        return {
            documents: reactive([]),
            document: new Document(),
            activeDocuments: reactive([]),
            activeDocument: new Document(),
            errors: null,
        }
    },
    getters: {
        getDocuments: state => state.documents,
        getDocument: state => state.document,
        getDocumentById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.documents.find(c => c.id === id);
        },
        getActiveDocuments: state => state.activeDocuments,
        getActiveDocument: state => state.activeDocument,
        getActiveDocumentById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activeDocuments.find(c => c.id === id);
        },
        filterDocumentsBySearchString: state => (searchString = '') => {
            if (!searchString) return state.documents;
            console.log(state.documents)
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.documents.filter(document => {
                const documentName = document.documentName ? document.documentName.toLowerCase() : '';
                const statusName = document.status?.name ? document.status?.name.toLowerCase() : '';

                return documentName.includes(lowerCaseSearchString) || statusName.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        setSelectedDocument(id) {
            this.document = this.getDocumentById(id);
        },
        async fetchDocumentsByCompany(id = 1) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Documents/company/${id}`);
                if (data && data.status) {
                    this.documents = data.data.map(d => new Document(d));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchDocumentationByRecordId(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Documents/GetDocumentByRecordId/${id}`);
                if (data && data.status) {
                    this.documents = data.data.map(d => new Document(d));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveDocumentsByCompany(id = 1) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Documents/company/${id}/active`);
                if (data && data.status) {
                    this.activeDocuments = data.data.map(d => new Document(d));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchDocumentById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/Documents/${id}`);
                if (data && data.status) {
                    this.document = new Document(data.data);
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createDocument(document) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.post('/Documents', document);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('documents', 'creatingSuccess'));
                    this.document = new Document(data.data);
                    // Add the new document to the list 
                    this.documents.unshift(new Document(data.data));
                } else {
                    $toast.success(getLocaleMessage('documents', 'creatingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateDocument(document) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.put(`/Documents/`, document);
                if (data && data.status) {
                    this.document = new Document(data.data);
                    // Update the document in the list
                    const index = this.documents.findIndex(c => c.id === document.id);
                    this.documents.splice(index, 1, new Document(data.data));
                    $toast.success(getLocaleMessage('documents', 'editingSuccess'));
                } else {
                    $toast.success(getLocaleMessage('documents', 'editingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        }
    },
});