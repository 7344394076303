<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>Folio de Solicitudes</th>
                <th>Folio de Cotizaciones</th>
                <th>Folio del Prospectos</th>
                <th>Matriz y/o Sucursales</th>
                <th>Solicitante</th>
                <th>Fecha de solicitud</th>
                <th>Tipo de persona</th>
                <th>Estatus</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in getDataPage()" :key="item.id" v-if="getDataPage().length != 0">
                <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                <td>{{ item.quotation.referenceNumber}}</td>
                <td>{{ item.prospectus.referenceNumber}}</td>
                <td>{{ item.prospectus.branchOffice.name }}</td>
                <td>{{ item.applicantsName }}</td>
                <td>{{ $filters.formatDate(item.applicationDate) }}</td>
                <td>{{ item.prospectus.personType.name }}</td>
                <td>{{ item.status.name }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" :data-target="dataTarget"
                        @click.prevent="editLoanApplication(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="9" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
        }
    },
    emits: ['edit-loan-application'],
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        dataTarget: {
            type: String,
            default: '#modal-lg'
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getLoanApplications() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            if (this.list == undefined) {
                return 0;
            }
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        editLoanApplication(loanApplication) {
            this.$emit('edit-loan-application', loanApplication);
        },
    },
    created() {
        this.getDataPage();
    }
}
</script>