import Prospectus from "./Prospects";

export default class Identification extends Prospectus {
    constructor({ ...rest } = {}) {
        super(rest);
        // additional attribute
        this.branchOfficeName = this?.branchOffice?.name ?? "";
        this.personTypeName = this?.personType?.name ?? "";
        this.generalInformationEmail = this?.generalInformation?.email || "";
        this.statusName = this?.status?.name ?? "";

        this.indentificationPhone = this.generalInformation.businessName
            ? this.generalInformation.businessName
            : `${this.generalInformation.firstName || ''} ${this.generalInformation.lastName || ''} ${this.generalInformation.mothersMaidenName || ''}`.trim();
        this.indentificationPostalCode = this.personTypeId == 1 ? this.homeAddress?.postalCode ?? ""
            : this.fiscalAddress?.postalCode ?? "";
        this.indetificationName = this.generalInformation?.businessName ? this.generalInformation?.businessName ?? ""
            : `${this.generalInformation?.firstName ?? ""} ${this.generalInformation?.lastName ?? ""} ${this.generalInformation?.mothersMaidenName ?? ""}`;


    }
}