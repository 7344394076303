import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Role from '../Models/Role';
import MenuGroup from '@/Models/MenuGroup';

import * as service from '@/services';

const $toast = useToast();

export const useRolesStore = defineStore('roles', {
    persist: false,
    state: () => {
        return {
            roles: [],
            role: {},
            paginatedRoles: [],
            menuGroups: [],
        }
    },
    getters: {
        getSelectedRole: state => state.role,
        getRoles: state => state.roles,
        getRoleById: state => id => {
            return state.roles.find(c => c.id === id);
        },
        getPaginatedRoles: state => state.paginatedRoles,
        getMenuGroupsByRoleId: state => {
            return state.menuGroups;
        }
    },
    actions: {
        SET_ROLES(roles) { this.roles = roles },
        SET_ROLE(role) { this.role = role },
        NEW_ROLE(role) { this.roles.unshift(role) },
        UPDATE_ROLE(role) {
            const index = this.roles.findIndex(c => c.id === role.id);
            if (index !== -1) {
                this.roles.splice(index, 1, role);
            }
        },
        DELETE_ROLE(id) {
            const index = this.roles.findIndex(c => c.id === id);
            if (index !== -1) {
                this.roles.splice(index, 1);
            }
        },
        setPaginatedRoles(roles) { this.paginatedRoles = roles },
        updatePaginatedRole(role) {
            const index = this.paginatedRoles.findIndex(c => c.id === role.id);
            if (index !== -1) {
                this.paginatedRoles.splice(index, 1, role);
            }
        },
        setErrors(errors) {
            this.errors = errors;
        },

        setCurrentRole(role) {
            this.SET_ROLE(role);
        },
        async fetchRoles() {
            try {
                const { data: data } = await service.profilesApi.get("/api/roles");
                const roles = data.map((roleData) => new Role(roleData));
                this.SET_ROLES(roles);
            } catch (error) {
                $toast.error("Ocurrió un error al cargar los roles.");
                this.setErrors(error);
            }
        },
        async fetchMenuGroupByRoleId(id)
        {
            try {
                const { data } = await service.profilesApi.get(`/api/roles/GetMenuGroupByRoleId/${id}`);
                const menus = data.map((menuData) => new MenuGroup(menuData));
                this.menuGroups = menus;
            } catch (error) {
                $toast.error("Ocurrió un error al cargar los menus.");
                this.setErrors(error);
            }
        },
        async getRoleByUserId(id) {
            try {
                const { data } = await service.profilesApi.get(`/api/roles/GetRoleByUserId/${id}`);
                const role = new Role(data[0]);
                this.SET_ROLE(role);
            } catch (error) {
                this.setErrors(error);
            }
        },
        async createRole(role) {
            try {
                const { data: data } = await service.profilesApi.post(`/api/roles`, role);
                if (data) {
                    $toast.success("Rol creado correctamente.");
                    this.NEW_ROLE(data);
                }
            } catch (error) {
                $toast.error("Ocurrió un error al crear el rol.");
                this.setErrors(error);
            }
        },
        async updateRole(role) {
            try {
                const { data: data } = await service.profilesApi.put(`/api/roles/${role.id}`, role);
                if (data) {
                    $toast.success("Rol actualizado correctamente.");
                    this.UPDATE_ROLE(data);
                }
            } catch (error) {
                $toast.error("Ocurrió un error al actualizar el rol.");
                this.setErrors(error);
            }
        },
        async deleteRole(id) {
            try {
                const { data: data } = await service.profilesApi.delete(`/api/roles/${id}`);
                $toast.success("Rol eliminado correctamente.");
                this.DELETE_ROLE(id);
                return data;
            } catch (error) {
                $toast.error("Ocurrió un error al eliminar el rol.");
                this.setErrors(error);
            }
        },
    },
});