<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>ID</th>
                <th>Nombre del rol</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in getDataPage()" :key="item.id" v-if="getDataPage().length != 0">
                <td class="sorting_1 dtr-control"> {{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-lg"
                        @click.prevent="editRole(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                    <PrimaryButton class="btn-danger" @click.prevent="deleteRole(item)"><i class="fa fa-trash"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="5" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
        }
    },
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getRoles() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        editRole(role) {
            this.$emit('edit-role', role);
        },
        deleteRole(role) {
            this.$emit('delete-role', role);
        }
    },
    created() {
        this.getDataPage();
    }
}
</script>