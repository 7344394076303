<template>
    <section class="content mt-5">
        <div class="error-page">
            <h2 class="headline text-warning"> 404</h2>
            <div class="error-content">
                <h3><i class="fas fa-exclamation-triangle text-warning"></i> ¡Ups! Página no encontrada.</h3>
                <p>
                    No pudimos encontrar la página que estabas buscando.
                    Mientras tanto puedes...
                </p>
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-success btn-block" to="/login">Volver a iniciar sesión</router-link>
                    </div>
                </div>
            </div>

        </div>

    </section>
</template>