export default class MonthlyIncome {
  constructor({
    id = 0, 
    loanApplicationId = 0, 
    salaries = 0, 
    rentalIncome = 0, 
    dividends = 0, 
    interests = 0, 
    other = 0, 
    total = 0,
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.salaries = salaries || 0;
    this.rentalIncome = rentalIncome || 0;
    this.dividends = dividends || 0;
    this.interests = interests || 0;
    this.other = other || 0;
    this.total = total || 0;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
