export default class Questionnaire {
    constructor({
        id = 0,
        contextId = 3,
        loanApplicationId = null,
        beneficiaryId = null,
        statusId = 2,
        referenceNumber = null,
        interviewerFullName = null,
        interviewerSignature = null,
        intervieweeFullName = null,
        intervieweeSignature = null,
        address = null,
        questions = null,
        loanApplication = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date()
    } = {}) {
        this.id = id;
        this.contextId = contextId;
        this.loanApplicationId = loanApplicationId;
        this.beneficiaryId = beneficiaryId;
        this.statusId = statusId;
        this.referenceNumber = referenceNumber;
        this.interviewerFullName = interviewerFullName;
        this.interviewerSignature = interviewerSignature;
        this.intervieweeFullName = intervieweeFullName;
        this.intervieweeSignature = intervieweeSignature;
        this.address = address;
        this.questions = questions;
        this.loanApplication = loanApplication;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}