<template>
    <table class="table table-hover no-overflow">
          <thead>
              <tr>
                  <th @click="sortBy('referenceNumber')">
                      Folio de Bancos
                      <span v-if="sortKey === 'referenceNumber'">
                          <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                      </span>
                  </th>
                  <th @click="sortBy('name')">
                      Nombre
                      <span v-if="sortKey === 'name'">
                          <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                      </span>
                  </th>
                  <th @click="sortBy('createdAt')">
                      Fecha de creación
                      <span v-if="sortKey === 'createdAt'">
                          <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                      </span>
                  </th>
                  <th @click="sortBy('updatedAt')">
                      Fecha de actualización
                      <span v-if="sortKey === 'updatedAt'">
                          <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                      </span>
                  </th>
                  <th>Acciones</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="item in sortedData" :key="item.id" v-if="sortedData.length != 0">
                  <td class="sorting_1 dtr-control">{{ item.referenceNumber }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ $filters.formatDate(item.createdAt) }}</td>
                  <td>{{ updatedAt(item.updatedAt) }}</td>
                  <td>
                      <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-lg"
                          @click.prevent="editBank(item)"><i class="fa fa-edit"></i>
                      </PrimaryButton>
                  </td>
              </tr>
              <tr v-else>
                  <td colspan="5" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
              </tr>
          </tbody>
      </table>
  </template>
  
  <script>
  import PrimaryButton from '@/Components/PrimaryButton.vue';
  
  export default {
      components: { PrimaryButton },
      emits: ['edit-bank'],
      props: {
          list: {
              type: Array,
              default: () => [],
              required: true,
          },
          itemsPerPage: {
              type: Number,
              default: () => 10,
          },
          currentPage: {
              type: Number,
              default: () => 1
          }
      },
      data() {
          return {
              sortKey: '',
              sortOrder: 1, // 1 for ascending, -1 for descending
          };
      },
      computed: {
          sortedData() {
              let sortedList = [...this.list];
              if (this.sortKey) {
                  sortedList.sort((a, b) => {
                      let aValue = this.getNestedValue(a, this.sortKey);
                      let bValue = this.getNestedValue(b, this.sortKey);
  
                      if (aValue < bValue) return -1 * this.sortOrder;
                      if (aValue > bValue) return 1 * this.sortOrder;
                      return 0;
                  });
              }
              return this.paginate(sortedList);
          }
      },
      methods: {
          sortBy(key) {
              if (this.sortKey === key) {
                  this.sortOrder *= -1; // Toggle the sort order
              } else {
                  this.sortOrder = 1; // Reset to ascending if a new column is sorted
              }
              this.sortKey = key;
          },
          getNestedValue(obj, key) {
              return key.split('.').reduce((o, i) => o[i], obj);
          },
          paginate(list) {
              if (!list || list.length === 0) return [];
              let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
              return list.slice(start, start + this.itemsPerPage);
          },
          updatedAt(date) {
              if (date === '0001-01-01T00:00:00') return null;
              return this.$filters.formatDate(date);
          },
          editBank(item) {
              this.$emit('edit-bank', item);
          }
      }
  }
  </script>  