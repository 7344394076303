<template>
        <div class="wrapper">
        <!-- Navbar -->
        
        <!-- /.navbar -->
        <Menu ></Menu>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper" :class="$route.name">
            <router-view></router-view>
        </div>
        <!-- /.content-wrapper -->

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->

        <!-- Main Footer -->
        <Footer></Footer>
    </div>
    <!-- ./wrapper -->
</template>

<script>
import Menu from '@/Layouts/Partials/Menu.vue';
import Footer from '@/Layouts/Partials/Footer.vue';

export default {
    components: {
        Menu,
        Footer,
    },
    computed: {},
    mounted() {},
}
</script>