<template>
    <div class="d-flex justify-content-between">
        <h3 class="card-title">Lista de Identificaciones</h3>
    </div>
    <br>

    <div class="position-relative col-sm-12">

        <IdentificationTable :list="getIdentifications" :currentPage="currentPage" :itemsPerPage="itemsPerPage" @click-on-edit-btn="openEditionForm">
        </IdentificationTable>
        <Pagination :list="getIdentifications" :currentPage="currentPage" :itemsPerPage="itemsPerPage">
        </Pagination>

        <Modal>
            <EditPFDetails v-if="modalContent == 1" 
                :initialData="getProspectus"
                :fetchById="individualProspectusById"
                :onSubmit="updateIndividualIdentification" 
                :formTitle="updatePFFormTitle" 
                :submitButtonText="updateButtonText" 
                :statusValues="getIdentificationStatus"
                :notBeneficiary="true" 
                @closeModal="modalStatus"
                >
            </EditPFDetails>
            <EditPMDetails v-if="modalContent == 2" 
                :initialData="getProspectus"
                :fetchById="corporateProspectusById"
                :onSubmit="updateCorporateIdentification" 
                :formTitle="updatePMFormTitle" 
                :submitButtonText="updateButtonText" 
                :statusValues="getIdentificationStatus"
                :notBeneficiary="true" 
                @closeModal="modalStatus"
            >
            </EditPMDetails>
        </Modal>

    </div>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import IdentificationTable from '@/Components/Tables/IdentificationTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import { sharedStore } from '@/pinia/sharedStore.module';
import { identificationsStore } from '@/pinia/identifications.module';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useCorporateProspectsStore } from '@/pinia/Prospects/corporate.module';
import { useStatusStore } from '@/pinia/status.module';

import Modal from '@/Components/Bootstrap/Modal.vue';
import EditPFDetails from '@/Components/Forms/LoanApplications/PLD/IdentificationPFDetails.vue';
import EditPMDetails from '@/Components/Forms/LoanApplications/PLD/IdentificationPMDetails.vue';

import Prospectus from '@/Models/Prospects';

export default {
    components: {
        IdentificationTable,
        Pagination,
        Modal,
        EditPFDetails,
        EditPMDetails,
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 10,
            modalId: 'modal-lg',
            modalContent: 0,
            selectedRecordId: 0,
            personTypeId: 0,
            prospectus : new Prospectus(),
            prospectusGetter: {
                getter: () => sharedStore().getProspectus,
                processIndividualData: (newValue) => {
                    const validProperties = ['generalInformation', 'homeAddress', 'nationalAddress'];
                    if (helper.validateObjectProperties(newValue, validProperties)) {
                        return {
                            pfType: newValue.pfType,
                            statusId: newValue.statusId,
                            generalInformation: newValue.generalInformation,
                            homeAddress: newValue.homeAddress,
                            nationalAddress: newValue.nationalAddress,
                        }
                    }
                    return {}
                }
            },
            corporateProspectusGetter: {
                getter: () => sharedStore().getProspectus,
                processCorporateData: (newValue) => {
                    const validProperties = ['generalInformation', 'fiscalAddress', 'legalRepresentative'];
                    if (helper.validateObjectProperties(newValue, validProperties)) {
                        return {
                            generalInformation: newValue.generalInformation,
                            fiscalAddress: newValue.fiscalAddress,
                            legalRepresentative: newValue.legalRepresentative,
                        }
                    }
                    return {};
                }
            }
        }
    },
    computed: {
        ...mapState(sharedStore, ['getProspectus']),
        ...mapState(identificationsStore, ['getIdentifications']),
        updatePFFormTitle() {
            return this.$getLocaleMessage('formTitles', 'update') + ' ' + "Identificación de Persona Física";
        },
        updatePMFormTitle() {
            return this.$getLocaleMessage('formTitles', 'update') + ' ' + "Datos y Documentos de Identificación de los Clientes o Usuarios";
        },
        updateButtonText() {
            return this.$getLocaleMessage('buttons', 'update');
        },
        getIdentificationStatus() {
            return useStatusStore().getAllStatusByType(2);
        },
    },
    methods: {
        ...mapActions(useIndividualProspectsStore, ['fetchIndividualProspectusById','updateProspect']),
        ...mapActions(identificationsStore, ['fetchIdentificationsById']),
        openEditionForm(selectedItem) {
            this.selectedRecordId = selectedItem.prospectusId;
            this.modalContent = selectedItem.personTypeId;
        },
        modalStatus(status) {
            if (status) {
                $(`#${this.modalId}`).modal('hide');
            }
        },
        updateIndividualIdentification(formData) {
            // Add form submission logic here
            this.prospectus = {
                prospectusId: this.getProspectus.prospectusId, 
                branchOfficeId: this.getProspectus.branchOfficeId,
                statusId: this.getProspectus.statusId,
                ...formData,
            };
            useIndividualProspectsStore().updateProspect(this.prospectus);
        },
        updateCorporateIdentification(formData) {
            // Add form submission logic here
            this.prospectus = {
                prospectusId: this.getProspectus.prospectusId, 
                branchOfficeId: this.getProspectus.branchOfficeId,
                statusId: this.getProspectus.statusId,
                ...formData,
            };
            useCorporateProspectsStore().updateProspect(this.prospectus);
        },
        individualProspectusById() {
            useIndividualProspectsStore().fetchIndividualProspectusById(this.selectedRecordId);
        },
        corporateProspectusById() {
            useCorporateProspectsStore().fetchCorpProspectusById(this.selectedRecordId);
        },
    },
    watch: {
    },
    created() {
        this.fetchIdentificationsById();
        useStatusStore().fetchStatus();
    },
    mounted() {
        $(`#${this.modalId}`).on('hidden.bs.modal', () => {
            this.modalContent = 0;
        });
    }
}
</script>