import { defineStore } from 'pinia';
import { getLocaleMessage } from '@/services/MessagesService';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useHandlerStore } from '@/pinia/handler.module';

import Status from '../Models/Status';

import * as service from '@/services';

const $toast = useToast();

export const useStatusStore = defineStore('status', {
    state: () => ({
        status: [],
        selectedStatus: new Status(),
        paginatedStatus: [],
        errors: null,
    }),
    getters: {
        getSelectedStatus: (state) => {
            if (typeof state.selectedStatus === 'undefined' || state.selectedStatus === null) return new Status({});
            return state.selectedStatus;
        },
        getStatus: (state) => state.status,
        getStatusById: (state) => (id = 0) => {
            if (typeof id === "undefined" || id === 0 || id === null) return {};
            return state.status.find((s) => s.id === id);
        },
        getAllStatusByType: (state) => (typeId) => {
            return state.status.filter((s) => s.statusTypeId === typeId);
        },
        getPaginatedStatus: (state) => state.paginatedStatus,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        setSelectedStatus(id) {
            this.selectedStatus = this.getStatusById(id);
        },
        newStatus(status) {
            this.status.unshift(status);
        },
        updateStatus(status) {
            const index = this.status.findIndex((s) => s.id === status.id);
            if (index !== -1) {
                this.status.splice(index, 1, status);
            }
        },
        deleteStatus(id) {
            const index = this.status.findIndex((s) => s.id === id);
            if (index !== -1) {
                this.status.splice(index, 1);
            }
        },
        setPaginatedStatus(status) {
            this.paginatedStatus = status;
        },
        updatePaginatedStatus(status) {
            const index = this.paginatedStatus.findIndex((s) => s.id === status.id);
            if (index !== -1) {
                this.paginatedStatus.splice(index, 1, status);
            }
        },
        async fetchStatus() {
            try {
                const data = await service.statusApi.get('/Status');
                if (data) {
                    useLoadingStore().loading = false;
                    this.status = data.data;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createStatus(status) {
            try {
                const response = await service.statusApi.post('/Status', status);
                this.newStatus(response.data);
            } catch (error) {
                this.setErrors(error);
            }
        },
        async updateStatus(status) {
            try {
                const response = await service.statusApi.put(`/Status`, status);
                this.updateStatus(response.data);
            } catch (error) {
                this.setErrors(error);
            }
        },
        async deleteStatus(id) {
            try {
                const response = await service.statusApi.delete(`/Status`, id);
                this.deleteStatus(id);
            } catch (error) {
                this.setErrors(error);
            }
        },
    },
});