<template>
    <div class="card" :class="customClass">
        <div class="card-header" :headerClass="headerClass">
            <slot name="header"></slot>
        </div>
        <div class="row card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: '',
    },
    headerClass: {
      type: String,
      default: '',
    },
  },
};
</script>