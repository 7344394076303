<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de documentación</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Documentación</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">

                            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="custom-tabs-four-records-tab" data-toggle="pill"
                                        href="#custom-tabs-four-records" role="tab"
                                        aria-controls="custom-tabs-four-records" aria-selected="true"
                                        @click="bodyContent = 1">Expedientes</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-documents-tab" data-toggle="pill"
                                        href="#custom-tabs-four-documents" role="tab"
                                        aria-controls="custom-tabs-four-documents" aria-selected="false"
                                        @click="bodyContent = 2">Documentos</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-checklists-tab" data-toggle="pill"
                                        href="#custom-tabs-four-checklists" role="tab"
                                        aria-controls="custom-tabs-four-checklists" aria-selected="false"
                                        @click="bodyContent = 3">Checklists</a>
                                </li>
                            </ul>

                        </div>
                        <div class="card-body">

                            <div class="tab-content" id="custom-tabs-four-tabContent">
                                <div class="tab-pane fade active show" id="custom-tabs-four-records" role="tabpanel"
                                    aria-labelledby="custom-tabs-four-records-tab">
                                    <Records v-if="bodyContent === 1"></Records>
                                </div>
                                <div class="tab-pane fade active show" id="custom-tabs-four-documents" role="tabpanel"
                                    aria-labelledby="custom-tabs-four-documents-tab">
                                    <Documents v-if="bodyContent === 2"></Documents>
                                </div>
                                <div class="tab-pane fade active show" id="custom-tabs-four-checklists" role="tabpanel"
                                    aria-labelledby="custom-tabs-four-checklists-tab">
                                    <CheckLists v-if="bodyContent === 3"></CheckLists>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import { mapActions } from "pinia";
import { useStatusStore } from '@/pinia/status.module';

import Records from './Records.vue';
import Documents from './Documents.vue';
import CheckLists from './CheckLists.vue';

export default {
    components: {
        Records,
        Documents,
        CheckLists,
    },
    data() {
        return {
            bodyContent: 1,
            editing: false,
        }
    },
    methods: {
        ...mapActions(useStatusStore, ['fetchStatus']),
    },
    mounted() {
        this.fetchStatus();
    }
}
</script>