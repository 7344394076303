<template>
  <Form ref="form" @submit="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Subir documentación digitalizada</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Información</h3>
        </div>
        <div class="row card-body">
          <div class="form-group col-sm-6">
            <label for="branchOfficeId">Matriz y/o Sucursales:</label>
            <BranchOfficesSelect v-model="selectedBranchOfficeId" :name="'branchOfficeId'" :options="activeBranchOfficesOptions"></BranchOfficesSelect>
          </div>
          <div class="col-sm-6 btn-search-table">
            <PrimaryButton v-if="enableProspectsBtn" class="btn btn-success" @click.prevent="emitData(2)"><i class="fa fa-search"></i>Seleccionar Prospecto</PrimaryButton>
          </div>

          <div class="form-group col-sm-6">
            <label for="ProspectFullname">Prospecto:</label>
            <Field v-model="getProspectusFullname" type="text" class="form-control col-sm-9" name="ProspectFullname" disabled/>
          </div>
          <div class="col-sm-6 btn-search-table">
            <PrimaryButton v-if="enableLoanApplicationsBtn" class="btn btn-success" @click.prevent="emitData(3)"><i class="fa fa-search"></i>Seleccionar Solicitud</PrimaryButton>
          </div>
          
          <div class="form-group col-sm-6">
            <label for="loanApplication">Folio de Solicitud:</label>
            <Field ref="loanApplicationField" v-model="getLoanApplication.referenceNumber" type="text" class="form-control col-sm-9" name="loanApplication" :rules="validateExistence" disabled/>
            <ErrorMessage name="loanApplication" class="input-error"></ErrorMessage>
          </div>

          <div class="form-group col-sm-12">
            <label for="recordTypeId">Tipos de expendiente:</label>
            <RecordTypesSelect v-model="selectedRecordTypeId" :name="'recordTypeId'" :options="activeRecordsOptions"></RecordTypesSelect>
          </div>
          <div class="col-sm-6 btn-search-table">
            <PrimaryButton v-if="enableRecordsBtn" class="btn btn-success" @click.prevent="emitData(4)"><i class="fa fa-search"></i>Seleccionar Expediente</PrimaryButton>
          </div>

          <div class="form-group col-sm-6">
          </div>
          <div class="form-group col-sm-6">
            <label for="referenceNumber">Folio de Expediente:</label>
            <Field
              v-model="financialRecord.referenceNumber"
              type="text"
              class="form-control"
              name="referenceNumber"
              disabled
            />
            <label for="name">Nombre de Expediente:</label>
            <Field
              v-model="financialRecord.name"
              type="text"
              class="form-control"
              name="name"
              disabled
            />
          </div>
          <div class="form-group col-sm-12">
            <Field name="completed" v-slot="{ field }">
                <label>
                    <input type="checkbox" name="completed" v-bind="field" v-model="isCompleted"/>
                    Marcar este Checklist como completado
                </label>
            </Field>
          </div>
        </div>
      </div>

      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Documentación</h3>
        </div>
        <div class="card-body">
          <div class="row" v-show="getActiveChecklists">
            <div
              class="info-box col-12 col-sm-12"
              v-for="(element, idx) in getActiveChecklists"
              :key="idx"
            >
              <div class="form-group">
                <label :for="'exampleInputFile'+idx">{{ element.document.documentName }}{{ (element.remarks) ? ` (${element.remarks})` : '' }}:</label>
                <div class="input-group">
                  <Field :name="'file-' + idx" type="file" :accept="allowedFileTypes" :id="'exampleInputFile' + idx" ref="fileInputs" :rules="validateDocument(element)" @change="handleFileChange($event,idx, element)"></Field>
                  <ErrorMessage :name="'file-' + idx" class="input-error"></ErrorMessage>

                  <!-- <Field
                    :name="'file-' + idx"
                    ref="fileInputs"
                    rules="required"
                    v-slot="{ field, errors }"
                  >
                    <label>
                      <input
                        v-bind="field"
                        type="file"
                        :accept="allowedFileTypes"
                        :id="'exampleInputFile' + idx"
                        class=""
                        @change="(e) => { handleFileChange(idx, element, e); }"
                      />
                      <span class="file-custom"></span>
                    </label>
                  </Field> -->

                  <!-- <label>
                    <input
                    type="file"
                    ref="fileInputs"
                    class=""
                    :id="'exampleInputFile'+idx"
                    accept=".jpg, .jpeg, .png, .gif, .pdf"
                    @change="handleFileChange(idx)"
                  />
                  <span class="file-custom"></span>
                  </label> -->
                  
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-show="getActiveChecklists.length === 0">
            <p class="">Seleccione un Tipo de Expediente y un Expediente para mostrar una lista de documentos.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        {{ cancelButtonText }}
      </button>
      <PrimaryButton class="btn btn-primary" type="submit">
        {{ createButtonText }}
      </PrimaryButton>
    </div>
  </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from "pinia";
import { useBranchOfficesStore } from "@/pinia/branchOffices.module";
import { useRecordTypesStore } from "@/pinia/recordTypes.module";
import { useIndividualProspectsStore } from "@/pinia/Prospects/individual.module";
import { financialRecordsStore } from "@/pinia/financialRecords.module";
import { checklistsStore } from '@/pinia/checklists.module';
import { useStatusStore } from '@/pinia/status.module';
import { sharedStore } from "@/pinia/sharedStore.module";

import BranchOfficesSelect from "@/Components/Selects/SelectField.vue";
import RecordTypesSelect from "@/Components/Selects/SelectField.vue";
import StatusSelect from "@/Components/Selects/SelectField.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";

import Prospectus from '@/Models/Prospects';
import FinancialRecord from '@/Models/FinancialRecord';
import RecordsProspects from '@/Models/RecordsProspects';
import RecordFileForm from '@/Models/RecordFileForm';

export default {
  components: {
    PrimaryButton,
    StatusSelect,
    BranchOfficesSelect,
    RecordTypesSelect,
  },
  mixins: [],
  data() {
    return {
      dataValues: {},
      recordProspectus: new RecordsProspects({}),
      recordFileFormDto: new RecordFileForm({}),
      selectedBranchOfficeId: null,
      selectedRecordTypeId: null,
      files: {},
      documentIds: [],
      fileInputs: [],
      isCompleted: false,
      recordExists: false,
      allowedFileTypes: ".jpg, .jpeg, .png, .gif, .pdf",
    }
  },
  emits: ['close-modal', 'emit-form-data',],
  props: {
    prospectus: {
      type: Object,
      default: () => new Prospectus({}),
    },
    loanApplication: {
      type: Object,
      default: () => {},
    },
    financialRecord: {
      type: Object,
      default: () => new FinancialRecord({}),
    },
    tempValues: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
    ...mapState(useRecordTypesStore, ['getRecordTypes', 'getSelectedRecordType']),
    ...mapState(useIndividualProspectsStore, ['getAllProspects', 'getSelectedProspectus', 'getProspectBySelected']),
    ...mapState(sharedStore, ['getProspectusFullname', 'getLoanApplication']),
    ...mapState(financialRecordsStore, ['getFinancialRecords', 'getActiveDocuments']),
    ...mapState(checklistsStore, ['getActiveChecklists']),
    ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
    cancelButtonText() {
        return this.$getLocaleMessage('buttons', 'cancel');
    },
    createButtonText() {
        return this.$getLocaleMessage('buttons', 'create')
    },
    activeBranchOfficesOptions() {
      return (this.getActiveBranchOffices) ? this.branchOfficeOptions(this.getActiveBranchOffices) : [];
    },
    activeRecordsOptions() {
      return (this.getRecordTypes) ? helper.mapOptions(this.getRecordTypes) : [];
    },
    statusOptions() {
      return (this.getAllStatusByType(4)) ? helper.mapOptions(this.getAllStatusByType(4)) : [];
    },
    branchOfficeIsSelected() {
      return this.selectedBranchOfficeId !== null;
    },
    recordTypeIsSelected() {
      return this.selectedRecordTypeId !== null;
    },
    prospectusIsSelected() {
      return this.prospectus.prospectusId != null && this.prospectus.prospectusId !== 0;
    },
    enableProspectsBtn() {
        return this.branchOfficeIsSelected || this.prospectusIsSelected;
    },
    enableRecordsBtn() {
        return this.recordTypeIsSelected;
    },
    enableLoanApplicationsBtn() {
      return this.prospectusIsSelected;
    },
    selectedRecordType() {
      return this.getSelectedRecordType.id;
    },
    selectedStatus() {
      return this.getSelectedStatus.id;
    },
  },
  methods: {
    ...mapActions(useIndividualProspectsStore, ['fetchActiveMixedProspectsByBranchOffice']),
    ...mapActions(financialRecordsStore, ['fetchActiveFinancialRecordsByCompanyAndRecordType', 'hasRecordType', 'createDocumentFile']),
    ...mapActions(checklistsStore, ['fetchActiveChecklistsByRecordId']),
    onSubmit() {
      const formData = new FormData();
      // Add files to the FormData
      formData.append('documentFileDto.loanApplicationId', this.loanApplication.id);
      formData.append('documentFileDto.FinancialRecordId', this.financialRecord.id);
      formData.append('documentFileDto.RecordTypeId', this.selectedRecordTypeId);
      formData.append('documentFileDto.IsCompleted', this.isCompleted);

      Object.values(this.documentIds).forEach((item, index) => {
        formData.append(`documentFileDto.DocumentIds[${index}]`, item);
      });

      Object.values(this.files).forEach((file, index) => {
        formData.append(`documentFileDto.Files`, file);
      });

      // for(var pair of formData.entries()) {
      //   console.log(pair[0]+ ', '+ pair[1]); 
      // }

      this.createDocumentFile(formData);
      this.$refs.form.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$emit("close-modal", true);
    },
    // Here we emit the values to the Index.vue component
    // to avoid losing them when the form component is unmouted and mounted again
    emitData(number) {
        this.dataValues.contentNumber = number;
        this.dataValues.branchOfficeIsSelected = this.branchOfficeIsSelected;
        this.dataValues.recordTypeIsSelected = this.recordTypeIsSelected;
        this.dataValues.prospectusIsSelected = this.prospectusIsSelected;
        this.dataValues.branchOfficeId = this.selectedBranchOfficeId;
        this.dataValues.isCompleted = this.isCompleted;
        this.dataValues.recordTypeId = this.selectedRecordTypeId;
        this.dataValues.loanApplicationId = this.getLoanApplication.id;
        this.$emit('emit-form-data', this.dataValues);
    },
    async handleFileChange(event, index, currentElement) {
      const fileId = currentElement.documentId; // Get the Id from Documents table
      const fileInput = event.target;

      if (fileInput.files.length > 0) {
        this.documentIds.push(fileId);
        this.files[index] = fileInput.files[0];
      } else {
        // this.$delete(this.files, index);
        delete this.files[index];
      }
    },
    branchOfficeOptions(data) {
        return helper.mapOptions(data);
    },
    async validateExistence() {
      if (this.loanApplication.id && this.selectedRecordTypeId) {
        const resp = await this.hasRecordType(this.loanApplication.id, this.selectedRecordTypeId);
        return resp ? 'La solicitud seleccionada ya tiene un expediente de este tipo' : true;
      }
      return true;
    },
    validateDocument(element) {
      return element.isRequired ? 'required': undefined;
    },
  },
  watch: {
    selectedBranchOfficeId: {
      handler(newValue) {
        if (newValue) {
          this.recordProspectus.branchOfficeId = newValue;
          this.fetchActiveMixedProspectsByBranchOffice(newValue);
        }
      }
    },
    prospectus: {
      handler(newProspectus) {
        if (newProspectus?.prospectusId) {
          this.recordProspectus.prospectusId = newProspectus.prospectusId;
        }
      },
      immediate: true,
    },
    selectedRecordTypeId: {
      handler(newValue) {
        if (newValue) {
          this.$refs.loanApplicationField.validate();
          this.fetchActiveFinancialRecordsByCompanyAndRecordType(newValue);
        }
      }
    },
    financialRecord: {
      handler(newFinancialRecord) {
        if (newFinancialRecord?.id) {
          this.recordProspectus.recordId = newFinancialRecord.id;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.financialRecord.id) {
      this.fetchActiveChecklistsByRecordId(this.financialRecord.id);
    }
  },
  mounted() {
    this.selectedBranchOfficeId = (this.tempValues?.branchOfficeId ? this.tempValues.branchOfficeId : null);
    this.selectedRecordTypeId = (this.tempValues?.recordTypeId ? this.tempValues.recordTypeId : null);
    this.isCompleted = (this.tempValues?.isCompleted ? this.tempValues.isCompleted : false);
  },
};
</script>