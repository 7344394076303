import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';

import { useLoadingStore } from '@/pinia/loading.module';
import Client from '@/Models/Client';

import * as service from '@/services';

const $toast = useToast();

export const useClientsStore = defineStore('clients', {
    persist: false,
    state: () => {
        return {
            clients: [],
            client: new Client({}),
            errors: [],
        }
    },
    getters: {
        getClients: state => state.clients,
        getClient: state => state.client,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchClients() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/clients`);
                if (data && data.status) {
                    const response = data.data;
                    const clients = response.map(cl => new Client(cl));
                    this.clients = clients;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los clientes.");
                this.setErrors(error);
            }
        },
        async updateClientsList() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.post(`/api/clients`);
                if (data && data.status) {
                    $toast.success("Lista de clientes actualizada.");
                    const newClients = data.data.map(cl => new Client(cl));
                    
                    newClients.forEach(client => {
                        this.clients.push(client);
                    });
                    
                    this.clients.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los clientes.");
                this.setErrors(error);
            }
        },
    }
});