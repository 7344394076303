<template>
    <div class="loading">
        <div class="inner">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin: auto; background: rgb(241, 242, 243); display: block;" width="80px" height="80px"
                viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="translate(20 20)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#e15b64">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.4s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(50 20)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#bda8a5">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.3s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(80 20)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#db9500">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(20 50)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#bda8a5">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.3s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(50 50)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#db9500">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(80 50)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#f3dead">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.1s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(20 80)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#db9500">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(50 80)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#f3dead">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.1s">
                        </animateTransform>
                    </rect>
                </g>
                <g transform="translate(80 80)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#e15b64">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline"
                            dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1"
                            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="0s">
                        </animateTransform>
                    </rect>
                </g>

            </svg>
        </div>
    </div>
</template>

<style>
.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(241, 242, 243, 0.77);
}

.loading .inner {
    width: 80px;
    height: 80px;
}

.loading .inner {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
</style>