
<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Banco
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="form-group">
                    <label for="name">Nombre de Entidad Bancaria:</label>
                    <Field v-model="form.name" type="text" class="form-control" name="name" placeholder="Nombre"
                        rules="required" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
            </div>

            <div class="form-group">
                <div class="form-group">
                    <label for="headquartersLocation">Dirección:</label>
                    <Field v-model="form.headquartersLocation" type="text" class="form-control" name="headquartersLocation" placeholder="Dirección"/>
                </div>
            </div>
            
            <div class="form-group">
                <div class="form-group">
                    <label for="foundedDate">Fecha de fundación:</label>
                    <Field v-model="form.foundedDate" type="date" class="form-control" name="foundedDate" />
                </div>
            </div>

            <div class="form-group">
                <div class="form-group">
                    <label for="website">Página web:</label>
                    <Field v-model="form.website" type="text" class="form-control" name="website" placeholder="Página web"/>
                </div>
            </div>

            <div class="form-group">
                <div class="form-group">
                    <label for="contactNumber">Número de contacto:</label>
                    <Field v-model="form.contactNumber" type="text" class="form-control" name="contactNumber" placeholder="Número de contacto"/>
                </div>
            </div>

        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useBanksStore } from '@/pinia/banks.module';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        PrimaryButton,
    },
    mixins: [],
    data: () => ({
        form: {}
    }),
    emits: ['close-modal'],
    computed: {
        ...mapState(useBanksStore, ['banks']),
    },
    methods: {
        ...mapActions(useBanksStore, ['createBank']),
        onSubmit() {
            this.createBank(this.form);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
    },
}
</script>