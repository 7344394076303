<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                Formulario de prueba
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="branchOfficeId">Select2:</label>
                    
                    <!-- Envolvemos el Select2 en Field para hacer la validación -->
                    <Field name="estados" rules="required" v-slot="{ field, meta }">
                        <!-- Componente SELECT2 -->
                        <v-select label="name" :options="estados" v-model="seletedOptions" multiple v-bind="field">
                        </v-select>
                        <!-- FIN Componente SELECT2 -->
                    </Field>
                    <ErrorMessage name="estados" class="input-error"></ErrorMessage>

                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        PrimaryButton,
    },
    mixins: [],
    data: () => {
        return {
            seletedOptions: [],
            estados: [
                { id: 1, name: 'Alabama' },
                { id: 2, name: 'Alaska' },
                { id: 3, name: 'California' },
                { id: 4, name: 'Delaware' },
                { id: 5, name: 'Tennessee' },
                { id: 6, name: 'Texas' },
                { id: 7, name: 'Washington' }
            ],
        }
    },
    emits: ['close-modal'],
    computed: {},
    methods: {
        onSubmit() {

        },
        closeModal() {
            this.$emit('close-modal', true);
        },
    },
    created() {}
}
</script>
