<template>
  <Form ref="form" @submit="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ $getLocaleMessage('formTitles', 'create') }} Fondeadora
      </h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="form-group">
          <label for="anchor">Nombre:</label>
          <Field v-model="financialFunder.anchor" type="text" class="form-control" name="anchor" placeholder="Nombre"
            rules="required|max:255" />
          <ErrorMessage name="anchor" class="input-error"></ErrorMessage>
        </div>
      </div>

      <div class="form-group">
        <div class="form-group">
          <label for="observations">Observaciones:</label>
          <Field v-model="financialFunder.observations" type="text" class="form-control" name="observations"
            placeholder="Observaciones" rules="max:500"/>
            <ErrorMessage name="observations" class="input-error"></ErrorMessage>
        </div>
      </div>

      <div class="form-group">
        <label for="statusId">Estatus:</label>
        <StatusSelect v-model="financialFunder.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>                
      </div>

    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
      <PrimaryButton class="btn btn-primary" type="submit">
        {{ $getLocaleMessage('buttons', 'create') }}
      </PrimaryButton>
    </div>
  </Form>
</template>

<script>
import * as helper from '@/helpers';
import FinancialFunder from '@/Models/FinancialFunder';

import { mapState, mapActions } from 'pinia';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useStatusStore } from '@/pinia/status.module';

import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
  components: {
    PrimaryButton,
    StatusSelect,
  },
  mixins: [],
  data: () => ({
    financialFunder: new FinancialFunder({}),
  }),
  emits: ['close-modal'],
  computed: {
    ...mapState(useStatusStore, ['getAllStatusByType']),
    statusOptions() {
      return helper.mapOptions(this.getAllStatusByType(1));
    },
  },
  methods: {
    ...mapActions(useFinancialFundersStore, ['createFinancialFunder']),
    ...mapActions(useStatusStore, ['setSelectedStatus']),
    onSubmit() {
      this.createFinancialFunder(this.financialFunder);
      this.$refs.form.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
  created() { },
}
</script>