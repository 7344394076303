import { useAuthStore } from '@/pinia/auth.module';

export default class FinancialRecord {
    constructor({
      id = 0,
      recordTypeId = 0,
      statusId = 0,
      referenceNumber = '',
      name = '',
      recordType = null,
      status = null,
      user = null,
      documents = [],
      createdAt = new Date(),
      updatedAt = new Date(),
      userAuth = useAuthStore(),
    } = {}) {
      this.id = id;
      this.companyId = userAuth.user.companyId;
      this.userId = userAuth.user.id;
      this.recordTypeId = recordTypeId;
      this.statusId = statusId;
      this.referenceNumber = referenceNumber;
      this.name = name;
      this.recordType = recordType;
      this.status = status;
      this.user = user;
      this.documents = documents;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }
  }
  