export default class CreditProfile {
    constructor({
        id = 0,
        name = null,
        percentValue = null,
        remarks = null,
        exception = null,
        createdAt = new Date(),
        updatedAt = new Date()
    }) {
        this.id = id || 0;
        this.name = name || null;
        this.percentValue = percentValue || null;
        this.remarks = remarks || null;
        this.exception = exception || null;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}