<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Fondeadoras y Cuentas</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Fondeadoras</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">

                            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="custom-tabs-four-funders-tab" data-toggle="pill"
                                        href="#custom-tabs-four-funders" role="tab" aria-controls="custom-tabs-four-funders"
                                        aria-selected="true" @click="bodyContent = 1">Fondeadoras</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-accounts-tab" data-toggle="pill"
                                        href="#custom-tabs-four-accounts" role="tab"
                                        aria-controls="custom-tabs-four-accounts" aria-selected="false"
                                        @click="bodyContent = 2">Cuentas</a>
                                </li>
                            </ul>

                        </div>
                        <div class="card-body">

                            <div class="tab-content" id="custom-tabs-four-tabContent">
                                <div class="tab-pane fade active show" id="custom-tabs-four-funders" role="tabpanel"
                                    aria-labelledby="custom-tabs-four-funders-tab">
                                    <Fondeadoras v-if="bodyContent === 1"></Fondeadoras>
                                </div>
                                <div class="tab-pane fade active show" id="custom-tabs-four-accounts" role="tabpanel"
                                    aria-labelledby="custom-tabs-four-accounts-tab">
                                    <Cuentas v-if="bodyContent === 2"></Cuentas>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>
  
<script>
import { mapState, mapActions } from 'pinia';
import { useStatusStore } from '@/pinia/status.module';

import Fondeadoras from './Funders.vue';
import Cuentas from './Accounts.vue';
  
  export default {
    components: {
        Fondeadoras,
        Cuentas,
    },
    data() {
        return {
            bodyContent: 1,
            modalContent: 0,
            editing: false,
        }
    },
    methods: {
        ...mapActions(useStatusStore, ['fetchStatus']),
    },
    created() {
        this.fetchStatus();
    }
  }
  </script>