<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Días Feriados</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Días Feriados</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Días Feriados</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                                    @click="openCreationForm">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Buscar Días feriados..."
                                    v-model="searchString" />
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <HolidaysTable :list="this.filteredHolidays" :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage" @edit-holiday="openEditionForm"></HolidaysTable>
                                <Pagination :list="this.filteredHolidays" :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage" @current-page="currentPage = $event"></Pagination>

                                <Modal>
                                    <AddHolidaysForm v-if="modalContent == 1 && editing == false"
                                        @close-modal="modalStatus"></AddHolidaysForm>
                                    <EditHolidaysForm v-else-if="modalContent == 2 && editing == true"
                                        :selected-record="selectedRecord" @close-modal="modalStatus"></EditHolidaysForm>
                                </Modal>

                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useHolidaysStore } from '@/pinia/holidays.module';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';

import HolidaysTable from '@/Components/Tables/HolidaysTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddHolidaysForm from '@/Components/Forms/Holidays/Add.vue';
import EditHolidaysForm from '@/Components/Forms/Holidays/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        HolidaysTable,
        Pagination,
        Modal,
        AddHolidaysForm,
        EditHolidaysForm,
        PrimaryButton
    },
    data() {
        return {
            selectedRecord: {},
            editing: false,
            currentPage: 1,
            itemsPerPage: 10,
            modalContent: 0,
            searchString: '',
        }
    },
    computed: {
        ...mapState(useHolidaysStore, ['getHolidays', 'getSelectedHoliday', 'filterHolidaysBySearchString']),
        getSelectedHolday() {
            return this.selectedRecord;
        },
        filteredHolidays() {
            return this.filterHolidaysBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(useHolidaysStore, ['fetchHolidays', 'setSelectedHoliday']),
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        modalStatus() {
            $('#modal-lg').modal('hide');
        },
        openCreationForm() {
            this.modalContent = 1;
            this.editing = false;
        },
        openEditionForm(selectedItem) {
            this.selectedRecord = { ...selectedItem }; // Clonar profundamente el objeto reactivo
            this.modalContent = 2;
            this.editing = true;
        },
    },
    created() {
        this.fetchHolidays();
        this.fetchActiveBranchOffices();
        this.fetchStatus();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            if (!helper.isEmptyObject(this.getSelectedHoliday)) {
                // Reset the selected item in the store to an empty object when finished editing
                this.setSelectedHoliday();
            }
        });
    }
}
</script>