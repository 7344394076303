import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { getLocaleMessage } from '@/services/MessagesService';

import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useHandlerStore } from '@/pinia/handler.module';

import Product from '@/Models//Product';
import PenaltyCharge from '@/Models/PenaltyCharge';
import ReferenceRate from '@/Models/ReferenceRate';
import Guarantee from '@/Models/Guarantee';

import * as service from '@/services';

const $toast = useToast();

export const useProductsStore = defineStore('products', {
    persist: false,
    state: () => {
        return {
            products: reactive([]),
            product: new Product(),
            activeProducts: reactive([]),
            activeProduct: new Product(),

            productTypes: [],
            productType: {},
            activeProductTypes: reactive([]),
            activeProductType: {},

            activeRateTypes: [],
            activeRateType: {},

            activeReferenceRates: [],
            activeReferenceRate: {},

            activePenaltyChargeRates: [],
            activePenaltyChargeRate: {},

            activePenaltyCharges: [],
            activePenaltyCharge: {},

            guarantees: reactive([]),
            guarantee: new Guarantee(),
            activeGuarantees: reactive([]), // For Selects components only
            activeGuarantee: new Guarantee(),

            activeLoanTerms: [],
            activeLoanTerm: {},

            errors: [],

        }
    },
    getters: {
        getProducts: state => state.products,
        getSelectedProduct: state => state.product,
        getProductById: state => (id = 0) => (id ? state.products.find(p => p.id === id) : {}) ?? {},
        getActiveProductsByProductTypeId: state => (productTypeId = 0) => {
            if (productTypeId === undefined || productTypeId === 0 || productTypeId === null) return [];
            return state.products.filter(p => p.productTypeId === productTypeId && p.status.name === "Activo");
        },
        sortAscendingByProperty: state => (list, propertyName) => list.sort((a, b) => (a[propertyName] > b[propertyName]) ? 1 : -1),
        getActiveProducts: state => state.activeProducts,
        getSelectedActiveProduct: state => state.activeProduct,
        getActiveProductById: state => (id = 0) => (id ? state.activeProducts.find(p => p.id === id) : {}) ?? {},
        getActiveProductOnCreate: state => (product = new Product()) => {
            return state.products.find(p =>
                p.branchOfficeId === product.branchOfficeId &&
                p.productTypeId === product.productTypeId &&
                p.rateTypeId === product.rateTypeId &&
                p.referenceRateId === product.referenceRateId &&
                p.penaltyChargeRateId === product.penaltyChargeRateId &&
                p.penaltyChargeId === product.penaltyChargeId &&
                p.guaranteeId === product.guaranteeId &&
                p.loanTermId === product.loanTermId &&
                p.statusId === product.statusId
            );
        },

        getActiveProductTypes: state => state.activeProductTypes,
        getSelectedActiveProductType: state => state.activeProductType,
        getActiveProductTypeById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activeProductTypes.find(pt => pt.id === id);
        },

        getActiveRateTypes: state => state.activeRateTypes,
        getSelectedActiveRateType: state => state.activeRateType,
        getActiveRateTypeById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activeRateTypes.find(rt => rt.id === id);
        },

        getActiveReferenceRates: state => state.activeReferenceRates,
        getSelectedActiveReferenceRate: state => state.activeReferenceRate,
        getActiveReferenceRateById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activeReferenceRates.find(rr => rr.id === id);
        },

        getActivePenaltyChargeRates: state => state.activePenaltyChargeRates,
        getSelectedActivePenaltyChargeRate: state => state.activePenaltyChargeRate,
        getActivePenaltyChargeRateById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activePenaltyChargeRates.find(pcr => pcr.id === id);
        },

        getActivePenaltyCharges: state => state.activePenaltyCharges,
        getSelectedActivePenaltyCharge: state => state.activePenaltyCharge,
        getActivePenaltyChargeById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activePenaltyCharges.find(pc => pc.id === id);
        },

        getGuarantees: state => state.guarantees,
        getSelectedGuarantee: state => state.guarantee,
        getGuaranteeById: state => (id = 0) => {
            if (typeof id === "undefined" || id === 0 || id === null) return {};
            return state.guarantees.find(g => g.id === id);
        },
        getActiveGuarantees: state => state.activeGuarantees,
        getSelectedActiveGuarantee: state => state.activeGuarantee,
        getActiveGuaranteeById: state => (id = 0) => {
            if (typeof id === "undefined" || id === 0 || id === null) return {};
            return state.activeGuarantees.find(g => g.id === id);
        },

        getActiveLoanTerms: state => state.activeLoanTerms,
        getSelectedActiveLoanTerm: state => state.activeLoanTerm,
        getActiveLoanTermById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activeLoanTerms.find(lt => lt.id === id);
        },
        filterProductsBySearchString: state => (searchString = '') => {
            if (!searchString) return state.products;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.products.filter(product => {
                const branchOffice = product.branchOffice?.name ? product.branchOffice?.name.toLowerCase() : '';
                const referenceNumber = product.referenceNumber ? product.referenceNumber.toLowerCase() : '';
                const productDescription = product.description ? product.description.toLowerCase() : '';
                return referenceNumber.includes(lowerCaseSearchString) ||
                    productDescription.includes(lowerCaseSearchString) ||
                    branchOffice.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        getFirstCreatedProductByProductType(productTypeId = 0) {
            const productList = this.getActiveProductsByProductTypeId(productTypeId);
            if (productList.length === 0) return {};
            // const sortedData = productList.sort((a, b) => a.id - b.id);
            const sortedData = this.sortAscendingByProperty(productList, 'id');
            const firstElement = sortedData[0];
            return firstElement;
        },
        productAlreadyExists(product) {
            return !!this.getActiveProductOnCreate(product);
        },
        productAlreadyExistsOnEdit(product) {
            const exist = this.getProductById(product.id);
            if (exist) {
                return !exist; // Denies the existence of the product to be edited
            }
            else {
                return !!this.getActiveProductOnCreate(product);
            }
        },
        setSelectedProduct(id) {
            this.product = this.getProductById(id);
        },
        setSelectedActiveProductType(id) {
            this.activeProductType = this.getActiveProductTypeById(id);
        },
        setSelectedActiveRateType(id) {
            this.activeRateType = this.getActiveRateTypeById(id);
        },
        setSelectedActiveReferenceRate(id) {
            this.activeReferenceRate = this.getActiveReferenceRateById(id);
        },
        setSelectedActivePenaltyChargeRate(id) {
            this.activePenaltyChargeRate = this.getActivePenaltyChargeRateById(id);
        },
        setSelectedActivePenaltyCharge(id) {
            this.activePenaltyCharge = this.getActivePenaltyChargeById(id);
        },
        setSelectedGuarantee(id) {
            this.guarantee = this.getGuaranteeById(id);
        },
        setSelectedActiveGuarantee(id) {
            this.activeGuarantee = this.getActiveGuaranteeById(id);
        },
        setSelectedActiveLoanTerm(id) {
            this.activeLoanTerm = this.getActiveLoanTermById(id);
        },
        async fetchProducts(companyId = 1) {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.productsApi.get(`/api/products/ByCompanyId/${companyId}`);
                if (data) {
                    useLoadingStore().loading = false;
                    this.products = data;
                }
            } catch (error) {
                useHandlerStore().requestError(error);
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveProductsByBranchOfficeId(branchOfficeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.get(`/api/products/ByBranchOfficeId/${branchOfficeId}/active`);
                if (data) {
                    useLoadingStore().loading = false;
                    this.activeProducts = data;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async isProductTypeUniqueOnCreate(branchOfficeId, productTypeId, rateTypeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.get(`/api/products/IsProductTypeUnique/${branchOfficeId}/${productTypeId}/${rateTypeId}`);
                useLoadingStore().loading = false;
                if (data && data.status) {
                    return data.data;
                }
                return data;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
            }
        },
        async isProductTypeUniqueOnEdit(productId, branchOfficeId, productTypeId, rateTypeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.get(`/api/products/IsProductTypeUnique/${productId}/${branchOfficeId}/${productTypeId}/${rateTypeId}`);
                useLoadingStore().loading = false;
                if (data && data.status) {
                    return data.data;
                }
                return data;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
            }
        },
        async createProduct(product) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.post(`/api/products`, product);
                if (data && data.status) {
                    this.products.unshift(data.data);
                    $toast.success(getLocaleMessage('products', 'creatingSuccess'));
                } else if (data && !data.status) {
                    useHandlerStore().setErrors(data.error);
                    $toast.error(getLocaleMessage('products', 'creatingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateProduct(product) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.put(`/api/products`, product);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('products', 'editingSuccess'));
                    const product = data.data;
                    const index = this.products.findIndex(p => p.id === product.id);
                    if (index !== -1) {
                        this.products.splice(index, 1, product);
                        this.products.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                } else if (data && !data.status) {
                    useHandlerStore().setErrors(data.error);
                    $toast.error(getLocaleMessage('productos', 'editingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        // async deleteProduct(id) {
        //     try {
        //         const { data: data } = await service.productsApi.delete(`/api/products?id=${id}`);
        //         $toast.success("Producto eliminado correctamente.");
        //         const index = this.products.findIndex(p => p.idProduct === id);
        //         if (index !== -1) {
        //             this.products.splice(index, 1);
        //         }
        //         return data;
        //     } catch (error) {

        //         useHandlerStore().setErrors(error);
        //     }
        // },
        async fetchActiveProductTypes(companyId = 1) {
            try {
                const { data } = await service.productsApi.get(`/api/products/productTypes/${companyId}`);
                if (data) {
                    this.activeProductTypes = data;
                }
            } catch (error) {
                $toast.error(getLocaleMessage('productTypes', 'fetchingError'));
                useHandlerStore().setErrors(error);
            }
        },
        async fetchActiveRateTypes(companyId = 1) {
            try {
                const { data } = await service.productsApi.get(`/api/products/rateTypes/${companyId}`);
                if (data) {
                    this.activeRateTypes = data;
                }
            } catch (error) {
                $toast.error(getLocaleMessage('rateTypes', 'fetchingError'));
                useHandlerStore().setErrors(error);
            }
        },

        async fetchActiveReferenceRates(rateTypeId) {
            try {
                if (!rateTypeId) {
                    return;
                }
                const { data } = await service.productsApi.get(`/api/referenceRates/ByRateTypeId/${rateTypeId}/active`);
                if (data) {
                    const referenceRates = data.map(rr => new ReferenceRate(rr));
                    this.activeReferenceRates = referenceRates;
                }
            } catch (error) {
                $toast.error(getLocaleMessage('referenceRates', 'fetchingError'));
                useHandlerStore().setErrors(error);
            }
        },
        async createReferenceRate(referenceRate) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.post(`/api/referenceRates`, referenceRate);
                if (data) {
                    $toast.success(getLocaleMessage('referenceRates', 'creatingSuccess'));
                    this.activeReferenceRates.splice(this.activeReferenceRates.length - 1, 0, data);
                }

                useLoadingStore().loading = false;
                return data;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },

        async fetchActivePenaltyChargeRates(companyId = 1) {
            try {
                const { data } = await service.productsApi.get(`/api/products/penaltyChargeRates/${companyId}`);
                if (data) {
                    this.activePenaltyChargeRates = data;
                }
            } catch (error) {
                $toast.error(getLocaleMessage('penaltyChargeRates', 'fetchingError'));
                useHandlerStore().setErrors(error);
            }
        },

        async fetchActivePenaltyCharges(penaltyChargeRateId) {
            try {
                useLoadingStore().loading = true;
                if (!penaltyChargeRateId) {
                    return;
                }
                const { data } = await service.productsApi.get(`/api/penaltyCharges/ByPenaltyChargeRateId/${penaltyChargeRateId}/active`);
                if (data) {
                    const penaltyCharges = data.map((pc) => new PenaltyCharge(pc));
                    this.activePenaltyCharges = penaltyCharges;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los moratorios.");
                useHandlerStore().setErrors(error);
            }
        },
        async createPenaltyCharge(penaltyCharge) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.post(`/api/penaltyCharges`, penaltyCharge);
                if (data) {
                    $toast.success(getLocaleMessage('penaltyCharge', 'creatingSuccess'));
                    this.activePenaltyCharges.splice(this.activePenaltyCharges.length - 1, 0, data);
                }

                useLoadingStore().loading = false;
                return data;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },

        async fetchGuaranteesByCompany(companyId = 1) {
            try {
                const { data } = await service.productsApi.get(`/api/guarantees/ByCompany/${companyId}`);
                if (data && data.status) {
                    const guarantees = data.data.map(g => new Guarantee(g));
                    this.guarantees = guarantees;
                }
            } catch (error) {
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('guarantees', 'fetchingError'));
            }
        },

        async fetchActiveGuarantees(companyId = 1) {
            try {
                const { data } = await service.productsApi.get(`/api/guarantees/ByCompany/${companyId}/active`);
                if (data && data.status) {
                    const guarantees = data.data.map(g => new Guarantee(g));
                    this.activeGuarantees = guarantees;
                }
            } catch (error) {
                useHandlerStore().requestError(error);
            }
        },
        async createGuarantee(guarantee) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.productsApi.post(`/api/guarantees`, guarantee);
                if (data && data.status) {
                    const newGuarantee = new Guarantee(data.data);
                    this.guarantees.unshift(newGuarantee);
                    // Obtener la penúltima posición
                    const penultimateIndex = this.activeGuarantees.length - 1;

                    // Verificar si hay al menos dos elementos antes de agregar en la penúltima posición
                    if (penultimateIndex >= 1) {
                        this.activeGuarantees.splice(penultimateIndex, 0, newGuarantee);
                    } else {
                        // Si no hay suficientes elementos, agregar al final
                        this.activeGuarantees.push(newGuarantee);
                    }
                    $toast.success(getLocaleMessage('guarantees', 'creatingSuccess'));
                    useLoadingStore().loading = false;
                    return newGuarantee;
                } else if (data && !data.status) {
                    $toast.error(getLocaleMessage('guarantees', 'creatingError'));
                    useHandlerStore().setErrors(error);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },

        async fetchActiveLoanTerms(companyId = 1) {
            try {
                const { data } = await service.productsApi.get(`/api/products/loanTerms/${companyId}`);
                if (data) {
                    this.activeLoanTerms = data;
                }
            } catch (error) {
                $toast.error(getLocaleMessage('loanTerms', 'fetchingError'));
                useHandlerStore().setErrors(error);
            }
        },

    }
});