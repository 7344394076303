export default class RecordType {
    constructor({
        id = 0,
        statusId = 0,
        name = null,
        createdAt = new Date(),
        updatedAt = new Date()
    } = {}) {
        this.id = id;
        this.statusId = statusId;
        this.name = name;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}
