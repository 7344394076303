<template>
  <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
    <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
      <a 
        class="nav-link" 
        :class="{ active: index === activeTab }" 
        id="'custom-tabs-four-tab-' + index" 
        data-toggle="pill"
        href="'#custom-tabs-four-content-' + index" 
        role="tab"
        :aria-controls="'custom-tabs-four-content-' + index"
        :aria-selected="index === activeTab"
        @click.prevent="selectTab(index)"
      >{{ tab.name }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    initialTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeTab: this.initialTab,
    };
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
      this.$emit('tab-selected', index);
    },
  },
};
</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>
