<template>
    <Form ref="corporate" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Prospecto - Persona Moral
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-sm-6">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="prospectus.branchOfficeId" :name="'branchOfficeId'" :options="activeBranchOfficesOptions"></BranchOfficesSelect>
                </div>
            </div>

            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Información General</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-4">
                        <label for="businessName">Denominación o Razón Social:</label>
                        <Field v-model="generalInformation.businessName" type="text" class="form-control" name="businessName" placeholder="Nombre"
                            rules="required|max:255" />
                        <ErrorMessage name="businessName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="dateOfIncorporation">Fecha de Constitución:</label>
                        <Field v-model="generalInformation.dateOfIncorporation" type="date" class="form-control" name="dateOfIncorporation" rules="required" />
                        <ErrorMessage name="dateOfIncorporation" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="notaryInformation">No. de Acta/Notario (Nombre y Número):</label>
                        <Field v-model="generalInformation.notaryInformation" type="text" class="form-control" name="notaryInformation"
                            placeholder="No. de Acta/Notario (Nombre y Número)" rules="required|max:255" />
                        <ErrorMessage name="notaryInformation" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="businessPurpose">Giro Mercantil:</label>
                        <Field v-model="generalInformation.businessPurpose" type="businessPurpose" class="form-control" name="businessPurpose" placeholder="Giro Mercantil" rules="required|max:255" />
                        <ErrorMessage name="businessPurpose" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="mainActivity">Actividad Preponderante:</label>
                        <Field v-model="generalInformation.mainActivity" type="text" class="form-control" name="mainActivity"
                            placeholder="Actividad Preponderante" rules="required|max:255" />
                        <ErrorMessage name="mainActivity" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="country">País:</label>
                        <Field v-model="generalInformation.country" type="text" class="form-control" name="country"
                            placeholder="País" rules="required|max:255" />
                        <ErrorMessage name="country" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="fielFolio">Folio FIEL:</label>
                        <Field v-model="generalInformation.fielFolio" type="text" class="form-control" name="fielFolio" placeholder="Folio FIEL"
                            rules="required|max:255" />
                        <ErrorMessage name="fielFolio" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="rfc">RFC:</label>
                        <Field v-model="generalInformation.rfc" type="text" class="form-control" name="rfc"
                            placeholder="RFC" rules="required|max:255" />
                        <ErrorMessage name="rfc" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="email">Correo Electrónico:</label>
                        <Field v-model="generalInformation.email" type="text" class="form-control" name="email"
                            placeholder="Correo Electrónico" rules="required|max:255" />
                        <ErrorMessage name="email" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="officePhone">Teléfono:</label>
                        <Field v-model="generalInformation.officePhone" type="text" class="form-control" name="officePhone" placeholder="Teléfono"
                            rules="required|max:255" />
                        <ErrorMessage name="officePhone" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="numberOfEmployees">Número de empleados:</label>
                        <Field v-model="generalInformation.numberOfEmployees" type="number" class="form-control" name="numberOfEmployees" placeholder="Número de empleados"
                            rules="required|number" />
                        <ErrorMessage name="numberOfEmployees" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Domicilio Fiscal</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street">Calle:</label>
                        <Field v-model="fiscalAddress.street" type="text" class="form-control" name="street" placeholder="Calle"
                            rules="required|max:255" />
                        <ErrorMessage name="street" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber">No. Exterior:</label>
                        <Field v-model="fiscalAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber" placeholder="No. Exterior"
                            rules="max:10" />
                        <ErrorMessage name="exteriorNumber" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="interiorNumber">No. Interior:</label>
                        <Field v-model="fiscalAddress.interiorNumber" type="text" class="form-control" name="interiorNumber" placeholder="No. Interior"
                            rules="max:10" />
                        <ErrorMessage name="interiorNumber" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="district">Colonia:</label>
                        <Field v-model="fiscalAddress.district" type="text" class="form-control" name="district" placeholder="Colonia"
                            rules="required|max:255" />
                        <ErrorMessage name="district" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="municipality">Delegación / Municipio:</label>
                        <Field v-model="fiscalAddress.municipality" type="text" class="form-control" name="municipality" placeholder="Delegación / Municipio"
                            rules="required|max:255" />
                        <ErrorMessage name="municipality" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="city">Ciudad:</label>
                        <Field v-model="fiscalAddress.city" type="text" class="form-control" name="city" placeholder="Ciudad"
                            rules="required|max:255" />
                        <ErrorMessage name="city" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="state">Estado:</label>
                        <Field v-model="fiscalAddress.state" type="text" class="form-control" name="state" placeholder="Estado"
                            rules="required|max:255" />
                        <ErrorMessage name="state" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="country2">País:</label>
                        <Field v-model="fiscalAddress.country" type="text" class="form-control" name="country2" placeholder="País"
                            rules="required|max:255" />
                        <ErrorMessage name="country2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="postalCode">Código Postal:</label>
                        <Field v-model="fiscalAddress.postalCode" type="text" class="form-control" name="postalCode" placeholder="Código Postal"
                            rules="required|max:20" />
                        <ErrorMessage name="postalCode" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="references">Referencias:</label>
                        <Field v-model="fiscalAddress.references" type="text" class="form-control" name="references" placeholder="Referencias"
                            rules="required|max:500" />
                        <ErrorMessage name="references" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="propertyType">Tipo de Inmueble:</label>
                        <Field v-model="fiscalAddress.propertyType" type="text" class="form-control" name="propertyType" placeholder="Tipo de Inmueble"
                            rules="required|max:255" />
                        <ErrorMessage name="propertyType" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="ageInHome">Antigüedad en el Domicilio:</label>
                        <Field v-model="fiscalAddress.ageInHome" type="text" class="form-control" name="ageInHome" placeholder="Antigüedad en el Domicilio"
                                rules="required|max:255" />
                            <ErrorMessage name="ageInHome" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="legalStatusProperty">Situación Legal del Inmueble:</label>
                        <Field v-model="fiscalAddress.legalStatusProperty" type="text" class="form-control" name="legalStatusProperty" placeholder="Situación Legal del Inmueble"
                                rules="required|max:255" />
                            <ErrorMessage name="legalStatusProperty" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Representante legal</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="firstName">Nombre:</label>
                        <Field v-model="legalRepresentative.firstName" type="text" class="form-control" name="firstName" placeholder="Nombre"
                            rules="required|max:255" />
                        <ErrorMessage name="firstName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="lastName">Apellido Paterno:</label>
                        <Field v-model="legalRepresentative.lastName" type="text" class="form-control" name="lastName" placeholder="Apellido Paterno"
                            rules="required|max:255" />
                        <ErrorMessage name="lastName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="mothersMaidenName">Apellido Materno:</label>
                        <Field v-model="legalRepresentative.mothersMaidenName" type="text" class="form-control" name="mothersMaidenName" placeholder="Apellido Materno"
                            rules="required|max:255" />
                        <ErrorMessage name="mothersMaidenName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="dateOfBirth">Fecha de Nacimiento:</label>
                        <Field v-model="legalRepresentative.dateOfBirth" type="date" class="form-control" name="dateOfBirth" />
                        <ErrorMessage name="dateOfBirth" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="birthState">Entidad federativa de nacimiento:</label>
                        <Field v-model="legalRepresentative.birthState" type="text" class="form-control" name="birthState" placeholder="Entidad federativa de nacimiento"
                            rules="required|max:255" />
                        <ErrorMessage name="birthState" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="nationality">Nacionalidad:</label>
                        <Field v-model="legalRepresentative.nationality" type="text" class="form-control" name="nationality" placeholder="Nacionalidad"
                            rules="required|max:255" />
                        <ErrorMessage name="nationality" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="gender">Género:</label>
                        <Field v-model="legalRepresentative.gender" type="text" class="form-control" name="gender" placeholder="Género"
                        rules="required|max:10" />
                        <ErrorMessage name="gender" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="maritalStatus">Estado Civil:</label>
                        <Field v-model="legalRepresentative.maritalStatus" type="text" class="form-control" name="maritalStatus" placeholder="Estado Civil"
                            rules="required|max:50" />
                        <ErrorMessage name="maritalStatus" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="maritalRegime">Régimen Matrimonial:</label>
                        <Field v-model="legalRepresentative.maritalRegime" type="text" class="form-control" name="maritalRegime" placeholder="Régimen Matrimonial"
                            :rules="maritalRegimeValidation" />
                        <ErrorMessage name="maritalRegime" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="rfc2">RFC:</label>
                        <Field v-model="legalRepresentative.rfc" type="text" class="form-control" name="rfc2" placeholder="RFC"
                            rules="required|max:255" />
                        <ErrorMessage name="rfc2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="curp">CURP:</label>
                        <Field v-model="legalRepresentative.curp" type="text" class="form-control" name="curp" placeholder="CURP"
                            rules="required|max:255" />
                        <ErrorMessage name="curp" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="homePhone2">Tel. de Casa:</label>
                        <Field v-model="legalRepresentative.homePhone" type="text" class="form-control" name="homePhone2" placeholder="Tel. de Casa" rules="max:20" />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="officePhone2">Tel. de Oficina:</label>
                        <Field v-model="legalRepresentative.officePhone" type="text" class="form-control" name="officePhone2" placeholder="Tel. de Oficina" rules="max:20" />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="cellPhone2">Tel. Celular:</label>
                        <Field v-model="legalRepresentative.cellPhone" type="text" class="form-control" name="cellPhone2" placeholder="Tel. Celular" rules="required|max:20" />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="email2">Correo Electrónico:</label>
                        <Field v-model="legalRepresentative.email" type="text" class="form-control" name="email2" placeholder="Correo Electrónico"
                            rules="required|max:255" />
                        <ErrorMessage name="email2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="identificationType">Tipo de Identificación:</label>
                        <Field v-model="legalRepresentative.identificationType" type="text" class="form-control" name="identificationType" placeholder="Tipo de Identificación"
                            rules="required|max:255" />
                        <ErrorMessage name="identificationType" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="identificationNumber">Número de Identificación:</label>
                        <Field v-model="legalRepresentative.identificationNumber" type="text" class="form-control" name="identificationNumber" placeholder="Número de Identificación"
                            rules="required|max:255" />
                        <ErrorMessage name="identificationNumber" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Domicilio del Representante</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street2">Calle:</label>
                        <Field v-model="representativeAddress.street" type="text" class="form-control" name="street2" placeholder="Calle"
                            rules="required|max:255" />
                        <ErrorMessage name="street2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber2">No. Exterior:</label>
                        <Field v-model="representativeAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber2" placeholder="No. Exterior"
                            rules="required|max:10" />
                        <ErrorMessage name="exteriorNumber2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="interiorNumber2">No. Interior:</label>
                        <Field v-model="representativeAddress.interiorNumber" type="text" class="form-control" name="interiorNumber2" placeholder="No. Interior"
                            rules="max:10" />
                        <ErrorMessage name="interiorNumber2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="district2">Colonia:</label>
                        <Field v-model="representativeAddress.district" type="text" class="form-control" name="district2" placeholder="Colonia"
                            rules="required|max:255" />
                        <ErrorMessage name="district2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="municipality2">Delegación / Municipio:</label>
                        <Field v-model="representativeAddress.municipality" type="text" class="form-control" name="municipality2" placeholder="Delegación / Municipio"
                            rules="required|max:255" />
                        <ErrorMessage name="municipality2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="city2">Ciudad:</label>
                        <Field v-model="representativeAddress.city" type="text" class="form-control" name="city2" placeholder="Ciudad"
                            rules="required|max:255" />
                        <ErrorMessage name="city2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="state2">Estado:</label>
                        <Field v-model="representativeAddress.state" type="text" class="form-control" name="state2" placeholder="Estado"
                        rules="required|max:255" />
                        <ErrorMessage name="state2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="country3">País:</label>
                        <Field v-model="representativeAddress.country" type="text" class="form-control" name="country3" placeholder="País"
                            rules="required|max:255" />
                        <ErrorMessage name="country3" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="postalCode2">Código Postal:</label>
                        <Field v-model="representativeAddress.postalCode" type="text" class="form-control" name="postalCode2" placeholder="Código Postal"
                            rules="required|max:20" />
                        <ErrorMessage name="postalCode2" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="statusId">Estatus:</label>
                <StatusSelect v-model="this.prospectus.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect> 
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useCorporateProspectsStore } from '@/pinia/Prospects/corporate.module';

import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

import Prospectus from '@/Models/Prospects';
import GeneralInformation from '@/Models/GeneralInformation';
import FiscalAddress from '@/Models/FiscalAddress';
import LegalRepresentative from '@/Models/LegalRepresentative';

export default {
    components: {
        BranchOfficesSelect,
        StatusSelect,
        PrimaryButton,
    },
    mixins: [],
    data() {
        return {
            prospectus: new Prospectus({ personTypeId: 2 }), // Always wiil be a Corporate Prospectus (2):
            generalInformation: new GeneralInformation({numberOfEmployees: 0}),
            fiscalAddress: new FiscalAddress(),
            legalRepresentative: new LegalRepresentative(),
            representativeAddress: new FiscalAddress(),
            activeBranchOfficesOptions: [],
        }
    },
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(2));
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        ...mapActions(useCorporateProspectsStore, ['createProspect',]),
        onSubmit() {
            this.prospectus.generalInformation = this.generalInformation;
            this.prospectus.fiscalAddress = this.fiscalAddress;
            this.prospectus.legalRepresentative = this.legalRepresentative;
            this.prospectus.representativeAddress = this.representativeAddress;

            this.createProspect(this.prospectus);
            this.$refs.corporate.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
        branchOfficeOptions(data) {
            return helper.mapOptions(data);
        },
        // Custom validations:
        maritalRegimeValidation(value) {
            let maritalStatus = this.legalRepresentative.maritalStatus;
            const max = 255;

            if(!maritalStatus) {
                return 'Especifique el estado civil';
            }

            if (maritalStatus.toLowerCase() !== 'soltero' && maritalStatus.toLowerCase() !== 'soltera' && value === null) {
                return 'El campo es obligatorio';
            }
            
            if (value && value.length > max) {
                return `El campo no debe ser mayor a ${max} caracteres`;
            } else {
                return true;
            }
        },
    },
    watch: {
        getActiveBranchOffices: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.activeBranchOfficesOptions = this.branchOfficeOptions(newValue);
                }
            }
        },
    },
    created() {},
    mounted() {
        this.activeBranchOfficesOptions = this.branchOfficeOptions(this.getActiveBranchOffices);
    }
}
</script>