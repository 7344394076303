import { defineStore } from 'pinia';
import { computed } from 'vue';
import { getLocaleMessage } from '@/services/MessagesService';
import { useToast } from 'vue-toast-notification';
import { sharedStore } from '@/pinia/sharedStore.module';
import { useLoadingStore } from '@/pinia/loading.module';
import { useAuthStore } from '@/pinia/auth.module';
import Prospectus from '@/Models/Prospects';

import * as service from '@/services';

const $toast = useToast();

export const useIndividualProspectsStore = defineStore('individualProspects', {
    persist: false,
    state: () => {
        return {
            allProspects: computed({
                get() { return sharedStore().allProspects },
                set(val) { sharedStore().allProspects = val }
            }),
            prospectus: computed({
                get() { return sharedStore().prospectus },
                set(val) { sharedStore().prospectus = val }
            }),
            individualProspects: [],
            individualProspectus: new Prospectus(),
            errors: [],
        }
    },
    getters: {
        getProspectBySelected: state => state.allProspects.map(p => {
            let name = p.personTypeId === 2 ? `${p.referenceNumber} | ${p.generalInformation.businessName} | ${p.generalInformation.rfc} | ${p.generalInformation.email}` : `${p.referenceNumber} | ${p.generalInformation.firstName} ${p.generalInformation.mothersMaidenName} | ${p.generalInformation.rfc} | ${p.generalInformation.email}`;
            return {
                id: p.prospectusId,
                name: name
            }
        }),
        getAllProspects: state => state.allProspects,
        getIndividualProspects: state => state.individualProspects,
        getSelectedProspectus: state => state.individualProspectus,
        getProspectById: state => id => {
            return state.allProspects.find(p => p.prospectusId === id);
        },
        getCreditApplicants: state => state.creditApplicants,
        getCreditApplicantById: state => id => {
            return state.creditApplicants.find(ca => ca.applicantID === id);
        },
        getSelectedCreditApplicant: state => state.creditApplicant,
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        setSelectedCreditApplicant(id) {
            this.creditApplicant = this.getCreditApplicantById(id);
        },
        async fetchMixedProspects() {
            try {
                
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individualProspects/all/company/${useAuthStore().user.companyId}`);
                if(data && data.status) {
                    const prospects = data.data;
                    const mixedProspects = prospects.map((prospectus) => new Prospectus(prospectus));
                    this.allProspects = mixedProspects;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveMixedProspects() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individualProspects/all/company/${useAuthStore().user.companyId}/active`);
                if(data && data.status) {
                    const prospects = data.data;
                    const mixedProspects = prospects.map((prospectus) => new Prospectus(prospectus));
                    this.allProspects = mixedProspects;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveMixedProspectsByBranchOffice(branchOfficeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individualProspects/all/branchOffice/${branchOfficeId}/active`);
                if (data && data.status) {
                    const prospects = data.data;
                    const mixedProspects = prospects.map((prospectus) => new Prospectus(prospectus));
                    this.allProspects = mixedProspects;

                    if (mixedProspects.length === 0) {
                        $toast.info(getLocaleMessage('prospects', 'noProspects'));
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchIndividualProspects() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individualProspects`);
                if(data && data.status) {
                    const prospects = data.data;
                    const individualProspects = prospects.map((prospect) => new Prospectus(prospect));
                    this.individualProspects = individualProspects;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchIndividualProspectusById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individualProspects/${id}`);
                if(data && data.status) {
                    this.individualProspectus = new Prospectus(data.data);
                    this.prospectus = new Prospectus(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchIndividualById(id) {
            return new Promise((resolve, reject) => {
                service.prospectsApi.get(`/api/individualProspects/${id}`)
                    .then(({ data }) => {
                        if (data) {
                            resolve(data.data[0] || {});
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        async createProspect(individualData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.post(`/api/individualProspects`, individualData);
                if(data && data.status) {
                    $toast.success(getLocaleMessage('prospects', 'creatingSuccess'));
                    const prospectus = data.data;
                    this.allProspects.unshift(prospectus);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateProspect(individualData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.put(`/api/individualProspects`, individualData);
                if(data && data.status) {
                    $toast.success(getLocaleMessage('prospects', 'editingSuccess'));
                    const prospectus = data.data;
                    const index = this.allProspects.findIndex(c => c.prospectusId === prospectus.prospectusId);
                    if (index !== -1) {
                        this.allProspects.splice(index, 1, prospectus);
                        this.allProspects.sort((a, b) => (new Date(a.updatedAt) < new Date(b.updatedAt)) ? -1 : 1); // Descending sorting
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
    }
});