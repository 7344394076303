<template>
    <Form ref="individual" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Prospecto - Persona Física
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-sm-4">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="prospectus.branchOfficeId" :name="'branchOfficeId'" :options="activeBranchOfficesOptions"></BranchOfficesSelect>
                </div>
                <div class="form-group col-sm-4">
                    <label for="isBusinessActivity">Actividad Empresarial:</label>
                    <ActivitySelect v-model="selectedActivity" :name="'isBusinessActivity'" :options="staticOptions"></ActivitySelect>
                </div>
                <div class="form-group col-sm-4">
                    <label for="rfcWithoutBusinessActivity">Inscrito al RFC sin Actividad Empresarial:</label>
                    <HasActivitySelect v-model="selectedHasActivity" :name="'rfcWithoutBusinessActivity'" :options="staticOptions"></HasActivitySelect>
                </div>
            </div>

            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Información Personal</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="name">Nombre:</label>
                        <Field v-model="generalInformation.firstName" type="text" class="form-control" name="name" placeholder="Nombre"
                            rules="required|max:255" />
                        <ErrorMessage name="name" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="lastName">Apellido Paterno:</label>
                        <Field v-model="generalInformation.lastName" type="text" class="form-control" name="lastName"
                            placeholder="Apellido Paterno" rules="required|max:255" />
                        <ErrorMessage name="lastName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="mothersMaidenName">Apellido Materno:</label>
                        <Field v-model="generalInformation.mothersMaidenName" type="text" class="form-control" name="mothersMaidenName"
                            placeholder="Apellido Materno" rules="required|max:255" />
                        <ErrorMessage name="mothersMaidenName" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Fecha de Nacimiento:</label>
                        <Field v-model="generalInformation.dateOfBirth" type="date" class="form-control" name="date" />
                        <ErrorMessage name="date" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Entidad Federativa de Nacimiento:</label>
                        <Field v-model="generalInformation.birthState" type="text" class="form-control" name="birthState"
                            placeholder="Entidad Federativa de Nacimiento" rules="required|max:255" />
                        <ErrorMessage name="birthState" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Nacionalidad:</label>
                        <Field v-model="generalInformation.nationality" type="text" class="form-control" name="nationality"
                            placeholder="Nacionalidad" rules="required|max:255" />
                        <ErrorMessage name="nationality" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Género:</label>
                        <Field v-model="generalInformation.gender" type="text" class="form-control" name="gender" placeholder="Género"
                            rules="required|max:10" />
                        <ErrorMessage name="gender" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Estado Civil:</label>
                        <Field v-model="generalInformation.maritalStatus" type="text" class="form-control" name="maritalStatus"
                            placeholder="Estado Civil" rules="required|max:50" />
                        <ErrorMessage name="maritalStatus" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Régimen Matrimonial:</label>
                        <Field v-model="generalInformation.maritalRegime" type="text" class="form-control" name="maritalRegime"
                            placeholder="Régimen Matrimonial" :rules="maritalRegimeValidation"/>
                        <ErrorMessage name="maritalRegime" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">RFC:</label>
                        <Field v-model="generalInformation.rfc" type="text" class="form-control" name="rfc" placeholder="RFC"
                            rules="required|max:255" />
                        <ErrorMessage name="rfc" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">CURP:</label>
                        <Field v-model="generalInformation.curp" type="text" class="form-control" name="curp" placeholder="CURP"
                            rules="required|max:255" />
                        <ErrorMessage name="curp" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Tel. Casa:</label>
                        <Field v-model="generalInformation.homePhone" type="text" class="form-control" name="homePhone"
                            placeholder="Tel. Casa" rules="max:20"/>
                        <ErrorMessage name="homePhone" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="date">Tel. Oficina:</label>
                        <Field v-model="generalInformation.officePhone" type="text" class="form-control" name="officePhone"
                            placeholder="Tel. Oficina" rules="max:20"/>
                        <ErrorMessage name="officePhone" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Tel. Celular:</label>
                        <Field v-model="generalInformation.cellPhone" type="text" class="form-control" name="cellPhone"
                            placeholder="Tel Celular" rules="required|max:20" />
                        <ErrorMessage name="cellPhone" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Correo Electrónico:</label>
                        <Field v-model="generalInformation.email" type="text" class="form-control" name="email"
                            placeholder="Correo Electrónico" rules="required|max:255" />
                        <ErrorMessage name="email" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">Tipo de Identificación:</label>
                        <Field v-model="generalInformation.identificationType" type="text" class="form-control" name="identificationType"
                            placeholder="Tipo de Identificación" rules="required|max:255" />
                        <ErrorMessage name="identificationType" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="date">No. De Identificación:</label>
                        <Field v-model="generalInformation.identificationNumber" type="text" class="form-control"
                            name="identificationNumber" placeholder="No. De Identificación" rules="required|max:255" />
                        <ErrorMessage name="identificationNumber" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Domicilio Particular</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street">Calle:</label>
                        <Field v-model="homeAddress.street" type="text" class="form-control" name="street" placeholder="Calle"
                            rules="required|max:255" />
                        <ErrorMessage name="street" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber">No. Exterior:</label>
                        <Field v-model="homeAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber" placeholder="No. Exterior"
                            rules="max:10" />
                        <ErrorMessage name="exteriorNumber" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="interiorNumber">No. Interior:</label>
                        <Field v-model="homeAddress.interiorNumber" type="text" class="form-control" name="interiorNumber" placeholder="No. Interior"
                            rules="max:10" />
                        <ErrorMessage name="interiorNumber" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="district">Colonia:</label>
                        <Field v-model="homeAddress.district" type="text" class="form-control" name="district" placeholder="Colonia"
                            rules="required|max:255" />
                        <ErrorMessage name="district" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="municipality">Delegación / Municipio:</label>
                        <Field v-model="homeAddress.municipality" type="text" class="form-control" name="municipality" placeholder="Delegación / Municipio"
                            rules="required|max:255" />
                        <ErrorMessage name="municipality" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="city">Ciudad:</label>
                        <Field v-model="homeAddress.city" type="text" class="form-control" name="city" placeholder="Ciudad"
                            rules="required|max:255" />
                        <ErrorMessage name="city" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="state">Estado:</label>
                        <Field v-model="homeAddress.state" type="text" class="form-control" name="state" placeholder="Estado"
                            rules="required|max:255" />
                        <ErrorMessage name="state" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="postalCode">Código Postal:</label>
                        <Field v-model="homeAddress.postalCode" type="text" class="form-control" name="postalCode" placeholder="Código Postal"
                            rules="required|max:20" />
                        <ErrorMessage name="postalCode" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="country">País:</label>
                        <Field v-model="homeAddress.country" type="text" class="form-control" name="country" placeholder="País"
                            rules="required|max:200" />
                        <ErrorMessage name="country" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="propertyType">Tipo de Vivienda:</label>
                        <Field v-model="homeAddress.propertyType" type="text" class="form-control" name="propertyType" placeholder="Tipo de Vivienda"
                            rules="required|max:255" />
                        <ErrorMessage name="propertyType" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                    <label for="ageInHome">Antigüedad de Domicilio:</label>
                    <Field v-model="homeAddress.ageInHome" type="text" class="form-control" name="ageInHome" placeholder="Antigüedad de Domicilio"
                            rules="required|max:255" />
                        <ErrorMessage name="ageInHome" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="mortgagedHome">Vivienda con Hipoteca:</label>
                        <MortgagedHome v-model="selectedMortgagedHome" :name="'mortgagedHome'" :options="staticOptions"></MortgagedHome>
                    </div>
                </div>
            </div>

            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Domicilio Fiscal ¿Es la misma que la dirección particular?</h3>
                    <Field as="input" name="sameAddress" type="checkbox" class="col-sm-1" v-model="useSameAddress" @click="updateUseSameAddress" />
                </div>
                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street2">Calle:</label>
                        <Field v-model="fiscalAddress.street" id="street2" type="text" class="form-control" name="street2" placeholder="Calle"
                            rules="required|max:255" />
                        <ErrorMessage name="street2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber2">No. Exterior:</label>
                        <Field v-model="fiscalAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber2" placeholder="No. Exterior"
                            rules="max:10" />
                        <ErrorMessage name="exteriorNumber2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="interiorNumber2">No. Interior:</label>
                        <Field v-model="fiscalAddress.interiorNumber" type="text" class="form-control" name="interiorNumber2" placeholder="No. Interior"
                            rules="max:10" />
                        <ErrorMessage name="interiorNumber2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="district2">Colonia:</label>
                        <Field v-model="fiscalAddress.district" type="text" class="form-control" name="district2" placeholder="Colonia"
                            rules="required|max:255" />
                        <ErrorMessage name="district2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="municipality2">Delegación / Municipio:</label>
                        <Field v-model="fiscalAddress.municipality" type="text" class="form-control" name="municipality2" placeholder="Delegación / Municipio"
                            rules="required|max:255" />
                        <ErrorMessage name="municipality2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="country2">País:</label>
                        <Field v-model="fiscalAddress.country" type="text" class="form-control" name="country2" placeholder="País"
                            rules="required|max:255" />
                        <ErrorMessage name="country2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="city2">Ciudad:</label>
                        <Field v-model="fiscalAddress.city" type="text" class="form-control" name="city2" placeholder="Ciudad"
                            rules="required|max:255" />
                        <ErrorMessage name="city2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="state2">Estado:</label>
                        <Field v-model="fiscalAddress.state" type="text" class="form-control" name="state2" placeholder="Estado"
                            rules="required|max:255" />
                        <ErrorMessage name="state2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="postalCode2">Código Postal:</label>
                        <Field v-model="fiscalAddress.postalCode" type="text" class="form-control" name="postalCode2" placeholder="Código Postal"
                            rules="required|max:20" />
                        <ErrorMessage name="postalCode2" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="statusId">Estatus:</label>
                <StatusSelect v-model="this.prospectus.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect> 
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';

import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import ActivitySelect from '@/Components/Selects/SelectField.vue';
import HasActivitySelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import MortgagedHome from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

import Prospectus from '@/Models/Prospects';
import GeneralInformation from '@/Models/GeneralInformation';
import FiscalAddress from '@/Models/FiscalAddress';

export default {
    components: {
        BranchOfficesSelect,
        StatusSelect,
        ActivitySelect,
        HasActivitySelect,
        MortgagedHome,
        PrimaryButton,
    },
    mixins: [],
    data: () => ({
        prospectus: new Prospectus({
            personTypeId: 1,  // Always wiil be a Individual Prospectus  (1)
        }),
        generalInformation: new GeneralInformation(),
        homeAddress: new FiscalAddress(),
        fiscalAddress: new FiscalAddress(),
        selectedActivity: null,
        selectedHasActivity: null,
        selectedMortgagedHome: null,
        selectOptions: [
            {
                id: 1,
                name: 'No'
            },
            {
                id: 2,
                name: 'Si'
            },
        ],
        useSameAddress: false,
        activeBranchOfficesOptions: [],
    }),
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        ...mapState(useIndividualProspectsStore, []),
        staticOptions() {
            return helper.mapOptions(this.selectOptions);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(2));
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        ...mapActions(useIndividualProspectsStore, ['createProspect']),
        onSubmit() {
            this.prospectus.generalInformation = this.generalInformation;
            this.prospectus.homeAddress = this.homeAddress;
            this.prospectus.fiscalAddress = this.fiscalAddress;

            this.createProspect(this.prospectus);
            this.$refs.individual.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
        updateUseSameAddress() {
            this.useSameAddress = !this.useSameAddress;
            this.setFiscalAddress();
        },
        setFiscalAddress() {
            if (this.useSameAddress) {
                this.fiscalAddress.street = this.homeAddress.street;
                this.fiscalAddress.exteriorNumber = this.homeAddress.exteriorNumber;
                this.fiscalAddress.interiorNumber = this.homeAddress.interiorNumber;
                this.fiscalAddress.district = this.homeAddress.district;
                this.fiscalAddress.municipality = this.homeAddress.municipality;
                this.fiscalAddress.country = this.homeAddress.country;
                this.fiscalAddress.city = this.homeAddress.city;
                this.fiscalAddress.state = this.homeAddress.state;
                this.fiscalAddress.postalCode = this.homeAddress.postalCode;
            } else {
                this.fiscalAddress.street = null;
                this.fiscalAddress.exteriorNumber = null;
                this.fiscalAddress.interiorNumber = null;
                this.fiscalAddress.district = null;
                this.fiscalAddress.municipality = null;
                this.fiscalAddress.country = null;
                this.fiscalAddress.city = null;
                this.fiscalAddress.state = null;
                this.fiscalAddress.postalCode = null;
            }
        },
        branchOfficeOptions(data) {
            return helper.mapOptions(data);
        },
        // Custom validations:
        maritalRegimeValidation(value) {
            let maritalStatus = this.generalInformation.maritalStatus;
            const max = 50;

            if(!maritalStatus) {
                return 'Especifique el estado civil';
            }

            if (maritalStatus.toLowerCase() !== 'soltero' && maritalStatus.toLowerCase() !== 'soltera' && value === null) {
                return 'El campo es obligatorio';
            }
            
            if (value && value.length > max) {
                return `El campo no debe ser mayor a ${max} caracteres`;
            } else {
                return true;
            }
        },
        setBooleanValue(value) {
            switch(value) {
                case 1:
                    return false;
                case 2:
                    return true;
                default:
                    return 0;
            }
        }
    },
    watch: {
        getActiveBranchOffices: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.activeBranchOfficesOptions = this.branchOfficeOptions(newValue);
                }
            }
        },
        selectedActivity: {
            immediate: true,
            handler(newValue) {
                this.generalInformation.isBusinessActivity = this.setBooleanValue(newValue);
            }
        },
        selectedHasActivity: {
            immediate: true,
            handler(newValue) {
                this.generalInformation.rfcWithoutBusinessActivity = this.setBooleanValue(newValue);
            }
        },
        selectedMortgagedHome: {
            immediate: true,
            handler(newValue) {
                this.homeAddress.mortgagedHome = this.setBooleanValue(newValue);
            }
        },
    },
    created() {},
    mounted() {
        this.activeBranchOfficesOptions = this.branchOfficeOptions(this.getActiveBranchOffices);
    }
}
</script>