import Beneficiary from './Beneficiary';

export default class IndividualBeneficiary extends Beneficiary {
    constructor(
        {
        // additional attributes
        pfType = null,
        foreignAndVisitor = null,
        homeAddress = null,
        nationalAddress = null,
        ...rest
    } = {}) {
        super(rest);
        // additional attributes
        this.pfType = pfType;
        this.foreignAndVisitor = foreignAndVisitor;
        this.homeAddress = homeAddress;
        this.nationalAddress = nationalAddress;
    }
}