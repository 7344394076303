import { defineStore } from 'pinia';
import axios from 'axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
export const useLocalizationStore = defineStore('localizations', {
    persist: false,
    state: () => {
        return {
            states: [],
            errors: [],
        }
    },
    getters: {
        getStatesByCountry: state => countryId => {
            return state.states.filter(s => s.countryId === countryId)
            .sort((a,b) => a.name.localeCompare(b.name));
        },
    },
    actions: {
        setStates(apiState) {
            this.states = apiState
        },
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchStates() {
            try {
                const { data: data} = await axios.get(`/localization/States`);
                this.setStates(data);
            } catch (error) {
                this.setErrors(error);
            }
        },
    },
    
});