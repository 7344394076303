<template>
  <Form ref="form" @submit="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ $getLocaleMessage('buttons', 'updating') }} Cuenta <strong>{{ account.referenceNumber }}</strong>
      </h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="form-group">
        <label for="financialFunderId">Fondeadora:</label>
        <FinancialFundersSelect v-model="account.financialFunderId" :name="'financialFunderId'" :options="financialFunderOptions"></FinancialFundersSelect>
      </div>

      <div class="form-group">
        <label for="bankId">Banco:</label>
        <BanksSelect v-model="account.bankId" :name="'bankId'" :options="bankOptions"></BanksSelect>
      </div>

      <div class="form-group">
        <label for="anchor">Cuenta:</label>
        <Field v-model="account.accountNumber" type="text" class="form-control" name="anchor" placeholder="Cuenta" rules="required|max:255" />
        <ErrorMessage name="anchor" class="input-error"></ErrorMessage>
      </div>

      <div class="form-group">
        <label for="observations">Observaciones:</label>
        <Field v-model="account.observations" type="text" class="form-control" name="observations" placeholder="Observaciones" rules="max:500"/>
        <ErrorMessage name="observations" class="input-error"></ErrorMessage>
      </div>

      <div class="form-group">
        <label for="statusId">Estatus:</label>
        <StatusSelect v-model="account.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>                
      </div>

    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
      <PrimaryButton class="btn btn-primary" type="submit">
        {{ $getLocaleMessage('buttons', 'update') }}
      </PrimaryButton>
    </div>
</Form></template>

<script>
import * as helper from '@/helpers';
import Account from '@/Models/Account';

import { mapState, mapActions } from 'pinia';
import { useAccountsStore } from '@/pinia/accounts.module';
import { useBanksStore } from '@/pinia/banks.module';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useStatusStore } from '@/pinia/status.module';

import FinancialFundersSelect from '@/Components/Selects/SelectField.vue';
import BanksSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
  components: {
    PrimaryButton,
    FinancialFundersSelect,
    BanksSelect,
    StatusSelect,
  },
  mixins: [],
  data() {
    return {
      account: new Account(),
    }
  },
  props: {
    selectedRecord: {
      type: Object,
      default: () => new Account(),
    },
  },
  emits: ['close-modal'],
  computed: {
    ...mapState(useFinancialFundersStore, ['getActiveFinancialFunders']),
    ...mapState(useBanksStore, ['getBanks']),
    ...mapState(useStatusStore, ['getAllStatusByType']),
    financialFunderOptions() {
      return helper.mapOptions(this.getActiveFinancialFunders, 'id', 'anchor');
    },
    bankOptions() {
      return helper.mapOptions(this.getBanks);
    },
    statusOptions() {
      return helper.mapOptions(this.getAllStatusByType(1));
    },
  },
  methods: {
    ...mapActions(useAccountsStore, ['updateAccount']),
    onSubmit() {
      this.updateAccount(this.account);
      this.$refs.form.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
  watch: {
    selectedRecord: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.account = newValue;
        }
      }
    }
  },
  created() {},
}
</script>