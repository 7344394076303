<template>
    <div class="wrapper">
        <Login></Login>
    </div>
    <!-- ./wrapper -->
</template>

<script>
import Login from '@/Pages/Users/Login.vue';

export default {
    components: {
        Login,
    },
    computed: {},
    mounted() {},
}
</script>