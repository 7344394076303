export default class RecordFileForm {
  constructor({
    recordProspectusDto = null,
    fileId = null,
    files = [] 
  } = {}) {
    this.recordProspectusDto = recordProspectusDto;
    this.fileId = fileId;
    this.files = files;
  }
}