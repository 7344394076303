import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import ApplicantType from '@/Models/LoanApplications/ApplicantType';

import * as service from '@/services';

const $toast = useToast();

export const useApplicantTypesStore = defineStore('applicantTypes', {
    persist: false,
    state: () => {
        return {
            applicantTypes: [],
            applicantType: {},
            errors: null,
        }
    },
    getters: {
        getApplicantTypes: state => state.applicantTypes,
        getApplicantType: state => state.applicantType,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchApplicantTypes() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get('/api/applicantTypes');
                if (data && data.status) {
                    const applicantTypes = data.data;
                    this.applicantTypes = applicantTypes.map(at => new ApplicantType(at));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar los tipos de solicitantes.");
            }
        },
        async fetchApplicantTypeById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/questions/${id}`);
                if (data && data.status) {
                    this.applicantType = new ApplicantType(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar el tipo de solicitante");
            }
        }
    }
});