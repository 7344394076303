<template>
    <Form ref="corporate" @submit="onSubmit">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Solicitud - Persona Moral
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">

            <!-- Section 1 -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title"></h3>
                </div>
                <div class="row card-body">
                    <div class="form-group col-sm-4">
                        <label for="applicantTypeId">Tipo de Solicitante:</label>
                        <ApplicantTypesSelect v-model="loanApplication.applicantTypeId" :name="'applicantTypeId'" :options="creditApplicantTypeOptions"></ApplicantTypesSelect>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="loanApplicationDate">Fecha de solicitud:</label>
                        <Field v-model="loanApplication.applicationDate" type="date" class="form-control" name="loanApplicationDate" :rules="minDate"/>
                        <ErrorMessage name="loanApplicationDate" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="financialFunderId">Fondeadora:</label>
                        <FinancialFundersSelect v-model="loanApplication.financialFunderId" :name="'financialFunderId'" :options="financialFundersOptions"></FinancialFundersSelect>
                    </div>
                </div>
            </div>

            <!-- Section 2 -->
            <div class="row">
                <div class="form-group col-sm-4">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="selectedBranchOfficeId" :name="'branchOfficeId'" :options="activeBranchOfficesOptions" :isDisabled="true"></BranchOfficesSelect>
                </div>
            </div>

            <!-- Información General -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Información General</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-4">
                        <label for="businessName">Denominación o Razón Social:</label>
                        <Field v-model="generalInformation.businessName" type="text" class="form-control" name="businessName" disabled />
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="dateOfIncorporation">Fecha de Constitución:</label>
                        <Field v-model="setSelectedDateOfIncorporation" type="date" class="form-control" name="dateOfIncorporation" disabled />
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="notaryInformation">No. de Acta/Notario (Nombre y Número):</label>
                        <Field v-model="generalInformation.notaryInformation" type="text" class="form-control" name="notaryInformation" disabled />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="businessPurpose">Giro Mercantil:</label>
                        <Field v-model="generalInformation.businessPurpose" type="businessPurpose" class="form-control" name="businessPurpose" disabled />
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="mainActivity">Actividad Preponderante:</label>
                        <Field v-model="generalInformation.mainActivity" type="text" class="form-control" name="mainActivity" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="country">País:</label>
                        <Field v-model="generalInformation.country" type="text" class="form-control" name="country" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="fielFolio">Folio FIEL:</label>
                        <Field v-model="generalInformation.fielFolio" type="text" class="form-control" name="fielFolio" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="rfc">RFC:</label>
                        <Field v-model="generalInformation.rfc" type="text" class="form-control" name="rfc" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="email">Correo Electrónico:</label>
                        <Field v-model="generalInformation.email" type="text" class="form-control" name="email" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="officePhone">Teléfono:</label>
                        <Field v-model="generalInformation.officePhone" type="text" class="form-control" name="officePhone" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="numberOfEmployees">Número de empleados:</label>
                        <Field v-model="generalInformation.numberOfEmployees" type="text" class="form-control" name="numberOfEmployees" disabled />
                        <ErrorMessage name="numberOfEmployees" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <!-- Domicilio Fiscal -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Domicilio Fiscal</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street">Calle:</label>
                        <Field v-model="fiscalAddress.street" type="text" class="form-control" name="street" disabled />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber">No. Exterior:</label>
                        <Field v-model="fiscalAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber" disabled />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="interiorNumber">No. Interior:</label>
                        <Field v-model="fiscalAddress.interiorNumber" type="text" class="form-control" name="interiorNumber" disabled />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="district">Colonia:</label>
                        <Field v-model="fiscalAddress.district" type="text" class="form-control" name="district" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="municipality">Delegación / Municipio:</label>
                        <Field v-model="fiscalAddress.municipality" type="text" class="form-control" name="municipality" disabled />
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="city">Ciudad:</label>
                        <Field v-model="fiscalAddress.city" type="text" class="form-control" name="city" disabled />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="state">Estado:</label>
                        <Field v-model="fiscalAddress.state" type="text" class="form-control" name="state" disabled />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="country2">País:</label>
                        <Field v-model="fiscalAddress.country" type="text" class="form-control" name="country2" disabled />
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="postalCode">Código Postal:</label>
                        <Field v-model="fiscalAddress.postalCode" type="text" class="form-control" name="postalCode" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="references">Referencias:</label>
                        <Field v-model="fiscalAddress.references" type="text" class="form-control" name="references" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="propertyType">Tipo de Inmueble:</label>
                        <Field v-model="fiscalAddress.propertyType" type="text" class="form-control" name="propertyType" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="ageInHome">Antigüedad en el Domicilio:</label>
                        <Field v-model="fiscalAddress.ageInHome" type="text" class="form-control" name="ageInHome" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="legalStatusProperty">Situación Legal del Inmueble:</label>
                        <Field v-model="fiscalAddress.legalStatusProperty" type="text" class="form-control" name="legalStatusProperty" disabled />
                    </div>
                </div>
            </div>

            <!-- Representante legal -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Representante legal</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="firstName">Nombre:</label>
                        <Field v-model="legalRepresentative.firstName" type="text" class="form-control" name="firstName" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="lastName">Apellido Paterno:</label>
                        <Field v-model="legalRepresentative.lastName" type="text" class="form-control" name="lastName" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="mothersMaidenName">Apellido Materno:</label>
                        <Field v-model="legalRepresentative.mothersMaidenName" type="text" class="form-control" name="mothersMaidenName" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="dateOfBirth">Fecha de Nacimiento:</label>
                        <Field v-model="setSelectedRepresentativeDateOfBirth" type="date" class="form-control" name="dateOfBirth" :rules="minDate" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="birthState">Entidad federativa de nacimiento:</label>
                        <Field v-model="legalRepresentative.birthState" type="text" class="form-control" name="birthState" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="nationality">Nacionalidad:</label>
                        <Field v-model="legalRepresentative.nationality" type="text" class="form-control" name="nationality" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="gender">Género:</label>
                        <Field v-model="legalRepresentative.gender" type="text" class="form-control" name="gender" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="maritalStatus">Estado Civil:</label>
                        <Field v-model="legalRepresentative.maritalStatus" type="text" class="form-control" name="maritalStatus" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="maritalRegime">Régimen Matrimonial:</label>
                        <Field v-model="legalRepresentative.maritalRegime" type="text" class="form-control" name="maritalRegime" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="rfc2">RFC:</label>
                        <Field v-model="legalRepresentative.rfc" type="text" class="form-control" name="rfc2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="curp">CURP:</label>
                        <Field v-model="legalRepresentative.curp" type="text" class="form-control" name="curp" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="homePhone2">Tel. de Casa:</label>
                        <Field v-model="legalRepresentative.homePhone" type="text" class="form-control" name="homePhone2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="officePhone2">Tel. de Oficina:</label>
                        <Field v-model="legalRepresentative.officePhone" type="text" class="form-control" name="officePhone2" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="cellPhone2">Tel. Celular:</label>
                        <Field v-model="legalRepresentative.cellPhone" type="text" class="form-control" name="cellPhone2" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="email2">Correo Electrónico:</label>
                        <Field v-model="legalRepresentative.email" type="text" class="form-control" name="email2" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="identificationType">Tipo de Identificación:</label>
                        <Field v-model="legalRepresentative.identificationType" type="text" class="form-control" name="identificationType" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="identificationNumber">Número de Identificación:</label>
                        <Field v-model="legalRepresentative.identificationNumber" type="text" class="form-control" name="identificationNumber" disabled />
                    </div>
                </div>
            </div>

            <!-- Domicilio del Representante -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Domicilio del Representante</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-6">
                        <label for="street2">Calle:</label>
                        <Field v-model="representativeAddress.street" type="text" class="form-control" name="street2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="exteriorNumber2">No. Exterior:</label>
                        <Field v-model="representativeAddress.exteriorNumber" type="text" class="form-control" name="exteriorNumber2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="interiorNumber2">No. Interior:</label>
                        <Field v-model="representativeAddress.interiorNumber" type="text" class="form-control" name="interiorNumber2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="district2">Colonia:</label>
                        <Field v-model="representativeAddress.district" type="text" class="form-control" name="district2" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="municipality2">Delegación / Municipio:</label>
                        <Field v-model="representativeAddress.municipality" type="text" class="form-control" name="municipality2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="city2">Ciudad:</label>
                        <Field v-model="representativeAddress.city" type="text" class="form-control" name="city2" disabled />
                    </div>

                    <div class="form-group col-sm-3">
                        <label for="state2">Estado:</label>
                        <Field v-model="representativeAddress.state" type="text" class="form-control" name="state2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="country2">País:</label>
                        <Field v-model="representativeAddress.country" type="text" class="form-control" name="country2" disabled />
                    </div>

                    <div class="form-group col-sm-2">
                        <label for="postalCode2">Código Postal:</label>
                        <Field v-model="representativeAddress.postalCode" type="text" class="form-control" name="postalCode2" disabled />
                    </div>
                </div>
            </div>

            <!-- Garantía -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Garantía</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-4">
                        <label for="typeOfGuarantee">Tipo de Garantía:</label>
                        <Field v-model="legalEntityGuarantee.typeOfGuarantee" type="text" class="form-control" name="typeOfGuarantee" rules="required|max:50"/>
                        <ErrorMessage name="typeOfGuarantee" class="input-error"></ErrorMessage>
                    </div>

                    <div class="form-group col-sm-8">
                        <label for="propertyOwnership">Acreditación de Propiedad:</label>
                        <Field v-model="legalEntityGuarantee.propertyOwnership" type="text" class="form-control" name="propertyOwnership" rules="required|max:50"/>
                        <ErrorMessage name="propertyOwnership" class="input-error"></ErrorMessage>
                    </div>

                    <div class="form-group col-sm-6">
                        <label for="marketValue">Valor Comercial:</label>
                        <Field v-model="legalEntityGuarantee.marketValue" type="number" class="form-control" name="marketValue" rules="required"/>
                        <ErrorMessage name="marketValue" class="input-error"></ErrorMessage>
                    </div>

                    <div class="form-group col-sm-6">
                        <label for="encumbrance">Gravamen:</label>
                        <Field v-model="legalEntityGuarantee.encumbrance" type="text" class="form-control" name="encumbrance" rules="required|max:50"/>
                        <ErrorMessage name="encumbrance" class="input-error"></ErrorMessage>
                    </div>
                </div>
            </div>

            <!-- Referencias Comerciales (Proveedores) -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Referencias Comerciales (Proveedores)</h3>
                </div>

                <div class="row card-body">
                    <div class="row col-sm-12" v-for="(reference, index) in commercialLoanReferences" :key="index">
                        <div class="form-group col-sm-5">
                            <label :for="'fullName1'+index">Nombre completo:</label>
                            <Field v-model="reference.fullName" type="text" class="form-control" :name="'fullName1'+index" placeholder="Nombre completo" rules="max:255" />
                            <ErrorMessage :name="'fullName1'+index" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-4">
                            <label :for="'address1'+index">Domicilio (Calle, Número, Ciudad, Estado):</label>
                            <Field v-model="reference.address" type="text" class="form-control" :name="'address1'+index" placeholder="Domicilio" rules="max:255" />
                            <ErrorMessage :name="'address1'+index" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-3">
                            <label :for="'phoneNumber1'+index">Teléfono:</label>
                            <Field v-model="reference.phoneNumber" type="text" class="form-control" :name="'phoneNumber1'+index" placeholder="Teléfono" rules="max:255" />
                            <ErrorMessage :name="'phoneNumber1'+index" class="input-error"></ErrorMessage>
                        </div>
                    </div>

                    <div class="form-group col-sm-12" v-if="commercialLoanReferences.length > 0">
                        <label for="comments1">Comentarios:</label>
                        <Field as="textarea" v-model="commercialLoanReferences[0].comments" type="text" class="form-control" name="comments1" placeholder="Comentarios" rules="max:500"/>
                    </div>
                </div>
            </div>

            <!-- Referencias Bancarias -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Referencias Bancarias</h3>
                </div>
                <div class="row card-body">
                    <div class="row col-sm-12" v-for="(reference, index) in bankLoanReferences" :key="index">
                        <div class="form-group col-sm-5">
                            <label :for="'fullName2'+index">Nombre completo:</label>
                            <Field v-model="reference.fullName" type="text" class="form-control" :name="'fullName2'+index" placeholder="Nombre completo" rules="max:255" />
                            <ErrorMessage :name="'fullName2'+index" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-4">
                            <label :for="'address2'+index">Domicilio (Calle, Número, Ciudad, Estado):</label>
                            <Field v-model="reference.address" type="text" class="form-control" :name="'address2'+index" placeholder="Domicilio" rules="max:255" />
                            <ErrorMessage :name="'address2'+index" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-3">
                            <label :for="'phoneNumber2'+index">Teléfono:</label>
                            <Field v-model="reference.phoneNumber" type="text" class="form-control" :name="'phoneNumber2'+index" placeholder="Teléfono" rules="max:255" />
                            <ErrorMessage :name="'phoneNumber2'+index" class="input-error"></ErrorMessage>
                        </div>
                    </div>

                    <div class="row col-sm-12" v-if="bankLoanReferences.length > 0">
                        <div class="form-group col-sm-12">
                            <label for="comments2">Comentarios:</label>
                            <Field as="textarea" v-model="bankLoanReferences[0].comments" type="text" class="form-control" name="comments2" placeholder="Comentarios" rules="max:500"/>
                            <ErrorMessage name="street" class="input-error"></ErrorMessage>
                        </div>
                        <h6 class="col-sm-12 subtitle-h6">Confirmación de Referencias (Para ser llenado por la empresa)</h6>
                        <div class="form-group col-sm-3">
                            <label for="varificationDate">Fecha de verificación:</label>
                            <Field v-model="bankLoanReferences[0].verificationDate" type="date" class="form-control" name="varificationDate" placeholder="Calle" />
                            <ErrorMessage name="varificationDate" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-5">
                            <label for="workersName">Nombre del trabajador:</label>
                            <Field v-model="bankLoanReferences[0].workersName" type="text" class="form-control" name="workersName" placeholder="Calle" rules="max:255" />
                            <ErrorMessage name="workersName" class="input-error"></ErrorMessage>
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="position">Puesto:</label>
                            <Field v-model="bankLoanReferences[0].position" type="text" class="form-control" name="position" placeholder="Calle" rules="max:255" />
                            <ErrorMessage name="position" class="input-error"></ErrorMessage>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Características del producto (Solicitante) -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Características del producto (Solicitante)</h3>
                </div>
                <div class="row card-body">
                    <div class="form-group col-sm-5">
                        <label for="productType">Nombre del producto:</label>
                        <Field v-model="quotation.product.productType.name" type="text" class="form-control" name="productType" disabled/>
                    </div>
                    <div class="form-group col-sm-5">
                        <!-- <label for="creditAmount">Importe (Número y Letra):</label> -->
                        <label for="creditAmount">Importe:</label>
                        <Field v-model="creditAmountFormat" type="text" class="form-control" name="creditAmount" disabled/>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="totalTerm">Plazo:</label>
                        <Field v-model="quotation.totalTerm" type="text" class="form-control" name="totalTerm" disabled/>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="purposeRequestedAmount">Destino del importe solicitado:</label>
                        <Field v-model="productDetail.purposeRequestedAmount" type="text" class="form-control" name="purposeRequestedAmount" placeholder="Destino del importe solicitado" rules="required|max:255" />
                        <ErrorMessage name="purposeRequestedAmount" class="input-error"></ErrorMessage>
                    </div>
                    <h6 class="col-sm-12 subtitle-h6">(Uso Exclusivo de la empresa)</h6>
                    <div class="form-group col-sm-4">
                        <!-- <label for="authorizedAmount">Monto autorizado (Número y Letra):</label> -->
                        <label for="authorizedAmount">Monto autorizado:</label>
                        <Field v-model="productDetail.authorizedAmount" type="text" class="form-control" name="authorizedAmount" placeholder="Monto autorizado"
                            rules="required" />
                        <ErrorMessage name="authorizedAmount" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="overpaymentFactor">Factor de Sobre Pago:</label>
                        <Field v-model="productDetail.overpaymentFactor" type="text" class="form-control" name="overpaymentFactor" rules="max:255"/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="mlpRisk">Riesgo PLD:</label>
                        <MLPSelect v-model="productDetail.mlpRisk" :name="'mlpRisk'" :options="staticMlpOptions"></MLPSelect>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="totalTerm2">Plazo:</label>
                        <Field v-model="quotation.totalTerm" type="text" class="form-control" name="totalTerm2" disabled/>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="paymentDays">Días de Pago:</label>
                        <Field v-model="productDetail.paymentDays" type="text" class="form-control" name="paymentDays" placeholder="Días de Pago"
                            rules="required" />
                        <ErrorMessage name="paymentDays" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="annualRate">Tasa ordinaria:</label>
                        <Field v-model="annualRateToPercent" type="text" class="form-control" name="annualRate" disabled/>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="penaltyRate">Tasa Moratoria:</label>
                        <Field v-model="quotation.street" type="text" class="form-control" name="penaltyRate" />
                        <ErrorMessage name="penaltyRate" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="openingCommission">Comisión por Apertura:</label>
                        <Field v-model="openingCommissionToPercent" type="text" class="form-control" name="openingCommission" disabled/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="gracePeriod">Días de Gracia:</label>
                        <Field v-model="quotation.gracePeriod" type="text" class="form-control" name="gracePeriod" disabled/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="firstPaymentDay">Primer Pago:</label>
                        <Field v-model="productDetail.street" type="date" class="form-control" name="firstPaymentDay" />
                        <ErrorMessage name="firstPaymentDay" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="authorizedBy">Autorizado por (Nombre Completo):</label>
                        <Field v-model="productDetail.authorizedBy" type="text" class="form-control" name="authorizedBy" placeholder="Autorizado por"  rules="required|max:255" />
                        <ErrorMessage name="authorizedBy" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="date2">Fecha (DD/MM/AA):</label>
                        <Field v-model="productDetail.date" type="date" class="form-control" name="date2" placeholder="Fecha"
                            rules="required" />
                        <ErrorMessage name="date2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="position2">Puesto y Firma:</label>
                        <Field v-model="productDetail.position" type="text" class="form-control" name="position2" placeholder="" rules="required|max:255" />
                        <ErrorMessage name="position2" class="input-error"></ErrorMessage>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="remarks">Observaciones a la Solicitud:</label>
                        <Field v-model="productDetail.remarks" as="textarea" type="text" class="form-control" name="remarks" placeholder="Observaciones a la Solicitud" rules="max:255"/>
                    </div>
                </div>
            </div>

            <!-- Pago domiciliado -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Pago domiciliado</h3>
                </div>
                <div class="row card-body">
                    <div class="form-group col-sm-12">
                        <div class="row">
                            <p class="col-sm-4">De ser aprobada la siguiente solicitud autorizo a:</p>
                            <Field v-model="directDebit.authorizedPersonName" type="text" class="form-control col-sm-8" name="authorizedPersonName"  placeholder="Nombre" rules="max:255" />
                            <ErrorMessage name="authorizedPersonName" class="input-error"></ErrorMessage>
                            <p class="col-sm-9">para realizar la transferencia del monto autorizado y aplicar los descuentos de forma automática a la cuenta número</p>
                            <Field v-model="directDebit.accountNumber" type="text" class="form-control col-sm-3" name="accountNumber"  placeholder="Cuenta" rules="max:255" />
                            <ErrorMessage name="accountNumber" class="input-error"></ErrorMessage>
                            <p class="col-sm-1">Clabe:</p> 
                            <Field v-model="directDebit.clabe" type="text" class="form-control col-sm-3" name="clabe" placeholder="Clabe" rules="max:255" />
                            <ErrorMessage name="clabe" class="input-error"></ErrorMessage>
                            <p class="col-sm-1">Banco:</p>
                            <Field v-model="directDebit.bank" type="text" class="form-control col-sm-3" name="bank" placeholder="Banco" rules="max:255" />
                            <ErrorMessage name="bank" class="input-error"></ErrorMessage>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Entrevista Personal del Solicitante -->
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Entrevista Personal del Solicitante</h3>
                </div>

                <div class="row card-body">
                    <div class="form-group col-sm-12">
                        <label for="question1">1.- ¿Realizara pagos en efectivo y cuál es el origen de los recursos?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <!-- NOTA: El componente Answer es un Select -->
                                <SelectAnswer v-model="answers[0].dataValue" :name="'answer1'" :options="staticAnswerOptions" @update="setNewAnswer(0, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-9">
                                <label for="origin">Origen</label>
                                <Field v-model="answers[1].dataValue" type="text" class="form-control" name="origin" placeholder="Origen" rules="max:500"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="question2">2.-¿El Importe solicitado será liquidado con recursos propios o por un tercero?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <SelectAnswer v-model="answers[2].dataValue" :name="'answer3'" :options="typesOptions" @update="setNewAnswer(2, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-9">
                                <label for="name2">Nombre del Tercero</label>
                                <Field v-model="answers[3].dataValue" type="text" class="form-control" name="name2" placeholder="Nombre del Tercero" rules="max:500"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="question3">3.- ¿Los recursos solicitados serán destinados para uso propio o un tercero disfrutara de ellos?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <SelectAnswer v-model="answers[4].dataValue" :name="'answer5'" :options="typesOptions" @update="setNewAnswer(4, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-9">
                                <label for="name3">Nombre del Tercero</label>
                                <Field v-model="answers[5].dataValue" type="text" class="form-control" name="name3" placeholder="Nombre del Tercero" rules="max:500"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="">4.- ¿Los recursos con los que se liquidara el monto solicitado proceden de actividades licitas?</label>
                        <SelectAnswer v-model="answers[6].dataValue" :name="'answer7'" :options="staticAnswerOptions" @update="setNewAnswer(6, $event)"></SelectAnswer>
                    </div>

                    <div class="form-group col-sm-12">
                        <label for="">5.- ¿Usted desempeña o ha desempeñado durante los últimos 12 meses funciones públicas o políticas destacadas dentro del territorio nacional, o extranjero?</label>
                        <div class="row align-end">
                            <div class="col-sm-3">
                                <SelectAnswer v-model="answers[7].dataValue" :name="'answer8'" :options="staticAnswerOptions" @update="setNewAnswer(7, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-3">
                                <label for="position3">Puesto</label>
                                <Field v-model="answers[8].dataValue" type="text" class="form-control" name="position3" placeholder="Puesto" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="assignmentDate">Fecha de Asignación (DD/MM/AA):</label>
                                <Field v-model="answers[9].dataValue" type="date" class="form-control" name="assignmentDate" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="withdrawalDate">Fecha de Retiro (DD/MM/AA):</label>
                                <Field v-model="answers[10].dataValue" type="date" class="form-control" name="withdrawalDate" rules="max:500"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="">
                            6.- ¿Su cónyuge o algún familiar por consanguinidad o afinidad hasta segundo grado, 
                            desempeña o ha desempeñado durante los últimos 12 meses funciones públicas o políticas destacadas dentro del territorio nacional, 
                            o en el extranjero?
                        </label>
                        <div class="row align-end">
                            <div class="col-sm-4">
                                <SelectAnswer v-model="answers[11].dataValue" :name="'answer12'" :options="staticAnswerOptions" @update="setNewAnswer(11, $event)"></SelectAnswer>
                            </div>
                            <div class="col-sm-4">
                                <label for="fullName">Nombre Completo:</label>
                                <Field v-model="answers[12].dataValue" type="text" class="form-control" name="fullName" placeholder="Nombre Completo" rules="max:500"/>
                            </div>
                            <div class="col-sm-4">
                                <label for="dependencia">Dependencia:</label>
                                <Field v-model="answers[13].dataValue" type="text" class="form-control" name="dependencia" placeholder="Dependencia" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="position4">Puesto:</label>
                                <Field v-model="answers[14].dataValue" type="text" class="form-control" name="position4" placeholder="Puesto" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="asignacion">Fecha de Asignación (DD/MM/AA):</label>
                                <Field v-model="answers[15].dataValue" type="date" class="form-control" name="asignacion" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="retiro">Fecha de Retiro (DD/MM/AA):</label>
                                <Field v-model="answers[16].dataValue" type="date" class="form-control" name="retiro" rules="max:500"/>
                            </div>
                            <div class="col-sm-3">
                                <label for="parentesco">Parentesco:</label>
                                <Field v-model="answers[17].dataValue" type="text" class="form-control" name="parentesco" placeholder="Parentesco" rules="max:500"/>
                            </div>
                        </div>
                    </div>
                    <!-- Fin de Preguntas -->

                    <!-- Entrevistador y Solicitiante -->
                    <div class="form-group col-sm-12">
                        <div class="row">
                            <div class="col-sm-4">
                                <label for="interviewer">Entrevista realizada por (Nombre completo):</label>
                                <Field v-model="interview.interviewer" type="text" class="form-control" name="interviewer" placeholder="Nombre Completo"
                                    rules="required|max:255" />
                                <ErrorMessage name="interviewer" class="input-error"></ErrorMessage>
                            </div>
                            <div class="col-sm-4">
                                <label for="applicantsName">Solicitante:</label>
                                <!-- TODO: Change input to select component (Opciones: Cliente Nuevo / Cliente Recurrente) -->
                                <Field v-model="loanApplication.applicantsName" type="text" class="form-control" name="applicantsName" placeholder="Solicitante" rules="required|max:255"/>
                                <ErrorMessage name="applicantsName" class="input-error"></ErrorMessage>
                            </div>
                            <div class="col-sm-4">
                                <label for="authorizer">Autorizado (Nombre completo):</label>
                                <Field v-model="interview.authorizer" type="text" class="form-control" name="authorizer" placeholder="Nombre Completo"
                                    rules="required|max:255" />
                                <ErrorMessage name="authorizer" class="input-error"></ErrorMessage>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="form-group">
                <label for="statusId">Estatus:</label>
                <StatusSelect v-model="loanApplication.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect> 
            </div>
        </div>

        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useCorporateProspectsStore } from '@/pinia/Prospects/corporate.module';
import { useCorpLoanApplicationStore } from '@/pinia/LoanApplications/corporate.module';
import { useApplicantTypesStore } from '@/pinia/LoanApplications/applicantTypes.module.js';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useQuotationsStore } from '@/pinia/quotations.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import ApplicantTypesSelect from '@/Components/Selects/SelectField.vue';
import FinancialFundersSelect from '@/Components/Selects/SelectField.vue';
import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import MLPSelect from '@/Components/Selects/SelectField.vue';
import SelectAnswer from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';

import ProspectData from '@/Models/Prospects/ProspectData';
import Prospectus from '@/Models/Prospects';
import GeneralInformation from '@/Models/GeneralInformation';
import FiscalAddress from '@/Models/FiscalAddress';
import LegalRepresentative from '@/Models/LegalRepresentative';
import CorporateLoanApplicationData from '@/Models/LoanApplications/CorporateLoanApplicationData';
import LoanApplication from '@/Models/LoanApplications/LoanApplication';
import BranchOffice from '@/Models/BranchOffice';
import LegalEntityGuarantee from '@/Models/LoanApplications/LegalEntityGuarantee';
import Reference from '@/Models/LoanApplications/Reference';
import ProductDetail from '@/Models/LoanApplications/ProductDetail';
import DirectDebit from '@/Models/LoanApplications/DirectDebit';
import Interview from '@/Models/LoanApplications/Interview';
import Answer from '@/Models/LoanApplications/Answer';
import Quotation from '@/Models/Quotation';
import * as yup from 'yup';

export default {
    components: {
        PrimaryButton,
        ApplicantTypesSelect,
        FinancialFundersSelect,
        BranchOfficesSelect,
        MLPSelect,
        SelectAnswer,
        StatusSelect,
    },
    mixins: [],
    data() {
        return {
            // Prospects Models:
            prospectData: new ProspectData({}),
            prospectus: new Prospectus({}),
            generalInformation: new GeneralInformation({}),
            fiscalAddress: new FiscalAddress({}),
            legalRepresentative: new LegalRepresentative({}),
            representativeAddress: new FiscalAddress({}),
            quotation: new Quotation({product: {productType: {name: ''} } }),
            // Loan Applications Models:
            corpData: new CorporateLoanApplicationData({}),
            loanApplication: new LoanApplication({ personTypeId: 2 }),
            branchOffice: new BranchOffice({}),
            legalEntityGuarantee: new LegalEntityGuarantee({}),
            commercialLoanReferences: [
                new Reference({ referenceTypeId: 2 }),
                new Reference({ referenceTypeId: 2 }),
                new Reference({ referenceTypeId: 2 }),
            ],
            bankLoanReferences: [
                new Reference({ referenceTypeId: 3 }),
                new Reference({ referenceTypeId: 3 }),
                new Reference({ referenceTypeId: 3 }),
            ],
            productDetail: new ProductDetail({}),
            directDebit: new DirectDebit({}),
            interview: new Interview({}),
            answers: [
                new Answer({ questionId: 1, answerTypeId: 1, itemOrder: 1 }),
                new Answer({ questionId: 1, answerTypeId: 2, itemOrder: 2 }),

                new Answer({ questionId: 2, answerTypeId: 1, itemOrder: 1 }),
                new Answer({ questionId: 2, answerTypeId: 2, itemOrder: 2 }),

                new Answer({ questionId: 3, answerTypeId: 1, itemOrder: 1 }),
                new Answer({ questionId: 3, answerTypeId: 2, itemOrder: 2 }),

                new Answer({ questionId: 4, answerTypeId: 1, itemOrder: 1 }),

                new Answer({ questionId: 5, answerTypeId: 1, itemOrder: 1 }),
                new Answer({ questionId: 5, answerTypeId: 2, itemOrder: 2 }),
                new Answer({ questionId: 5, answerTypeId: 3, itemOrder: 3 }),
                new Answer({ questionId: 5, answerTypeId: 3, itemOrder: 4 }),

                new Answer({ questionId: 6, answerTypeId: 1, itemOrder: 1 }),
                new Answer({ questionId: 6, answerTypeId: 2, itemOrder: 2 }),
                new Answer({ questionId: 6, answerTypeId: 2, itemOrder: 3 }),
                new Answer({ questionId: 6, answerTypeId: 2, itemOrder: 4 }),
                new Answer({ questionId: 6, answerTypeId: 3, itemOrder: 5 }),
                new Answer({ questionId: 6, answerTypeId: 3, itemOrder: 6 }),
                new Answer({ questionId: 6, answerTypeId: 2, itemOrder: 7 }),
            ],
            selectOptions: [
                {
                    id: 1,
                    name: 'No'
                },
                {
                    id: 2,
                    name: 'Si'
                },
            ],
            selectedTypes: [
                {
                    id: 1,
                    name: 'Propios'
                },
                {
                    id: 2,
                    name: 'Terceros'
                },
            ],
            answerOptions: [
                {
                    id: 1,
                    name: 'No'
                },
                {
                    id: 2,
                    name: 'Si'
                },
            ],
            mlpOptions: [
                {
                    id: 1,
                    name: 'Alto'
                },
                {
                    id: 2,
                    name: 'Medio'
                },
                {
                    id: 3,
                    name: 'Bajo'
                },
            ],
            selectedBranchOfficeId: null,
            activeBranchOfficesOptions: [],
        }
    },
    props: {
        prospectusId: {
            type: Number,
            required: true,
        },
        quotationId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState(useCorporateProspectsStore, ['getSelectedCorporateProspectus']),
        ...mapState(useFinancialFundersStore, ['getActiveFinancialFunders']),
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
        ...mapState(useQuotationsStore, ['getQuotation']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        ...mapState(useApplicantTypesStore, ['getApplicantTypes']),
        ...mapState(useFinancialFundersStore, ['getFinancialFunders']),
        creditApplicantTypeOptions() {
            return helper.mapOptions(this.getApplicantTypes);
        },
        financialFundersOptions() {
            return helper.mapOptions(this.getActiveFinancialFunders, 'id', 'anchor');
        },
        typesOptions() {
            return helper.mapOptions(this.selectedTypes);
        },
        staticMlpOptions() {
            return helper.mapOptions(this.mlpOptions)
        },
        staticAnswerOptions() {
            return helper.mapOptions(this.answerOptions);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(3));
        },
        // NOTE: We use filters to the preloaded data before rendering the values:
        // Format Date of Incorporation:
        setSelectedDateOfIncorporation() {
            return this.$filters.formatDate(this.generalInformation.dateOfIncorporation, 'yyyy-MM-dd');
        },
        // Format Date Of Birth of Legal Representative
        setSelectedRepresentativeDateOfBirth() {
            return this.$filters.formatDate(this.legalRepresentative.dateOfBirth, 'yyyy-MM-dd');
        },
        annualRateToPercent() {
            return this.$filters.formatPercent(this.quotation.annualRate);
        },
        openingCommissionToPercent() {
            return this.$filters.formatPercent(this.quotation.openingCommission);
        },
        // Custom validators:
        minDate() {
            const disbursmentDate = new Date(this.quotation.disbursementDate);
            return yup.date().required('El campo es requerido').max(disbursmentDate, 'La fecha de la solicitud no debe ser mayor a la fecha de disposición del crédito');
        },
        payload() {
            this.corpData.loanApplication = this.loanApplication;
            this.corpData.branchOffice.id = this.selectedBranchOfficeId;
            this.corpData.legalEntityGuarantee = this.legalEntityGuarantee;
            this.corpData.commercialLoanReferences = this.commercialLoanReferences;
            this.corpData.bankLoanReferences = this.bankLoanReferences;
            this.corpData.productDetails = this.productDetail;
            this.corpData.directDebit = this.directDebit;
            this.corpData.interview = this.interview;
            this.corpData.answers = this.answers;
            return this.corpData;
        },
        // Add format to data properties
        creditAmountFormat: {
            get() {
                return this.$filters.formatNumber(this.quotation.creditAmount);
            },
            set(value) {
                this.quotation.creditAmount = value;
            }
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
        ...mapActions(useQuotationsStore, ['fetchQuotationWithProductDetailsById']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        ...mapActions(useCorporateProspectsStore, ['fetchCorpProspectusById']),
        ...mapActions(useCorpLoanApplicationStore, ['createCorpLoanApplication']),
        onSubmit() {
            this.createCorpLoanApplication(this.payload);
            this.$refs.corporate.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
        branchOfficeOptions(data) {
            return helper.mapOptions(data);
        },
        setNewAnswer(index, selectedOptionValue) {
            this.answers[index].dataValue = selectedOptionValue.toString();
        },
    },
    watch: {
        'getActiveBranchOffices': {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.activeBranchOfficesOptions = this.branchOfficeOptions(newValue);
                }
            }
        },
        'getSelectedCorporateProspectus': {
            immediate: true,
            handler(newValue) {
                const validateProperties = ["generalInformation", "fiscalAddress", "legalRepresentative", "representativeAddress"];
                if (helper.validateObjectProperties(newValue, validateProperties)) {
                    this.prospectus = newValue;
                    this.generalInformation = newValue.generalInformation;
                    this.fiscalAddress = newValue.fiscalAddress;
                    this.legalRepresentative = newValue.legalRepresentative;
                    this.representativeAddress = newValue.representativeAddress;
                    this.selectedBranchOfficeId = newValue.branchOfficeId;
                    this.loanApplication.prospectusId = this.prospectusId;
                    this.loanApplication.quotationId = this.quotation.id;
                }
            },
        },
        getQuotation: {
            immediate: true,
            handler(newValue) {
                if (newValue.id !== 0) {
                    this.quotation = newValue;
                    this.loanApplication.quotationId = newValue.id;
                }
            }
        },
    },
    created() {
        this.fetchCorpProspectusById(this.prospectusId);
        this.fetchQuotationWithProductDetailsById(this.quotationId);
    },
    mounted() {
    },
}
</script>