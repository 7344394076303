
export default class Guarantee {
  constructor({
    id = 0, 
    companyId = 0, 
    statusId = 0, 
    name = null, 
    itemOrder = 0,
    createdAt = new Date(), 
    updatedAt = new Date()
} = {}) {
    this.id = id;
    this.companyId = companyId;
    this.statusId = statusId;
    this.name = name;
    this.itemOrder = itemOrder;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
