import { useAuthStore } from '@/pinia/auth.module';

export default class FinancialFunder {
    constructor({
        id = 0,
        statusId = null,
        referenceNumber = null,
        anchor = null,
        observations = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date(),
        userAuth = useAuthStore(),
    } = {}) {
        this.id = id;
        this.companyId = userAuth.user.companyId;
        this.statusId = statusId;
        this.referenceNumber = referenceNumber;
        this.anchor = anchor;
        this.observations = observations;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}
