export default class Role {
    constructor({
        id = 0,
        name = null,
        createdAt = new Date(),
        updatedAt = new Date()
    } ={}) {
        this.id = id;
        this.name = name;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}