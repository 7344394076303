<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Asignación de permisos</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Asignación de permisos</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex">
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12 mb-4" style="margin:auto;">

                                <Form @submit="onSubmit">
                                    <div class="form-group col-sm-3">
                                        <label for="id">Rol:</label>
                                        <RoleSelect v-model="role.id" :name="'id'" :options="roleOptions"
                                            @update="setNewRole"></RoleSelect>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group col-sm-3">
                                                <label for="menuGroups">Secciones permitidas:</label>
                                                <div v-for="(item, idx) in getMenuGroupsByRoleId" :key="idx"
                                                    class="form-check">
                                                    <input type="checkbox" class="form-check-input" :value="item.id"
                                                        checked="checked" @click.prevent="true">
                                                    <label class="form-check-label">{{ item.name }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>

                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import Role from '@/Models/Role';
import * as helper from '@/helpers';
import { mapState, mapActions, } from 'pinia';
import { useRolesStore } from '@/pinia/roles.module';
import { useMenuStore } from '@/pinia/menus.module';
import RoleSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import '@/Components/Validators/index';

export default {
    components: {
        RoleSelect,
        PrimaryButton,
    },
    mixins: [],
    data() {
        return {
            role: new Role(),
        }
    },
    watch: {},
    computed: {
        ...mapState(useRolesStore, ['getRoles', 'getMenuGroupsByRoleId']),
        ...mapState(useMenuStore, ['getMenuGroups', 'getMenuItems']),
        roleOptions() {
            return helper.mapOptions(this.getRoles);
        },
    },
    methods: {
        ...mapActions(useRolesStore, ['fetchRoles', 'fetchMenuGroupByRoleId']),
        ...mapActions(useMenuStore, ['fetchMenuGroups', 'fetchMenuItems']),
        setNewRole(roleId) {
            if (roleId != null) {
                this.fetchMenuGroupByRoleId(roleId);
            }
        },
    },
    created() {
        this.fetchRoles();
    },
    mounted() { }
};

</script>