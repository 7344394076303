
/**
 * Checks if the current object is undefined, null or has no properties
 * @date 3/5/2024 - 4:34:46 PM
 *
 * @param {Object} obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
    if (typeof obj === "undefined" || obj === null || Object.keys(obj).length === 0) return true;
    return false;
}

/**
 * Checks if the object has the specified properties
 * @date 5/11/2024 - 2:39:54 AM
 *
 * @export
 * @param {*} object
 * @param {*} properties
 * @returns {boolean}
 */
export function validateObjectProperties(object, properties) {
    for (let property of properties) {
      if (!(property in object) || object[property] === null) {
        return false;
      }
    }
    return true;
  }

// export default isEmptyObject;