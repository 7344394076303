import { defineStore } from 'pinia';
import axios from 'axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

export const useLoadingStore = defineStore('loading', {
    persist: false,
    state: () => {
        return {
            loading: false,
        }
    },
    getters: {
        // isAuthenticated: (state) => state.isAuthenticated,
        // message: (state) => state.message,
        getLoading: state => state.loading,
    },
    actions: {
        setLoading(loading) {
            this.loading = loading;
        }
    },
});