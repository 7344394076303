export default class Reference {
  constructor({
    id = 0, 
    loanApplicationId = 0, 
    referenceTypeId = null, 
    fullName = null, 
    address = null, 
    phoneNumber = null, 
    comments = null, 
    verificationDate = null, 
    workersName = null, 
    position = null, 
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.referenceTypeId = referenceTypeId || null;
    this.fullName = fullName || null;
    this.address = address || null;
    this.phoneNumber = phoneNumber || null;
    this.comments = comments || null;
    this.verificationDate = verificationDate || null;
    this.workersName = workersName || null;
    this.position = position || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}