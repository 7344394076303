export default class ScoreQuestion {
    constructor({
        id = 0,
        personTypeId = 0,
        questionGroup = null,
        questionText = null,
        itemOrder = null,
        answers = [],
        createdAt = new Date(),
        updatedAt = new Date()
    }) {
        this.id = id || 0;
        this.personTypeId = personTypeId || 0;
        this.questionGroup = questionGroup || null;
        this.questionText = questionText || null;
        this.itemOrder = itemOrder || null;
        this.answers = answers || [],
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}