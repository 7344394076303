<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('buttons', 'updating') }} Producto
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="record.branchOfficeId" :name="'branchOfficeId'" :options="branchOfficeOptions" @update="setBranchOfficeState" :customValidation="productUniqueness"></BranchOfficesSelect>                
                </div>
                <div class="form-group">
                    <label for="productTypeId">Tipo de producto:</label>
                    <ProductTypesSelect v-model="record.productTypeId" :name="'productTypeId'" :options="productTypeOptions" @update="setProductTypeState"></ProductTypesSelect>                
                </div>
                <div class="form-group">
                    <label for="rateTypeId">Tipo de tasa:</label>
                    <RateTypesSelect v-model="record.rateTypeId" :name="'rateTypeId'" :options="rateTypeOptions" @update="setRateTypeState"></RateTypesSelect>                
                </div>
                <div class="form-group">
                    <label for="referenceRateId">Tasa de referencia:</label>
                    <ReferenceRatesSelect v-model="record.referenceRateId" :name="'referenceRateId'" :options="referenceRateOptions" @update="setReferenceRateState"></ReferenceRatesSelect>                
                </div>
                <div class="form-group">
                    <label for="penaltyChargeRateId">Tipo de tasa moratorios:</label>
                    <PenaltyChargeRatesSelect v-model="record.penaltyChargeRateId" :name="'penaltyChargeRateId'" :options="penaltyChargeRateOptions" @update="setPenaltyChargeRateState"></PenaltyChargeRatesSelect>                
                </div>
                <div class="form-group">
                    <label for="penaltyChargeId">Moratorios:</label>
                    <PenaltyChargesSelect v-model="record.penaltyChargeId" :name="'penaltyChargeId'" :options="penaltyChargeOptions" @update="setPenaltyChargeState"></PenaltyChargesSelect>                
                </div>
                <div class="form-group">
                    <label for="guaranteeId">Tipo de garantía:</label>
                    <GuaranteesSelect v-model="record.guaranteeId" :name="'guaranteeId'" :options="guaranteeOptions" @update="setGuaranteeState"></GuaranteesSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="numberOfTerms">Plazo máximo:</label>
                        <Field v-model="selectedRecord.numberOfTerms" type="number" class="form-control" name="numberOfTerms" placeholder="Plazo máximo" :rules="isProductNumberOfTermsValid" :disabled="isFirstProduct"/>
                        <ErrorMessage name="numberOfTerms" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="loanTermId">Tipo de plazo:</label>
                    <LoanTermsSelect v-model="record.loanTermId" :name="'loanTermId'" :options="loanTermOptions" @update="setLoanTermState"></LoanTermsSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="maxAmount">Monto máximo:</label>
                        <Field v-model="selectedRecord.maxAmount" type="number" class="form-control" name="maxAmount" placeholder="Monto máximo"  :rules="isProductMaxAmountValid" :disabled="isFirstProduct"/>
                        <ErrorMessage name="maxAmount" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="taxId">Impuesto:</label>
                    <TaxesSelect v-model="record.taxId" :name="'taxId'" :options="taxOptions" @update="setTaxState"></TaxesSelect>                
                </div>
                <div class="form-group">
                    <label for="currencyTypeId">Moneda:</label>
                    <CurrencyTypesSelect v-model="record.currencyTypeId" :name="'currencyTypeId'" :options="currencyOptions" @update="setCurrencyTypeState"></CurrencyTypesSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="description">Descripción:</label>
                        <Field as="textarea" v-model="selectedRecord.description" type="text" class="form-control"
                            name="description" placeholder="Descripción" rules="required" />
                        <ErrorMessage name="description" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="record.statusId" :name="'statusId'" :options="statusOptions" @update="setStatusState"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import * as yup from 'yup';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useProductsStore } from '@/pinia/products.module';
import { useTaxesStore } from '@/pinia/taxes.module';
import { useCurrencyStore } from '@/pinia/currency.module';
import { useStatusStore } from '@/pinia/status.module';

import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import ProductTypesSelect from '@/Components/Selects/SelectField.vue';
import RateTypesSelect from '@/Components/Selects/SelectField.vue';
import ReferenceRatesSelect from '@/Components/Selects/SelectField.vue';
import PenaltyChargeRatesSelect from '@/Components/Selects/SelectField.vue';
import PenaltyChargesSelect from '@/Components/Selects/SelectField.vue';
import GuaranteesSelect from '@/Components/Selects/SelectField.vue';
import LoanTermsSelect from '@/Components/Selects/SelectField.vue';
import TaxesSelect from '@/Components/Selects/SelectField.vue';
import CurrencyTypesSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

import Product from '@/Models//Product';

export default {
    components: {
        BranchOfficesSelect,
        ProductTypesSelect,
        RateTypesSelect,
        ReferenceRatesSelect,
        PenaltyChargeRatesSelect,
        PenaltyChargesSelect,
        GuaranteesSelect,
        LoanTermsSelect,
        CurrencyTypesSelect,
        TaxesSelect,
        StatusSelect,
        PrimaryButton,
    },
    data() {
        return {
            product: new Product(),
            firstProduct: {},
        }
    },
    emits: ['close-modal', 'change-modal-content'],
    props: {
        selectedRecord: {
            type: Object,
            default: () => new Product()
        }
    },
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedActiveBranchOffice']),
        ...mapState(useProductsStore, [
            'getSelectedProduct',
            'getActiveProductTypes',
            'getSelectedActiveProductType',

            'getActiveRateTypes',
            'getSelectedActiveRateType',

            'getActiveReferenceRates',
            'getActiveReferenceRateById',
            'getSelectedActiveReferenceRate',

            'getActivePenaltyChargeRates',
            'getSelectedActivePenaltyChargeRate',

            'getActivePenaltyCharges',
            'getActivePenaltyChargeById',
            'getSelectedActivePenaltyCharge',

            'getActiveGuarantees',
            'getActiveGuaranteeById',
            'getSelectedActiveGuarantee',

            'getActiveLoanTerms',
            'getSelectedActiveLoanTerm',
        ]),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        ...mapState(useTaxesStore, ['getActiveTaxes', 'getSelectedActiveTax']),
        ...mapState(useCurrencyStore, ['getActiveCurrencies', 'getSelectedActiveCurrency']),
        record() {
            this.product = this.selectedRecord;
            return this.product;
        },
        branchOfficeOptions() {
            return helper.mapOptions(this.getActiveBranchOffices);
        },
        productTypeOptions() {
            return helper.mapOptions(this.getActiveProductTypes);
        },
        rateTypeOptions() {
            return helper.mapOptions(this.getActiveRateTypes);
        },
        referenceRateOptions() {
            return helper.mapOptions(this.getActiveReferenceRates);
        },
        penaltyChargeRateOptions() {
            return helper.mapOptions(this.getActivePenaltyChargeRates);
        },
        penaltyChargeOptions() {
            return helper.mapOptions(this.getActivePenaltyCharges);
        },
        guaranteeOptions() {
            return helper.mapOptions(this.getActiveGuarantees);
        },
        loanTermOptions() {
            return helper.mapOptions(this.getActiveLoanTerms);
        },
        taxOptions() {
            return helper.mapOptions(this.getActiveTaxes);
        },
        currencyOptions() {
            return helper.mapOptions(this.getActiveCurrencies, 'id', 'code');
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
        selectedActiveReferenceRate() {
            if (typeof this.getSelectedActiveReferenceRate !== "undefined") return this.getSelectedActiveReferenceRate.id;
            if (typeof this.selectedRecord !== "undefined") return this.selectedRecord.referenceRateId;
        },
        selectedActivePenaltyCharge() {
            if (typeof this.getSelectedActivePenaltyCharge !== "undefined") return this.getSelectedActivePenaltyCharge.id;
            if (typeof this.selectedRecord !== "undefined") return this.selectedRecord.penaltyChargeId;
        },
        selectedActiveTax() {
            if (typeof this.getSelectedActiveTax !== "undefined") return this.getSelectedActiveTax.id;
            if (typeof this.selectedRecord.taxId !== "undefined") return this.selectedRecord.taxId;
        },
        isFirstProduct() {
            return this.selectedRecord.id === this.getFirstCreatedProductByProductType(this.product.productTypeId).id;
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedActiveBranchOffice']),
        ...mapActions(useProductsStore, [
            'getFirstCreatedProductByProductType',
            'updateProduct',
            'productAlreadyExistsOnEdit',

            'setSelectedActiveProductType',
            'isProductTypeUniqueOnEdit',

            'fetchActiveReferenceRates',
            'setSelectedActiveRateType',

            'setSelectedActiveReferenceRate',

            'setSelectedActivePenaltyChargeRate',
            'fetchActivePenaltyCharges',

            'setSelectedActivePenaltyCharge',

            'setSelectedActiveGuarantee',

            'setSelectedActiveLoanTerm',
        ]),
        ...mapActions(useTaxesStore, ['fetchActiveTaxes', 'setSelectedActiveTax']),
        ...mapActions(useCurrencyStore, ['setSelectedActiveCurrency']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        onSubmit() {
            // Avoid to the user to submit new values for the maxAmount and numberOfTerms if the product is the first one created
            if (this.isFirstProduct) {
                this.product.maxAmount = this.firstProduct.maxAmount;
                this.product.numberOfTerms = this.firstProduct.numberOfTerms;
            }
            this.updateProduct(this.product);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        setBranchOfficeState(id) {
            this.setSelectedActiveBranchOffice(id);
            if (id !== "") {
                this.fetchActiveTaxes(id);
            }
        },
        setProductTypeState(id) {
            this.setSelectedActiveProductType(id);
        },
        setRateTypeState(id) {
            this.fetchActiveReferenceRates(id);
            this.setSelectedActiveRateType(id);
        },
        setReferenceRateState(id) {
            this.setSelectedActiveReferenceRate(id);
            this.addNewReferenceRate(id);
        },
        setPenaltyChargeRateState(id) {
            this.setSelectedActivePenaltyChargeRate(id);
            this.fetchActivePenaltyCharges(id);
        },
        setPenaltyChargeState(id) {
            this.setSelectedActivePenaltyCharge(id);
            this.addNewPenaltyCharge(id);
        },
        setGuaranteeState(id) {
            this.setSelectedActiveGuarantee(id);
            this.addNewGuaranteeType(id);
        },
        setLoanTermState(id) {
            this.setSelectedActiveLoanTerm(id);
        },
        setTaxState(id) {
            this.setSelectedActiveTax(id);
        },
        setCurrencyTypeState(id) {
            this.setSelectedActiveCurrency(id);
        },
        setStatusState(id) {
            this.setSelectedStatus(id);
        },
        addNewReferenceRate(referenceRateId) {
            if (referenceRateId == null && referenceRateId == undefined) return;
            if (this.getActiveReferenceRateById(referenceRateId).name == 'Otros especificar') {
                this.$emit('change-modal-content', 3);
            }
        },
        addNewPenaltyCharge(penaltyChargeId) {
            if (penaltyChargeId == null && penaltyChargeId == undefined) return;
            if (this.getActivePenaltyChargeById(penaltyChargeId).name == 'Otro especificar') {
                this.$emit('change-modal-content', 4);
            };
        },
        addNewGuaranteeType(guaranteeId) {
            if (guaranteeId == undefined || guaranteeId == null) return;
            if (this.getActiveGuaranteeById(guaranteeId).name == 'Otro especificar') {
                this.$emit('change-modal-content', 5);
            }
        },
        // Custom validations:
        productUniqueness(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            if (this.productAlreadyExistsOnEdit(this.product)) {
                return 'Ya existe un Producto con este parámetro seleccionado';
            } else {
                return true;
            }

        },
        productTypeUniqueness(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Branch Office is selected
            if (this.product.branchOfficeId === 0 || this.product.branchOfficeId == "") {
                return 'Seleccione una matriz o sucursal';
            }

            // If no Product Type is selected
            if (this.product.rateTypeId === 0 || this.product.rateTypeId != "") {
                return true;
            } else {
                // If is not unique
                return this.isProductTypeUniqueOnEdit(this.product.id, this.product.branchOfficeId, value, this.product.rateTypeId).then(status => {
                    if (status === false) {
                        return 'Ya existe un Producto con el Tipo de Producto y el Tipo de Tasa seleccionado';
                    }
                    // If all is good
                    return true;
                });
            }

        },
        rateTypeUniqueness(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Branch Office is selected
            if (this.product.branchOfficeId == 0 || this.product.branchOfficeId == "") {
                return 'Seleccione una matriz o sucursal';
            }

            // If no Product Type is selected
            if (this.product.productTypeId == 0 || this.product.productTypeId == "") {
                return 'Seleccione un tipo de producto';
            }

            // If is not unique
            return this.isProductTypeUniqueOnEdit(this.product.id, this.product.branchOfficeId, this.product.productTypeId, value).then(status => {
                if (status === false) {
                    return 'Ya existe un Producto con el Tipo de Producto y el Tipo de Tasa seleccionado';
                }
                // If all is good
                return true;
            });

        },
        isProductMaxAmountValid(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Product Type is selected
            if (this.product.productTypeId == 0 || this.product.productTypeId == "") {
                return 'Seleccione un tipo de producto';
            }

            const product = this.getFirstCreatedProductByProductType(this.product.productTypeId);
            if (product.maxAmount < value) {
                return `El monto máximo no puede exceder el monto máximo del primer producto creado de tipo ${product.productType.name}: ${this.$filters.formatNumber(product.maxAmount)}`;
            } else {
                return true;
            }

        },
        isProductNumberOfTermsValid(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Product Type is selected
            if (this.product.productTypeId == 0 || this.product.productTypeId == "") {
                return 'Seleccione un tipo de producto';
            }

            const product = this.getFirstCreatedProductByProductType(this.product.productTypeId);
            if (product.numberOfTerms < value) {
                return `El plazo máximo no puede exceder el plazo máximo del primer producto creado de tipo ${product.productType.name}: ${product.numberOfTerms}`;
            } else {
                return true;
            }
        },
    },
    watch: {
        selectedRecord: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.product = newItem;
                    this.fetchActiveReferenceRates(newItem.rateTypeId);
                    this.fetchActivePenaltyCharges(newItem.penaltyChargeRateId);
                    this.fetchActiveTaxes(newItem.branchOfficeId);
                    this.setSelectedActiveBranchOffice(newItem.branchOfficeId);
                    this.setSelectedActiveProductType(newItem.productTypeId);
                    this.setSelectedActiveRateType(newItem.rateTypeId);
                    this.setSelectedActiveReferenceRate(newItem.referenceRateId);
                    this.setSelectedActivePenaltyChargeRate(newItem.penaltyChargeRateId);
                    this.setSelectedActivePenaltyCharge(newItem.penaltyChargeId);
                    this.setSelectedActiveGuarantee(newItem.guaranteeId);
                    this.setSelectedActiveLoanTerm(newItem.loanTermId);
                    this.setSelectedActiveTax(newItem.taxId);
                    this.setSelectedActiveCurrency(newItem.currencyTypeId);
                    this.setSelectedStatus(newItem.statusId);
                }
            },
        },
        getSelectedActiveReferenceRate: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.product.referenceRateId = newItem.id;
                }
            },
        },
        getSelectedActivePenaltyCharge: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.product.penaltyChargeId = newItem.id;
                }
            },
        },
        getSelectedActiveGuarantee: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.product.guaranteeId = newItem.id;
                }
            },
        },
    },
    created() {
        this.firstProduct = JSON.parse(JSON.stringify(this.getFirstCreatedProductByProductType(this.product.productTypeId)));
        this.product.branchOfficeId = this.getSelectedActiveBranchOffice.id;
        this.product.productTypeId = this.getSelectedActiveProductType.id;
        this.product.rateTypeId = this.getSelectedActiveRateType.id;

        if (!helper.isEmptyObject(this.getSelectedActiveReferenceRate) && this.getSelectedActiveReferenceRate.id !== 0) {
            // If the reference rate state is not empty and is different from 0, then set the selected reference rate
            this.product.referenceRateId = this.getSelectedActiveReferenceRate.id;
        } else {
            // At the beginning, we must fetch the active reference rates, this sets the state automatically
            this.fetchActiveReferenceRates(this.product.rateTypeId);
            // then set the selected reference rate
            this.setSelectedActiveReferenceRate(this.product.referenceRateId);
        }

        this.product.penaltyChargeRateId = this.getSelectedActivePenaltyChargeRate.id;

        if (!helper.isEmptyObject(this.getSelectedActivePenaltyCharge) && this.getSelectedActivePenaltyCharge.id !== 0) {
            // If the penalty charge state is not empty and is different from 0, then set the selected penalty charge
            this.product.penaltyChargeId = this.getSelectedActivePenaltyCharge.id;
        } else {
            // At the beginning, we must fetch the active penalty charges, this sets the state automatically 
            this.fetchActivePenaltyCharges(this.product.penaltyChargeRateId);
            // hen set the selected penalty charge
            this.setSelectedActivePenaltyCharge(this.product.penaltyChargeId);
        }

        this.product.guaranteeId = this.getSelectedActiveGuarantee.id;
        this.product.loanTermId = this.getSelectedActiveLoanTerm.id;

        if (!helper.isEmptyObject(this.getSelectedActiveTax) && this.getSelectedActiveTax.id !== 0) {
            // If the tax state is not empty and is different from 0, then set the selected tax
            this.product.taxId = this.getSelectedActiveTax.id;
        } else {
            // At the beginning, we must fetch the active taxes, this sets the state automatically
            this.fetchActiveTaxes(this.product.branchOfficeId);
            // then set the selected tax
            this.setSelectedActiveTax(this.product.taxId);
        }

        this.product.currencyTypeId = this.getSelectedActiveCurrency.id;
        this.product.statusId = this.getSelectedStatus.id;
    }
}
</script>