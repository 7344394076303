import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { getLocaleMessage } from '@/services/MessagesService';

import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useAuthStore } from '@/pinia/auth.module';

import FinancialRecord from '@/Models/FinancialRecord';
import RecordProspectus from '@/Models/RecordsProspects';
import DocumentFile from '@/Models/DocumentFile';

import * as service from '@/services';

const $toast = useToast();

export const financialRecordsStore = defineStore('financialRecords', {
    persist: false,
    state: () => {
        return {
            financialRecords: reactive([]),
            financialRecord: new FinancialRecord(),
            activeFinancialRecords: reactive([]),
            activeFinancialRecord: new FinancialRecord(),

            documentRecords: reactive([]),
            documentRecordsByRecordId: reactive([]),
            recordsProspects: reactive([]),
            recordProspectus: new RecordProspectus({}),

            documentFiles: [],
            errors: reactive([]),
        }
    },
    getters: {
        getSelectedFinancialRecord: state => state.financialRecord,

        getFinancialRecords: state => state.financialRecords,
        getFinancialRecord: state => state.financialRecord,
        getFinancialRecordById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.financialRecords.find(fr => fr.id === id);
        },
        getActiveFinancialRecords: state => state.activeFinancialRecords,
        getActiveFinancialRecord: state => state.activeFinancialRecord,
        getActiveFinancialRecordById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.activeFinancialRecords.find(fr => fr.id === id);
        },
        getActiveDocuments: state => state.documentRecords,
        getRecordsProspects: state => state.recordsProspects,
        getRecordProspectus: state => {
            if (typeof state.recordProspectus === 'undefined' || state.recordProspectus.id === 0) return new RecordProspectus({});
            return state.recordProspectus;
        },
        filterRecordsBySearchString: state => (searchString = '') => {
            if (!searchString) return state.financialRecords;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.financialRecords.filter(financialRecord => {

                const referenceNumber = financialRecord.referenceNumber ? financialRecord.referenceNumber.toLowerCase() : '';
                const email = financialRecord.user?.email ? financialRecord.user?.email.toLowerCase() : '';
                const recordType = financialRecord.recordType?.name ? financialRecord.recordType?.name.toLowerCase() : '';
                const name = financialRecord.name ? financialRecord.name.toLowerCase() : '';

                return referenceNumber.includes(lowerCaseSearchString) ||
                    email.includes(lowerCaseSearchString) ||
                    recordType.includes(lowerCaseSearchString) ||
                    name.includes(lowerCaseSearchString);
            });
        },
        getAllDocumentFiles: state => state.documentFiles,
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        setSelectedFinancialRecord(id) {
            this.financialRecord = this.getFinancialRecordById(id);
        },
        setSelectedActiveFinancialRecord(id) {
            this.activeFinancialRecord = this.getActiveFinancialRecordById(id);
        },
        async fetchFinancialRecords() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get("/FinancialRecords");
                if (data && data.status) {
                    const financiaRecords = data.data.map(fr => new FinancialRecord(fr));
                    this.financialRecords = financiaRecords;
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveFinancialRecordsByCompany(companyId = 1) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/FinancialRecords/company/${companyId}/active`);
                if (data && data.status) {
                    const financiaRecords = data.data.map(fr => new FinancialRecord(fr));
                    this.activeFinancialRecords = financiaRecords;
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchFinancialRecordById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/FinancialRecords/${id}`);
                if (data && data.status) {
                    useLoadingStore().loading = false;
                    this.financialRecord = data.financialRecords;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los documentos.");
                this.setErrors(error);
            }
        },
        async fetchActiveFinancialRecordsByCompanyAndRecordType(recordTypeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/FinancialRecords/activeByCompanyAndRecordType?CompanyId=${useAuthStore().user.companyId}&RecordTypeId=${recordTypeId}`);
                if (data && data.status) {
                    this.activeFinancialRecords = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveFinancialRecordsWithParams(branchOfficeId, recordTypeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/FinancialRecords/active?BranchOfficeId=${branchOfficeId}&RecordTypeId=${recordTypeId}`);
                if (data && data.status) {
                    this.financialRecords = data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los documentos.");
                this.setErrors(error);
            }
        },
        async createFinancialRecord(financialRecords) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.post(`/FinancialRecords`, financialRecords);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('financialRecords', 'creatingSuccess'));
                    const financialRecord = new FinancialRecord(data.data);
                    this.financialRecords.unshift(financialRecord);
                } else {
                    $toast.success(getLocaleMessage('financialRecords', 'creatingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateFinancialRecord(financialRecords) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.put(`/FinancialRecords`, financialRecords);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('financialRecords', 'editingSuccess'));
                    const updatedRecord = new FinancialRecord(data.data);
                    const index = this.financialRecords.findIndex(c => c.id === updatedRecord.id);
                    if (index !== -1) {
                        this.financialRecords.splice(index, 1, updatedRecord);
                        // this.financialRecords.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                } else {
                    $toast.success(getLocaleMessage('financialRecords', 'editingError'));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async deleteFinancialRecord(id) {
            try {
                const { data } = await service.documentationApi.delete(`/FinancialRecords/${id}`);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('financialRecords', 'deletingSuccess'));
                    const index = this.financialRecords.findIndex(c => c.id === id);
                    if (index !== -1) {
                        this.financialRecords.splice(index, 1);
                    }
                    return data.data;
                } else {
                    $toast.error(getLocaleMessage('financialRecords', 'deletingError'));
                }
            } catch (error) {
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            }
        },
        async createRecordProspectus(formData) {
            try {
                useLoadingStore().loading = true;
                // const recordProspectus = recordFileForm.recordProspectus;
                // const files = recordFileForm.files;
                const { data } = await service.documentationApi.post(`RecordsProspects/recordProspectus`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (data && data.data) {
                    $toast.success("Documentación creada correctamente.");
                    this.recordsProspects.unshift(data.data);
                    // for each file, upload it
                    // for (let i = 0; i < files.length; i++) {
                    //     const file = files[i];
                    //     await this.uploadFile(file);
                    // }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al crear la documentación.");
            }
        },
        async updateRecordProspectus(formData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.put(`RecordsProspects/recordProspectus`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (data && data.data) {
                    $toast.success("Documentación actualizada correctamente.");
                    const index = this.recordsProspects.findIndex(c => c.id === data.data.id);
                    if (index !== -1) {
                        this.recordsProspects.splice(index, 1, data.data);
                        this.recordsProspects.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al actualizada la documentación.");
            }
        },
        async fetchRecordsProspects() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get("/RecordsProspects");
                if (data && data.data) {
                    this.recordsProspects = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los documentos.");
                this.setErrors(error);
            }
        },
        async fetchRecordProspectusById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/RecordsProspects/${id}`);
                if (data && data.data) {
                    this.recordProspectus = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los documentos.");
                this.setErrors(error);
            }
        },
        async fetchAllDocumentFiles() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get("/api/DocumentsFiles/allDocumentFiles");
                if (data && data.data) {
                    this.documentFiles = data.data; // Returns raw data
                    useLoadingStore().loading = false;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los datos.");
                this.setErrors(error);
            }
        },
        async createDocumentFile(formData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.post(`/api/DocumentsFiles`, formData);
                if (data && data.data) {
                    $toast.success("Archivos subidos correctamente.");
                    // From DocumentFile to RawData
                    const rawData = DocumentFile.toRawData(data.data);
                    this.documentFiles.unshift(rawData);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar el archivo.");
            }
        },
        async updateDocumentFile(formData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.put(`/api/DocumentsFiles`, formData);
                if (data && data.data) {
                    $toast.success("Archivos subidos correctamente.");
                    // From DocumentFile to RawData
                    const rawData = DocumentFile.toRawData(data.data);
                    const index = this.documentFiles.findIndex(df => df.loanId === rawData.loanId && df.recordId === rawData.recordId && df.documentId === rawData.documentId);
                    if (index !== -1) {
                        this.documentFiles.splice(index, 1, rawData);
                    } 
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar el archivo.");
            }
        },
        async hasRecordType(loanApplicationId, recordTypeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.documentationApi.get(`/api/LoanApplicationFinancialRecords/has-record-type?loanApplicationId=${loanApplicationId}&recordTypeId=${recordTypeId}`);
                useLoadingStore().loading = false;
                return data.data || null;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al hacer la petición.");
            }
        },
    },
});