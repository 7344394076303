<template>
    <div class="modal-header">
        <h4 class="modal-title">
            Seleccione una cotización
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card-body">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Folio de Cotizaciones</th>
                        <th>Fecha de disposición del crédito</th>
                        <th>Monto del préstamo</th>
                        <th>Plazo total</th>
                        <th>Período de gracia</th>
                        <!-- <th>Plazo</th> -->
                        <th>Tasa Anual sin IVA %</th>
                        <!-- <th>Tasa Anual con IVA %</th>
                        <th>Tasa {{ period }} sin IVA %</th>
                        <th>Tasa {{ period }} con IVA %</th>
                        <th>Pago {{ period }}</th> -->
                        <th>Comisión por apertura %</th>
                        <th>CAT sin IVA %</th>
                        <th>CAT con IVA %</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in getDataPage()" :key="item.id" v-if="list.length != 0" @click="selectedQuotation(item)">
                        <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                        <td>{{ $filters.formatDate(item.disbursementDate) }}</td>
                        <td>{{ $filters.formatNumber(item.creditAmount) }}</td>
                        <td>{{ item.totalTerm }}</td>
                        <td>{{ item.gracePeriod }}</td>
                        <td>{{ $filters.formatPercent(item.annualRate) }}</td>
                        <td>{{ $filters.formatPercent(item.openingCommission) }}</td>
                        <td>{{ $filters.formatPercent(item.apr) }}</td>
                        <td>{{ $filters.formatPercent(item.aprWithVat) }}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="9" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    components: { },
    data() {
        return {
            period: 'periodo',
        }
    },
    emits: ['change-modal-content','selected-quotation'],
    props: {
        list: {
            type: Array,
            default: () => [],
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        
    },
    methods: {
        getDataPage() {
            if (this.list === undefined) {
                return 0;
            }
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        selectedQuotation(quotation) {
            this.$emit('change-modal-content', 4);
            this.$emit('selected-quotation', quotation);
        }
    },
    created() {
        this.getDataPage();
    }
}
</script>