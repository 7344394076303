<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('buttons', 'updating') }} día feriado: <strong>{{ getSelectedHoliday.name
                    }}</strong>
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="holiday.branchOfficeId" :name="'branchOfficeId'"
                        :options="branchOfficeOptions"></BranchOfficesSelect>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="name">Nombre:</label>
                        <Field v-model="holiday.name" type="text" class="form-control" name="name" placeholder="Nombre"
                            rules="required" />
                        <ErrorMessage name="name" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="date">Día:</label>
                        <Field v-model="holiday.date" type="date" class="form-control" name="date" :rules="minDate" />
                        <ErrorMessage name="date" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="remarks">Observaciones:</label>
                        <Field as="textarea" v-model="selectedRecord.remarks" type="text" class="form-control"
                            name="remarks" placeholder="Observaciones" />
                        <ErrorMessage name="remarks" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="holiday.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons',
                'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useHolidaysStore } from '@/pinia/holidays.module';

import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import * as yup from 'yup';
import Holiday from '@/Models/Holiday';

export default {
    components: {
        BranchOfficesSelect,
        StatusSelect,
        PrimaryButton,
    },
    mixins: [],
    data: () => ({
        holiday: new Holiday(),
        selectedDateFormat: null,
    }),
    emits: ['close-modal'],
    props: {
        selectedRecord: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        ...mapState(useHolidaysStore, ['getSelectedHoliday']),
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        branchOfficeOptions() {
            return helper.mapOptions(this.getActiveBranchOffices);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(5));
        },
        minDate() {
            let date = new Date();
            let minDate = date.setDate(date.getDate() - 1);
            return yup.date().required('El campo es requerido').min(new Date(minDate), 'La fecha seleccionada no puede ser menor a la fecha actual');
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        ...mapActions(useHolidaysStore, ['setSelectedHoliday', 'updateHoliday']),
        onSubmit() {
            this.updateHoliday(this.holiday);
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        formatDate(date) {
            return this.$filters.formatDate(date, 'yyyy-MM-dd');
        },
        setBranchOfficeState(id) {
            this.selectedRecord.branchOfficeId = id;
            this.setSelectedBranchOffice(id);
        },
        setStatusState(id) {
            this.selectedRecord.statusId = id;
            this.setSelectedStatus(id);
        },
    },
    watch: {
        selectedRecord: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.holiday = {
                        ...newValue,
                        date: this.formatDate(newValue.date)
                    };
                }
            }
        }
    },
    created() {
        this.setSelectedHoliday(this.selectedRecord.id);
    }
}
</script>