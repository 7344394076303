
export default class Banks {
  constructor({
    id = 0, 
    referenceNumber = null,
    name = null, 
    headquartersLocation = null, 
    foundedDate = null, 
    website = null, 
    contactNumber = null,
    createdAt = new Date(),
    updatedAt = new Date(),
  }) {
    this.id = id || 0;
    this.referenceNumber = referenceNumber || null;
    this.name = name || null;
    this.headquartersLocation = headquartersLocation || null;
    this.foundedDate = foundedDate || null;
    this.website = website || null;
    this.contactNumber = contactNumber || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
