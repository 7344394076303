export default class MonthlyExpense {
  constructor({
    id = 0, 
    loanApplicationId = 0, 
    housing = 0, 
    maintenance = 0, 
    tuition = 0, 
    entertainment = 0, 
    other = 0,
    total = 0,
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.housing = housing || 0;
    this.maintenance = maintenance || 0;
    this.tuition = tuition || 0;
    this.entertainment = entertainment || 0;
    this.other = other || 0;
    this.total = total || 0;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
