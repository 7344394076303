<template>
    <div class="modal-header">
        <h4 class="modal-title">
            Seleccione un producto
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card-body">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Folio de Productos</th>
                        <th>Matriz y/o Sucursales</th>
                        <th>Descripción</th>
                        <th>Tipo de producto</th>
                        <th>Tipo de Tasa</th>
                        <th>Tasa de Referencia</th>
                        <th>Tipo de Tasa Moratorio</th>
                        <th>Moratorio</th>
                        <th>Garantía</th>
                        <th>Tipo de Plazo</th>
                        <th>Plazo máximo</th>
                        <th>Monto máximo</th>
                        <th>Moneda</th>
                        <th>Impuesto</th>
                        <th>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in getDataPage()" :key="item.id" v-if="getDataPage().length != 0" @click="selectedProduct(item)">
                        <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                        <td>{{ item.branchOffice.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.productType.name }}</td>
                        <td>{{ item.rateType.name }}</td>
                        <td>{{ item.referenceRate.name }}</td>
                        <td>{{ item.penaltyChargeRate.name }}</td>
                        <td>{{ item.penaltyCharge.name }}</td>
                        <td>{{ item.guarantee.name }}</td>
                        <td>{{ item.loanTerm.name }}</td>
                        <td>{{ item.numberOfTerms }}</td>
                        <td>{{ $filters.formatNumber(item.maxAmount) }}</td>
                        <td>{{ item.currencyType.code }}</td>
                        <td>{{ item.tax.name }}</td>
                        <td>{{ item.status.name }}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="14" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {
        }
    },
    emits: ['back-to-form', 'selected-item'],
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
    },
    methods: {
        getDataPage() {
            if (this.list == undefined) {
                return 0;
            }
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        selectedProduct(product) {
            this.$emit('back-to-form');
            this.$emit('selected-item', product);
        },
    },
    watch: {
        updateSelectedProduct(val) {
            this.selectedProduct = val;
        },
    },
    created() {
        this.getDataPage();
    }
}
</script>