<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination">
            <li @click="getPreviousPage()" :class="{ 'disabled': currentPage === 1 }" class="page-item"> <a
                    class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li v-for="page in totalPages()" @click="setCurrentPage(page)" :class="isActive(page)" class="page-item"><a
                    class="page-link" href="#">{{
                        page }}</a></li>
            <li @click="getNextPage()" :class="{ 'disabled': currentPage === totalPages() }" class="page-item"> <a
                    class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            childCurrentPage: this.currentPage,
        }
    },
    emits: ['current-page'],
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    watch: {
        list(newList) {
            this.setCurrentPage(1);
        }
    },

    methods: {
        getDataPage() {
            if (typeof this.list === "undefined" || this.list.length === 0) return [];

            let totalPages = this.totalPages();
            if (this.childCurrentPage > totalPages) {
                this.childCurrentPage = 1;
                this.setCurrentPage(1);
            }

            let start = (this.childCurrentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.childCurrentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        totalPages() {
            return (this.list == undefined) ? 0 : Math.ceil(this.list.length / this.itemsPerPage);
        },
        getPreviousPage() {
            if (this.childCurrentPage == 1) return;
            this.childCurrentPage--;
            this.getDataPage(this.childCurrentPage);
            this.setCurrentPage(this.childCurrentPage);
        },
        getNextPage() {
            if (this.childCurrentPage == this.totalPages) return;
            this.childCurrentPage++;
            this.getDataPage(this.childCurrentPage);
            this.setCurrentPage(this.childCurrentPage);
        },
        isActive(pageNumber) {
            if (pageNumber == this.currentPage) {
                return 'active';
            }
        },
        isDisabled(clickedBtn) {
            if (clickedBtn == 'prevBtn' && this.currentPage == 1) {
                return 'disabled';
            }
            if (clickedBtn == 'nextBtn' && this.currentPage == this.list.length) {
                return 'disabled';
            }
        },
        setCurrentPage(pageNum) {
            this.childCurrentPage = pageNum;
            this.$emit('current-page', pageNum);
            this.getDataPage();
        }
    },
    created() {
        this.getDataPage();
    }
};
</script>