export default class ReferenceRate {
    constructor({
        id = null,
        rateTypeId = null,
        statusId = null,
        name = '',
        itemOrder = 0,
        createdAt = new Date(),
        updatedAt = new Date()
    }) {
        this.id = id;
        this.rateTypeId = rateTypeId;
        this.statusId = statusId;
        this.name = name;
        this.itemOrder = itemOrder;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}