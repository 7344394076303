<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Empresa
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre de Empresa:</label>
                    <Field v-model="form.name" type="text" class="form-control" name="name" placeholder="Nombre"
                    rules="required" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="address">Ubicación:</label>
                        <Field v-model="form.address" type="text" class="form-control" name="address"
                        placeholder="Ubicación" rules="required" />
                        <ErrorMessage name="address" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="form.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useCompaniesStore } from '@/pinia/companies.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';

export default {
    components: { PrimaryButton, StatusSelect},
    mixins: [],
    data: () => ({
        form: {},
    }),
    watch: {},
    computed: {
        ...mapState(useStatusStore, ['getAllStatusByType']),
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
    },
    methods: {
        onSubmit() {
            const companiesStore = useCompaniesStore();
            companiesStore.createCompany(this.form);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        getUpdatedCompanies() {
            const companiesStore = useCompaniesStore();
            this.$emit('get-updated-companies', companiesStore.fetchCompanies());
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
        companies(){
            this.$emit('new-companies', true);
        },
        setNewStatus(statusId) {
            this.statusId = statusId;
        }
    },
    created() {},
    mounted() {}
};
</script>