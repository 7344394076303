export default class FinancialInformation {
  constructor({
    id = 0,
    loanApplicationId = 0,
    profession = null,
    business = null,
    employersName = null,
    seniority = null,
    employersAddress = null,
    employersEmail = null,
    currentEmploymentStatus = null,
    spouseFullName = null,
    otherIncomes = null,
    createdAt = new Date(),
    updatedAt = new Date()
  }) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.profession = profession || null;
    this.business = business || null;
    this.employersName = employersName || null;
    this.seniority = seniority || null;
    this.employersAddress = employersAddress || null;
    this.employersEmail = employersEmail || null;
    this.currentEmploymentStatus = currentEmploymentStatus || null;
    this.spouseFullName = spouseFullName || null;
    this.otherIncomes = otherIncomes || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
