export default class Client {
    constructor({
        id = 0,
        provisionId = 0,
        referenceNumber = null,
        provision = null,
        createdAt = new Date(),
        updatedAt = new Date()
    }) 
    {
        this.id = id || 0;
        this.provisionId = provisionId || 0;
        this.referenceNumber = referenceNumber || null;
        this.provision = provision || null;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}