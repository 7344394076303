
export default class Document {
  constructor({
    id = 0, 
    companyId = 0,
    statusId = 0,
    documentName = null, 
    itemOrder = 0,
    status = null,
    createdAt = new Date(),
    updatedAt = new Date(),
  } = {}) {
    this.id = id;
    this.companyId = companyId;
    this.statusId = statusId;
    this.documentName = documentName;
    this.itemOrder = itemOrder;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
