<template>
    <Form @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('buttons', 'updating') }} <strong>{{ referenceNumber }}</strong>
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre de Empresa:</label>
                    <Field v-model="selectedBranchOffice.name" type="text" class="form-control" name="name" placeholder="Nombre"
                        rules="required|max:50" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="address">Domicilio:</label>
                        <Field v-model="selectedBranchOffice.address" type="text" class="form-control" name="address"
                            placeholder="Ubicación" rules="max:80" />
                        <ErrorMessage name="address" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="stateId">Estado:</label>
                    <StatesSelect v-model="selectedBranchOffice.stateId" :name="'stateId'" :options="localizationOptions"></StatesSelect>                
                </div>
                <div class="form-group">
                    <label for="rfc">RFC:</label>
                    <Field v-model="selectedBranchOffice.rfc" type="text" class="form-control" name="rfc" placeholder="RFC" rules="max:30" />
                    <ErrorMessage name="rfc" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="phone">Teléfono Contacto:</label>
                    <Field v-model="selectedBranchOffice.phone" type="text" class="form-control" name="phone" placeholder="Teléfono" rules="max:50" />
                    <ErrorMessage name="phone" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="email">Correo Contacto:</label>
                    <Field v-model="selectedBranchOffice.email" type="text" class="form-control" name="email" placeholder="Correo" rules="max:50" />
                    <ErrorMessage name="email" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="legalName">Razón Social:</label>
                    <Field v-model="selectedBranchOffice.legalName" type="text" class="form-control" name="legalName" placeholder="Razón Social" rules="max:200" />
                    <ErrorMessage name="legalName" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="idUser">Responsable:</label>
                    <UsersSelect v-model="selectedBranchOffice.userRel.id" :name="'idUser'" :options="userOptions"></UsersSelect>                
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="selectedBranchOffice.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useLocalizationStore } from '@/pinia/localization.module';
import { useUsersStore } from '@/pinia/users.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import StatesSelect from '@/Components/Selects/SelectField.vue';
import UsersSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';

export default {
    components: { PrimaryButton, UsersSelect, StatusSelect, StatesSelect },
    mixins: [],
    data() {
        return {
            stateId: null,
            statusId: null,
            selectedBranchOffice: {},
            selectedUser: null,
            countryId: 129,
        }
    },
    emits: ['new-changes'],
    props: {
        selectedRecord: {
            type: Object,
            default: () => {}
        },
        editForm: false,
    },
    computed: {
        ...mapState(useUsersStore, ['getActiveUsers']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        ...mapState(useLocalizationStore, ['getStatesByCountry']),
        localizationOptions() {
            return helper.mapOptions(this.getStatesByCountry(this.countryId));
        },
        userOptions() {
            return helper.mapOptions(this.getActiveUsers, 'id', ['name', 'lastName']);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
        referenceNumber() {
            return `${this.selectedBranchOffice.identifier}-${this.selectedBranchOffice.code}`;
        }
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['updateBranchOffice', 'updatePaginatedBranchOffice']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        onSubmit() {
            this.updateBranchOffice(this.selectedBranchOffice);
            this.updatePaginatedBranchOffice(this.selectedBranchOffice);
            this.emitUpdatedRecord();
            this.closeModal();
        },
        emitUpdatedRecord() {
            this.$emit('new-changes', this.selectedBranchOffice)
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
    },
    watch: {
        selectedRecord: {
            handler(newValue) {
                if (newValue) {
                    this.selectedBranchOffice = newValue;
                    this.selectedUser = newValue.userRel?.id;
                }
            },
            immediate: true,
        }
    },
    created() {},
    mounted() {}
};
</script>