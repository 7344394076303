<template>
    <Form ref="questionnaire" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                Editar Conocimiento General del cliente o Usuario
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div  v-for="(question, idx) in getQuestionnaire.questions" :key="question.id" class="form-group col-sm-12">
                    <label :for="`question${idx}-${idx}`">{{ question.questionText }}</label>
                    <div v-for="(field, i) in question.fields" :key="field.id">
                        <template v-if="field.fieldTypeId === 1">
                            <!-- Renderizar un Select -->
                            <SelectField 
                                v-model="fieldValues[question.id][field.id]" 
                                :name="`question${idx}-field${i}`" 
                                :options="questionOptions(field.selectOptions)" 
                                :customValidation="setRule(idx, question, field.id)"
                                @update="handleFieldChange(question.id, field.id, $event)">
                            </SelectField>
                        </template>

                        <template v-else-if="field.fieldTypeId === 2">
                            <!-- Renderizar un Input de texto -->
                            <FieldGroup 
                                v-model="fieldValues[question.id][field.id]" 
                                :name="`question${idx}-field${i}`" 
                                colClass="col-sm-12" placeholder="" 
                                :label="getLabel(question, i)" :rules="setRule(idx, question, field.id)" 
                                @input="handleFieldChange(question.id, field.id, $event)">
                            </FieldGroup>
                        </template>

                        <template v-else-if="field.fieldTypeId === 3">
                            <!-- Renderizar un Input de Fecha -->
                            <FieldGroup 
                                v-model="fieldValues[question.id][field.id]" 
                                type="date"
                                :name="`question${idx}-field${i}`" 
                                colClass="col-sm-12 col-md-6" 
                                :label="getLabel(question, i)" 
                                :rules="setRule(idx, question, field.id)"
                                @change="handleFieldChange(question.id, field.id, $event)">
                            </FieldGroup>
                        </template>
                    </div>
                </div>
            </div>
            
            <!-- Nombre y Firma de Entrevistador y Entrevistado -->
            <div class="row">
                <FieldGroup v-model="getQuestionnaire.interviewerFullName" name="interviewerFullName" colClass="col-sm-12 col-md-6" label="Entrevistador:"
                    placeholder="Entrevistador" rules="required">
                </FieldGroup>
                <FieldGroup v-model="getQuestionnaire.intervieweeFullName" name="intervieweeFullName" colClass="col-sm-12 col-md-6" label="Entrevistado:"
                    placeholder="Entrevistado" rules="required">
                </FieldGroup>
                <FieldGroup v-model="getQuestionnaire.address" name="address" colClass="col-sm-12 col-md-6" label="Lugar:"
                    placeholder="Lugar">
                </FieldGroup>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ updateButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useQuestionnairesStore } from '@/pinia/questionnaires.module';
import QuestionnaireResponse from '@/Models/Questionnaires/QuestionnaireResponse';
import QuestionnaireFieldResponse from '@/Models/Questionnaires/QuestionnaireFieldResponse';

import FieldGroup from '@/Components/Fields/FieldGroup.vue';
import SelectField from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        FieldGroup,
        SelectField,
        PrimaryButton
    },
    props: {
        selectedItemId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            fieldValues: {},
        }
    },
    computed: {
        ...mapState(useQuestionnairesStore, ['getQuestionnaire']),
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel')
        },
        updateButtonText() {
            return this.$getLocaleMessage('buttons', 'update')
        },
    },
    methods: {
        ...mapActions(useQuestionnairesStore, ['fetchQuestionnaireById', 'updateQuestionnaire']),
        onSubmit() {
            this.updateQuestionnaire(this.getQuestionnaire);
            this.$refs.questionnaire.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        questionOptions(items) {
            return helper.mapOptions(items, 'optionValue', 'optionText');
        },
        // Inicializa los valores de todos los campos (select, text, date) con respuestas previas si están presentes
        initializeFieldValues() {
            if (this.getQuestionnaire?.questions && this.getQuestionnaire.questions.length > 0) {
                this.getQuestionnaire.questions.forEach((question) => {
                    if (!this.fieldValues[question.id]) {
                        this.fieldValues[question.id] = {};
                    }
    
                    question.fields.forEach((field) => {
                        // Busca si hay una respuesta almacenada para este field
                        const fieldResponse = question.questionnaireResponses?.questionnaireFieldResponses?.find(
                            (response) => response.fieldId === field.id
                        );
    
                        // Si hay una respuesta, la asigna como el valor preseleccionado
                        if (fieldResponse) {
                            this.fieldValues[question.id][field.id] = fieldResponse.answerText;
                        } else {
                            // Inicializa valores predeterminados de los selects y inputs
                            this.fieldValues[question.id][field.id] = '';
                        }
                    });
                });
            }
        },
        // Método para manejar cambios en los campos
        handleFieldChange(questionId, fieldId, event) {
            const newValue = (typeof event === "object") ? event.target.value : event;
            const question = (questionId ? this.getQuestionnaire.questions.find(q => q.id === questionId) : null);
            
            if (question) {
                let qresponse = question.questionnaireResponses;
                
                if (!qresponse) {
                    qresponse = new QuestionnaireResponse({
                        questionId: question.id,
                        questionnaireFieldResponses: []
                    });
                    question.questionnaireResponses = qresponse;
                }
                
                let fresponse = qresponse?.questionnaireFieldResponses?.find(
                    (fr) => fr.fieldId === fieldId
                );

                if (!fresponse) {
                    fresponse = new QuestionnaireFieldResponse({
                        fieldId: fieldId,
                        answerText: newValue,
                    });
                    question.questionnaireResponses.questionnaireFieldResponses.push(fresponse);
                } else {
                    fresponse.answerText = newValue;
                }

                if (!this.fieldValues[questionId]) {
                    this.fieldValues[questionId] = {};
                }
                this.fieldValues[questionId][fieldId] = newValue;
            }
        },
        getLabel(question, fieldIndex) {
            const answerType = Object.values(JSON.parse(question.answerType));
            return answerType[fieldIndex].label || null;
        },
        setRule(questionIndex, question, fieldId) {
            if (question.id === 13 || question.id === 14) {
                const isSelected = this.fieldValues[12][5];
                if (isSelected === "1") {
                    return 'required';
                } else {
                    return '';
                }
            } else if (question.id === 16) {
                const isSelected = this.fieldValues[15][5];
                if (isSelected === "1") {
                    return 'required';
                } else {
                    return '';
                }
            } else if (question.id === 17 && fieldId === 1) {
                const isSelected = this.fieldValues[question.id][8];
                if (isSelected === "2") {
                    return 'required';
                } else {
                    return '';
                }
            } else if (question.id === 18 && fieldId === 1) {
                const isSelected = this.fieldValues[question.id][11];
                const field = this.getQuestionnaire.questions[questionIndex].fields.find(f => f.id === 11);
                const totalItems = field.selectOptions.length;
                if (isSelected === totalItems.toString()) {
                    return 'required';
                } else {
                    return '';
                }
            } else if (question.id === 20 && fieldId === 1) {
                const isSelected = this.fieldValues[question.id][12];
                const field = this.getQuestionnaire.questions[questionIndex].fields.find(f => f.id === 12);
                const totalItems = field.selectOptions.length;
                if (isSelected === totalItems.toString()) {
                    return 'required';
                } else {
                    return '';
                }
            } else if (question.id === 23) {
                const isSelected = this.fieldValues[22][5];
                if (isSelected === "1") {
                    return 'required';
                } else {
                    return '';
                }
            } else if (question.id === 25) {
                const isSelected = this.fieldValues[24][5];
                if (isSelected === "1") {
                    return 'required';
                } else {
                    return '';
                }
            } else {
                return 'required';
            }

        },
    },
    watch: {
        'getQuestionnaire.questions'(newVal) {
            if (newVal && newVal.length > 0) {
                this.initializeFieldValues();
            }
        }
    },
    created() {
        this.fetchQuestionnaireById(this.selectedItemId);
        this.initializeFieldValues();
    }
}
</script>