<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>Folio de Prospectos</th>
                <th>Matriz y/o Sucursales</th>
                <th>Tipo de Persona</th>
                <th>Nombre(s)</th>
                <th>Correo electrónico</th>
                <th>Teléfono</th>
                <th>Código Postal</th>
                <th>Estatus</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <template v-if="getDataPage().length">
                <tr v-for="item in getDataPage()" :key="item.prospectusId">
                    <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                    <td>{{ item.branchOffice.name }}</td>
                    <td>{{ item.personType.name }}</td>
                    <td>{{ getName(item) }}</td>
                    <td>{{ item.generalInformation.email }}</td>
                    <td>{{ getPhone(item) }}</td>
                    <td>{{ getAddress(item) }}</td>
                    <td>{{ item.status.name }}</td>
                    <td>
                        <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-xl"
                            @click.prevent="editProspect(item)"><i class="fa fa-edit"></i>
                        </PrimaryButton>
                    </td>
                </tr>
            </template>
            <tr v-else>
                <td colspan="11" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
        }
    },
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getProspects() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            if (this.list == undefined) {
                return 0;
            }
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        editProspect(prospect) {
            this.$emit('edit-prospect', prospect);
        },
        getName(item) {
            if (item.generalInformation.businessName != null) {
                return item.generalInformation.businessName;
            } else {
                return `${item.generalInformation.firstName} ${item.generalInformation.lastName} ${item.generalInformation.mothersMaidenName}` ;
            }
        },
        getPhone(item) {
            if(item.personTypeId == 1) {
                return item.generalInformation.cellPhone;
            } else {
                return item.generalInformation.officePhone;
            }
        },
        getAddress(item) {
            if(item.personTypeId == 1) {
                return item.homeAddress.postalCode;
            } else {
                return item.fiscalAddress.postalCode
            }
        },
    },
    created() {
        this.getDataPage();
    }
}
</script>