import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Holiday from '@/Models/Holiday';

import * as service from '@/services';

const $toast = useToast();

export const useHolidaysStore = defineStore('holiday', {
    persist: false,
    state: () => {
        return {
            holidays: [],
            holiday: new Holiday(),
            errors: null,
        }
    },
    getters: {
        getSelectedHoliday: state => state.holiday,
        getHolidays: state => state.holidays,
        getHolidayById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.holidays.find(h => h.id === id);
        },
        getActiveHolidays: state => state.holidays,
        getPaginatedHolidays: state => state.paginatedHolidays,

        filterHolidaysBySearchString: state => (searchString = '') => {
            if (!searchString) return state.holidays;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.holidays.filter(holiday => {
                const branchOfficeRelName = holiday.branchOfficeRel?.name ? holiday.branchOfficeRel?.name.toLowerCase() : '';
                const nameHoliday = holiday.name ? holiday.name.toLowerCase() : '';
                const remarks = holiday.remarks ? holiday.remarks.toLowerCase() : '';
                const statusRelName = holiday.statusRel?.name ? holiday.statusRel?.name.toLowerCase() : '';

                return branchOfficeRelName.includes(lowerCaseSearchString) ||
                    nameHoliday.includes(lowerCaseSearchString) ||
                    remarks.includes(lowerCaseSearchString) ||
                    statusRelName.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        setSelectedHoliday(id) {
            this.holiday = this.getHolidayById(id);
        },
        async fetchHolidays() {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.holidaysApi.get(`/api/Holidays`);
                if (data) {
                    useLoadingStore().loading = false;
                    this.holidays = data;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los días feriados.");
                this.setErrors(error);
            }
        },
        async fetchActiveHolidaysByBranchOfficeId(branchOfficeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.holidaysApi.get(`/api/Holidays/branchOffice/${branchOfficeId}/active`);
                if (data) {
                    useLoadingStore().loading = false;
                    const holidays = data.map((holidayData) => new Holiday(holidayData));
                    this.holidays = holidays;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
            }
        },
        async createHoliday(holiday) {
            try {
                useLoadingStore().loading = true;
                const { data: data } = await service.holidaysApi.post(`/api/Holidays`, holiday);
                if (data) {
                    $toast.success("Día Feriado creado correctamente.");
                    this.holidays.unshift(data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
            }
        },
        async updateHoliday(holiday) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.holidaysApi.put(`/api/Holidays`, holiday);
                if (data) {
                    $toast.success("Día Feriado actualizado correctamente.");
                    const index = this.holidays.findIndex(c => c.id === data.id);
                    if (index !== -1) {
                        this.holidays.splice(index, 1, data);
                        this.holidays.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al actualizar el registro.");
                this.setErrors(error);
            }
        },
        setPaginatedHolidays(holiday) {
            this.paginatedHolidays = holiday;
        },
        updatePaginatedHoliday(holiday) {
            const index = this.paginatedHolidays.findIndex(c => c.idHoliday === holiday.idHoliday);
            if (index !== -1) {
                this.paginatedHolidays.splice(index, 1, holiday);
            }
        }

    },
});