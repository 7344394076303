<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>Folio de Calificaciones</th>
                <th>Folio de Solicitudes</th>
                <th>Folio de prospectos</th>
                <th>Matriz y/o Sucursales</th>
                <th>Tipo de persona</th>
                <th>Calificación</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in getDataPage()" :key="item.id" v-if="getDataPage().length != 0">
                <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                <td>{{ item.loanApplication.referenceNumber}}</td>
                <td>{{ item.loanApplication.prospectus.referenceNumber}}</td>
                <td>{{ item.loanApplication.prospectus.branchOffice.name }}</td>
                <td>{{ item.personType.name }}</td>
                <td>{{ this.$filters.formatPercent2(item.score) }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" :data-target="dataTarget"
                        @click.prevent="editItem(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="7" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
        }
    },
    emits: ['click-on-edit-btn'],
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        dataTarget: {
            type: String,
            default: '#modal-lg'
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getItem() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            if (this.list == undefined) {
                return 0;
            }
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        editItem(item) {
            this.$emit('click-on-edit-btn', item);
        },
    },
    created() {
        this.getDataPage();
    }
}
</script>