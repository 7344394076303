export default class QuestionnaireFieldResponse {
    constructor({
        id = 0,
        questionnaireResponseId = null,
        fieldId = null,
        answerText = null
    } = {}) {
        this.id = id;
        this.questionnaireResponseId = questionnaireResponseId;
        this.fieldId = fieldId;
        this.answerText = answerText;
    }
}