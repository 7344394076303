<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                Generar nueva Cotización
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="ProspectReferenceNumber">Prospecto:</label>
                    <PrimaryButton class="btn-success" @click.prevent="changeModalContent(2)"><i class="fa fa-search"></i></PrimaryButton>
                    <div class="row">
                        <Field v-model="getProspectusReferenceNumber" type="text" class="form-control col-sm-3" name="ProspectReferenceNumber" rules="required" disabled/>
                        <Field v-model="getProspectFullname" type="text" class="form-control col-sm-9" name="ProspectFullname" disabled/>
                        <ErrorMessage name="ProspectReferenceNumber" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <Field v-model="getBranchOffice" type="text" class="form-control" name="branchOfficeId" disabled/>
                </div>
                <div class="form-group">
                    <label for="ProductDescription">Producto:</label>
                    <PrimaryButton :class="disabledButton" @click.prevent="changeModalContent(3)" :disabled="!isProspectValid"><i class="fa fa-search"></i></PrimaryButton>
                    <Field v-model="productDesc" type="text" class="form-control" name="ProductDescription" rules="required" disabled/>
                    <ErrorMessage name="ProductDescription" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="referenceRateName">Tasa de interés ordinario:</label>
                    <Field v-model="productReferenceRate" type="text" class="form-control" name="referenceRateName" disabled/>
                </div>
                <div class="form-group">
                    <label for="penaltyChargeName">Tasa de interés moratorio:</label>
                    <Field v-model="productPenaltyCharge" type="text" class="form-control" name="penaltyChargeName" disabled/>
                </div>
                <div class="form-group">
                    <label for="currencyTypeCode">Moneda:</label>
                    <Field v-model="productCurrencyType" type="text" class="form-control col-sm-3" name="currencyTypeCode" disabled/>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="creditAmount">Monto del préstamo:</label>
                        <Field v-model="form.creditAmount" type="number" class="form-control" name="creditAmount" placeholder="Monto del préstamo" :rules="maxCreditAmount"/>
                        <ErrorMessage name="creditAmount" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="taxName">Impuesto:</label>
                    <TaxesSelect v-model="product.taxId" :name="'taxId'" :options="taxOptions" :isDisabled="true"></TaxesSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="totalTerm">Plazo:</label>
                        <Field v-model="form.totalTerm" type="number" class="form-control" name="totalTerm" placeholder="Plazo" :rules="maxNumberOfTerms"/>
                        <ErrorMessage name="totalTerm" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="loanTermName">Forma de pago:</label>
                    <Field v-model="productLoadTerm" type="text" class="form-control" name="loanTermName" disabled/>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="gracePeriod">Periodo de Gracia:</label>
                        <Field v-model="form.gracePeriod" type="number" class="form-control" name="gracePeriod"
                            placeholder="Periodo de Gracia" rules="number"/>
                        <ErrorMessage name="gracePeriod" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="annualRate">Tasa de interés anual sin IVA %:</label>
                        <Field v-model="form.annualRate" type="number" class="form-control" name="annualRate"
                            placeholder="Tasa de interés anual sin IVA %" :rules="validAnnualRate" :disabled="isAnnualRateVariable"/>
                        <ErrorMessage name="annualRate" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="openingCommission">Comisión por apertura %:</label>
                        <Field v-model="form.openingCommission" type="number" class="form-control" name="openingCommission"
                            placeholder="Comisión por apertura %" rules="required|number"/>
                        <ErrorMessage name="openingCommission" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="disbursementDate">Fecha disposición:</label>
                        <Field v-model="form.disbursementDate" type="date" class="form-control" name="disbursementDate" :rules="minDate"/>
                        <ErrorMessage name="disbursementDate" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="validityDays">Días de Validez:</label>
                        <Field v-model="form.validityDays" type="number" class="form-control" name="validityDays"
                            placeholder="Días de Validez" :rules="validDays"/>
                        <ErrorMessage name="validityDays" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="quotationDate">Fecha Cotización:</label>
                        <Field v-model="form.quotationDate" type="date" class="form-control" name="quotationDate" :rules="minDate"/>
                        <ErrorMessage name="quotationDate" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="comments">Comentarios:</label>
                        <Field as="textarea" v-model="form.comments" type="text" class="form-control" name="comments"
                            placeholder="Comentarios" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                Generar
            </PrimaryButton>
        </div>
    </Form>

</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useQuotationsStore } from '@/pinia/quotations.module';
import { useProductsStore } from '@/pinia/products.module';
import { useTaxesStore } from '@/pinia/taxes.module';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import TaxesSelect from '@/Components/Selects/SelectField.vue';
import * as yup from 'yup';

export default {
    components: {
        PrimaryButton,
        Modal,
        TaxesSelect
    },
    mixins: [],
    data: () => ({
        form: {
            companyId: 1, // TODO: Get the id from browser session / cookies
            branchOfficeId: null,
            prospectusId: null,
            gracePeriod: 0,
            validityDays: 8,
        },
        productDesc: '',
        loanTermName: '',
        isProspectValid: false,
        selectedTax: null,
        periodName: '',
        prospectusFullName: '',
    }),
    emits: ['closeModal'],
    props: {
        prospectus: {
            type: Object,
            default: () => ({}),
        },
        product: {
            type: Object,
            default: () => ({}),
        },
        updatedReferenceSelect: {
            type: Number,
            default: 0,
        },
        updatedPenaltySelect: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        prospectus: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.form.prospectusId = this.prospectus.prospectusId;
                    this.form.branchOfficeId = this.prospectus.branchOfficeId;
                }
            },
        },
    },
    computed: {
        ...mapState(useQuotationsStore, ['getQuotation', 'getQuotationDetails']),
        ...mapState(useProductsStore, ['getProducts']),
        ...mapState(useTaxesStore, ['getActiveTaxes', 'getTaxById']),
        products() {
            return this.getProducts;
        },
        isAnnualRateVariable() {
            return this.product.rateTypeId == 2 ? true : false;
        },
        getProspectusReferenceNumber() {
            if (this.prospectus.prospectusId === 0) {
                return;
            }
            return this.prospectus.referenceNumber;
        },
        getProspectFullname() {
            if (this.prospectus.prospectusId === 0) {
                this.isProspectValid = false;
                return;
            }
            this.isProspectValid = true;
            if (this.prospectus.personTypeId === 1) {
                this.prospectusFullName = `${this.prospectus.generalInformation.firstName} ${this.prospectus.generalInformation.lastName} ${this.prospectus.generalInformation.mothersMaidenName }`;
                return this.prospectusFullName;
            }
            this.prospectusFullName = `${this.prospectus.generalInformation.businessName}`;
            return  this.prospectusFullName;
        },
        getSelectedProduct() {
            return this.selectedRecord;
        },
        productReferenceRate: {
            get() {
                let value;
                try {
                    value = this.product.referenceRate.name;
                } catch (error) {
                    /// TODO: Verificar si aquí retornamos null o vacío
                    value = null;
                }
                return value;
            },
            // set(value) {
            //     this.product.referenceRate.name = value;
            // }
        },
        productPenaltyCharge: {
            get() {
                let value;
                try {
                    value = this.product.penaltyCharge.name;
                } catch (error) {
                    value = null;
                }
                return value;
            },
            // set(value) {
            //     this.product.penaltyCharge.name = value;
            // }
        },
        productCurrencyType: {
            get() {
                let value;
                try {
                    value = this.product.currencyType.code;
                } catch (error) {
                    value = null;
                }
                return value;
            },
            // set(value) {
            //     this.product.currencyType.code = value;
            // }
        },
        taxOptions() {
            return helper.mapOptions(this.getActiveTaxes);
        },
        productTax: {
            get() {
                let value;
                try {
                    value = this.product.tax.name;
                } catch (error) {
                    value = null;
                }
                return value;
            },
            // set(value) {
            //     this.product.tax.name = value;
            // }
        },
        productLoadTerm: {
            get() {
                let value;
                try {
                    value = this.product.loanTerm.name;
                } catch (error) {
                    value = null;
                }
                return value;
            },
            // set(value) {
            //     this.product.loanTerm.name = value;
            // }
        },
        disabledButton() {
            return {
                'btn-secondary': !this.isProspectValid,
                'btn-success': this.isProspectValid,
            }
        },
        getBranchOffice() {
            if (this.prospectus.prospectusId == 0) {
                return;
            }
            return this.prospectus.branchOffice.name;
        },
        // Custom YUP Validations:
        maxCreditAmount() {
            return yup
                    .number().typeError('Debe especificar un valor numérico')
                    .positive('El campo debe ser un número positivo')
                    .required('El campo es obligatorio')
                    .max(this.product.maxAmount, () => {
                        if (Object.keys(this.product).length == 0) {
                            return "Por favor seleccione un producto primero";
                        }
                        return `El monto del préstamo no debe exceder el monto máximo del producto: ${this.product.maxAmount}`;
                    }
            );
        },
        maxNumberOfTerms() {
            return yup
                    .number().typeError('Debe especificar un número')
                    .positive('El campo debe ser un número positivo')
                    .required('El campo es obligatorio')
                    .max(this.product.numberOfTerms, () => {
                        if (Object.keys(this.product).length == 0) {
                            return "Por favor seleccione un producto primero";
                        }
                        return `El Plazo no debe exceder el plazo máximo del producto: ${this.product.numberOfTerms}`;
                    }
            );
        },
        minDate() {
            let date = new Date();
            let minDate = date.setDate(date.getDate() - 1);
            return yup.date().required('El campo es requerido').min(new Date(minDate), 'La fecha seleccionada no puede ser menor a la fecha actual');
        },
        validDays() {
            return yup.number().required('El campo es requerido').positive('El campo debe ser un número positivo');
        },
        validAnnualRate() {
            if (this.isAnnualRateVariable) {
                return false;
            } else {
                return yup.number().required('El campo es requerido').positive('El campo debe ser un número positivo');
            }
        }
    },
    methods: {
        ...mapActions(useQuotationsStore, ['createQuotation', 'fetchQuotationDetailsByQuotationId']),
        onSubmit() {
            this.form.prospectusId = this.prospectus.prospectusId;
            this.form.branchOfficeId = this.prospectus.branchOffice.id;
            this.form.productId = this.product.id;
            this.form.taxId = this.product.taxId;
            this.createQuotation(this.form);
            // Emit new data to Index component:
            this.emitProspectusFullName();
            this.emitProductType();
            this.emitPeriodName();
            this.$refs.form.resetForm();
            this.closeModal();
        },
        changeModalContent(number) {
            this.$emit('changeModalContent', number);
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
        products() {
            this.$emit('new-products', true);
        },
        validateProspect() {
            this.isProspectValid = (this.prospectus.prospectusId == 0) ? false : true;
            return this.isProspectValid;
        },
        setNewTax(id) {
            this.form.taxId = id;
        },
        emitProspectusFullName() {
            this.$emit('prospectusFullName', this.prospectusFullName);
        },
        emitProductType() {
            this.$emit('prodType', this.product.productType.name);
        },
        emitPeriodName() {
            this.$emit('periodName', this.product.loanTerm.name);
        },
    },
    created() {
        this.validateProspect();
    },
    mounted() {
        try {
            if (this.product.id) {
                this.loanTermName = this.product.loanTerm.name;
                this.productDesc = this.product.description;
                this.productId = this.product.id;
                this.currencyId = this.product.currencyTypeId;
                this.referenceRateId = this.product.referenceRateId;
                this.penaltyChargeId = this.product.penaltyChargeId;
                this.paymentPeriodType = this.product.loanTerm.id;
            }
        } catch (error) {
                this.loanTermName = '';
                this.productDesc = '';
                this.productId = '';
                this.currencyId = '';
                this.referenceRateId = '';
                this.penaltyChargeId = '';
                this.paymentPeriodType = '';
        }

    }
};
</script>