<template>
<Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Día Feriado
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="form.branchOfficeId" :name="'branchOfficeId'" :options="branchOfficeOptions"></BranchOfficesSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="name">Nombre:</label>
                        <Field v-model="form.name" type="text" class="form-control" name="name"
                            placeholder="Nombre" rules="required"/>
                        <ErrorMessage name="name" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="date">Día:</label>
                        <Field v-model="form.date" type="date" class="form-control" name="date" :rules="minDate"/>
                        <ErrorMessage name="date" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="remarks">Observaciones:</label>
                        <Field as="textarea" v-model="form.remarks" type="text" class="form-control" name="remarks"
                            placeholder="Observaciones"/>
                        <ErrorMessage name="remarks" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="form.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useHolidaysStore } from '@/pinia/holidays.module';

import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import * as yup from 'yup';

export default {
    components: {
        BranchOfficesSelect,
        StatusSelect,
        PrimaryButton,
    },
    mixins: [],
    data: () => ({
        form: {}
    }),
    emits: ['close-modal'],
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedBranchOffice']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        branchOfficeOptions() {
            return helper.mapOptions(this.getActiveBranchOffices);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(5));
        },
        minDate() {
            let date = new Date();
            let minDate = date.setDate(date.getDate() - 1);
            return yup.date().required('El campo es requerido').min(new Date(minDate), 'La fecha seleccionada no puede ser menor a la fecha actual');
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedBranchOffice']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        ...mapActions(useHolidaysStore, ['createHoliday']),
        onSubmit() {
            this.createHoliday(this.form);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal');
        },
    },
    created() {},
    mounted() {},
}
</script>