import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { useAuthStore } from '@/pinia/auth.module';
import RecordType from '@/Models/RecordType';

import * as service from '@/services';

export const useRecordTypesStore = defineStore('recordTypes', {
    state: () => ({
        recordTypes: reactive([]),
        recordType: new RecordType({}),

        activeRecordTypes: reactive([]),
        activeRecordType: new RecordType({}),
        
        errors: [],
    }),
    getters: {
        getRecordTypes: (state) => state.recordTypes,
        getSelectedRecordType: (state) => state.recordType,
        getRecordTypeById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.recordTypes.find(rt => rt.id === id);
        },

        getActiveRecordTypes: (state) => state.activeRecordTypes,
        getSelectedActiveRecordType: state => state.activeRecordType,
        getActiveRecordTypeById: state => (id = 0)=> {
            if (id === 0 || id === null) return {};
            return state.activeRecordTypes.find(rt => rt.id === id);
        },

        getAllStatusByType: (state) => (typeId = 0) => {
            if (id === 0 || id === null) return {};
            return state.recordTypes.filter((s) => s.statusTypeId === typeId);
        },
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        setSelectedRecordType(id) {
            this.recordType = this.getRecordTypeById(id);
        },
        setSelectedActiveRecordType(id) {
            this.activeRecordType = this.getActiveRecordTypeById(id);
        },
        async fetchRecordTypesByCompany() {
            try {
                const { data } = await service.documentationApi.get(`/RecordTypes/company/${useAuthStore().user.companyId}`);
                if (data && data.status) {
                    const recordTypes = data.data.map(rt => new RecordType(rt));
                    this.recordTypes = recordTypes;
                }
            } catch (error) {
                this.setErrors(error);
            }
        },
        async fetchActiveRecordTypesByCompany() {
            try {
                const { data } = await service.documentationApi.get(`/RecordTypes/company/${useAuthStore().user.companyId}/active`);
                if (data && data.status) {
                    const activeRecordTypes = data.data.map(rt => new RecordType(rt));
                    this.activeRecordTypes = activeRecordTypes;
                }
            } catch (error) {
                this.setErrors(error);
            }
        },
        async createStatus(status) {
            try {
                const params = { api_path: 'status/Status', data: status };
                const response = await service.documentationApi.post('/api/postMethod', { params: params });
                this.newStatus(response.data);
            } catch (error) {
                this.setErrors(error);
            }
        },
        async updateStatus(status) {
            try {
                const params = { api_path: 'status/Status', data: status };
                const response = await service.documentationApi.put(`/api/putMethod`, { params: params });
                this.updateStatus(response.data);
            } catch (error) {
                this.setErrors(error);
            }
        },
        async deleteStatus(id) {
            try {
                const params = { api_path: 'status/Status', data: id };
                const response = await service.documentationApi.delete(`/api/deleteMethod`, { params: params });
                this.deleteStatus(id);
            } catch (error) {
                this.setErrors(error);
            }
        },
    },
});