<template>
    <div class="tab-content" id="custom-tabs-four-tabContent">
        <div v-for="(tab, index) in tabs" :key="index"
            :class="['tab-pane fade', { 'active show': index === activeTab }]" role="tabpanel">
            <slot :name="tab.slot" :active="index === activeTab"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        initialTab: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            activeTab: this.initialTab,
        };
    },
    methods: {
        updateActiveTab(index) {
            this.activeTab = index;
        },
    },
};
</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>