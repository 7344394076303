import { defineStore } from 'pinia';
import axios from 'axios';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Bank from '@/Models/Bank.js';

import * as service from '@/services';

const $toast = useToast();

export const useBanksStore = defineStore('banks', {
    persist: false,
    state: () => {
        return {
            banks: [],
            bank: {},
            errors: null,
        }
    },
    getters: {
        getSelectedBank: state => state.bank,
        getBanks: state => state.banks,
        getBankById: state => id => {
            return state.banks.find(c => c.idBank === id);
        },
        getActiveBanks: state => state.banks,
        getPaginatedBanks: state => state.paginatedBanks,
        filterBanksBySearchString: state => (searchString = '') => {
            if (!searchString) return state.banks;
            console.log(state.banks);
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.banks.filter(bank => {

                const referenceNumber = bank.referenceNumber ? bank.referenceNumber.toLowerCase() : '';
                const bankName = bank.name ? bank.name.toLowerCase() : '';

                return referenceNumber.includes(lowerCaseSearchString) || bankName.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchBanks() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.banksApi.get(`/api/banks`);
                if (data) {
                    const banks = data.data;
                    this.banks = banks.map((banks) => new Bank(banks));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los bancos.");
                this.setErrors(error);
            }
        },
        async fetchBankById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.banksApi.get(`/api/banks/${id}`);
                if (data) {
                    this.bank = new Bank(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar el banco.");
                this.setErrors(error);
            }
        },
        async createBank(bank) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.banksApi.post(`/api/banks`, bank);
                if (data) {
                    const bank = new Bank(data.data);
                    this.banks.unshift(bank);
                    $toast.success("Banco agregado correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
            }
        },
        async updateBank(bank) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.banksApi.put(`/api/banks`, bank);
                if (data) {
                    const bank = new Bank(data.data);
                    const index = this.banks.findIndex(b => b.id === bank.id);
                    if (index !== -1) {
                        this.banks.splice(index, 1, bank);
                        this.banks.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                    $toast.success("Banco actualizado correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al actualizar el registro.");
                this.setErrors(error);
            }
        },
        async deleteBank(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.banksApi.delete(`/api/banks/${id}`);
                if (data) {
                    const index = this.banks.findIndex(c => c.id === id);
                    if (index !== -1) {
                        this.banks.splice(index, 1);
                        // this.banks.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                        $toast.success("Banco eliminado correctamente.");
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al eliminar el registro.");
                this.setErrors(error);
            }
        },
        setPaginatedBanks(bank) {
            this.paginatedBanks = bank;
        },
        updatePaginatedBank(bank) {
            const index = this.paginatedBanks.findIndex(c => c.idBank === bank.idBank);
            if (index !== -1) {
                this.paginatedBanks.splice(index, 1, bank);
            }
        }

    },
});