<template>
  <Field :name="fieldName" as="select" :rules="customValidation" v-model="selectedValue" @change="set();optionData()" class="form-control" :class="customClass" :disabled="isDisabled">
        <option value="">Seleccione una opción</option>
        <option 
            v-for="(item, idx) in options" 
            :key="idx" 
            :value="getOptionId(item)" >
            {{ getTextToShow(item) }}
        </option>
    </Field>
    <ErrorMessage :name="fieldName" class="input-error"></ErrorMessage>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: this.selectedOption || null,
    };
  },
  props: {
    fieldName: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    selectedOption: {
      type: Number,
      default: null,
    },
    idFieldName: {
      type: String,
      default: 'id', // Nombre del campo que contiene el ID en el objeto de datos (por defecto es 'id')
    },
    textToShow: {
      type: [String, Array],
      default: 'name', // Nombre del campo que contiene el texto a mostrar en las opciones del Select (por defecto es 'name' de tipo String)
    },
    value: {
      type: [String, Number],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    customValidation: {
      type: [String, Function],
      default: 'required'
    },
    customClass: {
      type: String,
      default: '',
    }
  },
  emits: ['change', 'option-data'],
  methods: {
    getOptionId(option) {
      return option[this.idFieldName]; // Obtener el valor del campo que contiene el ID
    },
    getTextToShow(option) {
      if(Array.isArray(this.textToShow)) {
        return this.textToShow.map((field) => option[field]).join(' '); // Obtener el valor de los campos que contienen el texto a mostrar
      }
      
      return option[this.textToShow]; // Obtener el valor del campo que contiene el texto a mostrar
    },
    set() {
        this.$emit('change', this.selectedValue);
    },
    optionData() {
      const opt = this.options.find(o => o.id === this.selectedValue);
      this.$emit('option-data', opt);
    },
  },
  watch: {
    // Watcher para reaccionar a los cambios en el prop selectedOption
    selectedOption: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.selectedValue = newValue;
        }
      }
    }
  },
  mounted() {
    if(!this.selectedOption) return;
    this.selectedValue = this.selectedOption;
  },
};
</script>