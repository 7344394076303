export default class FlowStatus {
    constructor({
        loanApplicationId = 0,
        operational = null,
        legal = null,
        aml = null
    } = {}) {
        this.loanApplicationId = loanApplicationId;
        this.operational = operational;
        this.legal = legal;
        this.aml = aml;
    }
}