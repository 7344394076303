import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Tax from '@/Models/Tax.js';

import * as service from '@/services';

const $toast = useToast();

export const useTaxesStore = defineStore('taxes', {
    persist: false,
    state: () => {
        return {
            taxes: [],
            tax: {},

            activeTaxes: [],
            activeTax: {},
            paginatedTaxes: [],
        }
    },
    getters: {
        getSelectedTax: state => state.tax,
        getTaxes: state => state.taxes,
        getTaxById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            return state.taxes.find(t => t.id === id);
        },
        getActiveTaxes: state => state.activeTaxes,
        getSelectedActiveTax: state => state.activeTax,
        getActiveTaxById: state => (id = 0) => {
            if (id === 0 || id === null) return {};
            const avr = state.activeTaxes.find(t => t.id === id);
            return state.activeTaxes.find(t => t.id === id);
        },
        filterTaxesBySearchString: state => (searchString = '') => {
            if (!searchString) return state.taxes;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.taxes.filter(tax => {

                const branchOfficeName = tax.branchOffice?.name ? tax.branchOffice?.name.toLowerCase() : '';
                const taxName = tax.name ? tax.name.toLowerCase() : '';
                const taxDescription = tax.description ? tax.description.toLowerCase() : '';

                return branchOfficeName.includes(lowerCaseSearchString) ||
                    taxName.includes(lowerCaseSearchString) ||
                    taxDescription.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        setSelectedTax(id) {
            this.tax = this.getTaxById(id);
        },
        setSelectedActiveTax(id) {
            this.activeTax = this.getActiveTaxById(id);
        },
        async fetchTaxes() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.taxesApi.get("/api/taxes");
                if (data) {
                    const taxes = data.data;
                    // this.taxes = taxes.map((t) => new Tax(t));
                    this.taxes = taxes;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los impuestos.");
                this.setErrors(error);
            }
        },
        async fetchTaxById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.taxesApi.get(`/api/taxes/${id}`);
                if (data) {
                    const tax = data.data;
                    // this.tax = new Tax(tax);
                    this.tax = tax;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar el impuesto.");
                this.setErrors(error);
            }
        },
        async fetchTaxesByBranchOfficeId(branchOfficeId = 1) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.taxesApi.get(`/api/taxes/ByBranchOfficeId/${branchOfficeId}`);
                if (data) {
                    const taxes = data.data;
                    // this.taxes = taxes.map(t => new Tax(t));
                    this.taxes = taxes;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al cargar los impuestos.");
                this.setErrors(error);
            }
        },
        async fetchActiveTaxes(branchOfficeId = 1) {
            try {
                const { data } = await service.taxesApi.get(`/api/taxes/ByBranchOfficeId/${branchOfficeId}/active`);
                if (data) {
                    const taxes = data.data;
                    // this.taxes = taxes.map(t => new Tax({t}));
                    this.activeTaxes = taxes;
                }
            } catch (error) {
                $toast.error("Ocurrió un error al cargar los impuestos.");
                this.setErrors(error);
            }
        },

        async createTax(tax) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.taxesApi.post(`/api/taxes`, tax);
                if (data) {
                    const tax = data.data;
                    // this.taxes.push(new Tax(tax));
                    this.taxes.push(tax);
                    $toast.success("Impuesto creado correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al crear el impuesto.");
                this.setErrors(error);
            }
        },
        async updateTax(tax) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.taxesApi.put(`/api/taxes`, tax);
                if (data) {
                    // const tax = new Bank(data.data);
                    const tax = data.data;
                    const index = this.taxes.findIndex(b => b.id === tax.id);
                    if (index !== -1) {
                        this.taxes.splice(index, 1, tax);
                        this.taxes.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                    $toast.success("Impuesto actualizado correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al actualizar el impuesto.");
                this.setErrors(error);
            }
        }
    },
});