import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { useLoadingStore } from '@/pinia/loading.module';
import Identification from '@/Models/Identification';
import { useAuthStore } from '@/pinia/auth.module';

import * as service from '@/services';
import { useToast } from 'vue-toast-notification';
import { getLocaleMessage } from '@/services/MessagesService';
const $toast = useToast();

export const identificationsStore = defineStore('identifications', {
    persist: false,
    state: () => {
        return {
            identifications: reactive([]),
            errors: null,
        }
    },
    getters: {
        getIdentifications: state => state.identifications,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchIdentificationsById() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individualProspects/all/company/${useAuthStore().user.companyId}`);
                if (data && data.status) {
                    this.identifications = data.data.map(identification => new Identification(identification));
                } else {
                    $toast.error(getLocaleMessage('common', 'backendError'));
                }
            } catch (error) {
                this.setErrors(error);
                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        }
    }

});