export default class ProductDetail {
  constructor({
    id = 0,
    loanApplicationId = 0,
    purposeRequestedAmount = null,
    authorizedAmount = null,
    overpaymentFactor = null,
    mlpRisk = null,
    paymentDays = null,
    authorizedBy = null,
    date = null,
    position = null,
    remarks = null,
    createdAt = new Date(),
    updatedAt = new Date()
  }) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.purposeRequestedAmount = purposeRequestedAmount || null;
    this.authorizedAmount = authorizedAmount || null;
    this.overpaymentFactor = overpaymentFactor || null;
    this.mlpRisk = mlpRisk || null;
    this.paymentDays = paymentDays || null;
    this.authorizedBy = authorizedBy || null;
    this.date = date || null;
    this.position = position || null;
    this.remarks = remarks || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
