export default class GeneralInformation {
    constructor({
        generalInfoId = 0,
        isBusinessActivity = null,
        rfcWithoutBusinessActivity = null,
        firstName = null,
        lastName = null,
        mothersMaidenName = null,
        dateOfBirth = null,
        birthState = null,
        birthCountry = null,
        nationality = null,
        gender = null,
        maritalStatus = null,
        maritalRegime = null,
        curp = null,
        identificationType = null,
        issuingAuthority = null,
        identificationNumber = null,
        businessName = null,
        rfc = null,
        homePhone = null,
        officePhone = null,
        cellPhone = null,
        email = null,
        dateOfIncorporation = null,
        notaryInformation = null,
        businessPurpose = null,
        mainActivity = null,
        country = null,
        fielFolio = null,
        numberOfEmployees = null,
    } = {}) {
        this.generalInfoId = generalInfoId;
        this.isBusinessActivity = isBusinessActivity;
        this.rfcWithoutBusinessActivity = rfcWithoutBusinessActivity;
        this.firstName = firstName;
        this.lastName = lastName;
        this.mothersMaidenName = mothersMaidenName;
        this.dateOfBirth = dateOfBirth;
        this.birthState = birthState;
        this.birthCountry = birthCountry;
        this.nationality = nationality;
        this.gender = gender;
        this.maritalStatus = maritalStatus;
        this.maritalRegime = maritalRegime;
        this.curp = curp;
        this.identificationType = identificationType;
        this.issuingAuthority = issuingAuthority;
        this.identificationNumber = identificationNumber;
        this.businessName = businessName;
        this.rfc = rfc;
        this.homePhone = homePhone;
        this.officePhone = officePhone;
        this.cellPhone = cellPhone;
        this.email = email;
        this.dateOfIncorporation = dateOfIncorporation;
        this.notaryInformation = notaryInformation;
        this.businessPurpose = businessPurpose;
        this.mainActivity = mainActivity;
        this.country = country;
        this.fielFolio = fielFolio;
        this.numberOfEmployees = numberOfEmployees;
    }
}
