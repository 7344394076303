export default class Product {
    constructor({
        id = 0,
        companyId = 1,
        branchOfficeId = 0,
        productTypeId = 0,
        rateTypeId = 0,
        referenceRateId = 0,
        penaltyChargeRateId = 0,
        penaltyChargeId = 0,
        guaranteeId = 0,
        loanTermId = 0,
        currencyTypeId = 0,
        taxId = 0,
        statusId = 0,
        referenceNumber = null,
        numberOfTerms = null,
        maxAmount = null,
        description = null,
        branchOffice = null,
        productType = null,
        rateType = null,
        referenceRate = null,
        penaltyChargeRate = null,
        penaltyCharge = null,
        guarantee = null,
        loanTerm = null,
        currencyType = null,
        tax = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date()
    } = {}) {
        this.id = id;
        this.companyId = companyId;
        this.branchOfficeId = branchOfficeId;
        this.productTypeId = productTypeId;
        this.rateTypeId = rateTypeId;
        this.referenceRateId = referenceRateId;
        this.penaltyChargeRateId = penaltyChargeRateId;
        this.penaltyChargeId = penaltyChargeId;
        this.guaranteeId = guaranteeId;
        this.loanTermId = loanTermId;
        this.currencyTypeId = currencyTypeId;
        this.taxId = taxId;
        this.statusId = statusId;
        this.referenceNumber = referenceNumber;
        this.numberOfTerms = numberOfTerms;
        this.maxAmount = maxAmount;
        this.description = description;
        this.branchOffice = branchOffice;
        this.productType = productType;
        this.rateType = rateType;
        this.referenceRate = referenceRate;
        this.penaltyChargeRate = penaltyChargeRate;
        this.penaltyCharge = penaltyCharge;
        this.guarantee = guarantee;
        this.loanTerm = loanTerm;
        this.currencyType = currencyType;
        this.tax = tax;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}