<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th @click="sortBy('referenceNumber')">
                    Folio de Prospectos
                    <span v-if="sortKey === 'referenceNumber'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'referenceNumber'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('branchOfficeName')">
                    Matriz y/o Sucursales
                    <span v-if="sortKey === 'branchOfficeName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'branchOfficeName'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('personTypeName')">
                    Tipo de Persona
                    <span v-if="sortKey === 'personTypeName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'personTypeName'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('indetificationName')">
                    Nombre(s)
                    <span v-if="sortKey === 'indetificationName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'indetificationName'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('generalInformationEmail')">
                    Correo electrónico
                    <span v-if="sortKey === 'generalInformationEmail'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'generalInformationEmail'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('indentificationPhone')">
                    Teléfono
                    <span v-if="sortKey === 'indentificationPhone'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'indentificationPhone'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('indentificationPostalCode')">
                    Código Postal
                    <span v-if="sortKey === 'indentificationPostalCode'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'indentificationPostalCode'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('statusName')">
                    Estatus
                    <span v-if="sortKey === 'statusName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else="sortKey !== 'statusName'">
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in sortedData" :key="item.id" v-if="sortedData.length != 0">
                <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                <td>{{ item.branchOfficeName }}</td>
                <td>{{ item.personTypeName }}</td>
                <td>{{ item.indetificationName }}</td>
                <td>{{ item.generalInformationEmail }}</td>
                <td>{{ item.indentificationPhone }}</td>
                <td>{{ item.indentificationPostalCode }}</td>
                <td>{{ item.statusName }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-lg"  @click.prevent="editItem(item)"><i
                            class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="7" style="text-align: center; width: 100%;">{{ $getLocaleMessage('common', 'noDataFound')
                    }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
            sortKey: '',
            sortOrder: 1, // 1 for ascending, -1 for descending
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        sortedData() {
            let sortedList = [...this.list];
            if (this.sortKey) {
                sortedList.sort((a, b) => {
                    let aValue = this.getNestedValue(a, this.sortKey);
                    let bValue = this.getNestedValue(b, this.sortKey);

                    if (aValue < bValue) return -1 * this.sortOrder;
                    if (aValue > bValue) return 1 * this.sortOrder;
                    return 0;
                });
            }
            return this.paginate(sortedList);
        }
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1; // Toggle the sort order
            } else {
                this.sortOrder = 1; // Reset to ascending if a new column is sorted
            }
            this.sortKey = key;
        },
        getNestedValue(obj, key) {
            return key.split('.').reduce((o, i) => o[i], obj);
        },
        paginate(list) {
            if (typeof list === "undefined" || list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return list.slice(start, end);
        },
        editItem(item) {
            this.$emit('click-on-edit-btn', item);
        },
    },
    created() { }
}
</script>