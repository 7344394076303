export default class Answer {
  constructor({
    id = 0, 
    interviewId = 0, 
    questionId = 0, 
    answerTypeId = 0, 
    dataLabel = '', 
    dataValue = '', 
    itemOrder = null, 
    createdAt = new Date(), 
    updatedAt = new Date()
} = {}) {
    this.id = id;
    this.interviewId = interviewId;
    this.questionId = questionId;
    this.answerTypeId = answerTypeId;
    this.dataLabel = dataLabel;
    this.dataValue = dataValue;
    this.itemOrder = itemOrder;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
