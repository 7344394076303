<template>
  <Form ref="form" @submit="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">{{ formTitle }} documentación digitalizada</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Información</h3>
        </div>
        <div class="row card-body">
          <div class="form-group col-sm-12">
            <label for="branchOfficeName">Matriz y/o Sucursales:</label>
            <Field v-model="branchOfficeName" type="text" class="form-control" name="branchOfficeName" disabled />
          </div>

          <div class="form-group col-sm-12">
            <label for="ProspectFullname">Prospecto:</label>
            <Field v-model="prospectusFullName" type="text" class="form-control" name="ProspectFullname" disabled />
          </div>

          <div class="form-group col-sm-12">
            <label for="loanApplicationReferenceNumber">Solicitud:</label>
            <Field v-model="loanApplicationReferenceNumber" type="text" class="form-control" name="loanApplicationReferenceNumber" disabled />
          </div>

          <div class="form-group col-sm-12">
            <label for="recordTypeName">Tipos de expendiente:</label>
            <Field v-model="recordTypeName" type="text" class="form-control" name="recordTypeName" disabled />
          </div>
          <div class="form-group col-sm-6">
            <label for="financialRecordReferenceNumber">Folio de Expediente:</label>
            <Field v-model="financialRecordReferenceNumber" type="text" class="form-control" name="financialRecordReferenceNumber" disabled />
          </div>
          <div class="form-group col-sm-6">
            <label for="financialRecordName">Nombre de Expediente:</label>
            <Field v-model="financialRecordName" type="text" class="form-control" name="financialRecordName" disabled />
          </div>
          <div class="form-group col-sm-12">
            <Field name="completed" v-slot="{ field }">
                <label>
                    <input type="checkbox" name="completed" v-bind="field" v-model="isCompleted"/>
                    Marcar este Checklist como completado
                </label>
            </Field>
          </div>
        </div>
      </div>

      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Documentación</h3>
        </div>
        <div class="card-body">
          <div class="row" v-if="getActiveChecklists">
            <div class="info-box col-12 col-sm-12" v-for="(element, idx) in getActiveChecklists" :key="idx">
              <div class="form-group col-sm-6" v-if="element.file">
                <label>Descargar archivo:</label>
                <p><a :href="element.file.filePath" target="_blank">{{ element.file.name
                    }}</a></p>
              </div>
              <div class="form-group" :class="[element.file ? 'col-sm-6' : 'col-sm-12']">
                <label :for="'exampleInputFile'+idx">{{ element.document.documentName }}{{ (element.remarks) ? ` (${element.remarks})` : '' }}:</label>
                <div class="input-group">
                  <Field :name="'file-' + idx" type="file" :accept="allowedFileTypes" :id="'exampleInputFile' + idx" ref="fileInputs" :rules="validateDocument(element)" @change="handleFileChange($event,idx, element)"></Field>
                  <ErrorMessage :name="'file-' + idx" class="input-error"></ErrorMessage>
                  <!-- <label>
                    <input type="file" ref="fileInputs" class="" :id="'exampleInputFile' + idx"
                      accept=".jpg, .jpeg, .png, .gif, .pdf" @change="handleFileChange(idx)" />
                    <span class="file-custom"></span>
                  </label> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        {{ cancelButtonText }}
      </button>
      <PrimaryButton class="btn btn-primary" type="submit">
        {{ updateButtonText }}
      </PrimaryButton>
    </div>
  </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from "pinia";
import { financialRecordsStore } from "@/pinia/financialRecords.module";
import { checklistsStore } from '@/pinia/checklists.module';
import { useFlowStatusStore } from '@/pinia/flowStatusStore.module';

import BranchOfficesSelect from "@/Components/Selects/SelectField.vue";
import RecordTypesSelect from "@/Components/Selects/SelectField.vue";
import StatusSelect from "@/Components/Selects/SelectField.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";

import FinancialRecord from '@/Models/FinancialRecord';
import RecordsProspects from '@/Models/RecordsProspects';
import RecordFileForm from '@/Models/RecordFileForm';

export default {
  components: {
    PrimaryButton,
    StatusSelect,
    BranchOfficesSelect,
    RecordTypesSelect,
  },
  mixins: [],
  data() {
    return {
      dataValues: {},
      recordProspectus: new RecordsProspects({}),
      recordFileFormDto: new RecordFileForm({}),
      selectedBranchOfficeId: null,
      selectedRecordTypeId: null,
      selectedFinancialRecord: new FinancialRecord({}),
      files: {},
      documentIds: [],
      fileInputs: [],
      isCompleted: false,
      recordExists: false,
      allowedFileTypes: ".jpg, .jpeg, .png, .gif, .pdf",
      branchOfficeName: '',
      prospectusFullName: '',
      loanApplicationReferenceNumber: '',
      recordTypeName: '',
      financialRecordReferenceNumber: '',
      financialRecordName: '',
    }
  },
  emits: ['close-modal'],
  props: {
    selectedItem: {
      type: Object,
      default: () => new RecordsProspects({}),
    },
    getProspectusFullname: {
      type: Function,
      default: () => '',
    }
  },
  computed: {
    ...mapState(checklistsStore, ['getActiveChecklists']),
    ...mapState(useFlowStatusStore, ['getFlowStatusByRole']),
    formTitle() {
      return this.$getLocaleMessage('formTitles', 'update');
    },
    cancelButtonText() {
      return this.$getLocaleMessage('buttons', 'cancel');
    },
    updateButtonText() {
      return this.$getLocaleMessage('buttons', 'update');
    },
  },
  methods: {
    ...mapActions(financialRecordsStore, ['updateDocumentFile']),
    ...mapActions(checklistsStore, ['fetchActiveChecklistsWithFilesByRecordId']),
    ...mapActions(useFlowStatusStore, ['fetchFlowStatusByLoanApplicationId']),
    onSubmit() {
      const formData = new FormData();
      // Add files to the FormData
      formData.append('documentFileDto.loanApplicationId', this.selectedItem.loanApplicationId);
      formData.append('documentFileDto.FinancialRecordId', this.selectedItem.financialRecordId);
      formData.append('documentFileDto.RecordTypeId', this.selectedItem.financialRecord.recordTypeId);
      formData.append('documentFileDto.IsCompleted', this.isCompleted);

      Object.values(this.documentIds).forEach((item, index) => {
        formData.append(`documentFileDto.DocumentIds[${index}]`, item);
      });

      Object.values(this.files).forEach((file, index) => {
        formData.append(`documentFileDto.Files`, file);
      });

      // for(var pair of formData.entries()) {
      //   console.log(pair[0]+ ', '+ pair[1]); 
      // }

      this.updateDocumentFile(formData);
      this.$refs.form.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$emit("close-modal", true);
    },
    async handleFileChange(event, index, currentElement) {
      const fileId = currentElement.documentId; // Get the Id from Documents table
      const fileInput = event.target;

      if (fileInput.files.length > 0) {
        this.documentIds.push(fileId);
        this.files[index] = fileInput.files[0];
      } else {
        // this.$delete(this.files, index);
        delete this.files[index];
      }
    },
    validateDocument(element) {
      return (element.isRequired && element.file == null) ? 'required': undefined;
    },
  },
  watch: {
    'getFlowStatusByRole': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.isCompleted = newValue;
        }
      }
    }
  },
  created() {
    const validateProperties = ["loanApplicationId", "financialRecordId", "documentId", "fileId", "loanApplication", "financialRecord"];
    if (helper.validateObjectProperties(this.selectedItem, validateProperties)) {
      this.branchOfficeName = (this.selectedItem?.loanApplication?.prospectus?.branchOffice?.id ? `${this.selectedItem.loanApplication.prospectus.branchOffice.name } ` : null);
      this.loanApplicationReferenceNumber = (this.selectedItem?.loanApplication.referenceNumber ? this.selectedItem.loanApplication.referenceNumber : null);
      this.recordTypeName = (this.selectedItem?.financialRecord?.recordType?.name ? this.selectedItem.financialRecord.recordType.name : null);
      this.financialRecordReferenceNumber = (this.selectedItem?.financialRecord?.referenceNumber ? this.selectedItem.financialRecord.referenceNumber : null);
      this.financialRecordName = (this.selectedItem?.financialRecord?.name ? this.selectedItem.financialRecord.name : null);
      this.prospectusFullName = (this.selectedItem?.loanApplication?.prospectus?.prospectusId) ? this.getProspectusFullname(this.selectedItem.loanApplication.prospectus) : null;
      this.fetchActiveChecklistsWithFilesByRecordId(this.selectedItem.financialRecordId, this.selectedItem.loanApplicationId);
      // Make a request to get isCompleted value from FlowStatus table
      this.fetchFlowStatusByLoanApplicationId(this.selectedItem.loanApplicationId);
    }
  },
  mounted() {
  },
};
</script>