export default class ProspectData {
    constructor({
        prospectus = {}, 
        generalInformation = {}, 
        fiscalAddress = {},
        legalRepresentative = {},
        representativeAddress = {},
        nationalAddress = {},
    }) 
    {
        this.prospectus = prospectus || {};
        this.generalInformation = generalInformation || {};
        this.fiscalAddress = fiscalAddress || {};
        this.legalRepresentative = legalRepresentative || {};
        this.representativeAddress = representativeAddress || {};
        this.nationalAddress = nationalAddress || {};
    }
}