<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Empresas</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Empresas</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Empresas</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                                    @click="editing = false">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Buscar Empresas..."
                                    v-model="searchString" />
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12" style="margin:auto;">
                                <CompanyTable :list="filteredBusiness" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                                    @edit-company="selectedRecord = $event; editing = true"
                                    @delete-company="deleteRow($event)">
                                </CompanyTable>

                                <Pagination :list="filteredBusiness" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                                    @current-page="currentPage = $event">
                                </Pagination>

                                <Modal>
                                    <EditCompanyForm v-if="editing" :company="getSelectedCompany"
                                        @closeModal="modalStatus"></EditCompanyForm>
                                    <AddCompanyForm v-else @closeModal="modalStatus"></AddCompanyForm>
                                </Modal>

                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import { mapState, mapActions, mapGetters } from 'pinia';
import { useCompaniesStore } from '@/pinia/companies.module';
import { useStatusStore } from '@/pinia/status.module';

import Swal from 'sweetalert2';
import '@/Components/Validators/index';

import CompanyTable from '@/Components/Tables/CompanyTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddCompanyForm from '@/Components/Forms/Companies/Add.vue';
import EditCompanyForm from '@/Components/Forms/Companies/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';


export default {
    components: {
        CompanyTable,
        Pagination,
        Modal,
        AddCompanyForm,
        EditCompanyForm,
        PrimaryButton,
    },
    mixins: [],
    data() {
        return {
            selectedRecord: {},
            itemsPerPage: 7,
            currentPage: 1,
            editing: false,
            searchString: '',
        }
    },
    watch: {},
    computed: {
        ...mapState(useCompaniesStore, ['getCompanies', 'getPaginatedCompanies']),
        companies() {
            return this.getCompanies;
        },
        getSelectedCompany() {
            return this.selectedRecord;
        },
        ...mapGetters(useCompaniesStore, [
            'filterBusinessBySearchString',
        ]),
        filteredBusiness() {
            return this.filterBusinessBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(useCompaniesStore, ['fetchCompanies', 'deleteCompany']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        deleteRow(company) {
            const alert = Swal.mixin({
                buttonsStyling: true
            });
            alert.fire({
                title: `Desea eliminar ${company.name}?`,
                icon: 'question', showCancelButton: true,
                confirmButtonText: '<i class="fa fa-solid fa-check"></i> Si, eliminar',
                cancelButtonText: '<i class="fa fa-solid fa-ban"></i> Cancelar',
            }).then(res => {
                if (res.isConfirmed) {
                    this.deleteCompany(company.idCompany);
                }
            });

        },
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
    },
    created() {
        this.fetchCompanies();
        this.fetchStatus();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.editing = false;
            this.selectedRecord = {};
        });
    }
};
</script>