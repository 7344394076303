import { configure, defineRule, Form, Field, ErrorMessage } from 'vee-validate';
import { required, email, min, max } from '@vee-validate/rules';
import { setLocale, localize } from '@vee-validate/i18n';
// import es from '@vee-validate/i18n/dist/locale/es'; // JSON with spanish locales


// setLocale('es');
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('number', value => {
    return !isNaN(value);
  });

configure({
    // Generates an English message locale generator
    generateMessage: localize('en', {
      messages: {
        required: 'El campo es obligatorio',
        email: 'El campo debe ser un correo electrónico válido',
        min: 'El campo debe tener al menos 0:{length} caracteres',
        max: 'El campo no debe ser mayor a 0:{length} caracteres',
        number: 'El campo debe ser un número',
      },
    }),
  });

export  {
    Form,
    Field,
    ErrorMessage
}