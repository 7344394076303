export default class LegalEntityGuarantee {
  constructor({
    id = 0, 
    loanApplicationId = 0, 
    typeOfGuarantee = null, 
    propertyOwnership = null, 
    marketValue = null, 
    encumbrance = null, 
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.typeOfGuarantee = typeOfGuarantee || null;
    this.propertyOwnership = propertyOwnership || null;
    this.marketValue = marketValue || null;
    this.encumbrance = encumbrance || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
