<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Producto
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="product.branchOfficeId" :name="'branchOfficeId'" :options="branchOfficeOptions" @update="setBranchOfficeState" :customValidation="productUniqueness"></BranchOfficesSelect>                
                </div>
                <div class="form-group">
                    <label for="productTypeId">Tipo de producto:</label>
                    <ProductTypesSelect v-model="product.productTypeId" :name="'productTypeId'" :options="productTypeOptions" @update="setProductTypeState" :customValidation="productTypeUniqueness"></ProductTypesSelect>                
                </div>
                <div class="form-group">
                    <label for="rateTypeId">Tipo de tasa:</label>
                    <RateTypesSelect v-model="product.rateTypeId" :name="'rateTypeId'" :options="rateTypeOptions" @update="setRateTypeState" :customValidation="productUniqueness"></RateTypesSelect>                
                </div>
                <div class="form-group">
                    <label for="referenceRateId">Tasa de referencia:</label>
                    <ReferenceRatesSelect v-model="product.referenceRateId" :name="'referenceRateId'" :options="referenceRateOptions" @update="setReferenceRateState" :customValidation="productUniqueness"></ReferenceRatesSelect>                
                </div>
                <div class="form-group">
                    <label for="penaltyChargeRateId">Tipo de tasa moratorios:</label>
                    <PenaltyChargeRatesSelect v-model="product.penaltyChargeRateId" :name="'penaltyChargeRateId'" :options="penaltyChargeRateOptions" @update="setPenaltyChargeRateState" :customValidation="productUniqueness"></PenaltyChargeRatesSelect>                
                </div>
                <div class="form-group">
                    <label for="penaltyChargeId">Moratorios:</label>
                    <PenaltyChargesSelect v-model="product.penaltyChargeId" :name="'penaltyChargeId'" :options="penaltyChargeOptions" @update="setPenaltyChargeState" :customValidation="productUniqueness"></PenaltyChargesSelect>                
                </div>
                <div class="form-group">
                    <label for="guaranteeId">Tipo de garantía:</label>
                    <GuaranteesSelect v-model="product.guaranteeId" :name="'guaranteeId'" :options="guaranteeOptions" @update="setGuaranteeState" :customValidation="productUniqueness"></GuaranteesSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="numberOfTerms">Plazo máximo:</label>
                        <Field v-model="product.numberOfTerms" type="number" class="form-control" name="numberOfTerms" placeholder="Plazo máximo" :rules="isProductNumberOfTermsValid"/>
                        <ErrorMessage name="numberOfTerms" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="loanTermId">Tipo de plazo:</label>
                    <LoanTermsSelect v-model="product.loanTermId" :name="'loanTermId'" :options="loanTermOptions" @update="setLoanTermState" :customValidation="productUniqueness"></LoanTermsSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="maxAmount">Monto máximo:</label>
                        <Field v-model="product.maxAmount" type="number" class="form-control" name="maxAmount" placeholder="Monto máximo" :rules="isProductMaxAmountValid"/>
                        <ErrorMessage name="maxAmount" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="taxId">Impuesto:</label>
                    <TaxesSelect v-model="product.taxId" :name="'taxId'" :options="taxOptions" @update="setTaxState"></TaxesSelect>                
                </div>
                <div class="form-group">
                    <label for="currencyTypeId">Moneda:</label>
                    <CurrencyTypesSelect v-model="product.currencyTypeId" :name="'currencyTypeId'" :options="currencyOptions" @update="setCurrencyTypeState"></CurrencyTypesSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="description">Descripción:</label>
                        <Field as="textarea" v-model="product.description" type="text" class="form-control" name="description"
                            placeholder="Descripción" rules="required" />
                        <ErrorMessage name="description" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="product.statusId" :name="'statusId'" :options="statusOptions" @update="setStatusState" :customValidation="productUniqueness"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useProductsStore } from '@/pinia/products.module';
import { useTaxesStore } from '@/pinia/taxes.module';
import { useCurrencyStore } from '@/pinia/currency.module';
import { useStatusStore } from '@/pinia/status.module';

import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import ProductTypesSelect from '@/Components/Selects/SelectField.vue';
import RateTypesSelect from '@/Components/Selects/SelectField.vue';
import ReferenceRatesSelect from '@/Components/Selects/SelectField.vue';
import PenaltyChargeRatesSelect from '@/Components/Selects/SelectField.vue';
import PenaltyChargesSelect from '@/Components/Selects/SelectField.vue';
import GuaranteesSelect from '@/Components/Selects/SelectField.vue';
import LoanTermsSelect from '@/Components/Selects/SelectField.vue';
import TaxesSelect from '@/Components/Selects/SelectField.vue';
import CurrencyTypesSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

import Product from '@/Models/Product';

export default {
    components: {
        BranchOfficesSelect,
        ProductTypesSelect,
        RateTypesSelect,
        ReferenceRatesSelect,
        PenaltyChargeRatesSelect,
        PenaltyChargesSelect,
        GuaranteesSelect,
        LoanTermsSelect,
        CurrencyTypesSelect,
        TaxesSelect,
        StatusSelect,
        PrimaryButton,
    },
    data() {
        return {
            product: new Product(),
        }
    },
    emits: ['close-modal', 'change-modal-content'],
    props: {},
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices', 'getSelectedActiveBranchOffice']),
        ...mapState(useProductsStore, [
            'getActiveProductTypes',
            'getSelectedActiveProductType',

            'getActiveRateTypes',
            'getSelectedActiveRateType',

            'getActiveReferenceRates',
            'getActiveReferenceRateById',
            'getSelectedActiveReferenceRate',

            'getActivePenaltyChargeRates',
            'getSelectedActivePenaltyChargeRate',

            'getActivePenaltyCharges',
            'getActivePenaltyChargeById',
            'getSelectedActivePenaltyCharge',

            'getActiveGuarantees',
            'getActiveGuaranteeById',
            'getSelectedActiveGuarantee',

            'getActiveLoanTerms',
            'getSelectedActiveLoanTerm',
        ]),
        ...mapState(useTaxesStore, ['getActiveTaxes', 'getSelectedActiveTax']),
        ...mapState(useCurrencyStore, ['getActiveCurrencies', 'getSelectedActiveCurrency']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        branchOfficeOptions() {
            return helper.mapOptions(this.getActiveBranchOffices);
        },
        productTypeOptions() {
            return helper.mapOptions(this.getActiveProductTypes);
        },
        rateTypeOptions() {
            return helper.mapOptions(this.getActiveRateTypes);
        },
        referenceRateOptions() {
            return helper.mapOptions(this.getActiveReferenceRates);
        },
        penaltyChargeRateOptions() {
            return helper.mapOptions(this.getActivePenaltyChargeRates);
        },
        penaltyChargeOptions() {
            return helper.mapOptions(this.getActivePenaltyCharges);
        },
        guaranteeOptions() {
            return helper.mapOptions(this.getActiveGuarantees);
        },
        loanTermOptions() {
            return helper.mapOptions(this.getActiveLoanTerms);
        },
        taxOptions() {
            return helper.mapOptions(this.getActiveTaxes);
        },
        currencyOptions() {
            return helper.mapOptions(this.getActiveCurrencies, 'id', 'code');
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['setSelectedActiveBranchOffice']),
        ...mapActions(useProductsStore, [
            'getFirstCreatedProductByProductType',
            'createProduct',
            'productAlreadyExists',

            'setSelectedActiveProductType',
            'isProductTypeUniqueOnCreate',

            'fetchActiveReferenceRates',
            'setSelectedActiveRateType',

            'setSelectedActiveReferenceRate',

            'setSelectedActivePenaltyChargeRate',
            'fetchActivePenaltyCharges',

            'setSelectedActivePenaltyCharge',

            'setSelectedActiveGuarantee',

            'setSelectedActiveLoanTerm',
        ]),
        ...mapActions(useTaxesStore, ['fetchActiveTaxes', 'setSelectedActiveTax']),
        ...mapActions(useCurrencyStore, ['setSelectedActiveCurrency']),
        ...mapActions(useStatusStore, ['setSelectedStatus']),
        onSubmit() {
            this.createProduct(this.product);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        setBranchOfficeState(id) {
            this.setSelectedActiveBranchOffice(id);
            if (id !== "") {
                this.fetchActiveTaxes(id);
            }
        },
        setProductTypeState(id) {
            this.setSelectedActiveProductType(id);
        },
        setRateTypeState(id) {
            this.fetchActiveReferenceRates(id);
            this.setSelectedActiveRateType(id);
        },
        setReferenceRateState(id) {
            this.setSelectedActiveReferenceRate(id);
            this.addNewReferenceRate(id);
        },
        setPenaltyChargeRateState(id) {
            this.setSelectedActivePenaltyChargeRate(id);
            this.fetchActivePenaltyCharges(id);
        },
        setPenaltyChargeState(id) {
            this.setSelectedActivePenaltyCharge(id);
            this.addNewPenaltyCharge(id);
        },
        setGuaranteeState(id) {
            this.setSelectedActiveGuarantee(id);
            this.addNewGuaranteeType(id);
        },
        setLoanTermState(id) {
            this.setSelectedActiveLoanTerm(id);
        },
        setTaxState(id) {
            this.setSelectedActiveTax(id);
        },
        setCurrencyTypeState(id) {
            this.setSelectedActiveCurrency(id);
        },
        setStatusState(id) {
            this.setSelectedStatus(id);
        },
        addNewReferenceRate(referenceRateId) {
            if (referenceRateId == null && referenceRateId == undefined) return;
            if (this.getActiveReferenceRateById(referenceRateId).name == 'Otros especificar') {
                this.$emit('change-modal-content', 3);
            }
        },
        addNewPenaltyCharge(penaltyChargeId) {
            if (penaltyChargeId == null && penaltyChargeId == undefined) return;
            if (this.getActivePenaltyChargeById(penaltyChargeId).name == 'Otro especificar') {
                this.$emit('change-modal-content', 4);
            };
        },
        addNewGuaranteeType(guaranteeId) {
            if (guaranteeId == undefined || guaranteeId == null) return;
            if (this.getActiveGuaranteeById(guaranteeId).name == 'Otro especificar') {
                this.$emit('change-modal-content', 5);
            }
        },
        // Custom validations:
        productUniqueness(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            if (this.productAlreadyExists(this.product)) {
                return 'Ya existe un Producto con este parámetro seleccionado';
            } else {
                return true;
            }

        },
        productTypeUniqueness(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Branch Office is selected
            if (this.product.branchOfficeId === 0 || this.product.branchOfficeId == "") {
                return 'Seleccione una matriz o sucursal';
            }

            if (this.productAlreadyExists(this.product)) {
                return 'Ya existe un Producto con este parámetro seleccionado';
            }

            return true;

            // If no Product Type is selected
            // if (this.product.rateTypeId !== 0 || this.product.rateTypeId != "") {
            //     return true;
            // }
        },
        rateTypeUniqueness(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Branch Office is selected
            if (this.product.branchOfficeId == 0 || this.product.branchOfficeId == "") {
                return 'Seleccione una matriz o sucursal';
            }

            // If no Product Type is selected
            if (this.product.productTypeId == 0 || this.product.productTypeId == "") {
                return 'Seleccione un tipo de producto';
            }

            // If is not unique
            return this.isProductTypeUniqueOnCreate(this.product.branchOfficeId, this.product.productTypeId, value).then(status => {
                if (status === false) {
                    return 'Ya existe un Producto con el Tipo de Producto y el Tipo de Tasa seleccionado';
                }
                // If all is good
                return true;
            });

        },
        isProductMaxAmountValid(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Product Type is selected
            if (this.product.productTypeId == 0 || this.product.productTypeId == "") {
                return 'Seleccione un tipo de producto';
            }

            const product = this.getFirstCreatedProductByProductType(this.product.productTypeId);
            if (product.maxAmount < value) {
                return `El monto máximo no puede exceder el monto máximo del primer producto creado de tipo ${product.productType.name}: ${this.$filters.formatNumber(product.maxAmount)}`;
            } else {
                return true;
            }

        },
        isProductNumberOfTermsValid(value) {
            // If the field is empty
            if (!value) {
                return 'El campo es obligatorio';
            }

            // If no Product Type is selected
            if (this.product.productTypeId == 0 || this.product.productTypeId == "") {
                return 'Seleccione un tipo de producto';
            }

            const product = this.getFirstCreatedProductByProductType(this.product.productTypeId);
            if (product.numberOfTerms < value) {
                return `El plazo máximo no puede exceder el plazo máximo del primer producto creado de tipo ${product.productType.name}: ${product.numberOfTerms}`;
            } else {
                return true;
            }
        },
    },
    watch: {
        getSelectedActiveReferenceRate: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.product.referenceRateId = newItem.id;
                }
            },
        },
        getSelectedActivePenaltyCharge: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.product.penaltyChargeId = newItem.id;
                }
            },
        },
        getSelectedActiveGuarantee: {
            immediate: true,
            handler(newItem) {
                if (newItem) {
                    this.product.guaranteeId = newItem.id;
                }
            },
        },
    },
    created() {
        if (!helper.isEmptyObject(this.getSelectedActiveBranchOffice) && this.getSelectedActiveBranchOffice.id !== 0) {
            this.product.branchOfficeId = this.getSelectedActiveBranchOffice.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActiveProductType) && this.getSelectedActiveProductType.id !== 0) {
            this.product.productTypeId = this.getSelectedActiveProductType.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActiveRateType) && this.getSelectedActiveRateType.id !== 0) {
            this.product.rateTypeId = this.getSelectedActiveRateType.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActiveReferenceRate) && this.getSelectedActiveReferenceRate.id !== 0) {
            this.product.referenceRateId = this.getSelectedActiveReferenceRate.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActivePenaltyChargeRate) && this.getSelectedActivePenaltyChargeRate.id !== 0) {
            this.product.penaltyChargeRateId = this.getSelectedActivePenaltyChargeRate.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActivePenaltyCharge) && this.getSelectedActivePenaltyCharge.id !== 0) {
            this.product.penaltyChargeId = this.getSelectedActivePenaltyCharge.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActiveGuarantee) && this.getSelectedActiveGuarantee.id !== 0) {
            this.product.guaranteeId = this.getSelectedActiveGuarantee.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActiveLoanTerm) && this.getSelectedActiveLoanTerm.id !== 0) {
            this.product.loanTermId = this.getSelectedActiveLoanTerm.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActiveTax) && this.getSelectedActiveTax.id !== 0) {
            this.product.taxId = this.getSelectedActiveTax.id;
        }

        if (!helper.isEmptyObject(this.getSelectedActiveCurrency) && this.getSelectedActiveCurrency.id !== 0) {
            this.product.currencyTypeId = this.getSelectedActiveCurrency.id;
        }

        if (!helper.isEmptyObject(this.getSelectedStatus) && this.getSelectedStatus.id !== 0) {
            this.product.statusId = this.getSelectedStatus.id;
        }
    }
}
</script>