export default class IndividualData {
    constructor({
        prospectus = {}, 
        generalInformation = {}, 
        homeAddress = {},
        fiscalAddress = {},
        nationalAddress = {},
    }) 
    {
        this.prospectus = prospectus || {};
        this.generalInformation = generalInformation || {};
        this.homeAddress = homeAddress || {};
        this.fiscalAddress = fiscalAddress || {};
        this.nationalAddress = nationalAddress || {};
    }
}