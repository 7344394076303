export default class Tax {
    contructor({
        id = 0,
        branchOfficeId = null,
        statusId = null,
        name = null,
        description = null,
        value = null,
        remarks = null,
        branchOffice = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date()
    }) {
        this.id = id || 0;
        this.branchOfficeId = branchOfficeId || null;
        this.statusId = statusId || null;
        this.name = name || null;
        this.description = description || null;
        this.value = value || null;
        this.remarks = remarks || null;
        this.branchOffice = branchOffice || null;
        this.status = status || null;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}