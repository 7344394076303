<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>Folio de Cotizaciones</th>
                <th>Número de pago</th>
                <th>Vencimiento</th>
                <th>Saldo Inicial</th>
                <th>Capital</th>
                <th>IVA Capital</th>
                <th>Saldo final</th>
                <th>Interés</th>
                <th>Impuesto</th>
                <th>Comisión</th>
                <th>Impuesto Comisión</th>
                <th>Cuota</th>
                <th>Cuota más IVA</th>
                <th>Renta Total</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in list" :key="item.id" v-if="list != null">
                <td class="sorting_1 dtr-control"> {{ referenceNumber }}</td>
                <td>{{ item.installmentNumber }}</td>
                <td>{{ dueDateFormat(item.dueDate) }}</td>
                <td>{{ pesosFormat(item.initialCapital) }}</td>
                <td>{{ pesosFormat(item.capital) }}</td>
                <td>{{ pesosFormat(item.capitalVat) }}</td>
                <td>{{ pesosFormat(item.finalCapital) }}</td>
                <td>{{ pesosFormat(item.interest) }}</td>
                <td>{{ pesosFormat(item.tax) }}</td>
                <td>{{ pesosFormat(item.commissions) }}</td>
                <td>{{ pesosFormat(item.taxes) }}</td>
                <td>{{ pesosFormat(item.installment) }}</td>
                <td>{{ pesosFormat(item.installmentWithVat) }}</td>
                <td>{{ pesosFormat(item.totalInstallment) }}</td>
            </tr>
            <tr v-else>
                <td colspan="14" style="text-align: center;">{{ noDataFound }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr v-if="totals != null">
                <th></th>
                <th>Totales:</th>
                <th></th>
                <th></th>
                <th>{{ pesosFormat(totals.capital) }}</th>
                <th></th>
                <th></th>
                <th>{{ pesosFormat(totals.interest)}}</th>
                <th>{{ pesosFormat(totals.tax) }}</th>
                <th>{{ pesosFormat(totals.commissions) }}</th>
                <th>{{ pesosFormat(totals.taxes) }}</th>
                <th>{{ pesosFormat(totals.installment) }}</th>
                <th>{{ pesosFormat(totals.installmentWithVat) }}</th>
                <th>{{ pesosFormat(totals.totalInstallment) }}</th>
            </tr>
        </tfoot>
    </table>
</template>

<script>

export default {
    components: { },
    data() {
        return {
        }
    },
    props: {
        list: {
            type: Array,
            default: () => [],
            required: true
        },
        totals: {
            type: Object,
            default: () => {},
            requred: true,
        },
        referenceNumber: {
            type: String,
            default: () => [],
            required: true,
        },
    },
    computed: {
        noDataFound() {
            return this.$getLocaleMessage('common', 'noDataFound')
        },
    },
    methods: {
        dueDateFormat(value) {
            return this.$filters.formatDate(value, "dd 'de' MMMM 'del' yyy");
        },
        pesosFormat(value) {
            return this.$filters.formatNumber(value);
        },
    },
    created() {}
}
</script>