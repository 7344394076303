<template>
    <div class="card">
        <div class="card-header border-0">
            <div class="d-flex justify-content-between">
                <h3 class="card-title">Lista de Expedientes</h3>
                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                    @click="openCreationForm">
                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                </PrimaryButton>
            </div>
        </div>
        <div class="card-body">
            <div class="d-flex">
                <input type="text" class="form-control" placeholder="Buscar Expedientes..." v-model="searchString" />
            </div>
            <!-- /.d-flex -->

            <div class="position-relative col-sm-12">
                <FinancialRecordsTable :list="filteredRecords" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @click-on-edit-btn="openEditionForm">
                </FinancialRecordsTable>

                <Pagination :list="filteredRecords" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @current-page="currentPage = $event">
                </Pagination>

                <Modal>
                    <AddFinancialRecordForm v-if="modalContent == 1 && !editing" @closeModal="modalStatus">
                    </AddFinancialRecordForm>
                    <EditFinancialRecordForm v-if="modalContent == 2 && editing" :selected-record="selectedRecord"
                        @close-modal="modalStatus">
                    </EditFinancialRecordForm>
                </Modal>

            </div>

        </div>
    </div>
    <!-- /.card -->
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useRecordTypesStore } from "@/pinia/recordTypes.module";
import { financialRecordsStore } from '@/pinia/financialRecords.module';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';

import $ from 'jquery';
import '@/Components/Validators/index';

import FinancialRecordsTable from '@/Components/Tables/FinancialRecordsTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddFinancialRecordForm from '@/Components/Forms/FinancialRecords/Records/Add.vue';
import EditFinancialRecordForm from '@/Components/Forms/FinancialRecords/Records/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        FinancialRecordsTable,
        Pagination,
        Modal,
        AddFinancialRecordForm,
        EditFinancialRecordForm,
        PrimaryButton,
    },
    mixins: [],
    data() {
        return {
            selectedRecord: {},
            itemsPerPage: 10,
            currentPage: 1,
            modalContent: 0,
            editing: false,
            searchString: '',
        }
    },
    watch: {},
    computed: {
        ...mapState(financialRecordsStore, ['getFinancialRecords', 'getFinancialRecord', 'filterRecordsBySearchString']),
        financialRecords() {
            return this.getFinancialRecords;
        },
        getSelectedFinancialRecords() {
            return this.selectedRecord;
        },
        filteredRecords() {
            return this.filterRecordsBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(useRecordTypesStore, ['fetchActiveRecordTypesByCompany']),
        ...mapActions(financialRecordsStore, ['fetchFinancialRecords', 'setSelectedFinancialRecord']),
        ...mapActions(useBranchOfficesStore, ['fetchBranchOffices']),
        modalStatus(status) {
            if (status) {
                this.modalContent = 0
                this.editing = false;
                $('#modal-lg').modal('hide');
            }
        },
        openCreationForm() {
            this.modalContent = 1;
            this.editing = false;
        },
        openEditionForm(selectedItem) {
            this.selectedRecord = { ...selectedItem }; // Clonar profundamente el objeto reactivo
            this.modalContent = 2;
            this.editing = true;
        },
        isEmptyObject(obj) {
            if (typeof obj === "undefined" || Object.keys(obj).length === 0) return true;
            return false;
        },
    },
    created() {
        this.fetchFinancialRecords();
        this.fetchBranchOffices();
        this.fetchActiveRecordTypesByCompany();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            if (!this.isEmptyObject(this.getFinancialRecord)) {
                // Reset the selected item in the store to an empty object when finished editing
                this.setSelectedFinancialRecord();
            }
        });
    }
};
</script>