<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Prospectos</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Prospectos</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Prospectos</h3>
                                <div class="d-flex">
                                    <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-xl" @click="modalContent = 1; editing = false">
                                        {{ $getLocaleMessage('buttons', 'create') }} Persona Física
                                    </PrimaryButton>
                                    <PrimaryButton class="btn btn-info" data-toggle="modal" data-target="#modal-xl" @click="modalContent = 2; editing = false">
                                        {{ $getLocaleMessage('buttons', 'create') }} Persona Moral
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex"></div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <ProspectsTable :list="this.getAllProspects" :itemsPerPage="itemsPerPage" :currentPage="currentPage" @edit-prospect="openEditionForm"></ProspectsTable>
                                <Pagination :list="this.getAllProspects" :itemsPerPage="itemsPerPage" :currentPage="currentPage"></Pagination>

                                <Modal :modalId="'modal-xl'" :customClass="'modal-xl'">
                                    <AddPF v-if="modalContent == 1 && editing == false" @closeModal="modalStatus" @changeModalContent="modalContent = $event"></AddPF>
                                    <AddPM v-if="modalContent == 2 && editing == false" @closeModal="modalStatus" @changeModalContent="modalContent = $event"></AddPM>
                                    <EditPF v-if="modalContent === 3 && editing == true" @closeModal="modalStatus" @changeModalContent="modalContent = $event" :prospectusId="selectedRecordId"></EditPF>
                                    <EditPM v-if="modalContent === 4 && editing == true" @closeModal="modalStatus" @changeModalContent="modalContent = $event" :prospectusId="selectedRecordId"></EditPM>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import ProspectsTable from '@/Components/Tables/ProspectsTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddPF from '@/Components/Forms/Prospects/AddPF.vue';
import AddPM from '@/Components/Forms/Prospects/AddPM.vue';
import EditPF from '@/Components/Forms/Prospects/EditPF.vue';
import EditPM from '@/Components/Forms/Prospects/EditPM.vue';
export default {
    components: { PrimaryButton,ProspectsTable, Pagination, Modal, AddPF, AddPM, EditPF, EditPM },
    data() {
        return {
            editing: false,
            modalContent: 0,
            selectedRecord: {},
            selectedRecordId: 0,
            currentPage: 1,
            itemsPerPage: 10,
        }
    },
    computed: {
        ...mapState(useIndividualProspectsStore, ['getAllProspects','getProspects']),
    },
    methods: {
        ...mapActions(useIndividualProspectsStore, ['fetchMixedProspects', 'fetchIndividualProspects']),
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        modalStatus(status) {
            if (status) {
                $('#modal-xl').modal('hide');
            }
        },
        openEditionForm(selectedItem) {
            this.selectedRecordId = selectedItem.prospectusId;
            // Dependiendo del tipo de persona, se mostrará un formulario u otro
            this.modalContent = selectedItem.personTypeId === 1 ? 3 : 4;
            this.editing = true;
        },
    },
    created() {
        this.fetchMixedProspects();
        this.fetchActiveBranchOffices();
        this.fetchStatus();
    },
    mounted() {
        $('#modal-xl').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            this.selectedRecordId = 0;
        });
    }
}
</script>