import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useAuthStore } from '@/pinia/auth.module';
import { getLocaleMessage } from '@/services/MessagesService';
import * as service from '@/services';
import BureauInquiry from '@/Models/BureauInquiry';

const $toast = useToast();

export const useBureauInquiryStore = defineStore('bureauInquiry', {
    persist: false,
    state: () => {
        return {
            bureauInquiries: [],
            bureauInquiry: new BureauInquiry(),
            errors: [],
        }
    },
    getters: {
        getBureauInquiries: state => state.bureauInquiries,
        getBureauInquiery: state => state.bureauInquiry,
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        async fetchAllBureauInquiries() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.bureauInquiriesApi.get(`/api/CreditBureauInquiries/all/${useAuthStore().user.companyId}`);
                if (data && data.status) {
                    this.bureauInquiries = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchBureauInquiryById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.bureauInquiriesApi.get(`/api/CreditBureauInquiries/${id}`);
                if (data && data.status) {
                    this.bureauInquiry = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchIndividualBureauInquiryById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.bureauInquiriesApi.get(`/api/CreditBureauInquiries/individual/${id}`);
                if (data && data.status) {
                    this.bureauInquiry = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchCorporateBureauInquiryById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.bureauInquiriesApi.get(`/api/CreditBureauInquiries/corporate/${id}`);
                if (data && data.status) {
                    this.bureauInquiry = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createBureauInquiry(bureauInquiry) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.bureauInquiriesApi.post(`/api/CreditBureauInquiries/`, bureauInquiry);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('bureauInquiries', 'creatingSuccess'));
                    const bureauInquiry = new BureauInquiry(data.data);
                    this.bureauInquiries.unshift(bureauInquiry);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateBureauInquiry(bureauInquiry) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.bureauInquiriesApi.put(`/api/CreditBureauInquiries/`, bureauInquiry);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('bureauInquiries', 'editingSuccess'));
                    const updatedInquiry = new BureauInquiry(data.data);
                    const index = this.bureauInquiries.findIndex(b => b.id === updatedInquiry.id);
                    if (index !== -1) {
                        this.bureauInquiries.splice(index, 1, updatedInquiry);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
    }
});