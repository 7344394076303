import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useToast } from 'vue-toast-notification';

import { useLoadingStore } from '@/pinia/loading.module';
import { sharedStore } from '@/pinia/sharedStore.module';

import LoanApplication from '@/Models/LoanApplications/LoanApplication';

import * as service from '@/services';

const $toast = useToast();

export const useCorpLoanApplicationStore = defineStore('loanApplications', {
    persist: false,
    state: () => {
        return {
            mixedLoanApplications: computed({
                get() { return sharedStore().mixedLoanApplications },
                set(val) { sharedStore().mixedLoanApplications = val }
            }),
            loanApplications: computed({
                get() { return sharedStore().loanApplications },
                set(val) { sharedStore().loanApplications = val }
            }),
            loanApplication: computed({
                get() { return sharedStore().loanApplication },
                set(val) { sharedStore().loanApplication = val }
            }),
            corporateLoanApplication: {},
            unratedLoanApplications: computed({
                get() { return sharedStore().unratedLoanApplications},
                set(val) { sharedStore().unratedLoanApplications = val }
            }),
            errors: null,
        }
    },
    getters: {
        getMixedLoanApplications: state => state.mixedLoanApplications,
        getCorpLoanApplication: state => state.corporateLoanApplication,
        getQuestions : state => state.questions,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        async fetchCorpLoanApplicationsByProspectusId(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/corporateLoanApplications/prospectus/${id}`);
                if (data && data.status) {
                    const loanApplications = data.data;
                    this.loanApplications = loanApplications.map((la) => new LoanApplication(la));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar la solicitud.");
            }
        },
        // The getter for unrated LoanApplications is in the sharedStore module:
        async fetchUnratedCorpLoanApplicationsByProspectusId(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/corporateLoanApplications/prospectus/${id}/unrated`);
                if (data && data.status) {
                    const loanApplications = data.data;
                    this.unratedLoanApplications = loanApplications.map((la) => new LoanApplication(la));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar las solicitudes.");
            }
        },
        async fetchCorpLoanApplicationById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.get(`/api/corporateLoanApplications/${id}`);
                if (data && data.status) {
                    this.corporateLoanApplication = new LoanApplication(data.data);
                    this.loanApplication = new LoanApplication(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar la solicitud.");
            }
        },
        async createCorpLoanApplication(corporateData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.post('/api/corporateLoanApplications', corporateData);
                if (data && data.status) {
                    this.mixedLoanApplications.unshift(data.data);
                    $toast.success("Solicitud creada correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al crear la solicitud.");
            }
        },
        async updateCorpLoanApplication(corporateData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.loanApplicationsApi.put('/api/corporateLoanApplications', corporateData);
                if (data && data.status) {
                    const loanApplication = new LoanApplication(data.data);
                    const index = this.mixedLoanApplications.findIndex(c => c.id === loanApplication.id);
                    if (index !== -1) {
                        this.mixedLoanApplications.splice(index, 1, loanApplication);
                        this.mixedLoanApplications.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                    useLoadingStore().loading = false;
                    $toast.success("Solicitud actualizada correctamente.");
                }
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al actualizar la solicitud.");
            }
        },
    }
});