<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Matriz y/o Sucursales</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Matriz y/o Sucursales</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Matriz y/o Sucursales</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                                    @click="openCreationForm()">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Buscar Matriz y/o Sucursales..."
                                    v-model="searchString" />
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <BranchOfficeTable :list="filteredBranchOffices" :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage" @edit-branch="openEditionForm">
                                </BranchOfficeTable>

                                <Pagination :list="filteredBranchOffices" :itemsPerPage="itemsPerPage"
                                    :currentPage="currentPage" @current-page="currentPage = $event">
                                </Pagination>

                                <Modal>
                                    <EditBranchOfficeForm v-if="editing" :selected-record="selectedRecord"
                                        @closeModal="modalStatus"></EditBranchOfficeForm>
                                    <AddBranchOfficeForm v-else @closeModal="modalStatus">
                                    </AddBranchOfficeForm>
                                </Modal>

                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import { mapState, mapActions, mapGetters } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useLocalizationStore } from '@/pinia/localization.module';
import { useUsersStore } from '@/pinia/users.module';
import { useStatusStore } from '@/pinia/status.module';

import BranchOfficeTable from '@/Components/Tables/BranchOfficeTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddBranchOfficeForm from '@/Components/Forms/BranchOffices/Add.vue';
import EditBranchOfficeForm from '@/Components/Forms/BranchOffices/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import '@/Components/Validators/index';
import Swal from 'sweetalert2';

export default {
    components: {
        BranchOfficeTable,
        Pagination,
        Modal,
        AddBranchOfficeForm,
        EditBranchOfficeForm,
        PrimaryButton,
    },
    mixins: [],
    data() {
        return {
            selectedRecord: {},
            itemsPerPage: 7,
            currentPage: 1,
            editing: false,
            totalRecords: 0,
            searchString: '',
        }
    },
    watch: {},
    computed: {
        ...mapState(useBranchOfficesStore, ['getBranchOffices', 'getPaginatedBranchOffices']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        branches() {
            return this.getBranchOffices;
        },
        ...mapGetters(useBranchOfficesStore, [
            'filterBranchOfficeBySearchString',
        ]),
        filteredBranchOffices() {
            return this.filterBranchOfficeBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['fetchBranchOffices', 'updateBranchOffice', 'setBranchOfficesWithResponsibles', 'checkMatrixExistenceByCompany']),
        ...mapActions(useLocalizationStore, ['fetchStates']),
        ...mapActions(useUsersStore, ['fetchActiveUsersByCompany']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        openCreationForm() {
            this.editing = false;
            this.checkMatrixExistenceByCompany();
        },
        openEditionForm(selectedItem) {
            this.selectedRecord = { ...selectedItem }; // Clonar profundamente el objeto reactivo
            this.editing = true;
        },
        deleteRow(branch) {
            const alert = Swal.mixin({
                buttonsStyling: true
            });
            alert.fire({
                title: `Desea eliminar ${branch.name}?`,
                icon: 'question', showCancelButton: true,
                confirmButtonText: '<i class="fa fa-solid fa-check"></i> Si, eliminar',
                cancelButtonText: '<i class="fa fa-solid fa-ban"></i> Cancelar',
            }).then(res => {
                if (res.isConfirmed) {
                    this.updateBranchOffice(branch);
                }
            });
        },
    },
    created() {
        this.fetchBranchOffices();
        this.fetchStates();
        this.fetchActiveUsersByCompany();
        this.fetchStatus();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.editing = false;
            this.selectedRecord = {};
        });
    }
};
</script>