export default class RecordDocumentCondition {
    constructor({
        id = 0,
        recordId = 0,
        documentId = 0,
        conditionId = 0,
        statusId = 0,
        remarks = '',
        financialRecord = null,
        document = null,
        file = null,
        condition = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date()
    } = {}) {
        this.id = id;
        this.recordId = recordId;
        this.documentId = documentId;
        this.conditionId = conditionId;
        this.statusId = statusId;
        this.remarks = remarks;
        this.financialRecord = financialRecord;
        this.document = document;
        this.file = file;
        this.condition = condition;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}