<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Solicitudes</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Solicitudes</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <Card>
                        <template v-slot:header class="border-0">
                            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="custom-tabs-four-identification-tab"
                                        data-toggle="pill" href="#custom-tabs-four-identification" role="tab"
                                        aria-controls="custom-tabs-four-identification" aria-selected="true"
                                        @click="bodyContent = 1">Identificaciones</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-certificateOfAcknowledgment-tab" data-toggle="pill"
                                        href="#custom-tabs-four-certificateOfAcknowledgment" role="tab"
                                        aria-controls="custom-tabs-four-certificateOfAcknowledgment" aria-selected="false"
                                        @click="bodyContent = 2">Constancias de Beneficiarios</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-beneficiaryData-tab" data-toggle="pill"
                                        href="#custom-tabs-four-beneficiaryData" role="tab"
                                        aria-controls="custom-tabs-four-beneficiaryData" aria-selected="false"
                                        @click="bodyContent = 3">Datos de Beneficiarios</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-questionaires-tab" data-toggle="pill"
                                        href="#custom-tabs-four-questionaires" role="tab"
                                        aria-controls="custom-tabs-four-questionaires" aria-selected="false"
                                        @click="bodyContent = 4">Cuestionarios</a>
                                </li>
                            </ul>
                        </template>
                        <template v-slot:default>
                            <div class="tab-content w-100" id="custom-tabs-four-tabContent">
                                <div class="tab-pane fade active show" id="custom-tabs-four-identification"
                                    role="tabpanel" aria-labelledby="custom-tabs-four-identification-tab">
                                    <Identification v-if="bodyContent === 1"></Identification>
                                </div>
                                <div class="tab-pane fade active show" id="custom-tabs-four-certificateOfAcknowledgment"
                                    role="tabpanel" aria-labelledby="custom-tabs-four-certificateOfAcknowledgment-tab">
                                    <CertificateOfAcknowledgment v-if="bodyContent === 2"></CertificateOfAcknowledgment>
                                </div>
                                <div class="tab-pane fade active show" id="custom-tabs-four-beneficiaryData"
                                    role="tabpanel" aria-labelledby="custom-tabs-four-beneficiaryData-tab">
                                    <BeneficiaryIdentification v-if="bodyContent === 3"></BeneficiaryIdentification>
                                </div>
                                <div class="tab-pane fade active show" id="custom-tabs-four-questionaires"
                                    role="tabpanel" aria-labelledby="custom-tabs-four-questionaires-tab">
                                    <Questionnaire v-if="bodyContent === 4"></Questionnaire>
                                </div>
                            </div>
                        </template>
                    </Card>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import Prospectus from '@/Models/Prospects';
import { mapState, mapActions } from 'pinia';

import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useApplicantTypesStore } from '@/pinia/LoanApplications/applicantTypes.module.js';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';

import Card from '@/Components/Bootstrap/Card.vue';
import Tabs from '@/Components/Bootstrap/Tabs.vue';
import TabContent from '@/Components/Bootstrap/TabContent.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';

import Identification from '@/Pages/LoanApplications/PLD/Identification.vue';
import CertificateOfAcknowledgment from '@/Pages/LoanApplications/PLD/CertificatesOfAcknowledgment.vue';
import BeneficiaryIdentification from '@/Pages/LoanApplications/PLD/BeneficiaryIdentification.vue';
import Questionnaire from '@/Pages/LoanApplications/PLD/Questionnaire.vue';

export default {
    components: {
        PrimaryButton,
        Modal,
        Pagination,
        Card,
        Tabs,
        TabContent,
        Identification,
        CertificateOfAcknowledgment,
        BeneficiaryIdentification,
        Questionnaire,
    },
    data() {
        return {
            modalId: 'modal-xl',
            modalDataTarget: '#modal-xl',
            bodyContent: 1,
            selectedRecord: {},
            selectedPropectusId: null,
            selectedProspectus: new Prospectus(),
            selectedLoanApplication: {},
            personTypeId: 0,
            objectsIds: { prospectusId: 0, loanApplicationId: 0 },
            currentPage: 1,
            itemsPerPage: 10,
            signatureLabe1: "Nombre del cliente o usuario:",
            signatureLabe2: "Nombre del apoderado o representante del cliente o usuario:",
            cardClasses: {
                header: 'border-0'
            },
            tabs: [
                { name: 'Fondeadoras', slot: 'funders' },
                { name: 'Cuentas', slot: 'accounts' },
            ],
            activeTab: 0,
        }
    },
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        ...mapState(useFinancialFundersStore, ['getFinancialFunders']),
        ...mapState(useApplicantTypesStore, ['getApplicantTypes']),
        ...mapState(useIndividualProspectsStore, ['getAllProspects']),
        createIdentificationFormTitle() {
            if (this.personTypeId === 1) {
                return this.$getLocaleMessage('formTitles', 'create') + ' ' + "Datos de Identificación - Anexo 3";
            } else if (this.personTypeId === 2) {
                return this.$getLocaleMessage('formTitles', 'create') + ' ' + "Datos de Identificación - Anexo 4";
            } else {
                return "";
            }
        },
        createBeneficiaryFormTitle() {
            return this.$getLocaleMessage('formTitles', 'create') + ' ' + "Datos de Identificación - Beneficiario";
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        ...mapActions(useFinancialFundersStore, ['fetchActiveFinancialFundersByCompany']),
        ...mapActions(useApplicantTypesStore, ['fetchApplicantTypes']),
        handleTabSelected(index) {
            this.activeTab = index;
            this.$refs.tabContent.updateActiveTab(index);
        },
    },
    created() {
    },
    mounted() {
    }
}
</script>