<template>
    <Form ref="corporate" @submit="handleSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ formTitle }}
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <!-- I. DATOS GENERALES -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">I. DATOS GENERALES</h3>
                </template>
                <FieldGroup v-model="generalInformation.businessName" name="businessName" colClass="col-sm-4"
                    label="Denominación o Razón Social:" placeholder="Denominación o Razón Social" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="selectedDateOfIncorporation" name="dateOfIncorporation" colClass="col-sm-4"
                    label="Fecha de Constitución:" placeholder="Fecha de Constitución" type="date" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.nationality" name="nationality" colClass="col-sm-4"
                    label="País de Nacionalidad:" placeholder="País de Nacionalidad" rules="required|max:255">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.rfc" name="rfc" colClass="col-sm-4" label="R.F.C:"
                    placeholder="R.F.C" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.mainActivity" name="mainActivity" colClass="col-sm-8"
                    label="Actividad, Giro Mercantil, Actividad u Objeto Social:"
                    placeholder="Actividad, Giro Mercantil, Actividad u Objeto Social" :disabled="notBeneficiary">
                </FieldGroup>
            </Card>

            <!-- II. DOMICILIO -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">II. DOMICILIO</h3>
                </template>
                <FieldGroup v-model="fiscalAddress.street" name="street" colClass="col-sm-6" label="Calle / Avenida:"
                    placeholder="Calle / Avenida" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.exteriorNumber" name="exteriorNumber" colClass="col-sm-2"
                    label="No. Ext:" placeholder="No. Ext" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.interiorNumber" name="interiorNumber" colClass="col-sm-2"
                    label="No. Int:" placeholder="No. Int" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.district" name="district" colClass="col-sm-6"
                    label="Colonia o Urbanización:" placeholder="Colonia o Urbanización" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.municipality" name="municipality" colClass="col-sm-6"
                    label="Delegación / Municipio:" placeholder="Delegación / Municipio" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.city" name="city" colClass="col-sm-6" label="Ciudad o Población:"
                    placeholder="Ciudad o Población" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.state" name="state" colClass="col-sm-6" label="Entidad Federativa:"
                    placeholder="Entidad Federativa" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.postalCode" name="postalCode" colClass="col-sm-6"
                    label="Código Postal:" placeholder="Código Postal" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.country" name="country" colClass="col-sm-6" label="País:"
                    placeholder="País" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="fiscalAddress.references" name="references" colClass="col-sm-12"
                    label="Referencias de Ubicación del Domicilio:" placeholder="Referencias de Ubicación del Domicilio"
                    :disabled="notBeneficiary">
                </FieldGroup>
            </Card>

            <!-- III. DATOS DE LOCALIZACIÓN -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">III. DATOS DE LOCALIZACIÓN</h3>
                </template>
                <FieldGroup v-model="generalInformation.officePhone" name="officePhone" colClass="col-sm-4"
                    label="Teléfono Oficina:" placeholder="Teléfono Oficina" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.cellPhone" name="cellPhone" colClass="col-sm-4"
                    label="Teléfono Celular:" placeholder="Teléfono Celular" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.email" name="email" colClass="col-sm-4"
                    label="Correo Electrónico:" placeholder="Correo Electrónico" :disabled="notBeneficiary">
                </FieldGroup>
            </Card>

            <!-- IV. REPRESENTANTE LEGAL O PERSONA QUE REALICE EL ACTO U OPERACIÓN A NOMBRE DE LA PERSONA MORAL -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">IV. REPRESENTANTE LEGAL O PERSONA QUE REALICE EL ACTO U OPERACIÓN A NOMBRE DE
                        LA PERSONA MORAL</h3>
                </template>
                <FieldGroup v-model="legalRepresentative.lastName" name="lastName" colClass="col-sm-4"
                    label="Apellido Paterno:" placeholder="Apellido Paterno" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.mothersMaidenName" name="mothersMaidenName" colClass="col-sm-4"
                    label="Apellido Materno:" placeholder="Apellido Materno" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.firstName" name="firstName" colClass="col-sm-4"
                    label="Nombre(s):" placeholder="Nombre(s)" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="selectedDateOfBirth" name="dateOfBirth" colClass="col-sm-4"
                    label="Fecha de Nacimiento:" placeholder="Fecha de Nacimiento" type="date" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.rfc" name="rfc2" colClass="col-sm-4" label="R.F.C:"
                    placeholder="R.F.C" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.curp" name="curp2" colClass="col-sm-4" label="C.U.R.P:"
                    placeholder="C.U.R.P" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.nationality" name="nationality2" colClass="col-sm-4"
                    label="Nacionalidad:" placeholder="Nacionalidad" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.countryOfResidence" name="countryOfResidence" colClass="col-sm-4"
                    label="País de Residencia:" placeholder="País de Residencia" rules="required|max:255">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.officePhone" name="officePhone2" colClass="col-sm-4"
                    label="Teléfono:" placeholder="Teléfono" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.email" name="email2" colClass="col-sm-4"
                    label="Correo Electrónico:" placeholder="Correo Electrónico" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.identificationType" name="identificationType"
                    colClass="col-sm-4" label="Nombre de la Identificación:" placeholder="Nombre de la Identificación"
                    :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.issuingAuthority" name="issuingAuthority" colClass="col-sm-4"
                    label="Autoridad que la Emite:" placeholder="Autoridad que la Emite" rules="required|max:255">
                </FieldGroup>
                <FieldGroup v-model="legalRepresentative.identificationNumber" name="identificationNumber"
                    colClass="col-sm-4" label="No. de Identificación:" placeholder="No. de Identificación"
                    :disabled="notBeneficiary">
                </FieldGroup>
            </Card>

            <!-- BeneficiaryStatus -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">Estatus</h3>
                </template>
                <!-- Select para escoger el estatus del beneficiario -->
                <SelectField v-model="selectedStatus" name="'status'" :options="statusOptions" :isDisabled="notBeneficiary"></SelectField>
             </Card>

            <!-- Nombre y Firma -->
            <div class="row">
                <FieldGroup v-model="legalRepresentativeFullName" name="firstName2" colClass="col-sm-12"
                    label="Nombre del Apoderado o Representante del Beneficiario Controlador:" placeholder="Nombre Completo"
                    rules="required|max:255" :disabled="notBeneficiary">
                </FieldGroup>
            </div>

        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ submitButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';

import Card from '@/Components/Bootstrap/Card.vue';
import FieldGroup from '@/Components/Fields/FieldGroup.vue';
import SelectField from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

import GeneralInformation from '@/Models/GeneralInformation';
import FiscalAddress from '@/Models/FiscalAddress';
import LegalRepresentative from '@/Models/LegalRepresentative';

export default {
    components: {
        Card,
        FieldGroup,
        SelectField,
        PrimaryButton
    },
    props: {
        initialData: {
            type: Object,
            required: false,
            default: () => null,
        },
        fetchById: {
            type: Function,
            required: false,
            default: () => null,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        formTitle: {
            type: String,
            required: true,
        },
        submitButtonText: {
            type: String,
            required: true,
        },
        notBeneficiary: {
            type: Boolean,
            required: true,
        },
        statusValues: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            formData: null,
            cardClass: 'card-primary',
            selectedStatus: 0,
            fullNameObj: {firstName: '', lastName: '', mothersMaidenName: ''},
            generalInformation: new GeneralInformation(),
            fiscalAddress: new FiscalAddress(),
            legalRepresentative: new LegalRepresentative(),
        }
    },
    computed: {
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel')
        },
        selectedDateOfIncorporation: {
            get() {
                return this.$filters.formatDate(this.generalInformation.dateOfIncorporation, 'yyyy-MM-dd');
            },
            set(value) {
                this.generalInformation.dateOfIncorporation = value;
            },
        },
        selectedDateOfBirth: {
            get() {
                return this.$filters.formatDate(this.legalRepresentative.dateOfBirth, 'yyyy-MM-dd');
            },
            set(value) {
                this.legalRepresentative.dateOfBirth = value;
            }
        },
        legalRepresentativeFullName() {
            return this.fullNameObj.firstName + ' ' + this.fullNameObj.lastName + ' ' + this.fullNameObj.mothersMaidenName;
        },
        statusOptions() {
            return helper.mapOptions(this.statusValues);
        },
    },
    methods: {
        handleSubmit() {
            this.formData = {
                statusId: this.selectedStatus,
                generalInformation: this.generalInformation,
                fiscalAddress: this.fiscalAddress,
                legalRepresentative: this.legalRepresentative,
            }
            this.onSubmit(this.formData);
            this.$refs.corporate.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
    },
    watch: {
        initialData: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.referenceNumber != null && newValue.ReferenceNumber != "") {
                    this.selectedStatus = newValue.statusId;
                    this.generalInformation = newValue.generalInformation;
                    this.fiscalAddress = newValue.fiscalAddress;
                    this.legalRepresentative = newValue.legalRepresentative;
                }
            }
        },
        'legalRepresentative.firstName': {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.fullNameObj.firstName = this.legalRepresentative.firstName;
                }
            }
        },
        'legalRepresentative.lastName': {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.fullNameObj.lastName = this.legalRepresentative.lastName;
                }
            }
        },
        'legalRepresentative.mothersMaidenName': {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.fullNameObj.mothersMaidenName = this.legalRepresentative.mothersMaidenName;
                }
            }
        },
    },
    created() {
        if (this.fetchById() != null) {
            this.fetchById();
        }
    },
}
</script>