import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useBeneficiaryStore } from './BeneficiaryStore.module';
import { useLoadingStore } from '@/pinia/loading.module';
import { getLocaleMessage } from '@/services/MessagesService';
import * as service from '@/services';
import Beneficiary from '@/Models/Beneficiaries/Beneficiary';

const $toast = useToast();

export const useIndividualBeneficiaryStore = defineStore('individualBeneficiary', {
    persist: false,
    state: () => {
        return {
            // Heredar los estados de BeneficiaryStore.module.js
            // Éste enfoque no es recomendable por su alto acoplamiento
            // ...useBeneficiaryStore().$state,
        }
    },
    getters: {
        getBeneficiary: state => state.beneficiary,
    },
    actions: {
        setErrors(errors) {
            const baseStore = useBeneficiaryStore();
            baseStore.errors.push(errors); // Push into the errors array of the parent store
        },
        async fetchBeneficiaryById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individual-beneficiaries/${id}`);
                if (data && data.status) {
                    this.beneficiary = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createBeneficiary(beneficiary) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.post(`/api/individual-beneficiaries`, beneficiary);
                if(data && data.status) {
                    $toast.success(getLocaleMessage('beneficiaries', 'creatingSuccess'));
                    const beneficiary = new Beneficiary(data.data);
                    // En vez de usar la herencia, accedemos directamente al store base y sus estados:
                    const baseStore = useBeneficiaryStore();
                    baseStore.beneficiaries.unshift(beneficiary);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateBeneficiary(beneficiary) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.put(`/api/individual-beneficiaries`, beneficiary);
                if(data && data.status) {
                    $toast.success(getLocaleMessage('beneficiaries', 'editingSuccess'));
                    const beneficiary = new Beneficiary(data.data);
                    const baseStore = useBeneficiaryStore();
                    baseStore.updateBeneficiaryList(beneficiary);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        }
    }
});