export default class ApplicantType {
  constructor({
    id = 0, 
    name = null, 
    createdAt = new Date(), 
    updatedAt = new Date()
  })
    {
    this.id = id || 0;
    this.name = name || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
