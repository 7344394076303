<template>
    <Form @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('buttons', 'updating') }} {{ selectedRole.name }}
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre del Rol:</label>
                    <Field v-model="selectedRole.name" type="text" class="form-control" name="name" placeholder="Nombre"
                        rules="required" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'update') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import { mapActions } from 'pinia';
import { useRolesStore } from '@/pinia/roles.module';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton},
    mixins: [],
    data() {
        return {
            selectedRole: [],
        }
    },
    emits: ['new-changes'],
    props: {
        role: {
            type: Object,
            default: () => {}
        },
        editForm: false,
    },
    computed: {},
    methods: {
        ...mapActions(useRolesStore, ['updateRole', 'updatePaginatedRole']),
        onSubmit() {
            this.updateRole(this.selectedRole);
            this.updatePaginatedRole(this.selectedRole);
            this.emitUpdatedRecord();
            this.closeModal();
        },
        emitUpdatedRecord() {
            this.$emit('new-changes', this.selectedRole)
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
    },
    watch: {
        role: function() {
            this.selectedRole = this.role;
        }
    },
    created() {},
    mounted() {
        this.selectedRole = this.role;
    }
};
</script>