<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Impuestos</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Impuestos</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Impuestos</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                                    @click="openCreationForm">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Buscar Impuestos..."
                                    v-model="searchString" />
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12 mb-4" style="margin:auto;">
                                <TaxesTable :list="filteredTaxes" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                                    @editTax="openEditionForm"></TaxesTable>
                                <Pagination :list="filteredTaxes" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                                    @currentPage="currentPage = $event"></Pagination>

                                <Modal>
                                    <AddTaxForm v-if="modalContent == 1 && editing == false" @close-modal="modalStatus">
                                    </AddTaxForm>
                                    <EditTaxForm v-else-if="modalContent == 2 && editing == true"
                                        :selected-record="selectedRecord" @close-modal="modalStatus"></EditTaxForm>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useTaxesStore } from '@/pinia/taxes.module';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import TaxesTable from '@/Components/Tables/TaxesTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddTaxForm from '@/Components/Forms/Taxes/Add.vue';
import EditTaxForm from '@/Components/Forms/Taxes/Edit.vue';

export default {
    components: {
        PrimaryButton,
        TaxesTable,
        Pagination,
        Modal,
        AddTaxForm,
        EditTaxForm,
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 10,
            modalContent: 0,
            editing: false,
            selectedRecord: {},
            searchString: '',
        }
    },
    computed: {
        ...mapState(useTaxesStore, ['getTaxes', 'getTaxById', 'filterTaxesBySearchString']),
        filteredTaxes() {
            return this.filterTaxesBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(useTaxesStore, ['fetchTaxes', 'fetchTaxById', 'setSelectedTax']),
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        modalStatus() {
            $('#modal-lg').modal('hide');
        },
        openCreationForm() {
            this.modalContent = 1;
            this.editing = false;
        },
        openEditionForm(selectedItem) {
            this.selectedRecord = { ...selectedItem }; // Clonar profundamente el objeto reactivo
            this.modalContent = 2;
            this.editing = true;
        },
    },
    created() {
        this.fetchTaxes();
        this.fetchActiveBranchOffices();
        this.fetchStatus();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            if (!helper.isEmptyObject(this.getSelectedHoliday)) {
                // Reset the selected item in the store to an empty object when finished editing
                this.setSelectedTax();
            }
        });
    }
}
</script>