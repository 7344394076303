export default class QuestionnaireResponse {
    constructor({
        id = 0,
        questionnaireId = 0,
        questionId = 0,
        responseText = null,
        questionnaireFieldResponses = []
    } = {}) {
        this.id = id;
        this.questionnaireId = questionnaireId;
        this.questionId = questionId;
        this.responseText = responseText;
        this.questionnaireFieldResponses = questionnaireFieldResponses;
    }
}