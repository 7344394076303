<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Rol
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre de Rol:</label>
                    <Field v-model="form.name" type="text" class="form-control" name="name" placeholder="Nombre"
                        rules="required" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import { mapActions } from 'pinia';
import { useRolesStore } from '@/pinia/roles.module';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton},
    mixins: [],
    data: () => ({
        form: {},
    }),
    watch: {},
    computed: {
        
    },
    methods: {
        ...mapActions(useRolesStore, ['createRole', 'fetchRoles']),
        onSubmit() {
            this.createRole(this.form);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        getUpdatedRoles() {
            this.$emit('get-updated-roles', this.fetchRoles());
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
        roles(){
            this.$emit('new-roles', true);
        },
    },
    created() { },
    mounted() {
    }
};
</script>