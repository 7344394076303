import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Quotation from '@/Models/Quotation';

import * as service from '@/services';

const $toast = useToast();

export const useQuotationsStore = defineStore('quotations', {
    persist: false,
    state: () => {
        return {
            quotations: [],
            quotation: new Quotation({}),
            errors: null
        }
    },
    getters: {
        getQuotations: state => state.quotations,
        getQuotation: state => state.quotation,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        async createQuotation(quotation) {
            try {
                useLoadingStore().loading = true;
                const {  data } = await service.quotationsApi.post(`/api/Quotations`, quotation);
                if (data) {
                    const quotation = data.data;
                    this.quotation = new Quotation(quotation);
                    $toast.success("Cotización creada correctamente.");
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al generar la cotización.");
            }
        },
        async getQuotationsByProspectusId(prospectusId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.quotationsApi.get(`/api/Quotations/GetByProspectusId?prospectusId=${prospectusId}`);
                if (data) {
                    const quotations = data.data;
                    this.quotations = quotations.map(quotation => new Quotation(quotation));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al obtener las cotizaciones.");
                this.setErrors(error);
            }
        },
        async fetchQuotationById(quotationId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.quotationsApi.get(`/api/Quotations/${quotationId}`);
                if (data) {
                    this.quotation = new Quotation(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al obtener la cotización.");
                this.setErrors(error);
            }
        },
        async fetchQuotationWithProductDetailsById(quotationId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.quotationsApi.get(`/api/Quotations/withProductDetails/${quotationId}`);
                if (data) {
                    this.quotation = new Quotation(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                $toast.error("Ocurrió un error al obtener la cotización.");
                this.setErrors(error);
            }
        },
    },
});