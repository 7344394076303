<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Solicitudes</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Solicitudes</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <Card>
                        <template v-slot:header class="border-0">
                            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="custom-tabs-four-bureau-inquiry-tab"
                                        data-toggle="pill" href="#custom-tabs-four-bureau-inquiry" role="tab"
                                        aria-controls="custom-tabs-four-bureau-inquiry" aria-selected="true"
                                        @click="bodyContent = 1">Autorización de Buró</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-certificateOfAcknowledgment-tab" data-toggle="pill"
                                        href="#custom-tabs-four-certificateOfAcknowledgment" role="tab"
                                        aria-controls="custom-tabs-four-certificateOfAcknowledgment" aria-selected="false"
                                        @click="bodyContent = 2">Constancias de Beneficiarios</a>
                                </li> -->
                            </ul>
                        </template>
                        <template v-slot:default>
                            <div class="tab-content w-100" id="custom-tabs-four-tabContent">
                                <div class="tab-pane fade active show" id="custom-tabs-four-bureau-inquiry"
                                    role="tabpanel" aria-labelledby="custom-tabs-four-bureau-inquiry-tab">
                                    <BureauInquiry v-if="bodyContent === 1"></BureauInquiry>
                                </div>
                                <!-- <div class="tab-pane fade active show" id="custom-tabs-four-certificateOfAcknowledgment"
                                    role="tabpanel" aria-labelledby="custom-tabs-four-certificateOfAcknowledgment-tab">
                                    <CertificateOfAcknowledgment v-if="bodyContent === 2"></CertificateOfAcknowledgment>
                                </div> -->
                            </div>
                        </template>
                    </Card>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import Card from '@/Components/Bootstrap/Card.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';

import BureauInquiry from '@/Pages/LoanApplications/Legal/BureauInquiry.vue';

export default {
    components: {
        PrimaryButton,
        Modal,
        Card,
        BureauInquiry,
    },
    data() {
        return {
            bodyContent: 1,
        }
    },
    computed: {
    },
    methods: {
    },
    created() {
    },
    mounted() {
    }
}
</script>