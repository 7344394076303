import { useAuthStore } from '@/pinia/auth.module';

export default class Account {
  constructor({
    id = 0,
    financialFunderId = null,
    statusId = null,
    bankId = null,
    referenceNumber = null,
    accountNumber = null,
    observations = null,
    financialFunder = null,
    bank = null,
    status = null,
    createdAt = new Date(),
    updatedAt = new Date(),
    userAuth = useAuthStore(),
  } = {}) {
    this.id = id;
    this.companyId = userAuth.user.companyId;
    this.financialFunderId = financialFunderId;
    this.statusId = statusId;
    this.bankId = bankId;
    this.referenceNumber = referenceNumber;
    this.accountNumber = accountNumber;
    this.observations = observations;
    this.financialFunder = financialFunder;
    this.bank = bank;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
