import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useLoadingStore } from '@/pinia/loading.module';
import { useToast } from 'vue-toast-notification';
import { getLocaleMessage } from '@/services/MessagesService';

const $toast = useToast();

export const useHandlerStore = defineStore('handlers', {
    persist: false,
    state: () => {
        return {
            errors: reactive([]),
        }
    },
    getter: {},
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        apiError(errors) {
            this.setErrors(errors);
            $toast.error("Ocurrió un error al crear el registro.");
            $toast.info("Contacte al administrador del sistema.");
        },
        requestError(error) {
            useLoadingStore().loading = false;
            this.setErrors(error);
            $toast.error(getLocaleMessage('common', 'networkError'));
        },
    }
});