<template>
  <div class="d-flex justify-content-between">
    <h3 class="card-title">Lista de Fondeadoras</h3>
    <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg" @click="openCreationForm">
      {{ $getLocaleMessage('buttons', 'addNewRecord') }}
    </PrimaryButton>
  </div>

  <div class="card-body">
    <div class="d-flex">
      <input type="text" class="form-control" placeholder="Buscar Fondeadora..." v-model="searchString" />
    </div>
    <div class="position-relative col-sm-12" style="margin:auto;">
      <FundersTable :list="filteredFunders" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
        @editItem="openEditionForm"></FundersTable>
      <Pagination :list="filteredFunders" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
        @currentPage="currentPage = $event"></Pagination>

      <Modal>
        <AddForm v-if="modalContent === 1 && editing === false" @close-modal="modalStatus"></AddForm>
        <EditForm v-else-if="modalContent === 2 && editing === true" :selected-record="selectedRecord"
          @close-modal="modalStatus"></EditForm>
      </Modal>

    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useStatusStore } from '@/pinia/status.module';

import FundersTable from '@/Components/Tables/FinancialFundersTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddForm from '@/Components/Forms/FinancialFunders/Add.vue';
import EditForm from '@/Components/Forms/FinancialFunders/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';


export default {
  components: {
    PrimaryButton,
    FundersTable,
    Pagination,
    Modal,
    AddForm,
    EditForm,
  },
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      modalContent: 0,
      editing: false,
      selectedRecord: {},
      searchString: '',
    }
  },
  computed: {
    ...mapState(useFinancialFundersStore, ['getFinancialFunders', 'filterFundersBySearchString']),
    filteredFunders() {
      return this.filterFundersBySearchString(this.searchString);
    },
  },
  methods: {
    ...mapActions(useFinancialFundersStore, ['fetchFinancialFundersByCompany', 'fetchFinancialFunderById']),
    modalStatus() {
      $('#modal-lg').modal('hide');
    },
    openCreationForm() {
      this.modalContent = 1;
      this.editing = false;
    },
    openEditionForm(selectedItem) {
      this.selectedRecord = { ...selectedItem }; // Clonar profundamente el objeto reactivo
      // this.fetchFinancialFunderById(selectedItem.id);
      this.modalContent = 2;
      this.editing = true;
    },
  },
  created() {
    this.fetchFinancialFundersByCompany();
  },
  mounted() {
    $('#modal-lg').on('hidden.bs.modal', () => {
      this.modalContent = 0;
      this.editing = false;
    });
  },
}
</script>