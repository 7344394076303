export default class Quotation {
    constructor({
        id = 0, 
        branchOfficeId = null,
        prospectusId = null,
        productId = null,
        referenceNumber = null,
        creditAmount = null,
        disbursementDate = null,
        validityDays = null,
        quotationDate = null,
        comments = null,
        totalTerm = null,
        gracePeriod = 0,
        annualRate = null,
        annualRateWithVat = null,
        periodRate = null,
        periodRateWithVat = null,
        openingCommission = null,
        firstPaymentDay = null,
        paymentPeriodAmount = null,
        apr = null,
        aprWithVat = null,
        loanAmortization = null,
        amortizationTotal = null,
        product = null,
        createdAt = new Date(),
        updatedAt = new Date(),
    }) {
        this.id = id || 0;
        this.branchOfficeId = branchOfficeId || null;
        this.prospectusId = prospectusId || null;
        this.productId = productId || null;
        this.referenceNumber = referenceNumber || null;
        this.creditAmount = creditAmount !== undefined ? creditAmount : null;
        this.disbursementDate = disbursementDate || null;
        this.validityDays = validityDays || null;
        this.quotationDate = quotationDate || null;
        this.comments = comments || null;
        this.totalTerm = totalTerm || null;
        this.gracePeriod = gracePeriod || 0;
        this.annualRate = annualRate !== undefined ? annualRate : null;
        this.annualRateWithVat = annualRateWithVat !== undefined ? annualRateWithVat : null;
        this.periodRate = periodRate !== undefined ? periodRate : null;
        this.periodRateWithVat = periodRateWithVat !== undefined ? periodRateWithVat : null;
        this.openingCommission = openingCommission !== undefined ? openingCommission : null;
        this.firstPaymentDay = firstPaymentDay || null;
        this.paymentPeriodAmount = paymentPeriodAmount !== undefined ? paymentPeriodAmount : null;
        this.apr = apr !== undefined ? apr : null;
        this.aprWithVat = aprWithVat !== undefined ? aprWithVat : null;
        this.loanAmortization = loanAmortization || null;
        this.amortizationTotal = amortizationTotal || null;
        this.product = product || null;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}