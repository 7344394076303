export default class MenuItem {
    constructor({
        id = null,
        name = '',
        path = 0,
        friendlyName = '',
        itemOrder = 0,
        status = 0,
        createdAt = new Date(),
        updatedAt = new Date(),
    }) {
        this.id = id;
        this.name = name;
        this.path = path;
        this.friendlyName = friendlyName;
        this.itemOrder = itemOrder;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}