<template>
    <Form ref="form" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Impuesto
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="branchOfficeId">Matriz y/o Sucursales:</label>
                    <BranchOfficesSelect v-model="form.branchOfficeId" :name="'branchOfficeId'" :options="branchOfficeOptions"></BranchOfficesSelect>                
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="name">Nombre:</label>
                        <Field v-model="form.name" type="text" class="form-control" name="name" placeholder="Nombre"
                            rules="required" />
                        <ErrorMessage name="name" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="description">Descripción:</label>
                        <Field v-model="form.description" type="text" class="form-control" name="description" rules="required" />
                        <ErrorMessage name="description" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="value">Valor:</label>
                        <Field v-model="form.value" type="number" class="form-control" name="value" :rules="isNumeric" />
                        <ErrorMessage name="value" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label for="remarks">Observaciones:</label>
                        <Field as="textarea" v-model="form.remarks" type="text" class="form-control" name="remarks"
                            placeholder="Observaciones" />
                        <ErrorMessage name="remarks" class="input-error"></ErrorMessage>
                    </div>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="form.statusId" :name="'statusId'" :options="statusOptions"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useTaxesStore } from '@/pinia/taxes.module';

import BranchOfficesSelect from '@/Components/Selects/SelectField.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        PrimaryButton,
        BranchOfficesSelect,
        StatusSelect,
    },
    mixins: [],
    data: () => ({
        form: {}
    }),
    emits: ['close-modal'],
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices']),
        ...mapState(useStatusStore, ['getAllStatusByType']),
        branchOfficeOptions() {
            return helper.mapOptions(this.getActiveBranchOffices);
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['fetchBranchOffices']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        ...mapActions(useTaxesStore, ['createTax']),
        onSubmit() {
            this.createTax(this.form);
            this.$refs.form.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal');
        },
        // Custom vee-validate rules:
        isNumeric(value) {
            if(typeof(value) !== "undefined" && typeof(value) !== "number") {
                return 'El campo debe ser un número válido';
            } else if(typeof(value) !== "undefined" && typeof(value) === "number") {
                return true;
            } else {
                return 'El campo es obligatorio';
            }
        },
    },
    created() {}
}
</script>
