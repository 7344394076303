<template>
    <Form ref="penaltyChargeForm" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ $getLocaleMessage('formTitles', 'create') }} Moratorio
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre:</label>
                    <Field v-model="form.name" type="text" class="form-control" name="name"
                        placeholder="Nuevo moratorio" rules="required" />
                    <ErrorMessage name="name" class="input-error"></ErrorMessage>
                </div>
                <div class="form-group">
                    <label for="statusId">Estatus:</label>
                    <StatusSelect v-model="form.statusId" :name="'statusId'" :options="statusOptions" :isDisabled="true"></StatusSelect>                
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $getLocaleMessage('buttons', 'cancel') }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ $getLocaleMessage('buttons', 'create') }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useProductsStore } from '@/pinia/products.module';
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import StatusSelect from '@/Components/Selects/SelectField.vue';

export default {
    components: {
        PrimaryButton,
        StatusSelect
    },
    mixins: [],
    data: () => ({
        form: {
            penaltyChargeRateId: null,
            statusId: null,
            name: '',
        },
        contentNumber: 0,
    }),
    emits: [
        'change-modal-content',
    ],
    props: {
        isEdition: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        ...mapState(useProductsStore, ['penaltyCharges', 'getSelectedActivePenaltyChargeRate']),
        ...mapState(useStatusStore, ['getAllStatusByType']),
        getStatusList() {
            return this.getAllStatusByType(1);
        },
        getActiveStatus() {
            const st = this.statusOptions.find((s) => s.label === "Activo");
            return st.value;
        },
        statusOptions() {
            return helper.mapOptions(this.getAllStatusByType(1));
        },
    },
    methods: {
        ...mapActions(useProductsStore, ['createPenaltyCharge', 'setSelectedActivePenaltyCharge']),
        onSubmit() {
            this.createPenaltyCharge(this.form).then(pc => {
                this.setSelectedActivePenaltyCharge(pc.id);
                this.$refs.penaltyChargeForm.resetForm();
                this.contentNumber = (this.isEdition) ? 2 : 1;
                this.$emit('change-modal-content', this.contentNumber);
            });
        },
    },
    created() {
        this.form.penaltyChargeRateId = this.getSelectedActivePenaltyChargeRate.id;
        this.form.statusId = this.getActiveStatus;
    },
    mounted() {}
};

</script>