import { createStore } from 'vuex';
// import createPersistedState  from 'vuex-persistedstate';
import router from '@/routes/index';
import axios from "axios";
// import CompaniesModule from '@/vuex/companies.module';
// import StatusModule from '@/vuex/status.module';
// import branchOfficesModule from '@/vuex/branchOffices.module';
// import localizationModule from '@/vuex/localization.module';
// import authModule from '@/vuex/auth.module';
// import usersModule from '@/vuex/users.module';
// import rolesModule from '@/vuex/roles.module';


// Create a new store instance.
const store = createStore({
    modules: {
        // status: StatusModule,
        // company: CompaniesModule,
        // branchOffices: branchOfficesModule,
        // localization: localizationModule,
        // auth: authModule,
        // users: usersModule,
        // roles: rolesModule,
    },
    state() {
        return {
            loading: false
        }
    },
    getters: {
        getLoading: state => state.loading,
    },
    actions: {
        setLoading({ commit }, data) {
            commit('SET_LOADING', data);
        },
    },
    mutations: {
        SET_LOADING(state, data) {
            state.loading = data;
        },
    },
    plugins: [],
})

// restore(restore);

export default store