<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Documentación</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Documentación</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Documentos</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg" @click="modalContent = 1; editing = false">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <DocumentFilesTable 
                                    :list="getAllDocumentFiles" 
                                    :itemsPerPage="itemsPerPage" 
                                    :currentPage="currentPage" 
                                    @edit-item="openEditionForm"
                                >
                                </DocumentFilesTable>

                                <Pagination  
                                    :totalItems="getAllDocumentFiles.length" 
                                    :itemsPerPage="itemsPerPage" 
                                    :currentPage="currentPage" 
                                    @current-page="currentPage = $event"
                                >
                                </Pagination>

                                <Modal>
                                    <AddCreditDocumentationForm 
                                        v-if="modalContent === 1"
                                        :prospectus="selectedProspectus"
                                        :loanApplication="selectedLoanApplication"
                                        :financialRecord="selectedFinancialRecord"
                                        :temp-values="tempValues"
                                        @emit-form-data="setFormData($event)"
                                        @change-modal-content="modalContent = $event" 
                                        @closeModal="modalStatus"
                                    >
                                    </AddCreditDocumentationForm>

                                    <!-- Prospects Table -->
                                    <div v-if="modalContent === 2">
                                        <ProspectsTable
                                            :list="getAllProspects"
                                            :itemsPerPage="itemsPerPage"
                                            :currentPage="currentPage"
                                            @back-to-form="backToForm($event)"
                                            @selected-prospectus="selectedProspectus = $event;"
                                        >
                                        </ProspectsTable>
                                        <Pagination
                                            :totalItems="getAllProspects.length"
                                            :itemsPerPage="itemsPerPage"
                                            :currentPage="currentPage"
                                            @current-page="currentPage = $event"
                                        >
                                        </Pagination>
                                    </div>

                                    <!-- Loan Application Table -->
                                    <div v-if="modalContent === 3">
                                        <LoanApplicationsTable
                                            :list="getLoanApplications"
                                            :itemsPerPage="itemsPerPage"
                                            :current-page="currentPage"
                                            @selected-item="selectedLoanApplication = $event"
                                            @back-to-form="backToForm($event)"
                                        >
                                        </LoanApplicationsTable>
                                        <Pagination
                                            :totalItems="getLoanApplications.length"
                                            :itemsPerPage="itemsPerPage"
                                            :currentPage="currentPage"
                                            @current-page="currentPage = $event"
                                        >
                                        </Pagination>
                                    </div>

                                    <!-- FinancialRecords Table -->
                                    <div v-if="modalContent === 4">
                                        <FinancialRecordsMinTable 
                                            :list="getActiveFinancialRecords" 
                                            :itemsPerPage="itemsPerPage" 
                                            :currentPage="currentPage" 
                                            @back-to-form="backToForm($event)"
                                            @selected-item="selectedFinancialRecord = $event"
                                        >
                                        </FinancialRecordsMinTable>

                                        <Pagination  
                                            :totalItems="getActiveFinancialRecords.length" 
                                            :itemsPerPage="itemsPerPage" 
                                            :currentPage="currentPage" 
                                            @current-page="currentPage = $event"
                                        >
                                        </Pagination>
                                    </div>

                                    <EditCreditDocumentationForm
                                        v-if="modalContent === 5"
                                        :selectedItem="selectedRecord"
                                        :getProspectusFullname="getProspectusFullname"
                                        @change-modal-content="modalContent = $event" 
                                        @closeModal="modalStatus"
                                    >
                                    </EditCreditDocumentationForm>

                                </Modal>

                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';

import { mapState, mapActions } from 'pinia';
import { financialRecordsStore  } from '@/pinia/financialRecords.module';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useCorpLoanApplicationStore } from '@/pinia/LoanApplications/corporate.module';
import { useRecordTypesStore } from "@/pinia/recordTypes.module";
import { useIndividualProspectsStore } from "@/pinia/Prospects/individual.module";
import { useBranchOfficesStore } from "@/pinia/branchOffices.module";
import { sharedStore } from "@/pinia/sharedStore.module";
import { checklistsStore } from '@/pinia/checklists.module';

import DocumentFilesTable from '@/Components/Tables/DocumentFilesTable.vue';
import LoanApplicationsTable from '@/Components/Tables/LoanApplicationsMinTable.vue';
import FinancialRecordsMinTable from '@/Components/Tables/FinancialRecordsMinTable.vue';
import ProspectsTable from '@/Components/Tables/ProspectsMinTable.vue';
import Pagination from '@/Components/Bootstrap/Paginationv2.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddCreditDocumentationForm from '@/Components/Forms/Documents/Add.vue';
import EditCreditDocumentationForm from '@/Components/Forms/Documents/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import '@/Components/Validators/index';

import Prospectus from '@/Models/Prospects';
import LoanApplication from '@/Models/LoanApplications/LoanApplication';
import FinancialRecord from '@/Models/FinancialRecord';
import DocumentFile from '@/Models/DocumentFile';

export default {
    components: { 
        DocumentFilesTable, 
        LoanApplicationsTable,
        FinancialRecordsMinTable,
        Pagination, 
        Modal, 
        AddCreditDocumentationForm,
        EditCreditDocumentationForm,
        PrimaryButton, 
        ProspectsTable,
    },
    mixins: [],
    data() {
        return {
            selectedRecord: [],
            itemsPerPage: 10,
            currentPage: 1,
            editing: false,
            branchOfficeId: null, // This parameter will be for requesting a list of prospects by the BranchOfficeId selected in the form
            recordTypeId: null,
            statusId: null,
            modalContent: 0,
            selectedProspectus: new Prospectus(),
            selectedLoanApplication: new LoanApplication(),
            selectedFinancialRecord: new FinancialRecord(),
            documentFile: null,
            tempValues: {},
        }
    },
    computed: {
        ...mapState(financialRecordsStore, ['getActiveFinancialRecords', 'getPaginatedFinancialRecords', 'getAllDocumentFiles']),
        ...mapState(useRecordTypesStore, ['getSelectedRecordType']),
        ...mapState(useIndividualProspectsStore, ['getAllProspects']),
        ...mapState(sharedStore, ['getProspectusById', 'getProspectus', 'getLoanApplications']),
        ...mapState(useBranchOfficesStore, ['getSelectedBranchOffice']),
    },
    methods: {
        ...mapActions(financialRecordsStore, ['fetchActiveFinancialRecordsWithParams', 'deleteFinancialRecord', 'setSelectedFinancialRecord', 'fetchAllDocumentFiles']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchIndividualLoanApplicationsByProspectusId']),
        ...mapActions(useCorpLoanApplicationStore, ['fetchCorpLoanApplicationsByProspectusId']),
        ...mapActions(useRecordTypesStore, ['fetchRecordTypesByCompany', 'setSelectedRecordType']),
        ...mapActions(useIndividualProspectsStore, ['fetchActiveMixedProspectsByBranchOffice']),
        ...mapActions(sharedStore, ['setSelectedProspectus', 'fetchProspectus', 'setSelectedLoanApplication']),
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices', 'setSelectedBranchOffice']),
        ...mapActions(checklistsStore, ['unsetActiveCheckLists']),
        deleteRow(data) {
            const alert = Swal.mixin({
                buttonsStyling: true
            });
            
            alert.fire({
                title: `¿Desea eliminar registro?`,
                icon: 'question', showCancelButton: true,
                confirmButtonText: '<i class="fa fa-solid fa-check"></i> Si, eliminar',
                cancelButtonText: '<i class="fa fa-solid fa-ban"></i> Cancelar',
            }).then(res => {
                if (res.isConfirmed) {
                    this.deleteFinancialRecord(data.id);
                }
            });
        },
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        backToForm() {
            this.modalContent = this.editing ? 5 : 1;
            // Recover states to repopulate selects
        },
        setFormData(data) {
            this.tempValues = data;
            this.branchOfficeId = data.branchOfficeId;
            this.recordTypeId = data.recordTypeId;
            this.statusId = data.statusId;
            this.modalContent = data.contentNumber;
        },
        openEditionForm(rawDocumentFile) {
            this.documentFile = DocumentFile.fromRawData(rawDocumentFile);
            this.selectedRecord = {...this.documentFile}; 
            this.modalContent = 5; 
            this.editing = true;
        },
        getProspectusFullname: (currentProspectus) => {
            if (currentProspectus.prospectusId === 0 || currentProspectus.generalInformation === null) return;

            const { generalInformation } = currentProspectus;
            if (currentProspectus.personTypeId === 1) {
                return `${generalInformation.firstName} ${generalInformation.mothersMaidenName} ${generalInformation.lastName}`;
            } else {
                return `${generalInformation.businessName}`;
            }
        },
    },
    watch: {
        // Watching for changes to the 'getSelectedBranchOffice' getter in the pinia store:
        'getSelectedBranchOffice.id': {
            handler(newBranchOfficeId) {
                if (newBranchOfficeId && newBranchOfficeId > 0) {
                    // Reset prospectus to make sure the user selects a new one
                    if (this.getProspectus.id !== 0) {
                        this.setSelectedProspectus(0);
                    }
                    // Make a new requests
                    this.fetchActiveMixedProspectsByBranchOffice(newBranchOfficeId);
                    if (this.getSelectedRecordType.id !== 0) {
                        this.fetchActiveFinancialRecordsWithParams(newBranchOfficeId, this.getSelectedRecordType.id);
                    }
                }
            },
            immediate: true, // Llama al handler inmediatamente para manejar el caso donde los documentos ya están disponibles durante la creación del componente
        },
        'getSelectedRecordType.id': {
            handler(newRecordTypeId) {
                if (newRecordTypeId && newRecordTypeId > 0 && this.getSelectedBranchOffice.id !== 0) {
                    // Reset financial record to make sure the user selects a new one
                    this.selectedFinancialRecord = new FinancialRecord({});
                    this.setSelectedFinancialRecord(0);
                    this.fetchActiveFinancialRecordsWithParams(this.getSelectedBranchOffice.id, newRecordTypeId);
                }
            }
        },
        selectedProspectus: {
            handler(newItem) {
                if (newItem && newItem.prospectusId !== 0) {
                    this.setSelectedProspectus(newItem.prospectusId);
                    if (newItem.personTypeId === 1) {
                        this.fetchIndividualLoanApplicationsByProspectusId(newItem.prospectusId);
                    } else {
                        this.fetchCorpLoanApplicationsByProspectusId(newItem.prospectusId);
                    }
                }
            },
            immediate: true,
        },
        selectedLoanApplication: {
            handler(newItem) {
                if (newItem && newItem.id !== 0) {
                    this.setSelectedLoanApplication(newItem.id);
                }
            }
        },
    },
    created() { 
        this.fetchAllDocumentFiles();
        this.fetchRecordTypesByCompany();
        this.fetchActiveBranchOffices();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            this.selectedProspectus = new Prospectus();
            this.selectedFinancialRecord = new FinancialRecord();
            this.setSelectedProspectus(0);
            this.setSelectedBranchOffice(0);
            this.setSelectedRecordType(0);
            this.unsetActiveCheckLists();
            this.tempValues = {
                branchOfficeIsSelected: false,
                recordTypeIsSelected: false,
                branchOfficeId: null,
                recordTypeId: null,
                statusId: null,
                contentNumber: 0,
            };
        });
    }
};
</script>