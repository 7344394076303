export default class ExceptionPercent { 
    constructor({
        id = null,
        textValue = null,
        numberValue = null,
        createdAt = new Date(),
        updatedAt = new Date()
    }) {
        this.id = id || 0;
        this.textValue = textValue || null;
        this.numberValue = numberValue || null;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}