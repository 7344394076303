<template>
    <div class="d-flex justify-content-between">
        <h3 class="card-title">Lista de Autorización de Consulta de Buró de Crédito</h3>
        <div class="d-flex">
            <PrimaryButton class="btn btn-primary mr-3" data-toggle="modal" :data-target="modalDataTarget"
                @click="openLoanApplicationTable">
                Agregar Consulta de Buró
            </PrimaryButton>
        </div>
    </div>
    <br>

    <div class="position-relative col-sm-12">
        <BureauInquiryTable :list="getBureauInquiries" @click-on-edit-btn="setSelectedItem"></BureauInquiryTable>
        <Pagination :totalItems="getBureauInquiries.length" :currentPage="currentPage" @current-page="currentPage = $event"></Pagination>

        <Modal :modalId="modalId" :customClass="modalId">

            <div v-if="modalContent === 1">
                <LoanApplicationsMinTable :list="getMixedLoanApplications" @selected-item="setSelectedLoanApplication">
                </LoanApplicationsMinTable>
                <Pagination :totalItems="getMixedLoanApplications.length" :currentPage="currentPage" @current-page="currentPage = $event">
                </Pagination>
            </div>

            <BureauInquiryForm v-if="modalContent === 2"
                :initialData="initialData"
                :onSubmit="submitBureauInquiry" 
                :formTitle="formTitle" 
                :submitButtonText="buttonText" 
                :statusValues="getBureauInquiriesStatus"
                :editing="editing"
                @closeModal="modalStatus"
            ></BureauInquiryForm>

            <BureauInquiryForm v-if="modalContent === 3 && editing"
                :initialData="initialData"
                :fetchById="bureauInquiryById"
                :onSubmit="submitBureauInquiry" 
                :formTitle="formTitle" 
                :submitButtonText="buttonText" 
                :statusValues="getBureauInquiriesStatus"
                :editing="editing"
                @closeModal="modalStatus"
            ></BureauInquiryForm>
        </Modal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useBureauInquiryStore } from '@/pinia/LoanApplications/BureauInquiryStore.module';
import { useStatusStore } from '@/pinia/status.module';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import Pagination from '@/Components/Bootstrap/Paginationv2.vue';
import LoanApplicationsMinTable from '@/Components/Tables/LoanApplicationsMinTable.vue';
import BureauInquiryTable from '@/Components/Tables/BureauInquiryTable.vue';
import BureauInquiryForm from '@/Components/Forms/LoanApplications/Legal/BureauInquiryForm.vue';

import BureauInquiry from '@/Models/BureauInquiry';

export default {
    components: {
        PrimaryButton,
        Modal,
        Pagination,
        LoanApplicationsMinTable,
        BureauInquiryTable,
        BureauInquiryForm,
    },
    data() {
        return {
            modalId: 'modal-lg',
            modalDataTarget: '#modal-lg',
            editing: false,
            modalContent: 0,
            currentPage: 1,
            itemsPerPage: 10,
            selectedItem: {},
            selectedLoanApplication: {},
            bureauInquiry: new BureauInquiry(),
        }
    },
    computed: {
        ...mapState(useBureauInquiryStore, ['getBureauInquiries', 'getBureauInquiery']),
        ...mapState(useIndividualLoanApplicationStore, ['getMixedLoanApplications']),
        formTitle() {
            if (this.editing) {
                return this.$getLocaleMessage('formTitles', 'update');
            }
            return this.$getLocaleMessage('formTitles', 'create');
        },
        buttonText() {
            if (this.editing) {
                return this.$getLocaleMessage('buttons', 'update');
            }
            return this.$getLocaleMessage('buttons', 'create');
        },
        initialData() {
            return this.getBureauInquiery ? this.getBureauInquiery : new BureauInquiry();
        },
        getBureauInquiriesStatus() {
            return useStatusStore().getAllStatusByType(1);
        },
    },
    methods: {
        ...mapActions(useBureauInquiryStore, ['fetchAllBureauInquiries', 'fetchBureauInquiryById', 'createBureauInquiry', 'updateBureauInquiry']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchMixedLoanApplications']),
        modalStatus(status) {
            if (status) {
                $(this.modalDataTarget).modal('hide');
                this.modalContent = 0;
                this.editing = false;
            }
        },
        openLoanApplicationTable() {
            this.modalContent = 1;
            this.fetchMixedLoanApplications();
        },
        setSelectedLoanApplication(item) {
            this.selectedLoanApplication = item;
            this.modalContent = 2;
        },
        setSelectedItem(item) {
            this.selectedItem = item;
            this.modalContent = 3;
            this.editing = true;
        },
        bureauInquiryById() {
            this.fetchBureauInquiryById(this.selectedItem.id);
        },
        submitBureauInquiry(formData) {
            if (this.editing) {
                this.bureauInquiry = {
                    ...formData,
                }
                this.updateBureauInquiry(this.bureauInquiry);
            } else {
                this.bureauInquiry = {
                    ...formData,
                    loanApplicationId: this.selectedLoanApplication.id,
                }
                this.createBureauInquiry(this.bureauInquiry);
            }
        },
    },
    created() {
        this.fetchAllBureauInquiries();
        useStatusStore().fetchStatus();
    },
    mounted() {
        $(`#${this.modalId}`).on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
        });
    }
}
</script>