export default class certificateFieldResponses {
    constructor({
        id = 0,
        certificateResponseId = null,
        fieldId = null,
        answerText = null,
    } = {}) {
        this.id = id;
        this.certificateResponseId = certificateResponseId;
        this.fieldId = fieldId;
        this.answerText = answerText;
    }
}