<template>
  <Form ref="form" @submit="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">{{ $getLocaleMessage('formTitles', 'create') }} expediente</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row card-body">
        <div class="form-group col-sm-6 d-none">
          <div class="form-group">
            <label for="referenceNumber">Folio:</label>
            <Field
              v-model="financialRecord.referenceNumber"
              type="text"
              class="form-control"
              name="referenceNumber"
              placeholder="Folio"
              readonly
            />
          </div>
        </div>
        <div class="form-group col-sm-12">
          <label for="recordTypeId">Tipo de expendiente:</label>
          <RecordTypesSelect v-model="financialRecord.recordTypeId" :name="'recordTypeId'" :options="recordTypeOptions" rules="required"></RecordTypesSelect>
        </div>
        <div class="form-group col-sm-12">
          <label for="name">Nombre:</label>
          <Field
            v-model="financialRecord.name"
            type="text"
            class="form-control"
            name="name"
            placeholder="Expediente"
            rules="required"
          />
          <ErrorMessage name="name" class="input-error"></ErrorMessage>
        </div>
        <div class="form-group col-sm-12">
          <label for="statusId">Estatus:</label>
          <StatusSelect v-model="financialRecord.statusId" :name="'statusId'" :options="statusOptions" rules="required"></StatusSelect>
        </div>
      </div>
      
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        {{ $getLocaleMessage('buttons', 'cancel') }}
      </button>
      <PrimaryButton class="btn btn-primary" type="submit">
        {{ $getLocaleMessage('buttons', 'create') }}
      </PrimaryButton>
    </div>
  </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from "pinia";
import { financialRecordsStore } from "@/pinia/financialRecords.module";
import { useRecordTypesStore } from "@/pinia/recordTypes.module";
import { useStatusStore } from '@/pinia/status.module';

import PrimaryButton from "@/Components/PrimaryButton.vue";
import StatusSelect from '@/Components/Selects/SelectField.vue';
import RecordTypesSelect from '@/Components/Selects/SelectField.vue';

import FinancialRecord from '@/Models/FinancialRecord';

export default {
  components: { PrimaryButton, StatusSelect, RecordTypesSelect },
  mixins: [],
  data() {
    return {
      statusId: null,
      financialRecord: new FinancialRecord(),
    }
  },
  emits: ['close-modal'],
  computed: {
    ...mapState(useRecordTypesStore, ['getActiveRecordTypes']),
    ...mapState(useStatusStore, ['getAllStatusByType']),
    recordTypeOptions() {
      return helper.mapOptions(this.getActiveRecordTypes);
    },
    statusOptions() {
        return helper.mapOptions(this.getAllStatusByType(5));
    },
  },
  methods: {
    ...mapActions(financialRecordsStore, ['createFinancialRecord']),
    onSubmit() {
      this.createFinancialRecord(this.financialRecord);
      this.$refs.form.resetForm();
      this.closeModal();
    },
    closeModal() {
      this.$emit('close-modal', true);
    },
    setNewStatus(id) {
        this.financialRecord.statusId = id;
    },
    setNewRecordTypes(id) {
      this.financialRecord.recordTypeId = id;
    },
  },
  created() {},
  mounted() {},
};
</script>