import { defineStore } from 'pinia';
import { getLocaleMessage } from '@/services/MessagesService';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import FinancialFunder from '../Models/FinancialFunder';
import { useAuthStore } from '@/pinia/auth.module';

import * as service from '@/services';

const $toast = useToast();

export const useFinancialFundersStore = defineStore('financialFunder', {
    persist: false,
    state: () => {
        return {
            financialFunders: [],
            financialFunder: new FinancialFunder(),

            activeFinancialFunders: [],
            activeFinancialFunder: new FinancialFunder(),
            errors: [],
        }
    },
    getters: {
        getSelectedFinancialFunder: state => state.financialFunder,
        getFinancialFunders: state => state.financialFunders,
        getFinancialFunderById: state => (id = 0) => {
            if (typeof id === "undefined" || id === 0 || id === null) return {};
            return state.financialFunders.find(c => c.id === id);
        },

        getActiveFinancialFunders: state => state.activeFinancialFunders,
        getSelectedActiveFinancialFunder: state => state.activeFinancialFunder,
        getActiveFinancialFunderById: state => (id = 0) => {
            if (typeof id === "undefined" || id === 0 || id === null) return {};
            return state.activeFinancialFunders.find(c => c.id === id);
        },
        getPaginatedFinancialFunders: state => state.paginatedFinancialFunders,
        filterFundersBySearchString: state => (searchString = '') => {
            if (!searchString) return state.financialFunders;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.financialFunders.filter(funder => {

                const referenceNumber = funder.referenceNumber ? funder.referenceNumber.toLowerCase() : '';
                const anchor = funder.anchor ? funder.anchor.toLowerCase() : '';
                const observations = funder.observations ? funder.observations.toLowerCase() : '';
                const statusName = funder.status?.name ? funder.status?.name.toLowerCase() : '';

                return referenceNumber.includes(lowerCaseSearchString) ||
                    anchor.includes(lowerCaseSearchString) ||
                    observations.includes(lowerCaseSearchString) ||
                    statusName.includes(lowerCaseSearchString);
            });
        },
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        setSelectedFinancialFunder(id) {
            this.financialFunder = this.getFinancialFunderById(id);
        },
        setSelectedActiveFinancialFunder(id) {
            this.activeFinancialFunder = this.getActiveFinancialFunderById(id);
        },
        async fetchFinancialFundersByCompany() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.get(`/api/Funders/company/${useAuthStore().user.companyId}`);
                if (data) {
                    const financialData = data.data;
                    this.financialFunders = financialData.map((f) => new FinancialFunder(f));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchFinancialFunderById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.get(`/api/Funders/${id}`);
                if (data) {
                    this.financialFunder = new FinancialFunder(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar la fondeadora.");
            }
        },
        async fetchActiveFinancialFundersByCompany() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.get(`/api/Funders/company/${useAuthStore().user.companyId}/active`);
                if (data) {
                    const financialData = data.data;
                    this.activeFinancialFunders = financialData.map((f) => new FinancialFunder(f));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createFinancialFunder(financialFunder) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.post(`/api/Funders`, financialFunder);
                if (data) {
                    $toast.success(getLocaleMessage('funders', 'creatingSuccess'));
                    const financialData = data.data;
                    this.financialFunders.unshift(financialData);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateFinancialFunder(financialFunder) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.financialFundersApi.put(`/api/Funders`, financialFunder);
                if (data) {
                    $toast.success(getLocaleMessage('funders', 'editingSuccess'));
                    const financialData = new FinancialFunder(data.data);
                    const index = this.financialFunders.findIndex(f => f.id === financialData.id);
                    if (index !== -1) {
                        this.financialFunders.splice(index, 1, financialData);
                        this.financialFunders.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
    }
});