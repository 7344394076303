// Model class for Checklists
export default class FinancialRecordDocument {
    constructor({
        financialRecordDocumentId = 0,
        financialRecordId = 0,
        documentId = 0,
        statusId = 0,
        remarks = '',
        isRequired = false,
        financialRecord = null,
        document = null,
        file = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date(),
    } = {}) {
        this.financialRecordDocumentId = financialRecordDocumentId;
        this.financialRecordId = financialRecordId;
        this.documentId = documentId;
        this.statusId = statusId;
        this.remarks = remarks;
        this.isRequired = isRequired;
        this.financialRecord = financialRecord;
        this.document = document;
        this.file = file;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}