export default class Interview {
  constructor({
    id = 0, 
    loanApplicationId = 0, 
    interviewer = null, 
    authorizer = null, 
    createdAt = new Date(), 
    updatedAt = new Date()
}) {
    this.id = id || 0;
    this.loanApplicationId = loanApplicationId || 0;
    this.interviewer = interviewer || null;
    this.authorizer = authorizer || null;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
