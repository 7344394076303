<template>
    <div class="card">
        <div class="card-header border-0">
            <div class="d-flex justify-content-between">
                <h3 class="card-title">Checklist de Documentación</h3>
                <PrimaryButton v-if="hasSelection" class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                    @click="openCreationForm">
                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                </PrimaryButton>
            </div>
        </div>
        <div class="card-body">
            <div class="d-flex">
                <div class="form-group">
                    <label for="recordId">Seleccione un expediente:</label>
                    <FinancialRecordsSelect :name="'recordId'" :options="setActiveFinancialRecordsOptions"
                        @update="setNewFinancialRecord($event)"></FinancialRecordsSelect>


                    <br>
                    <label for="recordId">Buscar documento:</label>
                    <input type="text" class="form-control" placeholder="Buscar documentos..." v-model="searchString" />
                </div>



            </div>
            <!-- /.d-flex -->

            <div class="position-relative col-sm-12">
                <ChecklistsTable :list="filteredCheckList" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @click-on-edit-btn="openEditionForm">
                </ChecklistsTable>

                <Pagination :list="filteredCheckList" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @current-page="currentPage = $event">
                </Pagination>

                <Modal>
                    <AddChecklistForm v-if="modalContent == 5 && !editing" @close-modal="modalStatus">
                    </AddChecklistForm>
                    <EditChecklistForm v-if="modalContent == 6 && editing" :selected-record="selectedRecord"
                        @close-modal="modalStatus">
                    </EditChecklistForm>
                </Modal>

            </div>

        </div>
    </div>
    <!-- /.card -->
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { financialRecordsStore } from '@/pinia/financialRecords.module';
import { documentsStore } from '@/pinia/documents.module';
import { checklistsStore } from '@/pinia/checklists.module';

import PrimaryButton from "@/Components/PrimaryButton.vue";
import DocumentsTable from '@/Components/Tables/DocumentsTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import FinancialRecordsSelect from '@/Components/Selects/SelectField.vue';
import ChecklistsTable from '@/Components/Tables/ChecklistsTable.vue';
import AddChecklistForm from '@/Components/Forms/FinancialRecords/CheckLists/Add.vue';
import EditChecklistForm from '@/Components/Forms/FinancialRecords/CheckLists/Edit.vue';

export default {
    components: {
        PrimaryButton,
        DocumentsTable,
        Pagination,
        Modal,
        FinancialRecordsSelect,
        ChecklistsTable,
        AddChecklistForm,
        EditChecklistForm,
    },
    data() {
        return {
            itemsPerPage: 10,
            currentPage: 1,
            modalContent: 0,
            editing: false,
            selectedRecord: {},
            hasSelection: false,
            searchString: '',
        }
    },
    computed: {
        ...mapState(checklistsStore, ['getChecklists', 'getChecklist', 'filterCheckListBySearchString']),
        ...mapState(financialRecordsStore, ['getActiveFinancialRecords', 'getActiveFinancialRecord']),
        setActiveFinancialRecordsOptions() {
            return helper.mapOptions(this.getActiveFinancialRecords);
        },
        filteredCheckList() {
            return this.filterCheckListBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(financialRecordsStore, ['fetchActiveFinancialRecordsByCompany', 'setSelectedActiveFinancialRecord']),
        ...mapActions(documentsStore, ['fetchActiveDocumentsByCompany']),
        ...mapActions(checklistsStore, ['fetchChecklistsByRecordId', 'setSelectedChecklist', 'emptyTable']),
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        openCreationForm() {
            this.editing = false;
            this.modalContent = 5;
        },
        openEditionForm(selectedItem) {
            this.selectedRecord = JSON.parse(JSON.stringify(selectedItem));
            this.editing = true;
            this.modalContent = 6;
        },
        isEmptyObject(obj) {
            if (typeof obj === "undefined" || Object.keys(obj).length === 0) return true;
            return false;
        },
        setNewFinancialRecord(id) {
            this.currentPage = 1;
            if (id === "" || id === 0) {
                this.hasSelection = false;
                this.emptyTable();
                return;
            }
            this.hasSelection = true;
            this.fetchChecklistsByRecordId(id);
            this.setSelectedActiveFinancialRecord(id);
        },
    },
    created() {
    },
    mounted() {
        if (!helper.isEmptyObject(this.getChecklists)) {
            this.emptyTable();
        }
        this.fetchActiveFinancialRecordsByCompany();
        this.fetchActiveDocumentsByCompany();
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            if (!helper.isEmptyObject(this.getDocument)) {
                // Reset the selected item in the store to an empty object when finished editing
                this.setSelectedChecklist();
            }
        });
    }
}
</script>