<template>
    <div class="card">
        <div class="card-header border-0">
            <div class="d-flex justify-content-between">
                <h3 class="card-title">Lista de Documentos</h3>
                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg"
                    @click="openCreationForm">
                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                </PrimaryButton>
            </div>
        </div>
        <div class="card-body">
            <div class="d-flex">
                <input type="text" class="form-control" placeholder="Buscar Documentos..." v-model="searchString" />
            </div>
            <!-- /.d-flex -->

            <div class="position-relative col-sm-12">
                <DocumentsTable :list="filteredDocuments" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @click-on-edit-btn="openEditionForm">
                </DocumentsTable>

                <Pagination :list="filteredDocuments" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @current-page="currentPage = $event">
                </Pagination>

                <Modal>
                    <AddDocumentForm v-if="modalContent == 1 && !editing" @close-modal="modalStatus">
                    </AddDocumentForm>
                    <EditDocumentForm v-if="modalContent == 2 && editing" :selected-record="selectedRecord"
                        @close-modal="modalStatus">
                    </EditDocumentForm>
                </Modal>

            </div>

        </div>
    </div>
    <!-- /.card -->
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { documentsStore } from '@/pinia/documents.module';

import PrimaryButton from "@/Components/PrimaryButton.vue";
import DocumentsTable from '@/Components/Tables/DocumentsTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddDocumentForm from '@/Components/Forms/FinancialRecords/Documents/Add.vue';
import EditDocumentForm from '@/Components/Forms/FinancialRecords/Documents/Edit.vue';

export default {
    components: {
        PrimaryButton,
        DocumentsTable,
        Pagination,
        Modal,
        AddDocumentForm,
        EditDocumentForm,
    },
    data() {
        return {
            itemsPerPage: 10,
            currentPage: 1,
            modalContent: 0,
            editing: false,
            selectedRecord: {},
            searchString: '',
        }
    },
    computed: {
        ...mapState(documentsStore, ['getDocuments', 'getDocument', 'filterDocumentsBySearchString']),
        filteredDocuments() {
            return this.filterDocumentsBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(documentsStore, ['fetchDocumentsByCompany', 'setSelectedDocument']),
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        openCreationForm() {
            this.editing = false;
            this.modalContent = 1;
        },
        openEditionForm(selectedItem) {
            this.selectedRecord = JSON.parse(JSON.stringify(selectedItem));
            this.editing = true;
            this.modalContent = 2;
        },
    },
    created() {
    },
    mounted() {
        this.fetchDocumentsByCompany();
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            if (!helper.isEmptyObject(this.getDocument)) {
                // Reset the selected item in the store to an empty object when finished editing
                this.setSelectedDocument();
            }
        });
    }
}
</script>