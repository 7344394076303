import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useHandlerStore } from '@/pinia/handler.module';
import { useAuthStore } from '@/pinia/auth.module';
import CertificateOfAcknowledgment from '@/Models/Certificates/CertificateOfAcknowledgment';
import Question from '@/Models/Certificates/CertificateQuestion.js';
import { getLocaleMessage } from '@/services/MessagesService';
import * as service from '@/services';

const $toast = useToast();

export const useCertificateOfAcknowledgment = defineStore('certificateOfAcknowledgment', {
    persist: false,
    state: () => {
        return {
            certificatesOfAcknowledgment: [],
            certificateOfAcknowledgment: new CertificateOfAcknowledgment(),

            questions: [],
            errors: [],
        }
    },
    getters: {
        getCertificates: state => state.certificatesOfAcknowledgment,
        getCertificate: state => state.certificateOfAcknowledgment,
        getCertificateQuestions: state => state.questions,
        getQestionById: state => (id = 0) => (id ? state.questions.find(q => q.id === id) : {}) ?? {},
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        async fetchCertificates() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.certificatesApi.get(`/api/Certificates/Company/${useAuthStore().user.companyId}`);
                if (data && data.status) {
                    const certificates = data.data;
                    this.certificatesOfAcknowledgment = certificates.map(certificate => new CertificateOfAcknowledgment(certificate));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchCertificateQuestions() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.certificatesApi.get(`/api/Questions`);
                if (data && data.status) {
                    const questions = data.data;
                    this.questions = questions.map(q => new Question(q));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchCertificateById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.certificatesApi.get(`/api/Certificates/${id}`);
                if (data && data.status) {
                    this.certificateOfAcknowledgment = new CertificateOfAcknowledgment(data.data);
                    this.questions = this.certificateOfAcknowledgment.questions.map(q => new Question(q));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createCertificate(certificateOfAcknowledgment) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.certificatesApi.post(`/api/Certificates`, certificateOfAcknowledgment);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('financialRecords', 'creatingSuccess'));
                    const newCertificate = new CertificateOfAcknowledgment(data.data);
                    this.certificatesOfAcknowledgment.unshift(newCertificate);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateCertificate(certificateOfAcknowledgment) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.certificatesApi.put(`/api/Certificates`, certificateOfAcknowledgment);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('financialRecords', 'editingSuccess'));
                    const updatedCertificate = new CertificateOfAcknowledgment(data.data);
                    const index = this.certificatesOfAcknowledgment.findIndex(c => c.id === updatedCertificate.id);
                    if (index !== -1) {
                        this.certificatesOfAcknowledgment.splice(index, 1, updatedCertificate);
                        this.certificatesOfAcknowledgment.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
    }
});