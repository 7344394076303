<template>
    <Form ref="certificate" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                Constancia de Conocimiento de la existencia del Beneficiario Controlador
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div v-for="(question, idx) in getCertificateQuestions" :key="question.id" class="form-group col-sm-12">
                    <label :for="`question${idx}`">{{ question.questionText }}</label>
                    <div v-for="(field, i) in question.fields" :key="field.id">
                        <SelectField v-model="selectValues[question.id][field.id]" :name="`question${idx}`" :options="questionOptions(field.selectOptions)" @update="handleFieldChange(question.id, field.id, $event)">
                        </SelectField>
                    </div>
                </div>
                <FieldGroup v-model="certificate.respondentFullName" name="respondentFullName" colClass="col-sm-12" :label="clientLabel"
                    placeholder="Nombre Completo" rules="required|max:255">
                </FieldGroup>
                <FieldGroup v-model="certificate.address" name="address" colClass="col-sm-12 col-md-6" label="Lugar:"
                    placeholder="Lugar" rules="required|max:300">
                </FieldGroup>
                <!-- <FieldGroup v-model="certificate.createdAt" name="createdAt" colClass="col-sm-12 col-md-6" label="Fecha:" type="date"
                    rules="required">
                </FieldGroup> -->
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ createButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';
import { mapState, mapActions } from 'pinia';
import { useCertificateOfAcknowledgment } from '@/pinia/certificateOfAcknowledgment.module';
import Certificate from '@/Models/Certificates/CertificateOfAcknowledgment';
import CertificateResponse from '@/Models/Certificates/CertificateResponse';
import CertificateFieldResponse from '@/Models/Certificates/CertificateFieldResponse';

import FieldGroup from '@/Components/Fields/FieldGroup.vue';
import SelectField from '@/Components/Selects/SelectField.vue';
import Question1 from '@/Components/Selects/SelectField.vue';
import Question2 from '@/Components/Selects/SelectField.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        SelectField,
        Question1,
        Question2,
        FieldGroup,
        PrimaryButton
    },
    props: {
        loanApplicationId: {
            type: Number,
            required: true,
        },
        personTypeId: {
            type: Number,
            required: true,
        },
        clientLabel: {
            type: String,
            required: true,
        },
    },
    emits: ['close-modal', "update:modelValue"],
    data() {
        return {
            certificate: new Certificate({
                loanApplicationId: this.loanApplicationId,
            }),
            selectValues: {},
        }
    },
    computed: {
        ...mapState(useCertificateOfAcknowledgment, ['getCertificateQuestions', 'getQestionById']),
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel')
        },
        createButtonText() {
            return this.$getLocaleMessage('buttons', 'create')
        },
    },
    methods: {
        ...mapActions(useCertificateOfAcknowledgment, ['fetchCertificateQuestions', 'createCertificate']),
        onSubmit() {
            // Add form submission logic here
            this.certificate.questions = this.getCertificateQuestions;
            this.createCertificate(this.certificate);
            this.$refs.certificate.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
        emitSelectedValue() {
            this.$emit('update:modelValue', this.selectedValue);
        },
        questionOptions(items) {
            return helper.mapOptions(items, 'optionValue', 'optionText');
        },
        // Inicializar selectValues con ids de preguntas y selectFields
        initializeSelectValues() {
            if (this.getCertificateQuestions.length > 0) {
                this.getCertificateQuestions.forEach(question => {
                    // Inicializamos el objeto para la pregunta
                    if (!this.selectValues[question.id]) {
                        this.selectValues[question.id] = {};
                    }
        
                    // Recorremos cada selectField y lo inicializamos
                    question.fields.forEach((selectField) => {
                        // Busca si hay una respuesta almacenada para este field
                        const fieldResponse = question.certificateResponses?.certificateFieldResponses.find(
                            (response) => response.fieldId === field.id
                        );

                        // Si hay una respuesta, la asigna como el valor preseleccionado
                        if (fieldResponse) {
                            this.selectValues[question.id][field.id] = fieldResponse.answerText;
                        }
                    });
                });
            }
        },
        handleFieldChange(questionId, fieldId, newValue) {
            // Encuentra la pregunta actual
            const question = this.getQestionById(questionId);

            if (question) {
                // Encuentra la respuesta de la pregunta si existe
                let certificateResponse = question.certificateResponses;

                // Si no existe aún la respuesta de este field, inicializa el objeto
                if (!certificateResponse) {
                    certificateResponse = new CertificateResponse({
                        questionId: question.id,
                        certificateFieldResponses: []
                    });
                    question.certificateResponses = certificateResponse;
                }

                // Encuentra el field response correspondiente a este campo
                let fieldResponse = certificateResponse.certificateFieldResponses.find(
                    (fr) => fr.fieldId === fieldId
                );

                // Si no existe, crea un nuevo Field Response para este campo
                if (!fieldResponse) {
                    fieldResponse = new CertificateFieldResponse({
                        fieldId: fieldId,
                        answerText: newValue, // Aquí establecemos la respuesta seleccionada
                    });
                    certificateResponse.certificateFieldResponses.push(fieldResponse);
                } else {
                    // Si ya existe, actualiza el valor seleccionado
                    fieldResponse.answerText = newValue;
                }
            }
        },
    },
    watch: {
        getCertificateQuestions(newVal) {
            if (newVal.length > 0) {
                this.initializeSelectValues();
            }
        }
    },
    created() {
        this.fetchCertificateQuestions();
        this.initializeSelectValues();
    },
}
</script>