export default class Beneficiary {
    constructor( {
        id = 0,
        loanApplicationId = null,
        branchOfficeId = null,
        statusId = null,
        referenceNumber = null,
        loanApplication = null,
        branchOffice = null,
        generalInformation = null,
        status = null,
        createdAt = new Date(),
        updatedAt = new Date(),
    } = {}) {
        this.id = id;
        this.loanApplicationId = loanApplicationId;
        this.branchOfficeId = branchOfficeId;
        this.statusId = statusId;
        this.referenceNumber = referenceNumber;
        this.loanApplication = loanApplication;
        this.branchOffice = branchOffice;
        this.generalInformation = generalInformation;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}