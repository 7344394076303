export default class User {
    constructor({
      id = 0,
      companyId = null,
      statusId = null,
      roleIds = null,
      email = '',
      password = '',
      name = '',
      lastName = '',
      jobTitle = '',
      workArea = '',
      phone = '',
      remarks = '',
      roles = null,
      company = null,
      menuItems = null,
      status = null,
      createdAt = new Date(),
      updatedAt = new Date(),
    } = {}) {
      this.id = id;
      this.companyId = companyId;
      this.statusId = statusId;
      this.roleIds = roleIds;
      this.email = email;
      this.password = password;
      this.name = name;
      this.lastName = lastName;
      this.jobTitle = jobTitle;
      this.workArea = workArea;
      this.phone = phone;
      this.remarks = remarks;
      this.roles = roles;
      this.company = company;
      this.menuItems = menuItems;
      this.status = status;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }
  }
  