<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Cotizaciones</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Cotizaciones</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Cotizaciones</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg" @click="contentNumber = 1">
                                    Generar Nueva Cotización
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <ul>
                                    <li>Folio Cotización: {{ getQuotation.referenceNumber }}</li>
                                    <li>Tipo de crédito: {{ this.productType }}</li>
                                    <li>Nombre del prospecto: {{ this.prospectusFullName }}</li>
                                    <li>Fecha de disposición del crédito: {{ disburmentDateFormat(getQuotation.disbursementDate) }}</li>
                                    <li>Monto del préstamo: {{ pesosFormat(getQuotation.creditAmount) }}</li>
                                    <li>Plazo total: {{ getQuotation.totalTerm }}</li>
                                    <li>Período de gracia: {{ getQuotation.gracePeriod }}</li>
                                    <li>Plazo: {{ getTotalTermWithGracePeriod }}</li>
                                    <li>Tasa Anual sin IVA %: {{ decimalToPercentFormat(getQuotation.annualRate) }}</li>
                                    <li>Tasa Anual con IVA %: {{ decimalToPercentFormat(getQuotation.annualRateWithVat) }}</li>
                                    <li>Tasa {{ period }} sin IVA %: {{ decimalToPercentFormat(getQuotation.periodRate) }}</li>
                                    <li>Tasa {{ period }} con IVA %: {{ decimalToPercentFormat(getQuotation.periodRateWithVat) }}</li>
                                    <li>Pago {{ period }}: {{ pesosFormat(getTotalInstallment(getQuotation.gracePeriod)) }}</li>
                                    <li>Comisión por apertura %: {{ decimalToPercentFormat(getQuotation.openingCommission) }}</li>
                                    <li>CAT con IVA %: {{ decimalToPercentFormat(getQuotation.aprWithVat) }}</li>
                                    <li>CAT sin IVA %: {{ decimalToPercentFormat(getQuotation.apr) }}</li>
                                </ul>

                                <QuotationDetails 
                                :list="loanAmortization" 
                                :totals="getQuotation.amortizationTotal"
                                :referenceNumber="getQuotation.referenceNumber">
                                </QuotationDetails>

                                <Modal>
                                    <AddQuotationForm v-if="contentNumber == 1"
                                        @changeModalContent="setContentNumber"
                                        :prospectus="selectedProspectus"
                                        :product="selectedProduct"
                                        @prodType="productType = $event"
                                        @periodName="period = $event"
                                        @prospectusFullName="prospectusFullName = $event"
                                        @closeModal="modalStatus">
                                    </AddQuotationForm>

                                    <div v-else-if="contentNumber == 2">
                                        <ProspectsTable 
                                            @back-to-form="backToForm($event)"
                                            @selectedProspectus="selectedProspectus = $event"
                                            :list="getAllProspects"
                                            :itemsPerPage="itemsPerPage"
                                            :currentPage="currentPage">
                                        </ProspectsTable>
                                        <Pagination 
                                        :list="getAllProspects" 
                                        :itemsPerPage="itemsPerPage" 
                                        :currentPage="currentPage" 
                                        @current-page="currentPage = $event">
                                        </Pagination>
                                    </div>

                                    <div v-else-if="contentNumber == 3">
                                        <ProductsTable 
                                            @back-to-form="backToForm($event)"
                                            @selectedItem="getSelectedProduct"
                                            :list="getActiveProducts"
                                            :itemsPerPage="itemsPerPage"
                                            :currentPage="currentPage">
                                        </ProductsTable>
                                        <Pagination 
                                        :list="getActiveProducts" 
                                        :itemsPerPage="itemsPerPage" 
                                        :currentPage="currentPage" 
                                        @current-page="currentPage = $event">
                                        </Pagination>
                                    </div>

                                </Modal>

                            </div>

                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import '@/Components/Validators/index';
import { mapState, mapActions } from 'pinia';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useTaxesStore } from '@/pinia/taxes.module';
import { useProductsStore } from '@/pinia/products.module';
import { useQuotationsStore } from '@/pinia/quotations.module';

import Product from '@/Models/Product';
import Prospectus from '@/Models/Prospects';

import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import ProspectsTable from '@/Components/Tables/ProspectsMinTable.vue';
import ProductsTable from '@/Components/Tables/ProductsMinTable.vue';
import AddQuotationForm from '@/Components/Forms/Quotations/Add.vue';
import QuotationDetails from '@/Components/Tables/QuotationDetailsTable.vue';

export default {
    components: {
        ProspectsTable,
        ProductsTable,
        Pagination,
        Modal,
        AddQuotationForm,
        PrimaryButton,
        QuotationDetails
    },
    data() {
        return {
            selectedRecord: {},
            selectedProspectus: new Prospectus({}),
            selectedProduct: new Product({}),
            productType: '',
            prospectusFullName: '',
            annualRateWithVat: '',
            period: 'periodo',
            contentNumber: 0,
            currentPage: 1,
            itemsPerPage: 10,
        }
    },
    computed: {
        ...mapState(useIndividualProspectsStore, ['getAllProspects']),
        ...mapState(useQuotationsStore, ['getProspects', 'getQuotation']),
        ...mapState(useProductsStore, ['getActiveProducts']),
        loanAmortization() {
            let value;
            try {
                value = this.getQuotation.loanAmortization;
            } catch (error) {
                value = [];
            }
            return value;
        },
        getTotalTermWithGracePeriod() {
            let value;
            try {
                value = this.getQuotation.totalTerm - this.getQuotation.gracePeriod;
            } catch (error) {
                value = null;
            }
            return value;
        },
    },
    methods: {
        ...mapActions(useIndividualProspectsStore, ['fetchActiveMixedProspects']),
        ...mapActions(useTaxesStore, ['fetchActiveTaxes']),
        ...mapActions(useProductsStore, ['fetchActiveProductsByBranchOfficeId', 'deleteProduct']),
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        backToForm() {
            this.contentNumber = 1;
        },
        setContentNumber(number) {
            if (number == 2) {
                // When select a new Prospectus we must empty the Product state and make user select a new Product
                this.selectedProduct = {};
            }
            this.contentNumber = number;
        },
        getSelectedProduct(product) {
            this.selectedProduct = product;
        },
        getSelectedProspectus(p) {
            this.selectedProspectus = p;
            this.prospectus = p;
        },
        disburmentDateFormat(value) {
            return  this.$filters.formatDate(value, "dd 'de' MMMM 'del' yyy");
        },
        pesosFormat(value) {
            return this.$filters.formatNumber(value);
        },
        decimalToPercentFormat(value) {
            return this.$filters.formatPercent(value);
        },
        getTotalInstallment(gracePeriod) {
            let value;
            try {
                // We select the first installment after grace period is the grace period + 1
                // Seleccionamos el primer pago después del periodo de gracia es el periodo de gracia + 1
                value = this.getQuotation.loanAmortization[gracePeriod+1].totalInstallment;
            } catch (error) {
                value = 0;
            }
            return value;
        },
    },
    watch: {
        selectedProspectus(p) {
            if (p?.branchOfficeId) {
                this.fetchActiveProductsByBranchOfficeId(p.branchOfficeId);
                this.fetchActiveTaxes(p.branchOfficeId);
            }
        },
    },
    created() {
        this.fetchActiveMixedProspects();
     },
    mounted() { }
}
</script>